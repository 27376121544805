<template>
    <div class="user-requirement-file-to-fill-edit">
        <NewDownloadable
            v-if="canDownload"
            :company-file="userRequirement.company_file"
            :is-requirement="true"
            :inline="true"
        />
        <div v-else>
            <HtBadge
                size="large"
            >
                <HtIndicator status="error" />
                <t>To fill</t>
            </HtBadge>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NewUserRequirementFileToFillRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        canDownload() {
            return this.userRequirement.company_file
                && this.userRequirement.company_file.id;
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementFileToFillEdit.scss" scoped />
