import Vue from 'vue';
import api from '@/store/api';

const SET_RESET = 'set_reset';
const SET_CONFIGURATIONS = 'set_configurations';

export default {
    namespaced: true,

    state() {
        return {
            data: [],
            dataLoadedAt: null,
        };
    },

    getters: {
        getConfigurations({ data }) {
            return data;
        },
        getLoadedAt({ dataLoadedAt }) {
            return dataLoadedAt;
        },
        isLoaded({ dataLoadedAt }) {
            return dataLoadedAt !== null;
        },
    },

    actions: {
        fetchConfigurations({ commit }) {
            commit(SET_RESET);

            return api.configuration.configurations.fetchAll().then(({ data }) => {
                commit(SET_CONFIGURATIONS, data.data);
            });
        },
        update({ commit }, data) {
            return api.configuration.configurations.update(data.id, data).then(({ data }) => {
                commit(SET_CONFIGURATIONS, [data.data]);
            });
        },
    },

    mutations: {
        [SET_RESET](state) {
            state.dataLoadedAt = null;
        },
        [SET_CONFIGURATIONS](state, data) {
            state.data = data;
            state.dataLoadedAt = Vue.prototype.$moment();
        },
    },
};
