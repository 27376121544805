<template>
    <div
        v-if="
            userRequirement.value
                && userRequirement.value.owner.length
                && userRequirement.value.iban.length
                && userRequirement.value.bic.length
        "
        class="copy-clipboard"
    >
        <span>{{ userRequirement.value.owner }}</span>&nbsp;
        <span>{{ userRequirement.value.iban }}</span>&nbsp;
        <span>{{ userRequirement.value.bic }}</span>
        <HtTooltip
            align="center"
            position="block-start"
            class="btn-copy"
        >
            <button
                type="button"
                @click="copyClipboard"
            >
                <HtIcon
                    name="copy"
                    size="16"
                    stroke-width="2"
                />
            </button>
            <template #target>
                <span v-if="!copied"><t>Click to copy</t></span>
                <span v-else><t>Copied!</t></span>
            </template>
        </HtTooltip>
    </div>
    <div v-else>
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
</template>

<script>

export default {
    name: 'NewUserRequirementIbanRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            copied: false,
        };
    },
    methods: {
        copyClipboard() {
            if (!this.userRequirement.value.iban) return;
            navigator.clipboard.writeText(this.userRequirement.value.iban);
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.copy-clipboard {
    display: inline-flex;
    align-items: center;
}
.btn-copy {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    .ht-icon {
        position: relative;
        top: -1px;
    }
}
</style>
