<template>
    <router-link
        :to="{ name: 'Company', label: 'Company'}"
        :style="headbarStyle"
        class="wrapper-company"
    >
        <h3 class="company-title">
            <t>Discover the presentation of my company</t>
        </h3>
        <span class="discover-company"><t>Discover now</t></span>
    </router-link>
</template>

<script>

export default {
    name: 'CompanyWidget',
    computed: {
        headbarStyle() {
            const image = this.$Utils.resolveS3PublicImage(this.shared.session.company.cover);

            return {
                backgroundImage: `url(${image})`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.wrapper-company {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 1 / 0.6;
    padding: 20px;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    color: var(--text-inversed-primary);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    overflow: hidden;
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    text-decoration: none;
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.7;
    }
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
}
.company-title {
    position: relative;
    z-index: 2;
    @include ht-heading-3;
    margin: 0;
}
.discover-company {
    position: relative;
    margin-top: 4px;
    @include ht-body;
    color: var(--text-inversed-primary);
    z-index: 2;
    text-decoration: underline;
    > span {
        &::after {
            content: '\00a0›';
        }
    }
}
</style>
