<template>
    <div
        class="timeline-menu"
    >
        <div class="timeline-text-progress">
            <div class="timeline-menu-text">
                <div class="timeline-menu-title">
                    {{ translatedTitle }}
                </div>
                <div class="timeline-menu-subtitle">
                    <t :count="countTask">
                        {count} action to do
                    </t>
                </div>
            </div>
            <div
                v-if="showDone"
                class="timeline-progress"
            >
                <div
                    class="timeline-progress-bar"
                    :style="'width: '+((countAllTasks - countTask) / countAllTasks)*100+'%'"
                />
            </div>
        </div>
        <div class="timeline-arrow-checkbox">
            <HtIcon
                name="nav-arrow-right"
                size="24"
                class="toggle-icon"
            />
            <HtCheckbox
                v-show="showMultiSelect"
                class="timeline-checkbox"
                :value="isSelected"
                @input="onChange"
            />
        </div>
    </div>
</template>

<script>
import HtCheckbox from '@/components/globals/HtCheckbox.vue';

export default {
    name: 'ActionProgramTimelineTitle',
    components: {
        HtCheckbox,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        countAllTasks: {
            type: Number,
            default: 0,
        },
        countTask: {
            type: Number,
            default: 0,
        },
        showMultiSelect: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
        programId: {
            type: Number,
            required: true,
        },
        showDone: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        translatedTitle() {
            return this.translate(this.title, {
                key_date_name: this.mainKeyDateName,
            });
        },
        mainKeyDateName() {
            const storePrograms = this.$store.state.programs.programs;
            const keyDates = storePrograms.find((program) => program.id === this.programId).key_dates;
            const mainKeyDate = keyDates.find((keyDate) => keyDate.is_main);
            return mainKeyDate.name;
        },
    },

    methods: {
        onChange(el) {
            this.$emit('onSelectAll', el);
        },
    },
};

</script>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/typography";

.timeline-menu {
    position: relative;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    &-title {
        @include ht-heading-4;
    }

    &-subtitle {
        @include ht-caption;
        color: var(--text-secondary);
    }
}
.timeline-text-progress {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.timeline-menu-text {
    flex: 1 1 auto;
}
.timeline-progress {
    flex: 1 1 auto;
    display: flex;
    height: 8px;
    border-radius: var(--radius-full);
    background-color: var(--fill-secondary);
}
.timeline-progress-bar {
    height: 100%;
    border-radius: var(--radius-full);
    background-color: var(--fill-success);
    box-shadow: 0px 0px 4px 0px rgba(83, 180, 131, 0.60), 0px 0px 13px 0px rgba(54, 255, 154, 0.40);
}
.timeline-arrow-checkbox {
    display: flex;
    align-items: center;
    .toggle-icon, .timeline-checkbox {
        flex: 0 0 auto;
    }
}
.toggle-icon {
    transition: var(--transition-s);
    .opened & {
        transform: rotate(90deg);
    }
}
.timeline-checkbox {
    margin-left: 16px;
}

@media (min-width: $tablet){
    .timeline-menu {
        padding: 20px;
    }
}
@media (min-width: $desktop-small){
    .timeline-text-progress {
        flex-direction: row;
        align-items: center;
    }
    .timeline-progress {
        flex: 0 0 auto;
        width: 260px;
    }
}
</style>
