<template>
    <div class="list-tasks">
        <template v-if="(!loading || users.length)">
            <HtSearchField
                id="search-user"
                v-model.trim="search"
                type="text"
                :placeholder="translate('Search')"
                name="search-user"
                cypress="search-user"
                class="search"
            />
            <transition-group
                v-if="users.length"
                ref="masonryTransition"
                name="list"
                tag="masonry"
            >
                <TasksListByUserItem
                    v-for="user in users"
                    v-show="filteredUsers.includes(user)"
                    :key="`user-${user.id}`"
                    :user="user"
                    :auto-load-tasks="autoLoadTasks"
                    class="user-item"
                    :add-button="canCreateResourceForUser(user.id)"
                    :is-history="isHistory"
                />
            </transition-group>
            <div
                v-else
                class="list-tasks-no-task"
            >
                <t v-if="isHistory">
                    You haven't completed any actions
                </t>
                <t v-else>
                    You are up to date on your actions
                </t>
            </div>
        </template>
        <div v-else>
            <HtIconLoading />
        </div>
    </div>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import TasksListByUserItem from './TasksListByUserItem.vue';

export default {
    name: 'TasksListByUser',
    permissions: [
        'ModelCompanyPlanningEvent',
        'ModelCompanyUserDoc',
        'ModelCompanyUserDocument',
        'ModelCompanyUserEmailCustom',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserNudge',
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserQuiz',
        'ModelCompanyUserRequirementCategory',
        'ModelCompanyUserSoftware',
        'ModelCompanyUserSurvey',
    ],
    components: { TasksListByUserItem, HtSearchField },
    props: {
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            users: [],
            loading: false,
            search: '',
            limitNbUserWithTaskToLoad: 10,
        };
    },
    computed: {
        filteredUsers() {
            const search = this.$Utils.removeDiacritics(this.search).toLowerCase();
            return this.users.filter((user) => {
                const firstname = this.$Utils.removeDiacritics(user.firstname).toLowerCase();
                const lastname = this.$Utils.removeDiacritics(user.lastname).toLowerCase();
                return `${firstname} ${lastname}`.includes(search)
                    || `${lastname} ${firstname}`.includes(search);
            });
        },
        autoLoadTasks() {
            return this.users
                .filter((user) => user.nb_tasks)?.length < this.limitNbUserWithTaskToLoad;
        },
    },
    created() {
        this.getUsers();
    },
    methods: {
        setMasonryProps() {
            this.$nextTick(() => {
                // allows to use transition group with masonry
                const masonryComponent = this.$refs.masonryTransition.$children[0];
                // eslint-disable-next-line no-underscore-dangle
                masonryComponent._props = {
                    // eslint-disable-next-line no-underscore-dangle
                    ...masonryComponent._props,
                    cols: { default: 1 },
                    gutter: 16,
                };
            });
        },
        async getUsers() {
            this.loading = true;
            await this.$store.dispatch(this.isHistory ? 'tasks/fetchHistoryUsersAndEmployees' : 'tasks/fetchCurrentUsersAndEmployees')
                .then((result) => {
                    this.users = result.data.data;
                    this.loading = false;
                    if (this.users.length) {
                        this.setMasonryProps();
                    }
                });
        },
        canCreateResourceForUser(userId) {
            for (let i = 0; i < this.$options.permissions?.length; i += 1) {
                const permission = this.$options.permissions[i];
                if (this.$canCreate(permission, { company_user_id: userId })) return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" src="../tasksList.scss" scoped />
<style lang="scss" scoped>
@import "~@/styles/transition-list";
.user-item{
    margin-bottom: 16px;
}
.search{
    width: fit-content;
    margin-bottom: 8px;

}
</style>
