<template>
    <NewDownloadable
        v-if="canDownload"
        :company-file="userRequirement.company_file"
        :is-requirement="true"
        :inline="true"
    />
    <div v-else>
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
</template>

<script>

export default {
    name: 'NewUserRequirementFileRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
    computed: {
        canDownload() {
            return this.userRequirement.company_file
                && this.userRequirement.company_file.id;
        },
    },
};
</script>
