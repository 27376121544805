<template>
    <div
        v-if="isLoaded"
        class="full-width"
    >
        <HtCard class="mb-5">
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="true"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="$emit('on-change-step', 'SelectResourceTypeModal')"
                />
            </template>
            <template #default>
                <EmailsList
                    :items="resourceCollection.$.models"
                    :external-search="search"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import ResourceFilter from '@/components/resources/ResourceFilter.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import EmailsList, { emailConfig } from '@/resourcesList/EmailsList.vue';

export default {
    name: 'EmailCustom',
    components: {
        ResourceFilter,
        HtCardTitleBar,
        EmailsList,
    },

    mixins: [
        ProgramBuilderListResourceMixin,
    ],

    data() {
        return {
            collectionName: 'CompanyEmailCustomTemplateCollection',
            resourceTitleSingular: 'email',
            resourceTitlePlural: 'emails',
            itemFields: emailConfig.fields,
            itemRelations: emailConfig.relations,
        };
    },

    methods: {
        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                resource: (query) => {
                    query.whereHas({
                        programs: (query) => {
                            query.where([
                                ['company_program_id', '!=', this.companyProgramId],
                            ]);
                        },
                    });
                },
            });
        },

        AddCollectionOrderBy() {
            this.resourceCollection.orderBy([
                ['name', 'asc'],
            ]);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.email-recipients {
    margin-left: 1.5em;
}

.notification-section {
    margin-top: 2em;
}

.notification-section-label {
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    font-size: 2rem;
}
</style>
