<template>
    <div class="company-settings-informations">
        <h2 class="align-center">
            <t>General information</t>
        </h2>
        <form
            v-if="company.isLoaded()"
            class="form-2 spaced"
            action=""
            @submit.prevent="'false'"
        >
            <SwitchNewUi />

            <h3 class="small">
                <t>Set up the page in several languages</t>
            </h3>
            <HtLanguageSelect
                id="company-language"
                :label="''"
                :value="shared.session.company.company_languages.enabled.find(language => language.key === editingLang)"
                name="company-language"
                :custom-option-label="customLanguageOptionLabel"
                @input="v => $emit('update:editing-lang', v.key)"
            />

            <company-name
                v-show="editingLang === shared.session.company.company_languages.default.key"
                :is-saving-company="isSavingCompany"
                :errors="errors"
                :value.sync="company.name"
                @on-update="onUpdate"
            />

            <div v-show="editingLang === shared.session.company.company_languages.default.key">
                <h3 class="small">
                    <t>Full logo</t>
                </h3>
                <fieldset>
                    <div class="photo-edit-wrapper">
                        <div class="photo-edit">
                            <div class="logo-zone">
                                <Croppable
                                    v-show="show_logo_croppable"
                                    ref="croppable_logo"
                                    style="display: flex;justify-content: center;"
                                    :show-width="426"
                                    :show-height="127"
                                    :dest-width="250"
                                    :dest-height="250"
                                    :auto-scale="true"
                                    save-url="company_file"
                                    :image="$Utils.resolveS3PublicImage(company.image)"
                                    @onChange="onLogoChange"
                                    @onUploadEnd="onLogoSave"
                                />
                                <img
                                    v-show="!show_logo_croppable"
                                    :src="$Utils.resolveS3PublicImage(company.image)"
                                    alt=""
                                >
                            </div>
                            <div v-if="show_logo_croppable">
                                <a
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="show_logo_croppable=false"
                                ><t>Cancel</t></a>
                                &nbsp;
                                <a
                                    v-if="show_logo_save"
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="$refs.croppable_logo.onSave()"
                                ><t>Save</t></a>
                            </div>
                            <a
                                v-else
                                class="button button-secondary"
                                @click=" $refs.croppable_logo.onOpenFileSelector(); show_logo_croppable=true;"
                            ><t>Change</t></a>
                        </div>
                        <div class="photo-edit-infos">
                            <p>
                                <strong><t>Instructions</t></strong><br>
                                <t>Use a square or rectangular image</t><br>
                                <t>Max height : 60px</t><br>
                                <t>Leave some space around your icon</t><br>
                                <t>Use .jpeg or .png format</t><br>
                                <t>Use a solid background color</t><br>
                                <t>Use a graphical logo or image rather than text</t><br>
                            </p>
                            <p class="mt-10">
                                <strong><t>This logo is used in all e-mails</t></strong><br>
                            </p>
                        </div>
                    </div>
                </fieldset>

                <!-- LOGO SMALL -->
                <h3 class="small">
                    <t>Square logo</t>
                </h3>

                <fieldset>
                    <div class="photo-edit-wrapper">
                        <div class="photo-edit">
                            <div class="logo-zone">
                                <Croppable
                                    v-show="show_logo_square_croppable"
                                    ref="croppable_logo_square"
                                    style="display: flex;justify-content: center;"
                                    :show-width="127"
                                    :show-height="127"
                                    :dest-width="250"
                                    :dest-height="250"
                                    save-url="company_file"
                                    :image="$Utils.resolveS3PublicImage(company.image_square)"
                                    @onChange="onLogoSquareChange"
                                    @onUploadEnd="onLogoSquareSave"
                                />
                                <img
                                    v-show="!show_logo_square_croppable"
                                    :src="$Utils.resolveS3PublicImage(company.image_square)"
                                    alt=""
                                >
                            </div>
                            <div v-if="show_logo_square_croppable">
                                <a
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="show_logo_square_croppable=false"
                                ><t>Cancel</t></a>
                                &nbsp;
                                <a
                                    v-if="show_logo_square_save"
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="$refs.croppable_logo_square.onSave()"
                                ><t>Save</t></a>
                            </div>
                            <a
                                v-else
                                class="button button-secondary"
                                @click=" $refs.croppable_logo_square.onOpenFileSelector(); show_logo_square_croppable=true;"
                            ><t>Change</t></a>
                        </div>
                        <div class="photo-edit-infos">
                            <p>
                                <strong><t>Instructions</t></strong><br>
                                <t>Use a square image</t><br>
                                <t>Max height : 60px</t><br>
                                <t>Use .jpeg or .png format</t><br>
                                <t>Use a graphical logo or image rather than text</t><br>
                            </p>
                        </div>
                    </div>
                </fieldset>

                <h3 class="small align-top">
                    <t>Cover</t>
                </h3>

                <fieldset>
                    <div class="photo-edit-wrapper">
                        <div class="photo-edit">
                            <div class="photo-zone">
                                <Croppable
                                    v-show="show_cover_croppable"
                                    ref="croppable_cover"
                                    style="display: flex;justify-content: center;"
                                    :show-width="426"
                                    :show-height="127"
                                    :dest-width="1100"
                                    :dest-height="350"
                                    save-url="company_file"
                                    :image="$Utils.resolveS3PublicImage(company.cover)"
                                    @onChange="onCoverChange"
                                    @onUploadEnd="onCoverSave"
                                />

                                <img
                                    v-show="!show_cover_croppable"
                                    :src="$Utils.resolveS3PublicImage(company.cover)"
                                    alt=""
                                >
                            </div>
                            <div v-if="show_cover_croppable">
                                <a
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="show_cover_croppable=false"
                                ><t>Cancel</t></a>
                                &nbsp;
                                <a
                                    v-if="show_cover_save"
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="$refs.croppable_cover.onSave()"
                                ><t>Save</t></a>
                            </div>
                            <a
                                v-else
                                class="button button-secondary"
                                @click=" $refs.croppable_cover.onOpenFileSelector(); show_cover_croppable=true;"
                            ><t>Change</t></a>
                        </div>
                        <div class="photo-edit-infos">
                            <p>
                                <strong><t>Instructions</t></strong><br>
                                <t>Use a rectangular image</t><br>
                                <t>Use .jpeg or .png format</t><br>
                                <t>Image is exactly the same here and in Dashboard</t><br>
                                <t>Dimensions : 900*200 or 1350*300</t><br>
                            </p>
                        </div>
                    </div>
                </fieldset>

                <!-- FAVICON -->
                <h3 class="small">
                    <t>Favicon</t>
                </h3>

                <fieldset>
                    <div class="photo-edit-wrapper">
                        <div class="photo-edit">
                            <div class="logo-zone">
                                <Croppable
                                    v-show="show_favicon_croppable"
                                    ref="croppable_favicon"
                                    style="display: flex;justify-content: center;"
                                    :show-width="128"
                                    :show-height="128"
                                    :dest-width="256"
                                    :dest-height="256"
                                    save-url="company_file"
                                    :image="$Utils.resolveS3PublicImage(favicon)"
                                    @onChange="onFaviconChange"
                                    @onUploadEnd="onFaviconSave"
                                />
                                <img
                                    v-show="!show_favicon_croppable"
                                    :src="$Utils.resolveS3PublicImage(favicon)"
                                    alt=""
                                >
                            </div>
                            <div v-if="show_favicon_croppable">
                                <a
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="show_favicon_croppable=false"
                                ><t>Cancel</t></a>
                                &nbsp;
                                <a
                                    v-if="show_favicon_save"
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="$refs.croppable_favicon.onSave()"
                                ><t>Save</t></a>
                            </div>
                            <a
                                v-else
                                class="button button-secondary"
                                @click=" $refs.croppable_favicon.onOpenFileSelector(); show_favicon_croppable=true;"
                            ><t>Change</t></a>
                        </div>
                        <div class="photo-edit-infos">
                            <p>
                                <strong><t>Instructions</t></strong><br>
                                <t>Use a square image</t><br>
                                <t>Preferred dimensions : 32x32</t><br>
                                <t>Use .ico or .png format</t><br>
                            </p>
                        </div>
                    </div>
                </fieldset>

                <!-- LOGO LOGIN PAGE -->
                <h3 class="small align-top">
                    <t>Login page logo</t>
                </h3>

                <fieldset>
                    <div class="photo-edit-wrapper">
                        <div class="photo-edit">
                            <div class="logo-zone">
                                <Croppable
                                    v-show="show_login_logo_croppable"
                                    ref="croppable_login_logo"
                                    style="display: flex;justify-content: center;"
                                    :auto-scale="true"
                                    save-url="company_file"
                                    :image="$Utils.resolveS3PublicImage(loginLogo, true)"
                                    @onChange="onLoginLogoChange"
                                    @onUploadEnd="onLoginLogoSave"
                                />
                                <img
                                    v-show="!show_login_logo_croppable"
                                    :src="$Utils.resolveS3PublicImage(loginLogo, true)"
                                    alt=""
                                >
                            </div>
                            <div v-if="show_login_logo_croppable">
                                <a
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="show_login_logo_croppable=false"
                                ><t>Cancel</t></a>
                                &nbsp;
                                <a
                                    v-if="show_login_logo_save"
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="$refs.croppable_login_logo.onSave()"
                                ><t>Save</t></a>
                            </div>
                            <a
                                v-else
                                class="button button-secondary"
                                @click=" $refs.croppable_login_logo.onOpenFileSelector(); show_login_logo_croppable=true;"
                            >
                                <t>Change</t>
                            </a>
                        </div>
                        <div class="photo-edit-infos">
                            <p>
                                <strong><t>Instructions</t></strong><br>
                                <t>Use a square or a rectangular image</t><br>
                                <t>Max height : 60px</t><br>
                                <t>Use .jpeg or .png format</t><br>
                            </p>
                        </div>
                    </div>
                </fieldset>

                <!-- COVER LOGIN PAGE -->
                <h3 class="small align-top">
                    <t>Login page cover</t>
                </h3>

                <fieldset>
                    <div class="photo-edit-wrapper">
                        <div class="photo-edit">
                            <div class="photo-zone">
                                <Croppable
                                    v-show="show_login_cover_croppable"
                                    ref="croppable_login_cover"
                                    style="display: flex;justify-content: center;"
                                    :show-width="400"
                                    :show-height="127"
                                    :dest-width="1920"
                                    :dest-height="1080"
                                    save-url="company_file"
                                    :image="$Utils.resolveS3PublicImage(loginCover, true)"
                                    @onChange="onLoginCoverChange"
                                    @onUploadEnd="onLoginCoverSave"
                                />
                                <img
                                    v-show="!show_login_cover_croppable"
                                    :src="$Utils.resolveS3PublicImage(loginCover, true)"
                                    alt=""
                                >
                            </div>
                            <div v-if="show_login_cover_croppable">
                                <a
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="show_login_cover_croppable=false"
                                ><t>Cancel</t></a>
                                &nbsp;
                                <a
                                    v-if="show_login_cover_save"
                                    style="display:inline-block"
                                    class="button button-secondary"
                                    @click="$refs.croppable_login_cover.onSave()"
                                ><t>Save</t></a>
                            </div>
                            <a
                                v-else
                                class="button button-secondary"
                                @click=" $refs.croppable_login_cover.onOpenFileSelector(); show_login_cover_croppable=true;"
                            >
                                <t>Change</t>
                            </a>
                        </div>
                        <div class="photo-edit-infos">
                            <p>
                                <strong><t>Instructions</t></strong><br>
                                <t>Use a rectangular image</t><br>
                                <t>Use .jpeg or .png format</t><br>
                                <t>Dimensions : 1920*1080</t><br>
                            </p>
                        </div>
                    </div>
                </fieldset>
                <section
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    class="mb-5"
                >
                    <h3 class="small">
                        <t>Support</t>
                    </h3>
                    <div class="row align-items-center">
                        <div class="col-md">
                            <HtInputText
                                id="company_support_link"
                                v-model="company.support_link"
                                v-validate.disable="'required|url'"
                                type="url"
                                name="support_link"
                                placeholder="https://support-link.com"
                                @change="onUpdate"
                            />
                        </div>
                        <div class="col-md-auto">
                            <Button
                                class="button negative"
                                @click="resetSupportLink"
                            >
                                <t>Reset Heyteam support link</t>
                            </Button>
                        </div>
                    </div>
                </section>
                <h3 class="small">
                    <t>Pick the primary color of your company</t>
                </h3>
                <fieldset v-if="companyBrandingActive">
                    <div class="input-picker-group">
                        <div class="input-picker">
                            <button
                                :style="{ backgroundColor: companyBrandingActive.content.background }"
                                @click="showChromePicker = !showChromePicker"
                            />
                            <span>{{ companyBrandingActive.content.background }}</span>
                        </div>
                        <chrome-picker
                            v-if="showChromePicker"
                            :disable-alpha="true"
                            class="chrome-picker"
                            :value="companyBrandingActive.content.background"
                            @input="updateBackground"
                        />
                    </div>
                    <CompanyBrandingViewer :color="companyBrandingActive.content.background" />
                    <div
                        v-if="companyBrandingActive.content.background !== companyBrandingCurrent.content.background"
                        class="save-button"
                    >
                        <div class="modal-actions right">
                            <Button @click="onUpdateBranding()">
                                <t>Save</t>
                            </Button>
                        </div>
                    </div>
                </fieldset>
            </div>

            <company-translatable
                :value="companyAbout"
                :label="`${translate('About us')} / ${translate('Presentation')} / ${translate('History')}`"
                :max-length="characters_about"
                :character-limit="characterLimit"
                @on-update="onUpdate"
                @update:value="v => company.translations.about[editingLang] = v"
            />
            <company-translatable
                :value="companyMission"
                :label="`${translate('Missions')}`"
                :max-length="characters_mission"
                :character-limit="characterLimit"
                @on-update="onUpdate"
                @update:value="v => company.translations.mission[editingLang] = v"
            />

            <div
                v-show="editingLang === shared.session.company.company_languages.default.key"
                class="wrapper-half-form form-2"
            >
                <fieldset class="big">
                    <h3 class="small">
                        <t>Creation Date</t>
                    </h3>
                    <HtFormSelect
                        id="company-founding-year"
                        v-model="company.founded_year"
                        name="company-founding-year"
                        :options="yearOptions"
                        @input="onUpdate"
                    />
                </fieldset>
                <fieldset class="big">
                    <h3 class="small">
                        <t>Number of employees</t>
                    </h3>
                    <HtFormSelect
                        id="company-employees-count"
                        v-model="company.employees"
                        name="company-employees-count"
                        :options="employeesOptions"
                        @input="updateEmployees"
                    />
                    <div
                        v-if="customEmployees"
                        class="wrapper-half-form form-2 mt-25"
                    >
                        <fieldset>
                            <div class="item-content">
                                <input
                                    v-model="minEmployees"
                                    v-validate.disable="'required|integer|min_value:0'"
                                    type="number"
                                    name="minEmployees"
                                    data-vv-scope="employees"
                                    :data-vv-as="translate('minimum')"
                                    :placeholder="translate('Minimum')"
                                >
                            </div>
                            <div
                                v-if="errors.has('minEmployees', 'employees')"
                                class="error-message"
                            >
                                {{ errors.first('minEmployees', 'employees') }}
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="item-content">
                                <input
                                    v-model="maxEmployees"
                                    v-validate.disable="maxEmployeesRules"
                                    type="number"
                                    name="maxEmployyes"
                                    data-vv-scope="employees"
                                    :data-vv-as="translate('maximum')"
                                    :placeholder="translate('Maximum')"
                                >
                            </div>
                            <div
                                v-if="errors.has('maxEmployyes', 'employees')"
                                class="error-message"
                            >
                                {{ errors.first('maxEmployyes', 'employees') }}
                            </div>
                        </fieldset>
                    </div>
                    <Button
                        v-if="customEmployees"
                        @click="addAndUpdateEmployees"
                    >
                        <t>Save</t>
                    </Button>
                </fieldset>
            </div>
            <div
                v-show="editingLang === shared.session.company.company_languages.default.key"
                class="wrapper-half-form form-2"
            >
                <fieldset class="big">
                    <h3 class="small">
                        <t>Timezone</t>
                    </h3>
                    <timezone-selector
                        id="timezone"
                        v-validate="'required'"
                        :data-vv-as="translate('Timezone')"
                        :value.sync="company.timezone"
                        name="timezone"
                        :error-messages="errors.collect('timezone')"
                        @update:value="updateTimezone"
                    />
                </fieldset>
            </div>

            <HtFormSwitch
                v-show="editingLang === shared.session.company.company_languages.default.key"
                v-if="companyPrimary"
                :id="'mode-picture'"
                v-model="companyPrimary.content['mode_picture']"
                :label="translate('Mode Picture')"
                :on-label="translate('Enabled')"
                :off-label="translate('Disabled')"
                :tooltip="translate('By activating this mode, the company page will consist only of carousels that you create')"
                :name="'mode-picture'"
                :show-optional="false"
                @input="onChangeMode"
            />
        </form>
    </div>
</template>
<script>
import { Sketch } from 'vue-color';
import moment from 'moment';
import Croppable from '@/components/tools/Croppable.vue';
import CompanyBrandingViewer from '@/components/settings/CompanyBrandingViewer.vue';
import HtInputText from '@/components/globals/HtInputText.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyCustomizeCollection from '@/models/CompanyCustomizeCollection';
import Company from '@/models/Company';
import TimezoneSelector from '@/components/globals/timezone/TimezoneSelector.vue';
import CompanyName from '@/components/CompanySettings/CompanyName.vue';
import CompanyTranslatable from '@/components/CompanySettings/CompanyTranslatable.vue';
import SwitchNewUi from '@/components/settings/Platform/SwitchNewUi.vue';

export default {
    name: 'CompanySettingsInformations',
    components: {
        Croppable,
        CompanyBrandingViewer,
        'chrome-picker': Sketch,
        HtFormSelect,
        HtLanguageSelect,
        HtFormSwitch,
        TimezoneSelector,
        CompanyName,
        CompanyTranslatable,
        HtInputText,
        SwitchNewUi,
    },
    props: {
        editingLang: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'October', 'November', 'December'],
            years: [],

            companyBrandingCollection: new CompanyCustomizeCollection([
                'id',
                'company_id',
                'type',
                'content',
            ]).where([
                ['type', '=', 'branding'],
            ]),

            companyBrandingActive: null,
            companyBrandingCurrent: null,

            companyGeneral: new CompanyCustomizeCollection([
                'id',
                'type',
                'content',
                'subtype',
                'is_enabled',
            ]).where([
                ['type', '=', 'page'],
                ['subtype', '=', 'company'],
            ]),

            companyPrimary: null,
            company: new Company([
                'id',
                'name',
                'image',
                'image_square',
                'cover',
                'language',
                'employees',
                'founded_year',
                'founded_month',
                'about',
                'mission',
                'timezone',
                'company_file_id',
                'cover_company_file_id',
                'image_square_company_file_id',
                'favicon_company_file_id',
                'login_cover_company_file_id',
                'login_logo_company_file_id',
                'translations',
                'support_link',
            ]).with({
                companyLanguage: (query) => query.select([
                    'id', 'key', 'code', 'name',
                ]),
                faviconFile: (query) => {
                    query.select([
                        'id', 'name', 'path', 'detected_mime_type',
                    ]);
                },
                loginCoverFile: (query) => {
                    query.select([
                        'id', 'name', 'path', 'detected_mime_type',
                    ]);
                },
                loginLogoFile: (query) => {
                    query.select([
                        'id', 'name', 'path', 'detected_mime_type',
                    ]);
                },
            }),

            employees: [
                {
                    value: '1-25',
                    text: '1-25',
                },
                {
                    value: '26-50',
                    text: '26-50',
                },
                {
                    value: '51-100',
                    text: '51-100',
                },
                {
                    value: '101-200',
                    text: '101-200',
                },
                {
                    value: '201-500',
                    text: '201-500',
                },
                {
                    value: '501-1000',
                    text: '501-1000',
                },
                {
                    value: '+1000',
                    text: '+1000',
                },
                {
                    value: 'custom',
                    text: this.translate('Custom value'),
                },
            ],
            minEmployees: '',
            maxEmployees: '',
            customEmployees: false,

            name: '',
            about: '',
            mission: '',
            show_logo_croppable: false,
            show_logo_save: false,
            show_logo_square_croppable: false,
            show_logo_square_save: false,
            show_cover_croppable: false,
            show_cover_save: false,
            show_favicon_croppable: false,
            show_favicon_save: false,
            show_login_cover_croppable: false,
            show_login_cover_save: false,
            show_login_logo_croppable: false,
            show_login_logo_save: false,
            showChromePicker: false,
            characters_about: 2000,
            characters_mission: 1000,
            body: {
                mode: 'first',
                field: [
                    'id',
                    'name',
                    'image',
                    'image_square',
                    'cover',
                    'language',
                    'employees',
                    'founded_year',
                    'founded_month',
                    'about',
                    'mission',
                    'support_link',
                ],
            },

            show: 'https://amp.businessinsider.com/images/5bdc87448c35ab6a120935eb-750-563.jpg',
        };
    },
    computed: {
        companyAbout() {
            this.$set(this.company.translations, this.about, {});
            if (!(this.editingLang in this.company.translations.about)) {
                this.$set(this.company.translations.about, this.editingLang, '');
            }
            return this.company.translations.about[this.editingLang];
        },
        companyMission() {
            this.$set(this.company.translations, this.mission, {});
            if (!(this.editingLang in this.company.translations.mission)) {
                this.$set(this.company.translations.mission, this.editingLang, '');
            }
            return this.company.translations.mission[this.editingLang];
        },
        maxEmployeesRules() {
            const min = Number(this.minEmployees) < 0 ? 1 : Number(this.minEmployees) + 1;
            return `integer|min_value:${min}`;
        },

        isSavingCompany() {
            return (this.company._state.isSaving === true) ? 'loading' : 'idle';
        },

        yearOptions() {
            return this.years.map((year) => ({
                id: year,
                name: year,
            }));
        },

        employeesOptions() {
            return this.employees.map(({ value, text }) => ({
                id: value,
                name: text,
            }));
        },

        favicon() {
            if (this.company.favicon_file == null) return '/static/images/logo.png';
            return this.company.favicon_file.path + this.company.favicon_file.name;
        },

        loginCover() {
            if (this.company.login_cover_file == null) return null;
            return this.company.login_cover_file.path + this.company.login_cover_file.name;
        },

        loginLogo() {
            if (this.company.login_logo_file == null) return null;
            return this.company.login_logo_file.path + this.company.login_logo_file.name;
        },
    },

    async created() {
        // generate years
        for (let i = moment().year(); i >= 1800; i--) {
            this.years.push(i);
        }

        await this.load();
        await this.companyBrandingCollection.get();
        await this.companyGeneral.get();

        this.companyPrimary = this.companyGeneral.models[0];

        this.companyBrandingActive = this.companyBrandingCollection.$.models[0].active;
        this.companyBrandingCurrent = this.companyBrandingCollection.$.models[0];
    },

    methods: {
        characterLimit(string, count) {
            return string.length >= count ? 0 : count - string.length;
        },

        updateEmployees() {
            if (this.company.employees === 'custom') {
                this.customEmployees = true;
            } else {
                this.customEmployees = false;
                this.onUpdate();
            }
        },
        updateTimezone() {
            this.onUpdate();
        },

        async addAndUpdateEmployees() {
            await this.$validator.validateAll('employees');

            if (this.errors.any('employees')) {
                return;
            }

            this.customEmployees = false;
            const name = this.maxEmployees ? `${this.minEmployees}-${this.maxEmployees}` : `+${this.minEmployees}`;
            if (!this.employees.some((e) => e.value === name)) {
                this.employees.unshift({ value: name, text: name });
            }
            this.company.employees = name;
            this.onUpdate();
        },

        async load() {
            await this.company.get();
            if (this.company.favicon_file !== null) {
                this.$store.dispatch('faviconTitle/setFavicon', {
                    href: this.$Utils.resolveS3PublicImage(this.company.favicon_file.path + this.company.favicon_file.name),
                    type: this.company.favicon_file.detected_mime_type,
                });
            }
            if (!this.employees.some((e) => e.value === this.company.employees)) {
                this.employees.unshift({ value: this.company.employees, text: this.company.employees });
            }
        },

        onChangeMode() {
            this.companyPrimary.save();
        },

        updateBackground(color) {
            this.companyBrandingActive.content.background = color.hex;
        },

        async onUpdate() {
            await this.$validator.validateAll();

            if (this.errors.any()) return;

            this.company.save().then(() => {
                this.shared.session.company.fill(this.company);
            });
        },

        async onUpdateBranding() {
            await this.companyBrandingActive.save();

            this.$Branding.setPrimaryBranding(this.companyBrandingCurrent);
        },

        onLogoChange() {
            this.show_logo_save = true;
        },

        onLogoSquareChange() {
            this.show_logo_square_save = true;
        },

        onCoverChange() {
            this.show_cover_save = true;
        },

        onFaviconChange() {
            this.show_favicon_save = true;
        },

        onLoginCoverChange() {
            this.show_login_cover_save = true;
        },

        onLoginLogoChange() {
            this.show_login_logo_save = true;
        },

        onLogoSave(uploadInfo, data) {
            this.company.company_file_id = data.id;

            this.company.save().then(() => {
                this.show_logo_croppable = false;
                this.shared.session.company.fill(this.company);
            });
        },
        onLogoSquareSave(uploadInfo, data) {
            this.company.image_square_company_file_id = data.id;

            this.company.save().then(() => {
                this.show_logo_square_croppable = false;
                this.shared.session.company.fill(this.company);
            });
        },

        onCoverSave(uploadInfo, data) {
            this.company.cover_company_file_id = data.id;

            this.company.save().then(() => {
                this.show_cover_croppable = false;
                this.shared.session.company.fill(this.company);
            });
        },

        onFaviconSave(uploadInfo, data) {
            this.company.favicon_company_file_id = data.id;

            this.company.save().then(() => {
                this.show_favicon_croppable = false;
                this.shared.session.company.fill(this.company);
            }).then(() => {
                this.$store.dispatch('faviconTitle/setFavicon', {
                    href: this.$Utils.resolveS3PublicImage(this.company.favicon_file.path + this.company.favicon_file.name),
                    type: this.company.favicon_file.detected_mime_type,
                });
            });
        },

        onLoginCoverSave(uploadInfo, data) {
            this.company.login_cover_company_file_id = data.id;

            this.company.save().then(() => {
                this.show_login_cover_croppable = false;
                this.shared.session.company.fill(this.company);
            });
        },

        onLoginLogoSave(uploadInfo, data) {
            this.company.login_logo_company_file_id = data.id;

            this.company.save().then(() => {
                this.show_login_logo_croppable = false;
                this.shared.session.company.fill(this.company);
            });
        },

        customLanguageOptionLabel(language) {
            return language.name;
        },

        resetSupportLink() {
            if (this.company.support_link === Company.heyteam_support_link) {
                return;
            }

            this.company.support_link = Company.heyteam_support_link;
            this.onUpdate();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.mt-25 {
    margin-top: 25px;
}

.input-picker-group {
    margin-bottom: 15px;
}

.input-picker {
    display: flex;
    align-items: center;

    button {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border: 4px solid $grey-ultra-light;
    }
}

.chrome-picker {
    position: absolute;
    margin-top: 5px;
    z-index: 1000;
}

.label-medium{
    font-weight: normal;
    font-family: "latobold", "lato", sans-serif;
    margin-top: 10px;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
}
h3{
    margin: 10px;
    margin-left: 0;
    .first{
        font-size: 2.2rem;
    }
    &.label-form-special{
        margin-bottom: -10px;
        margin-top: 20px;
    }
}
.photo-zone, .logo-zone {
    overflow: hidden;
}

.form textarea {
    margin-bottom: -4px;
}

.save-button {
    padding: 0.75em 0;
    text-align: center;
    width: 100%;
}
.photo-edit-infos{
    padding: 0 25px;
}
.align-top{
    margin-top: 20px;margin-bottom: 0px;
}
</style>
