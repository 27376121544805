<template>
    <div>
        <NewProfileBloc
            :is-edit.sync="isEdit"
            :can-edit="canUpdate"
            :button-state="buttonState"
            @onCancel="reset"
            @on-save="onUpdate"
        >
            <div class="profile-rows">
                <div class="profile-row">
                    <!-- JOB DESCRIPTION -->
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Job description</t>
                        </div>

                        <div
                            v-if="!isEdit"
                            class="profile-item-content"
                        >
                            <HtFormattedText
                                v-if="companyUser.job_duties"
                                :text="companyUser.job_duties"
                            />
                            <template v-else>
                                -
                            </template>
                        </div>
                        <HtFormTextarea
                            v-else
                            :id="'job_duties'"
                            v-model="companyUser.job_duties"
                            :name="'job_duties'"
                        />
                    </div>
                    <!-- SKILLS -->
                    <div class="profile-item form-2">
                        <NewProfilMetadata
                            v-model="newSkill"
                            :company-user-metadata-collection="
                                companyUser.company_user_metadata
                            "
                            :type="'skill'"
                            :is-edit="isEdit"
                            :section-title="translate('Skills')"
                        />
                    </div>
                </div>

                <div
                    v-if="canShowEntities"
                    class="profile-row"
                >
                    <!-- Department -->
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Department</t>
                        </div>

                        <HtFormSelect
                            v-if="isEdit && canModifyEntities"
                            :id="'department'"
                            v-model="companyUser.company_department_id"
                            :name="'department'"
                            :options="getDepartmentOptions"
                        />
                        <div
                            v-else
                            class="profile-item-content"
                        >
                            {{
                                companyUser.company_department
                                    ? companyUser.company_department.name
                                    : '' | empty
                            }}
                        </div>
                    </div>

                    <!-- Office -->
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Site</t>
                        </div>
                        <HtFormSelect
                            v-if="isEdit && canModifyEntities"
                            :id="'office'"
                            v-model="companyUser.company_office_id"
                            :name="'office'"
                            :options="getOfficeOptions"
                        />
                        <div
                            v-else
                            class="profile-item-content"
                        >
                            {{
                                companyUser.company_office
                                    ? companyUser.company_office.name
                                    : '' | empty
                            }}
                        </div>
                    </div>
                </div>

                <!-- Custom entities -->
                <ProfilCustomEntities
                    v-if="canShowEntities"
                    :company-user="companyUser"
                    :entity-ids="entityIds"
                    :is-edit="isEdit && canModifyEntities"
                />

                <div class="profile-row">
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Timezone</t>
                        </div>

                        <timezone-selector
                            v-if="isEdit"
                            id="timezone"
                            v-validate="'required'"
                            :data-vv-as="translate('Timezone')"
                            :value.sync="companyUser.timezone"
                            name="timezone"
                            :error-messages="errors.collect('timezone')"
                        />
                        <div
                            v-else
                            class="profile-item-content"
                        >
                            <timezone-display
                                :timezone="companyUser.timezone ? companyUser.timezone : '' | empty"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </NewProfileBloc>
    </div>
</template>
<script>
import CompanyOfficeCollection from '@/models/CompanyOfficeCollection';
import CompanyDepartmentCollection from '@/models/CompanyDepartmentCollection';
import CompanyUserMetadata from '@/models/CompanyUserMetadata';
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';
import HtFormattedText from '@/components/globals/HtFormattedText.vue';
import TimezoneSelector from '@/components/globals/timezone/TimezoneSelector.vue';
import TimezoneDisplay from '@/components/globals/timezone/TimezoneDisplay.vue';
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import ProfilCustomEntities from './ProfilCustomEntities/ProfilCustomEntities.vue';
import NewProfilMetadata from './ProfilMetadata/NewProfilMetadata.vue';
import NewProfileBloc from '../NewProfileBloc.vue';

export default {
    name: 'NewProfileInfoExtra',
    components: {
        NewProfileBloc,
        NewProfilMetadata,
        HtFormattedText,
        TimezoneSelector,
        TimezoneDisplay,
        ProfilCustomEntities,
    },
    mixins: [ProfileBlocMixin],

    data() {
        return {
            newSkill: '',
            loading: true,
            companyDepartment: new CompanyDepartmentCollection([
                'id',
                'name',
                'is_heyteam',
            ]),
            companyOffice: new CompanyOfficeCollection([
                'id',
                'name',
                'is_heyteam',
            ]),
            customEntities: new CompanyEntityCollection(['id', 'name', 'slug', 'is_private'])
                .with({
                    values: (query) => query.select([
                        'id',
                        'company_entity_id',
                        'resourceable_type',
                        'resourceable_id',
                    ]),
                    customData: (query) => query.select(['id', 'key', 'name']),
                })
                .whereHas({
                    values: (query) => query.where([['is_custom', '=', true]]),
                }),
        };
    },

    computed: {
        getDepartmentOptions() {
            return this.companyDepartment.models
                .map((department) => ({
                    name: department.name,
                    id: department.id,
                }))
                .sort((a, b) => a.name.localeCompare(b.name));
        },

        getOfficeOptions() {
            return this.companyOffice.models
                .map((office) => ({
                    name: office.name,
                    id: office.id,
                }))
                .sort((a, b) => a.name.localeCompare(b.name));
        },

        entityIds() {
            return this.companyUser.default_entities.models.filter((entity) => !entity.entity.is_private && !entity.entity.is_heyteam).map((entity) => entity.entity.id);
        },
    },

    created() {
        Promise.all([
            this.companyDepartment.get(),
            this.companyOffice.get(),
            this.customEntities.get(),
        ])
            .then(() => (this.loading = false))
            .catch(() => (this.loading = false));
    },

    methods: {
        reset() {
            this.newSkill = '';
        },

        onUpdate() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.buttonState = 'loading';

                    const splittedMetadataToAdd = this.newSkill.split(',');

                    if (splittedMetadataToAdd.length > 0) {
                        for (const item of splittedMetadataToAdd) {
                            const companyMetaData = this.companyUser.company_user_metadata.new();
                            companyMetaData.type = CompanyUserMetadata.TYPE_SKILL;
                            companyMetaData.value = item.trim();
                            companyMetaData.company_user_id = this.companyUser.id;

                            if (
                                companyMetaData.value
                                && companyMetaData.value.length > 0
                            ) {
                                this.companyUser.company_user_metadata.add(
                                    companyMetaData,
                                );
                            }
                        }
                    }
                    this.onUpdateUser();
                    this.reset();
                }
            });
        },
    },
};
</script>
