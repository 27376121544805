<template>
    <div v-if="permissionsLoaded">
        <HtTablePaginated
            ref="programsTable"
            :title="title"
            :subtitle="translate('Enrollees linked to your perimeter.')"
            :program-entities-ids="companyProgram.entities.models
                .map((entity) => entity.company_entity_id)"
            :url="urlGetUserPrograms"
            :columns="columns"
            cypress="user-programs-table"
            :empty-message="emptyMessage"
            :params="params"
            :has-quick-actions="false"
            :button-text="buttonText"
            :show-button="showButton"
            type="program"
            @on-row-clicked="onRowClicked"
            @on-button-clicked="$emit('on-button-clicked')"
            @pagination-refreshed="$emit('pagination-refreshed', $event)"
        >
            <template #head(progress)="scope">
                {{ scope.column.label }}
                <span
                    v-tooltip.right-end="translate('Progress is calculated from the available actions that the collaborator has to complete on the current date')"
                    class="px-2 position-relative"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </span>
            </template>

            <template #head(state)="scope">
                {{ scope.column.label }}
                <span
                    v-tooltip.right-end="translate('State is based on the tasks assigned to the employee.<br/>Calculation rule:<br/>Green: 0 to 4 overdue tasks<br/>Orange: 4 to 15 overdue tasks<br/>Red: More than 15 overdue tasks')"
                    class="px-2 position-relative"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </span>
            </template>

            <template #cell(user_lastname)="scope">
                <div class="d-flex align-items-center">
                    <div
                        v-user-image="{
                            image: scope.item.user_image,
                            firstname: scope.item.user_firstname,
                            lastname: scope.item.user_lastname
                        }"
                        class="user-image mr-3"
                    />
                    <span>{{ scope.item.user_fullname }}</span>
                </div>
            </template>

            <template #skeleton(user_lastname)>
                <div class="d-flex align-items-center">
                    <Skeleton
                        width="35px"
                        height="34px"
                        class="mr-3"
                        :loading="true"
                    />
                    <Skeleton
                        width="100%"
                        class="flex-grow-1"
                        :loading="true"
                    />
                </div>
            </template>

            <template #cell(user_program_starts_at)="scope">
                {{ scope.item.user_program_starts_at ? $Utils.moment(scope.item.user_program_starts_at).format('L') : '-' }}
            </template>

            <template #cell(progress)="scope">
                <HtPercentageCompletionProgram
                    :enrollee-completion="parseInt(scope.item.user_program_enrollee_completion)"
                    :participants-completion="parseInt(scope.item.user_program_participants_completion)"
                />
            </template>

            <template #cell(state)="scope">
                <div class="d-flex justify-content-center">
                    <HtStatusLight
                        :status="getProgramStatus(scope.item.task_count_late_enrollee)"
                        :label="getProgramStatusLabel(scope.item.task_count_late_enrollee)"
                    />
                </div>
            </template>

            <template #cell(trash)="scope">
                <HtButton
                    v-if="$canDelete('ModelCompanyUserProgram', { company_user_id: scope.item.user_id})"
                    type="secondary"
                    size="small"
                    cypress="delete-program"
                    class="delete-program-button"
                    :data-user="scope.item.user_fullname"
                    @click.native.stop="onDestroyConfirm(scope.item)"
                >
                    <FontAwesomeIcon :icon="['far', 'trash-alt']" />
                </HtButton>
            </template>

            <template #responsive="scope">
                <div class="responsive-program">
                    <div class="user-fullname">
                        {{ scope.item.user_fullname }}
                    </div>
                    <div>
                        <t class="arrival-date">
                            Arrival date:
                        </t> {{ $Utils.moment(scope.item.user_arrival_date).format('L') }}
                    </div>
                    <div>
                        <t>
                            {{ scope.item.user_program_enrollee_completion }} % done for enrollee
                        </t>
                        <t>
                            {{ scope.item.user_program_participants_completion }} % done for collaborators
                        </t>
                    </div>
                    <HtStatus
                        :status="getProgramStatus(scope.item.task_count_late_enrollee)"
                        :label="getProgramStatusLabel(scope.item.task_count_late_enrollee)"
                    />

                    <HtActionMenu
                        position="top-right"
                        :actions="[{label: translate('Delete'), id: 'delete'}]"
                        @action-clicked="onDestroyConfirm(scope.item)"
                    />
                </div>
            </template>

            <template #cell(user_program_created_at)="scope">
                {{ $Utils.moment(scope.item.user_program_created_at).format('L') }}
            </template>
        </HtTablePaginated>
    </div>
</template>

<script>
import CompanyUserProgram from '@/models/CompanyUserProgram';
import HtStatusLight from '@/components/globals/HtStatusLight.vue';
import HtStatus from '@/components/globals/HtStatus.vue';
import HtActionMenu from '@/components/globals/HtActionMenu.vue';
import HtPercentageCompletionProgram from '@/components/globals/HtPercentageCompletionProgram.vue';
import { Skeleton } from 'vue-loading-skeleton';
import modalMixin from '@/components/globals/modals/modalMixin';
import HtTablePaginated from '@/components/globals/HtTable/HtTablePaginated.vue';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'ProgramList',
    permissions: ['ModelCompanyUserProgram'],

    components: {
        HtPercentageCompletionProgram,
        HtStatusLight,
        HtStatus,
        HtActionMenu,
        HtTablePaginated,
        Skeleton,
        HtButton,
    },
    mixins: [modalMixin],
    props: {
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        buttonText: {
            type: String,
            default: null,
        },
        showButton: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
        },
        externalSearch: {
            type: String,
            default: null,
        },
        programType: {
            type: String,
            required: true,
        },
        companyProgram: {
            type: Object,
            required: true,
        },
        emptyMessage: {
            type: String,
            default: 'There are no resource to display yet...',
        },
    },

    data() {
        return {
            items: [],
            page: 1,
            lastPage: 1,
            perPage: 15,
            loading: false,
            params: {},
            filters: {},
        };
    },

    computed: {
        urlGetUserPrograms() {
            return `programs/${this.$router.currentRoute.params.program_id}/user-programs?status=${this.type}`;
        },
        getProgramType() {
            return this.$Utils.capitalize(this.programType);
        },

        buttonState() {
            return (this.loading) ? 'loading' : 'idle';
        },

        columns() {
            const arrivalLabel = this.companyProgram.key_date.name_translated;
            const cols = {
                active: [
                    {
                        label: this.translate('User'),
                        key: 'user_lastname',
                        search: true,
                        style: 'min-width: 240px;',
                    },
                    {
                        label: arrivalLabel,
                        key: 'user_program_starts_at',
                    },
                    {
                        label: this.translate('Department'),
                        sortable: false,
                        key: 'user_department_name',
                    },
                    {
                        label: this.translate('Progress'),
                        key: 'progress',
                        sortable: false,
                        style: 'min-width: 182px;',
                    },
                    {
                        label: this.translate('State'),
                        key: 'state',
                        sortable: false,
                    },
                    {
                        label: '',
                        key: 'trash',
                        sortable: false,
                        headerClass: 'quick-actions',
                        class: 'quick-actions',
                    },
                ],
                draft: [
                    {
                        label: this.translate('Name'),
                        key: 'user_lastname',
                        search: true,
                        style: 'min-width: 240px;',
                    },
                    {
                        label: arrivalLabel,
                        key: 'user_program_starts_at',
                    },
                    {
                        label: this.translate('Site'),
                        sortable: false,
                        key: 'user_office_name',
                    },
                    {
                        label: this.translate('Created at'),
                        key: 'user_program_created_at',
                    },
                    {
                        label: '',
                        key: 'trash',
                        sortable: false,
                        headerClass: 'quick-actions',
                        class: 'quick-actions',
                    },
                ],
                completed: [
                    {
                        label: this.translate('Name'),
                        key: 'user_lastname',
                        search: true,
                        style: 'min-width: 240px;',
                    },
                    {
                        label: arrivalLabel,
                        key: 'user_program_starts_at',
                    },
                    {
                        label: this.translate('Department'),
                        sortable: false,
                        key: 'user_department_name',
                    },
                    {
                        label: this.translate('Site'),
                        sortable: false,
                        key: 'user_office_name',
                    },
                    {
                        label: '',
                        key: 'trash',
                        sortable: false,
                        headerClass: 'quick-actions',
                        class: 'quick-actions',
                    },
                ],
            };
            return cols[this.type];
        },
    },

    methods: {
        /**
         * @param {number} lateTasks
         * @returns {string}
         */
        getProgramStatus(lateTasks) {
            if (lateTasks <= 3) {
                return 'success';
            }

            if (lateTasks <= 15) {
                return 'warning';
            }

            return 'danger';
        },

        /**
             * @param {number} lateTasks
             * @returns {string}
             */
        getProgramStatusLabel(lateTasks) {
            return this.translate('{count} late task | {count} late tasks', { count: lateTasks });
        },

        onRowClicked(row) {
            if (row.item.user_program_status !== CompanyUserProgram.STATUS_DRAFT) {
                this.$router.push({
                    name: 'ProfileProgramDetail',
                    params: {
                        company_user_id: row.item.user_id,
                        company_user_program_id: row.item.user_program_id,
                    },
                });
            } else {
                this.$router.push({
                    name: 'Enrolling',
                    query: {
                        user_program_id: row.item.user_program_id,
                    },
                });
            }
        },

        /**
        * Ouvre la modal de suppression
        * @param {object} programToDelete Programme à supprimer
        * @returns {void}
        */
        onDestroyConfirm(programToDelete) {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate('Do you really want to permanently delete {firstname}’s program ?', {
                    firstname: programToDelete.user_firstname,
                }),
            ).then(() => {
                this.onDestroy(programToDelete);
            });
        },

        /**
        * @returns {void}
        */
        async onDestroy(programToDelete) {
            if (this.loading) return;

            try {
                this.loading = true;

                const program = new CompanyUserProgram(['id']);
                program.id = programToDelete.user_program_id;

                await program.delete();

                this.$refs.programsTable.loadItems();
                this.loading = false;
            } catch (data) {
                this.$Notifier('App').showError(this.translate(data.message));
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

    .responsive-program {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        align-items: flex-start;

        .user-fullname {
            font-family: $lato-bold;
            body:not(.old-ui) &{
                font-family: var(--poppins);
                font-weight: 600;
            }
            font-size: 1.6rem;
        }

        .arrival-date {
            color: $color-grey;
        }
    }
    .delete-program-button{
        margin: auto;
    }

</style>
