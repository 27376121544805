<template>
    <div>
        <div class="agenda-widget">
            <div class="agenda-infos">
                <div>
                    <h3 class="agenda-title">
                        <t>My agenda</t>
                    </h3>
                    <p class="agenda-subtitle">
                        <t>My upcoming events</t>
                    </p>
                </div>
                <div class="agenda-switch">
                    <button
                        class="agenda-switch-btn"
                        type="button"
                        :class="{active: mode === 'by-day'}"
                        @click="mode = 'by-day'"
                    >
                        <t>Day</t>
                    </button>
                    <button
                        class="agenda-switch-btn"
                        type="button"
                        :class="{active: mode === 'by-week'}"
                        @click="mode = 'by-week'"
                    >
                        <t>Week</t>
                    </button>
                </div>
            </div>
            <div class="agenda-calendar">
                <div class="calendar-days-wrapper">
                    <button
                        type="button"
                        class="calendar-btn"
                        @click="byDayWeekOffset = byDayWeekOffset - 1"
                    >
                        <HtIcon name="nav-arrow-left" />
                    </button>
                    <div
                        v-if="mode === 'by-day'"
                        class="calendar-days"
                    >
                        <button
                            v-for="(day, index) in daysWithEvents"
                            :key="day.date.format()"
                            class="calendar-day"
                            :class="{
                                selected: index === byDaySelectedDay,
                                current: day.current
                            }"
                            type="button"
                            :disabled="day.events.length === 0"
                            @click="byDaySelectedDay = index"
                        >
                            <span>{{ day.date.format('ddd') }}</span>
                            <span class="calendar-day-number">{{ day.date.format('DD') }}</span>
                            <span>{{ day.date.format('MMM') }}</span>
                        </button>
                    </div>
                    <div
                        v-else
                        class="agenda-week-name"
                    >
                        {{ fullWeekName }}
                    </div>
                    <button
                        type="button"
                        class="calendar-btn"
                        @click="byDayWeekOffset = byDayWeekOffset + 1"
                    >
                        <HtIcon name="nav-arrow-right" />
                    </button>
                </div>
                <div
                    v-if="mode === 'by-day' && eventsToShow.length"
                    class="calendar-events"
                >
                    <button
                        v-for="event in eventsToShow"
                        :key="event.id"
                        class="calendar-event"
                        type="button"
                        @click="openItemModal(event)"
                    >
                        <HtEmojiIcon
                            size="32"
                            font-size="16"
                            :style="{backgroundColor: resourcesInfo[event.task.resource].color }"
                        >
                            <HtIcon
                                :name="resourcesInfo[event.task.resource].iconName || 'task-list'"
                                stroke-width="2"
                                size="16"
                                :style="{color: resourcesInfo[event.task.resource].iconColor }"
                            />
                        </HtEmojiIcon>
                        <div class="event-infos">
                            <p class="event-name">
                                {{ event.name }}
                            </p>
                            <p class="event-time">
                                {{ getTimeStart(event) }}
                            </p>
                        </div>
                        <HtIcon name="nav-arrow-right" />
                    </button>
                </div>
                <div
                    v-if="mode === 'by-week'"
                    class="calendar-events-by-week"
                >
                    <div
                        v-for="(day) in daysWithEvents"
                        :key="day.date.format()"
                        class="week-day"
                    >
                        <div
                            class="week-day-infos"
                            :class="{ 'has-events': day.events.length }"
                        >
                            <p>
                                {{ day.date.format('dddd Do MMMM') }}
                            </p>
                            <span
                                v-if="day.current"
                                class="today"
                            >{{ translate('Today') }}</span>
                        </div>
                        <div
                            v-if="day.events.length"
                            class="calendar-events"
                        >
                            <button
                                v-for="event in day.events"
                                :key="event.id"
                                class="calendar-event"
                                type="button"
                                @click="openItemModal(event)"
                            >
                                <HtEmojiIcon
                                    size="32"
                                    font-size="16"
                                    :style="
                                        {backgroundColor: resourcesInfo[event.task.resource].color }
                                    "
                                >
                                    <HtIcon
                                        :name="
                                            resourcesInfo[event.task.resource].iconName
                                                || 'task-list'
                                        "
                                        stroke-width="2"
                                        size="16"
                                        :style="
                                            {color: resourcesInfo[event.task.resource].iconColor }
                                        "
                                    />
                                </HtEmojiIcon>
                                <div class="event-infos">
                                    <p class="event-name">
                                        {{ event.name }}
                                    </p>
                                    <p class="event-time">
                                        {{ getTimeStart(event) }}
                                    </p>
                                </div>
                                <HtIcon name="nav-arrow-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewUserPlanningEventItem
            ref="userItem"
            from="dashboard"
            @onDelete="onDelete()"
            @onUpdate="onUpdate()"
        />
    </div>
</template>
<script>
import moment from 'moment';

import NewUserPlanningEventItem from '../modals/planningEvent/NewUserPlanningEventItem.vue';

export default {
    name: 'AgendaWidget',
    components: {
        NewUserPlanningEventItem,
    },
    props: {
        events: { type: Array, default: () => [] },
    },

    shared: {
        dashboard: {
            removeIncrementEvent: {
                type: Number,
                default: 0,
            },
        },
    },

    data() {
        return {
            byDayWeekOffset: 0,
            byDaySelectedDay: -1,
            daysWithEvents: [],
            fullWeekName: '',
            mode: 'by-day',
            sortedEvents: [],
            resourcesInfo: {
                custom: {
                    color: '#FFE3AC',
                    iconName: 'task-list',
                    iconColor: '#997328',
                },
                company_survey: {
                    color: '#FFDCCE',
                    iconName: 'chat-bubble-question',
                    iconColor: '#9A3F21',
                },
                company_user_quiz: {
                    color: '#EDE5DE',
                    iconName: 'chat-lines',
                    iconColor: '#694F4F',
                },
                company_nudge: {
                    color: '#F5F4F4',
                    iconName: 'playstation-gamepad',
                    iconColor: '#504F4F',
                },
                company_doc: {
                    color: '#FFE2E2',
                    iconName: 'submit-document',
                    iconColor: '#B73E23',
                },
                company_document: {
                    color: '#D7F5F3',
                    iconName: 'bookmark-book',
                    iconColor: '#4F7075',
                },
                company_planning: {
                    color: '#D9EEFF',
                    iconName: 'group',
                    iconColor: '#305E95',
                },
                company_email_custom: {
                    color: '#FDF2DD',
                    iconName: 'mail',
                    iconColor: '#E6A456',
                },
                company_equipment: {
                    color: '#E3DCFF',
                    iconName: 'computer',
                    iconColor: '#51397A',
                },
                company_software: {
                    color: '#D0FFF4',
                    iconName: 'pc-mouse',
                    iconColor: '#31B59E',
                },
                company_requirement_category: {
                    color: '#FFF9C7',
                    iconName: 'journal',
                    iconColor: '#A69712',
                },
            },
        };
    },

    computed: {
        eventsToShow() {
            return this.daysWithEvents[this.byDaySelectedDay]?.events || [];
        },
    },

    watch: {
        byDayWeekOffset() {
            this.updateWeek();
        },
        events(events) {
            if (events.length) {
                this.sortedEvents = [...events].sort(
                    (a, b) => new Date(b.task.datetime_start) - new Date(a.task.datetime_start),
                );
                this.updateWeek();
            }
        },
    },

    created() {
        if (this.events.length) {
            this.sortedEvents = [...this.events].sort(
                (a, b) => new Date(a.task.datetime_start) - new Date(b.task.datetime_start),
            );
            this.updateWeek();
        }
    },

    methods: {
        getCurrentUserTz() {
            return this.shared.session.companyUser.timezone;
        },
        getTimezone(event) {
            if (event.timezone) {
                return event.timezone;
            }
            return event.user.timezone;
        },
        getTimeStart(event) {
            const date = moment(event.datetime_end).format('YYYY-MM-DD');
            const [hour, minute] = event.time_start.split(':');
            const to = moment.tz(`${date} ${hour}:${minute}:00`, 'YYYY-MM-DD HH:mm:ss', this.getTimezone(event));
            return to.tz(this.getCurrentUserTz()).format('HH:mm');
        },
        updateWeek() {
            this.setWeekData();
            this.setSelectedDay();
        },
        setSelectedDay() {
            // Set new byDaySelectedDay when changing weeks
            const dayToSelect = this.daysWithEvents.findIndex((day) => {
                // we take the first day of the week that have events
                const hasEvents = day.events.length !== 0;
                if (this.byDayWeekOffset === 0) {
                    // if it's "next event week", we want to select the next day with a event
                    const dayIsNotBeforeToday = !day.date.isBefore(new Date(), 'day');
                    return hasEvents && dayIsNotBeforeToday;
                }
                return hasEvents;
            });
            this.byDaySelectedDay = dayToSelect;
        },
        setWeekData() {
            const weekDaysWithEvents = [];

            const nextEvent = this.sortedEvents.find((event) => !this.$Utils.moment(event.task.datetime_start).isBefore(new Date(), 'day'));

            if (nextEvent) {
                const nextEventDate = this.$Utils.moment(
                    nextEvent.task.datetime_start,
                    'YYYY-MM-DD HH:mm:ss',
                );
                const firstDayOfTheWeekToShow = this.$Utils.moment(
                    nextEventDate.startOf('isoWeek').format('YYYY-MM-DD'),
                );
                firstDayOfTheWeekToShow.add(this.byDayWeekOffset, 'weeks');

                const lastDayOfTheWeekToShow = firstDayOfTheWeekToShow.clone().endOf('isoWeek');

                this.fullWeekName = this.translate('From {firstDay} to {lastDay}', {
                    firstDay: firstDayOfTheWeekToShow.format('Do/MM'),
                    lastDay: lastDayOfTheWeekToShow.format('Do/MM'),
                });

                const dayOfTheWeekToShow = firstDayOfTheWeekToShow.clone();

                for (let i = 0; i < 5; i++) {
                    const eventsForTheDay = this.events.filter(
                        (e) => dayOfTheWeekToShow.format('YYYY-MM-DD') === this.$Utils.moment(e.task.datetime_start).format('YYYY-MM-DD'),
                    ).sort(
                        (a, b) => this.$Utils.moment(a.task.datetime_start)
                            .diff(this.$Utils.moment(b.task.datetime_start)),
                    );
                    weekDaysWithEvents.push({
                        date: dayOfTheWeekToShow.clone(),
                        events: eventsForTheDay,
                        current: dayOfTheWeekToShow.isSame(new Date(), 'day'),
                    });
                    dayOfTheWeekToShow.add(1, 'days');
                }
            }
            this.daysWithEvents = weekDaysWithEvents;
        },
        onUpdate() {
            this.shared.dashboard.removeIncrementEvent++;
        },

        onDelete() {
            this.shared.dashboard.removeIncrementEvent++;
        },

        openItemModal(item) {
            this.$refs.userItem.open(item.id, item.company_user_id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.agenda-widget {
    padding: 20px;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    background-color: var(--fill-primary);
}

.agenda-title {
    @include ht-heading-4;
    margin: 0;
}

.agenda-subtitle {
    @include ht-caption;
    color: var(--text-secondary);
}

.agenda-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
}
.agenda-switch {
    flex-shrink: 0;
    box-shadow: 0px 1px 1.5px 0px rgba(82, 88, 102, 0.06);
}

.agenda-switch-btn {
    padding: 4px 8px;
    border-radius: var(--radius-1) 0 0 var(--radius-1);
    border: 1px solid var(--border-primary);
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: var(--letter-spacing-small);
    background-color: var(--fill-primary);
    &:last-child {
        border-radius: 0 var(--radius-1) var(--radius-1) 0;
    }
    &.active {
        color: var(--text-inversed-primary);
        background-color: var(--fill-inversed-primary);
        border-color: var(--fill-inversed-primary);
    }

}

.agenda-week-name {
    @include ht-body;
}

.calendar-days-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.calendar-days {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 6px;
}

.calendar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: var(--radius-1);
    border: 1px solid #E2E4E9;
    box-shadow: 0px 1px 1.5px 0px rgba(82, 88, 102, 0.06);
}

.calendar-day {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 6px 0;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    .calendar-day-number {
        font-size: 13px;
        line-height: 19px;
        letter-spacing: var(--letter-spacing-small);
        font-weight: 500;
    }
    > span:last-child {
        text-transform: lowercase;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--fill-hover);
        border-radius: var(--radius);
        transform-origin: 50% 50%;
        transform: scale(0.7);
        opacity: 0;
        transition: var(--transition-s);
        transition-property: transform, opacity;
    }
    &.selected, &:hover {
        &::before {
            transform: scale(1);
            opacity: 1;
        }
    }
    &.current {
        .calendar-day-number {
            padding: 0 4px;
            border-radius: var(--radius-1);
            color: var(--text-inversed-primary);
            background-color: var(--branding-color);
        }
    }
    &:disabled {
        opacity: 0.2;
    }
}

.calendar-events {
    margin-top: 12px;
}

.calendar-event {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    margin-bottom: 12px;
    z-index: 1;
    .ht-emoji-icon {
        flex-shrink: 0;
        .ht-icon {
            flex-shrink: 0;
            margin-left: 0;
        }
    }
    .ht-icon {
        flex-shrink: 0;
        margin-left: auto;
    }
    &::before {
        content: '';
        position: absolute;
        top: -8px;
        bottom: -8px;
        left: -12px;
        right: -12px;
        background-color: var(--fill-hover);
        opacity: 0;
        border-radius: var(--radius);
        transform: scale(0.9);
        z-index: -1;
        transition:  var(--transition-s);
    }
    &:hover::before {
        opacity: 1;
        transform: scale(1);
    }
}

.calendar-events-by-week {
    margin-top: 20px;
}

.week-day-infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: var(--text-small);
    font-weight: 600;
    line-height: var(--line-height-medium);
    text-transform: uppercase;
    p {
        color: var(--text-inactive);
    }
    &.has-events {
        p {
            color: var(--text-secondary);
        }
    }
    .today {
        padding: 0 4px;
        border-radius: var(--radius-1);
        background-color: var(--branding-color);
        color: var(--text-inversed-primary);
        line-height: 17px;
    }
}

.event-infos {
    margin-left: 12px;
}

.event-name {
    @include ht-body;
}
.event-time {
    @include ht-caption;
    color: var(--text-secondary);
}
</style>
