<template>
    <HtTooltip
        inverted
        position="inline-end"
    >
        <button
            type="button"
            class="mainbar-menu-item-link"
            @click="onClickChat"
        >
            <HtIcon
                name="chat-bubble-empty"
                size="18"
            />
            <span class="mainbar-menu-item-label"><t>Messages</t></span>
            <HtBadge
                v-if="shared.socket.count"
                theme="branding"
            >
                {{ chatCountLabel }}
            </HtBadge>
        </button>
        <template #target>
            <t>Messages</t>
        </template>
    </HtTooltip>
</template>

<script>
export default {
    name: 'HtMainBarMessage',
    shared: {
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
            count: {
                type: Number,
                default: 0,
            },
        },
    },
    computed: {
        chatCountLabel() {
            return this.shared.socket.count < 10 ? this.shared.socket.count : '+9';
        },
    },
    methods: {
        onClickChat() {
            this.shared.socket.chatOpened = !this.shared.socket.chatOpened;
        },
    },
};
</script>
