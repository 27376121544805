<template>
    <div>
        <NewUserPlanningEventItem
            ref="userPlanningEventItem"
            :from="convertToFrom"
            :should-show-availability-date="shouldShowAvailabilityDate"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />
        <NewUserPlanningDelegatedEventEdit
            ref="userPlanningDelegatedEventEdit"
            :should-show-availability-date="shouldShowAvailabilityDate"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />

        <NewUserActionItem
            ref="userActionItem"
            :can-validate="canValidate"
            :should-show-availability-date="shouldShowAvailabilityDate"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />

        <NewUserRequirementCategoryEditModal
            ref="userRequirementCategory"
            :mode-edit="isEdit"
            :should-show-availability-date="shouldShowAvailabilityDate"
            @on-save="onUpdate"
            @on-delete="onDelete"
        />

        <UserSurveyCompletion
            ref="userSurveyCompletion"
            :should-show-availability-date="shouldShowAvailabilityDate"
            @on-save="onUpdate"
            @on-delete="onDelete"
        />
        <HtModalable
            ref="modal"
            :class="['modalable-1', size]"
            :mode="2"
        >
            <template
                v-if="isEmailCustom"
            >
                <component
                    :is="resources[resource]"
                    :id="resourceId"
                    :company-user-id="companyUserId"
                    :company-user-program-id="companyUserProgramId"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    :from="convertToFrom"
                    :variables="emailVariables"
                    @onUpdate="onUpdate"
                    @onDelete="onDelete"
                />
            </template>
            <template
                v-else
            >
                <component
                    :is="resources[resource]"
                    :id="resourceId"
                    :company-user-id="companyUserId"
                    :can-validate="canValidate"
                    :company-user-program-id="companyUserProgramId"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    @onUpdate="onUpdate"
                    @onDelete="onDelete"
                />
            </template>
        </HtModalable>
    </div>
</template>

<script>
import api from '@/store/api';
import modalMixin from '@/components/globals/modals/modalMixin';

// ITEMS
import NewUserPlanningEventItem from '@/components/pages/dashboard/modals/planningEvent/NewUserPlanningEventItem.vue';
import NewUserPlanningDelegatedEventEdit from '@/components/pages/dashboard/modals/planningEvent/NewUserPlanningDelegatedEventEdit.vue';
import NewUserEquipmentItem from '@/components/pages/dashboard/modals/equipment/NewUserEquipmentItem.vue';
import NewUserTrainingItem from '@/components/pages/dashboard/modals/training/NewUserTrainingItem.vue';
import NewUserSoftwareItem from '@/components/pages/dashboard/modals/software/NewUserSoftwareItem.vue';
import NewUserSurveyItem from '@/components/pages/dashboard/modals/survey/NewUserSurveyItem.vue';
import NewUserActionItem from '@/components/pages/dashboard/modals/action/NewUserActionItem.vue';
import NewUserDocItem from '@/components/pages/dashboard/modals/doc/NewUserDocItem.vue';
import NewUserQuizItem from '@/components/pages/dashboard/modals/quiz/NewUserQuizItem.vue';

// EDIT
import UserPlanningEventEdit from '@/components/pages/dashboard/modals/planningEvent/UserPlanningEventEdit.vue';
import UserEquipmentEdit from '@/components/pages/dashboard/modals/equipment/UserEquipmentEdit.vue';
import UserTrainingEdit from '@/components/pages/dashboard/modals/training/UserTrainingEdit.vue';
import UserSoftwareEdit from '@/components/pages/dashboard/modals/software/UserSoftwareEdit.vue';
import UserSurveyEdit from '@/components/pages/dashboard/modals/survey/UserSurveyEdit.vue';
import UserActionEdit from '@/components/pages/dashboard/modals/action/UserActionEdit.vue';
import UserDocEdit from '@/components/pages/dashboard/modals/doc/UserDocEdit.vue';
import UserEmailCustomEdit from '@/components/pages/dashboard/modals/emailCustom/UserEmailCustomEdit.vue';
import UserQuizEdit from '@/components/pages/dashboard/modals/quiz/UserQuizEdit.vue';
import NewUserNudgeItem from '@/components/pages/dashboard/modals/nudge/NewUserNudgeItem.vue';
import UserNudgeEdit from '@/components/pages/dashboard/modals/nudge/UserNudgeEdit.vue';
import NewUserRequirementCategoryEditModal from '@/components/pages/onboarding/NewUserRequirementCategoryEditModal.vue';
import UserSurveyCompletion from '@/components/resources/user/surveys/UserSurveyCompletion.vue';

// FILL
import UserQuizFill from '@/components/pages/dashboard/modals/quiz/UserQuizFill.vue';
import CompanyUser from '@/models/CompanyUser';
import CompanyRole from '../../models/CompanyRole';

export default {
    name: 'NewUserWrapperModal',
    components: {
        UserSurveyCompletion,
        NewUserEquipmentItem,
        NewUserPlanningEventItem,
        NewUserPlanningDelegatedEventEdit,
        NewUserSoftwareItem,
        NewUserTrainingItem,
        NewUserSurveyItem,
        NewUserDocItem,
        NewUserActionItem,

        NewUserRequirementCategoryEditModal,
        UserEquipmentEdit,
        UserPlanningEventEdit,
        UserSoftwareEdit,
        UserTrainingEdit,
        UserSurveyEdit,
        UserDocEdit,
        UserActionEdit,
        UserQuizFill,
    },

    mixins: [modalMixin],
    props: {
        size: { type: String, default: 'medium' },
        isEdit: { type: Boolean, default: false },
        isFill: { type: Boolean, default: false },
        canValidate: { type: Boolean, default: true },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            resources: {
                company_requirement_category: NewUserRequirementCategoryEditModal,
                company_equipment: (this.isEdit === true) ? UserEquipmentEdit : NewUserEquipmentItem,
                company_planning: (this.isEdit === true) ? UserPlanningEventEdit : NewUserPlanningEventItem,
                company_software: (this.isEdit) ? UserSoftwareEdit : NewUserSoftwareItem,
                company_document: (this.isEdit) ? UserTrainingEdit : NewUserTrainingItem,
                company_survey: (this.isFill === true) ? NewUserSurveyItem : (this.isEdit === true) ? UserSurveyEdit : NewUserSurveyItem,
                company_quiz: (this.isFill === true) ? UserQuizFill : (this.isEdit === true) ? UserQuizEdit : NewUserQuizItem,
                company_user_quiz: (this.isFill === true) ? UserQuizFill : (this.isEdit === true) ? UserQuizEdit : NewUserQuizItem,
                company_nudge: (this.isEdit) ? UserNudgeEdit : NewUserNudgeItem,
                company_doc: (this.isEdit === true) ? UserDocEdit : NewUserDocItem,
                company_email_custom: UserEmailCustomEdit,
                custom: (this.isEdit === true) ? UserActionEdit : NewUserActionItem,
            },
            resource: '',
            resourceId: null,
            companyUserId: null,
            emailVariables: [],
            companyUserProgramId: null,
            isUserSurveyCompletionModalOpen: false,
            companyUser: new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'arrival_date',
            ]).with({
                companyRoleUserEmployee: (query) => {
                    query.select([
                        'id',
                        'company_role_user_id',
                    ]).with({
                        roleUser: (query) => {
                            query.select([
                                'id',
                                'company_user_id',
                                'company_role_id',
                            ]).with({
                                user: (query) => {
                                    query.select([
                                        'firstname',
                                        'lastname',
                                    ]);
                                },
                                role: (query) => {
                                    query.select(['name', 'alias']);
                                },
                            });
                        },
                    });
                },
                companyDepartment: (query) => {
                    query.select(['name']);
                },
                companyOffice: (query) => {
                    query.select(['name']);
                },
                company: (query) => {
                    query.select(['name']);
                },
            }),
            resourcesNames: {
                company_doc: this.translate('Documents'),
                company_document: this.translate('Trainings'),
                company_survey: this.translate('Surveys'),
                company_user_quiz: this.translate('Quiz'),
                custom: this.translate('Actions'),
                company_planning: this.translate('Events to organize'),
                company_requirement_category: this.translate('Requirements'),
                company_nudge: this.translate('Nudge'),
                company_software: this.translate('Softwares to install'),
                company_equipment: this.translate('Equipments'),
            },
        };
    },

    shared: {
        socket: {
        },
    },

    computed: {
        isEmailCustom() {
            return this.resource === 'company_email_custom';
        },

        convertToFrom() {
            return (this.isEdit === true) ? 'bop' : 'cop';
        },
    },

    methods: {
        /**
             * @param {Number} enrolleeId
             * @returns {Array}
             */
        loadEmailCustomVariableData(enrolleeId) {
            return this.companyUser.where([['id', '=', enrolleeId]]).get().then((companyUser) => {
                if (!companyUser) return [];

                const supervisors = companyUser.company_role_user_employee.models.map((company_role_user_employee) => company_role_user_employee.role_user);

                const buddy = supervisors.find((item) => item.role.name === CompanyRole.NAME_BUDDY);
                const manager = supervisors.find((item) => item.role.name === CompanyRole.NAME_MANAGER);
                const adminResp = supervisors.find((item) => item.role.name === CompanyRole.NAME_ADMINISTRATIVE_MANAGER);

                return [
                    ...(companyUser ? [{ name: 'enrollee_firstname', label: 'Enrollee firstname', value: companyUser.firstname }] : []),
                    ...(companyUser ? [{ name: 'enrollee_lastname', label: 'Enrollee lastname', value: companyUser.lastname }] : []),
                    ...(companyUser ? [{ name: 'starting_date', label: 'Starting date', value: this.$Utils.moment(companyUser.arrival_date).format('DD MMMM') }] : []),
                    ...(buddy ? [{ name: 'buddy_name', label: 'Buddy name', value: `${buddy.user.firstname} ${buddy.user.lastname}` }] : []),
                    ...(manager ? [{ name: 'manager_name', label: 'Manager name', value: `${manager.user.firstname} ${manager.user.lastname}` }] : []),
                    ...(companyUser.company_department ? [{ name: 'department', label: 'Department name', value: companyUser.company_department.name }] : []),
                    ...(companyUser.company_office ? [{ name: 'site_location', label: 'Site location', value: companyUser.company_office.name }] : []),
                    ...(companyUser.company ? [{ name: 'company_name', label: 'Company name', value: companyUser.company.name }] : []),
                    ...(adminResp ? [{ name: 'admin_responsible_name', label: 'Admin responsible name', value: `${adminResp.user.firstname} ${adminResp.user.lastname}` }] : []),
                ];
            }).catch((data, code) => {
                this.$Notifier('App').showError(`${this.translate('Unable to complete this operation !')} (${code}) `);
            });
        },

        open(task) {
            this.resource = task.resource;
            this.resourceId = task.resource === 'custom' ? task.id : task.resource_id;
            this.companyUserId = task.company_user_id;
            this.companyUserProgramId = task.company_user_program_id;

            if (this.resource === 'company_email_custom') {
                this.loadEmailCustomVariableData(this.companyUserId).then((variables) => {
                    this.emailVariables = variables;
                    this.$refs.modal.open();
                });
            } else if (this.resource === 'company_planning') {
                api.user.planningEvents.get(this.resourceId).then((response) => {
                    const event = response.data.data;
                    if (event.is_delegated) {
                        this.$refs.userPlanningDelegatedEventEdit.open(this.resourceId, this.companyUserId);
                    } else {
                        this.$refs.userPlanningEventItem.open(this.resourceId, this.companyUserId);
                    }
                });
            } else if (this.resource === 'company_requirement_category') {
                this.$refs.userRequirementCategory.open(this.companyUserId, this.resourceId, task);
            } else if (this.resource === 'custom') {
                this.$refs.userActionItem.open(this.resourceId, this.companyUserId);
            } else if (this.isFill === true && this.resource === 'company_survey') {
                api.user
                    .surveys
                    .get(this.resourceId)
                    .then((res) => {
                        const survey = res.data.data;

                        if (!survey.is_conversational) {
                            this.$refs.userSurveyCompletion.open(survey);
                            this.isUserSurveyCompletionModalOpen = true;
                        } else {
                            if (!survey.company_chat_channel_id) {
                                return this.$Notifier('App')
                                    .showWarning(this.translate('This survey will be available in a few minutes. Please try again later.'));
                            }

                            this.shared.socket.chatListChannelComponent
                                .setActive(survey.company_chat_channel_id)
                                .then(() => {
                                    this.shared.socket.chatOpened = true;
                                });
                        }
                    });
            } else {
                this.$refs.modal.open();
            }
        },

        async onUpdate(nextOccurrence) {
            if (nextOccurrence) {
                await this.openNextOccurrencePreviewModal(
                    nextOccurrence,
                    this.translate('Survey completed'),
                    this.translate('Next occurrence planned for '),
                );
            }

            if (this.isUserSurveyCompletionModalOpen) {
                this.$refs.userSurveyCompletion.closeModal();
            }

            this.$emit('onUpdate');
        },

        onDelete() {
            this.$refs.modal.close();
            this.$emit('onDelete');
        },
    },
};
</script>
