import { render, staticRenderFns } from "./HtPageTitle.vue?vue&type=template&id=90b3afbc&scoped=true&"
import script from "./HtPageTitle.vue?vue&type=script&lang=js&"
export * from "./HtPageTitle.vue?vue&type=script&lang=js&"
import style0 from "./HtPageTitle.vue?vue&type=style&index=0&id=90b3afbc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90b3afbc",
  null
  
)

export default component.exports