var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"friezes multiple"},[_vm._l((_vm.shownPrograms),function(program,programIndex){return _c('div',{key:program.id,staticClass:"frieze"},[_c('div',{staticClass:"wrapper-heading"},[_c('div',{staticClass:"heading"},[_c('div',{staticClass:"ht-heading-4"},[_c('t',[_vm._v("My progress in my program")])],1),_c('div',{staticClass:"ht-heading-3"},[_vm._v(" "+_vm._s(_vm.getProgramDescription(program))+" ")])])]),_c('div',{staticClass:"wrapper-body"},[_c('HtTooltip',{attrs:{"position":"block-end","align":"center","offset":-4},scopedSlots:_vm._u([{key:"target",fn:function(){return [_vm._v(" "+_vm._s(_vm.translate('{completion}% of tasks completed', { completion: _vm.programs[programIndex].enrollee_completion, }))+" ")]},proxy:true}],null,true)},[_c('div',{staticClass:"frieze-progress-bar"},[_c('div',{staticClass:"progress-bar-placeholder"}),_c('div',{staticClass:"progress-bar-indicator",style:(`width: calc(${_vm.programs[programIndex].enrollee_completion}*1%);`)})])])],1)])}),(_vm.isMobile)?_c('div',{staticClass:"wrapper-other-links"},[_c('router-link',{staticClass:"other-link",attrs:{"to":{ name: 'TeamMyTeam', label: 'My team'}}},[_c('HtEmojiIcon',{staticClass:"other-link-icon",attrs:{"size":"30","font-size":"16"}},[_vm._v(" 👥 ")]),_c('span',{staticClass:"other-link-text"},[_c('t',[_vm._v("Discover my team")])],1),_c('HtIcon',{staticClass:"other-link-arrow",attrs:{"name":"nav-arrow-right","size":"24"}})],1)],1):_vm._e()],2),_c('router-link',{staticClass:"other-programs",attrs:{"to":{
            label: 'Programs',
            name: 'ProfileProgram',
            params: {
                company_user_id: _vm.shared.session.companyUser.id,
            },
        }}},[_c('span',[_vm._v(_vm._s(_vm.programs.length > 2 ? _vm.getRemainingPrograms : _vm.translate('View my program')))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }