<template>
    <div class="layout">
        <div class="layout__sidebar">
            <slot name="sidebar" />
        </div>
        <div class="layout__container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewPageWithStickySidebar',
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .layout__sidebar {
        flex: 1 1 auto;
    }
    .layout__container {
        min-width: 0;
        flex: 1 1 auto;
        margin: 0;
    }
}

@media (min-width: $tablet-large) {
    .layout {
        flex-direction: row;
        align-items: flex-start;
        gap: 12px;
        .layout__sidebar {
            flex: 0 0 auto;
            width: 210px;
            position: sticky;
            top: 20px;
            left: 0;
        }
    }
}
</style>
