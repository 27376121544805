<template>
    <div
        v-if="isLoaded"
        class="wrapper-statistics"
    >
        <div
            v-if="$canRead('AbstractProgramsDetailsWidget')"
            class="wrapper-top-widgets"
        >
            <ActiveProgramsCountWidget />
            <LateProgramsCountWidget />
        </div>

        <ProgramsDistribution
            :programs="programsWithUsersInProgress"
            :loading="loadingPrograms"
        />

        <ProgramsDetails
            :programs="filteredPrograms"
            :loading="loadingPrograms"
        />

        <ActionsTracking v-if="$canRead('AbstractProgramsDetailsWidget')" />

        <UsersTracking v-if="$canRead('AbstractProgramsDetailsWidget')" />
    </div>
</template>

<script>
import LateProgramsCountWidget from '@/components/pages/piloting/widgets/LateProgramsCountWidget.vue';
import ActiveProgramsCountWidget from '@/components/pages/piloting/widgets/ActiveProgramsCountWidget.vue';
import ProgramsDistribution from '@/components/pages/piloting/widgets/ProgramsDistribution.vue';
import ProgramsDetails from '@/components/pages/piloting/widgets/ProgramsDetails.vue';
import ActionsTracking from '@/components/pages/piloting/widgets/ActionsTracking.vue';
import UsersTracking from '@/components/pages/piloting/widgets/UsersTracking.vue';

import api from '@/store/api';
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'PilotingStatistics',
    permissions() {
        return [
            'AbstractDeadlinesWidget',
            'AbstractNotJoinedWidget',
            'AbstractMostActiveMembersWidget',
            'AbstractNextKeyDatesWidget',
            'AbstractProgramsDetailsWidget',
            'PageBulkEnrolling',
            'AbstractCanSelfEnroll',
            'ModelCompanyProgram',
        ];
    },

    components: {
        LateProgramsCountWidget,
        ActiveProgramsCountWidget,
        ProgramsDistribution,
        ProgramsDetails,
        ActionsTracking,
        UsersTracking,
    },

    data() {
        return {
            loadingPrograms: true,
            programsList: [],
        };
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    computed: {
        isLoaded() {
            return this.permissionsLoaded
                && this.$store.state.entities.entitiesLoaded
                && this.$store.state.programs.programsLoaded;
        },
        filteredPrograms() {
            const accessAll = this.$canUpdate('ModelCompanyProgram') || this.$canCreate('ModelCompanyUserProgram');
            if (accessAll) return this.programsList;
            if (!this.programsList.length) return [];
            return this.programsList.filter((program) => program.user_programs_completed_count > 0
                || program.user_programs_draft_count > 0
                || program.user_programs_in_progress_count > 0
                || program.user_programs_late_count > 0);
        },
        programsWithUsersInProgress() {
            if (!this.filteredPrograms.length) return [];
            return this.filteredPrograms.filter(
                (program) => program.user_programs_in_progress_count > 0,
            );
        },
    },

    created() {
        this.$store.dispatch('entities/fetchEntities');
        this.$store.dispatch('programs/fetchPrograms');
        this.fetchProgramsAndStats();
    },

    methods: {
        fetchProgramsAndStats() {
            api.configuration.programs.getPrograms({ with_statistics: true, with_default_program: false }).then(({ data }) => {
                this.programsList = data.data.map((program) => ({ ...program, name_translated: this.getTranslatedName(program) }));
            }).finally(() => {
                this.loadingPrograms = false;
            });
        },
        getTranslatedName(program) {
            const translated = program.locales.find((l) => l.language_key === this.shared.session.companyUser.company_language.key);
            if (translated) return translated.name;
            return program.name;
        },

    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.wrapper-statistics {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
    > * {
        flex: 0 0 auto;
        width: 100%;
    }
}
.wrapper-top-widgets {
    flex: 1 1 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 20px;
    > *{
        flex: 1 1 0;
    }
}

@media(min-width: $desktop-small){
    .wrapper-statistics {
        flex-direction: row;
        > * {
            width: calc(50% - 10px);
        }
    }
    .wrapper-top-widgets {
        display: flex;
        align-items: stretch;
        flex-direction: row;
    }
}
</style>
