<template>
    <div class="row form-wrapper">
        <StepperTabLayout class="col content">
            <template #main>
                <div class="sub-page-header">
                    <HtSelect
                        v-model="currentSubPage"
                        :options="subPageOptions"
                        fullwidth
                        max-height="none"
                        class="sub-page-select"
                    />
                    <!-- TODO: Outsource this part to a separate component -->
                    <div class="channel-selector branding-border-color">
                        <div
                            :class="[
                                {'branding-bg font-weight-bold': currentCanalType === 'email'},
                                'channel-selector-option',
                            ]"
                            @click="currentCanalType = 'email'"
                        >
                            <t>Email</t>
                        </div>
                        <div
                            :class="[
                                {'branding-bg font-weight-bold': currentCanalType === 'sms'},
                                'channel-selector-option',
                            ]"
                            @click="currentCanalType = 'sms'"
                        >
                            <t>SMS</t>
                        </div>
                    </div>
                </div>
                <HtLanguageSelect
                    :id="'language'"
                    v-model="currentLanguage"
                    :name="'language'"
                    class="tmp"
                />
                <HtFormCard
                    v-if="item.notifications.models[currentIndexNotif].show_enabled"
                >
                    <HtFormSwitch
                        :id="'emailSendingEnabled'"
                        v-model="item.notifications.models[currentIndexNotif].is_enabled"
                        :label="translate('Enable email sending')"
                        :off-label="translate('No')"
                        :on-label="translate('Yes')"
                        :name="'emailSendingEnabled'"
                        :show-optional="false"
                    />
                </HtFormCard>
                <EmailForm
                    v-if="isFormEmail"
                    :item="item"
                    :index-notif="currentIndexNotif"
                    :index-template="currentIndexTemplate"
                    :language="currentLanguage"
                />
                <SmsForm
                    v-else
                    :item="item"
                    :index-notif="currentIndexNotif"
                    :index-template="currentIndexTemplate"
                    :language="currentLanguage"
                />
                <HtFormCard class="mt-5">
                    <HtFormUserOrRoleSelect
                        :id="'sender'"
                        v-model="item.notifications.models[currentIndexNotif].sender_user"
                        v-validate.disable="'required'"
                        :data-vv-as="translate('sender')"
                        :label="translate('Sender')"
                        :name="'sender'"
                        :show-asterisk="true"
                        @on-add="openAddSenderModal"
                        @on-remove="onRemove"
                    />
                    <label class="mb-2"><t>Recipients</t> *</label>
                    <ParticipantList
                        :type="'stacked'"
                        :users="item.notifications.models[currentIndexNotif].recipients.models"
                        :can-add="canAddRecipients"
                        :has-group="true"
                        @onAdd="openAddRecipientsModal"
                    />
                    <input
                        v-validate.disable="'required'"
                        :value="item.notifications.models[currentIndexNotif].recipients && item.notifications.models[currentIndexNotif].recipients.length ? '-' : ''"
                        type="hidden"
                        name="recipients"
                        :data-vv-as="translate('recipients')"
                    >
                    <div
                        v-if="errors.has('recipients')"
                        class="ht-form-error mt-3"
                    >
                        {{ errors.first('recipients') }}
                    </div>
                    <HtFormInput
                        :id="'sending date'"
                        v-validate.disable="'required'"
                        :name="'sending date'"
                        :label="translate('Sending date')"
                        :value="sendingDateLabel"
                        :disabled="true"
                    />
                    <HtTextDescription
                        :text="sendingDateDescription"
                    />
                </HtFormCard>
            </template>
            <template #buttonsFooter>
                <Button
                    class="button"
                    @click="onClick"
                >
                    <t>Save</t>
                </Button>
            </template>
            <template #modalables>
                <modalable
                    ref="modalableSearchUser"
                    class="modalable-1 small"
                    :options="{ title: translate('Add sender') }"
                >
                    <SearchRolePanel
                        ref="searchUserPanel"
                        :has-roles="false"
                        @onValidate="$refs.modalableSearchUser.close()"
                    />
                </modalable>
                <modalable
                    ref="modalableSearchRole"
                    class="modalable-1 small"
                    :options="{ title: translate('Add recipient') }"
                >
                    <SearchRolePanel
                        ref="searchRolePanel"
                        :multiple-mode="true"
                        :is_assignable_onboardee="true"
                        @onValidate="$refs.modalableSearchRole.close()"
                    />
                </modalable>
            </template>
        </StepperTabLayout>
    </div>
</template>

<script>
import HtFormCard from '@/components/globals/HtFormCard.vue';
import CompanyProgram from '@/models/CompanyProgram';
import HtFormVariables from '@/components/globals/HtFormVariables.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtTextDescription from '@/components/globals/HtTextDescription.vue';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import Button from '@/components/globals/Button.vue';
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import CompanyProgramNotificationTemplate from '@/models/CompanyProgramNotificationTemplate';
import MenuList from '@/components/globals/MenuListItem/MenuList.vue';
import { MenuListItem } from '@/components/globals/MenuListItem/MenuListItemClass';
import I18n from '@/modules/i18n/I18n';
import SmsForm from './email/SmsForm.vue';
import EmailForm from './email/EmailForm.vue';

export default {
    name: 'NewProgramFormEmail',
    components: {
        HtFormCard,
        HtFormVariables,
        HtFormInput,
        HtFormEditor,
        HtFormSwitch,
        HtFormUserOrRoleSelect,
        HtTextDescription,
        StepperTabLayout,
        Button,
        HtLanguageSelect,
        SmsForm,
        MenuList,
        EmailForm,
    },
    props: {
        item: {
            type: CompanyProgram,
            required: true,
        },
    },
    data() {
        return {
            currentLanguage: this.shared.session.company.company_language,
            currentCanalType: CompanyProgramNotificationTemplate.CANAL_TYPE_EMAIL,
            templates: [
                new MenuListItem(
                    I18n.translate('Invite the program recipient'),
                    { slug: CompanyProgramNotificationTemplate.TEMPLATE_INVITE },
                ),
                new MenuListItem(
                    I18n.translate('Invite other program participants'),
                    { slug: CompanyProgramNotificationTemplate.TEMPLATE_INVITE_ON_PROGRAM },
                ),
                new MenuListItem(
                    I18n.translate('Enrollee program finished'),
                    { slug: CompanyProgramNotificationTemplate.TEMPLATE_ENROLLEE_PROGRAM_FINISHED },
                ),
                new MenuListItem(
                    I18n.translate('Recipients program finished'),
                    { slug: CompanyProgramNotificationTemplate.TEMPLATE_RECIPIENTS_PROGRAM_FINISHED },
                ),
            ],
            currentSubPage: {
                label: I18n.translate('Invite the program recipient'),
                value: CompanyProgramNotificationTemplate.TEMPLATE_INVITE,
            },
            subPageOptions: [
                {
                    label: I18n.translate('Invite the program recipient'),
                    value: CompanyProgramNotificationTemplate.TEMPLATE_INVITE,
                },
                {
                    label: I18n.translate('Invite other program participants'),
                    value: CompanyProgramNotificationTemplate.TEMPLATE_INVITE_ON_PROGRAM,
                },
                {
                    label: I18n.translate('Enrollee program finished'),
                    value: CompanyProgramNotificationTemplate.TEMPLATE_ENROLLEE_PROGRAM_FINISHED,
                },
                {
                    label: I18n.translate('Recipients program finished'),
                    value: CompanyProgramNotificationTemplate.TEMPLATE_RECIPIENTS_PROGRAM_FINISHED,
                },
            ],
        };
    },
    computed: {
        currentTemplate() {
            return this.currentSubPage.value;
        },
        sendingDateLabel() {
            return this.isFinalizedEmailSelected ? I18n.translate('End of program') : I18n.translate('Program creation');
        },

        sendingDateDescription() {
            if (this.isFinalizedEmailSelected) {
                return I18n.translate('This e-mail will be sent automatically as soon as the enrollee’s program is finalized.');
            }

            return I18n.translate('This email will be automatically sent as soon as a collaborator is added to the program.');
        },

        isFinalizedEmailSelected() {
            return [CompanyProgramNotificationTemplate.TEMPLATE_ENROLLEE_PROGRAM_FINISHED, CompanyProgramNotificationTemplate.TEMPLATE_RECIPIENTS_PROGRAM_FINISHED].includes(this.currentTemplate);
        },

        /**
         * @returns {boolean}
         */
        isFormEmail() {
            return this.currentCanalType === CompanyProgramNotificationTemplate.CANAL_TYPE_EMAIL;
        },

        /**
         * current CompanyProgramNotification
         * @returns {number}
         */
        currentIndexNotif() {
            return this.item.notifications.models.findIndex((element) => element.template_slug === this.currentTemplate);
        },

        /**
         * current CompanyProgramNotificationTemplate
         * @returns {number}
         */
        currentIndexTemplate() {
            return this.item.notifications.models[this.currentIndexNotif].templates.models.findIndex((element) => element.canal_type === this.currentCanalType);
        },

        /**
         * Seul l'onboardee reçoit la notif pour le template "invite"
         *
         * @returns {boolean}
         */
        canAddRecipients() {
            return this.item.notifications.models[this.currentIndexNotif].template_slug !== CompanyProgramNotificationTemplate.TEMPLATE_INVITE;
        },
    },

    methods: {
        /**
         * @param {MenuListItem} emit
         * @returns {void}
         */
        onSelect(emit) {
            this.currentTemplate = emit.value.slug;
        },

        openAddSenderModal() {
            this.$refs.searchUserPanel.init({
                onSelect: (user) => {
                    this.item.notifications.models[this.currentIndexNotif].sender_user_id = user.id;
                    this.item.notifications.models[this.currentIndexNotif].sender_user = user;
                    this.$refs.modalableSearchUser.close();
                },
            });
            this.$refs.modalableSearchUser.open();
        },

        onRemove() {
            this.item.notifications.models[this.currentIndexNotif].sender_user_id = null;
            this.item.notifications.models[this.currentIndexNotif].sender_user = null;
        },

        openAddRecipientsModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addRecipients,
                selected: this.item.notifications.models[this.currentIndexNotif].recipients.models,
            });
            this.$refs.modalableSearchRole.open();
        },

        addRecipients(participants) {
            this.item.notifications.models[this.currentIndexNotif].recipients = participants;
        },

        /**
         * Validate data and save
         * @returns {void}
         */
        async onClick() {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                await this.item.save();
                this.$router.push({ name: 'Programs' });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.sub-page-header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.sub-page-select {
    flex-grow: 1;
    margin-right: 18px;
    max-width: 400px;
}

.button {
    align-self: center;
    margin-bottom: 1rem;
}

.channel-selector {
    // Aligning the element to the right
    margin-left: auto;
    width: min-content;

    display: flex;
    border-radius: 0.4rem;
    cursor: pointer;
    font-size: 1.4rem;

    .channel-selector-option {
        padding: 0.8rem 1.2rem;
    }
}

.content {
    max-width: 100%;
}
</style>
