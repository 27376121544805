<template>
    <div class="ht-text-field">
        <HtIcon
            v-if="leadIcon"
            class="ht-text-field-lead-icon"
            :name="leadIcon"
            stroke-width="2"
        />
        <input
            :id="id"
            ref="input"
            class="ht-text-field-input"
            :class="{'has-lead-icon': leadIcon }"
            :name="name"
            :value="value"
            type="text"
            :placeholder="placeholder"
            :disabled="disabled"
            :data-cy="cypress"
            @input="$emit('input', $event.target.value)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @keyup.13="$emit('onKeyupEnter')"
        >
    </div>
</template>

<script>

export default {
    name: 'HtTextField',

    props: {
        value: {
            type: [Number, String],
            default: () => '',
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        leadIcon: {
            type: String,
            required: false,
            default: '',
        },
    },

};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-text-field {
    position: relative;
}

.ht-text-field-lead-icon {
    position: absolute;
    top: 10px;
    left: 16px;
}

.ht-text-field-input {
    width: 295px;
    padding: 8px 16px;
    border-radius: var(--radius);
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    font-family: var(--poppins);
    @include ht-body;
    line-height: 1;
    transition: box-shadow 0.2s ease-out;
    &.has-lead-icon {
        padding-left: 40px;
    }
    &::placeholder {
        color: var(--text-inactive);
    }
    &:hover {
        box-shadow: var(--shadow-1);
    }
    &:focus {
        border-color: var(--border-primary) !important;
        outline: none;
        box-shadow: var(--focus-ring);
    }
}
</style>
