<template>
    <div v-if="companyUserSurveys.isLoaded()">
        <div
            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#FFDCCE' }"
                >
                    <HtIcon
                        name="chat-bubble-question"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#9A3F21' }"
                    />
                </HtEmojiIcon>
                {{ translate('Surveys') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ companyUserSurveys.$.models.length }}
                </HtBadge>
                <HtIcon
                    v-if="companyUserSurveys.$.models.length"
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <button
                    v-for="item in companyUserSurveys.$.models"
                    :key="item.id"
                    class="item"
                    @click="openItemModal(item.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ item.name }}
                        </div>
                        <div class="item-program">
                            {{ translate('Program "{programName}"', { programName: item.company_user_program.company_program.name_translated }) }}
                        </div>
                    </div>

                    <HtAvatarGroup
                        v-if="item.company_user_participant"
                        size="s"
                        :users="$Utils.getAvatarGroupData([item.company_user_participant])"
                    />

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[item.company_user_program_task.status]" />
                        <t>
                            {{ item.company_user_program_task.statusText }}
                        </t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>
        <modalable
            ref="modalableItem"
            class="modalable-1 medium"
            :mode="2"
        >
            <NewUserSurveyItem
                :id="currentId"
                ref="userItem"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import NewUserSurveyItem from '@/components/pages/dashboard/modals/survey/NewUserSurveyItem.vue';
import CompanyUserSurveyCollection from '@/models/CompanyUserSurveyCollection';
import resourceEventBus from '@/eventBus/resourceEventBus';

export default {
    name: 'NewUserSurveyProfile',
    components: {
        NewUserSurveyItem,
    },
    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            open: false,
            badgeStates: {
                done: 'success',
                pending: 'neutral',
                draft: 'warning',
                in_progress: 'primary',
            },
            currentId: null,
            companyUserSurveys: new CompanyUserSurveyCollection([
                'id', 'company_user_program_id', 'company_user_id', 'company_survey_id', 'permissions',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'status',
                        'datetime_availability',
                    ]);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUserParticipant: (query) => {
                    query
                        .select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companySurvey: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            resource: (query) => {
                                query.select([
                                    'id',
                                    'name',
                                ]);
                            },
                        });
                },
            })
                .where([
                    ['company_user_id', '=', this.companyUserId],
                ]),
        };
    },

    created() {
        this.companyUserSurveys.get().then(() => {
            this.$emit('on-load', this.companyUserSurveys.$.models.length);
        });
        resourceEventBus.$on('survey-done', () => {
            this.companyUserSurveys.get();
        });
    },

    methods: {
        toggle() {
            if (this.companyUserSurveys.$.models.length) this.open = !this.open;
        },

        getParticipant(item) {
            return [{ ...item.company_user_participant, company_user: item.company_user_participant }];
        },

        onUpdate() {
            this.companyUserSurveys.get();
        },

        onDelete() {
            this.companyUserSurveys.get();
        },

        openItemModal(id) {
            this.currentId = id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>

<style lang="scss" scoped src="../ressourceItem.scss" />
