<template>
    <div class="ht-page-title">
        <router-link
            v-if="backRoute"
            :to="backRoute"
        >
            <FontAwesomeIcon
                class="icon"
                :icon="['far', 'arrow-left']"
            />
        </router-link>
        <h2>
            <slot />
        </h2>
    </div>
</template>

<script>
export default {
    props: {
        backRoute: {
            type: Object,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.icon {
    border: 1px solid $grey-ultra-light;
    border-radius: 50%;
    padding: 0.4rem;
    width: 2rem;
    height: 2rem;
}

@media (max-width: $tablet) {
    .ht-page-title {
        display: flex;
        gap: 0.8rem;
        align-items: center;
        margin: 0 0 16px;
        padding: 0;

        .old-ui & {
            // Compensating the Index margin
            background-color: white;
            margin: -4.5rem 0 0;
            padding: 2.2rem 2rem 0.2rem;
        }
    }

    h2 {
        margin: 0;
    }
}

@media (min-width: $tablet) {
    .ht-page-title {
        display: none;
    }
}
</style>
