<template>
    <div class="team-filters">
        <HtTextField
            id="search_query"
            name="search_query"
            :value="queryValue"
            :placeholder="translate('User')"
            lead-icon="search"
            @input="updateQueryFilter"
        />

        <HtSelect
            :value="selectedValuesByEntityId[officeEntity.id][0]"
            :disabled="hasFilterOffice"
            :label="translate('Sites')"
            :options="valuesByEntityId[officeEntity.id]"
            @input="value => updateEntitiesFilter(officeEntity, value)"
        />

        <HtSelect
            :value="selectedValuesByEntityId[departmentEntity.id][0]"
            :disabled="hasFilterDepartment"
            :label="translate('Departments')"
            :options="valuesByEntityId[departmentEntity.id]"
            @input="value => updateEntitiesFilter(departmentEntity, value)"
        />

        <HtButton
            class="more-filter-btn"
            type="secondary"
            @click="$refs.modal.open()"
        >
            <HtIcon
                name="plus-circle"
                size="16"
                stroke-width="2"
            />
            {{ otherFiltersLabel }}
        </HtButton>
        <TeamFiltersModal ref="modal" />
        <HtSelect
            v-model="teamView"
            class="view-filter"
            :label="translate('View type')"
            :options="teamViewOptions"
        />
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import debounce from 'lodash.debounce';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import TeamFiltersModal from './TeamFiltersModal.vue';

export default {
    name: 'NewTeamFilters',
    components: {
        TeamFiltersModal,
        HtFormInput,
        HtButton,
    },

    data() {
        return {
            teamView: {
                label: this.translate('Card'),
                value: 'card',
            },
            teamViewOptions: [
                {
                    label: this.translate('Card'),
                    value: 'card',
                },
                {
                    label: this.translate('List'),
                    value: 'list',
                },
            ],
        };
    },

    computed: {
        officeEntity() {
            return this.$store.getters['entities/officeEntity'];
        },
        departmentEntity() {
            return this.$store.getters['entities/departmentEntity'];
        },
        entities() {
            return this.$store.state.entities.entities.filter((entity) => [this.officeEntity.id, this.departmentEntity.id].includes(entity.id));
        },
        hasFilterOffice() {
            return this.$store.state.team.hasFilterOffice;
        },
        hasFilterDepartment() {
            return this.$store.state.team.hasFilterDepartment;
        },
        queryValue() {
            return this.$store.state.team.filters.query;
        },
        valuesByEntityId() {
            const valuesByEntityId = this.$store.getters['entities/valuesByEntityId'];

            for (const [key, values] of Object.entries({ ...valuesByEntityId })) {
                valuesByEntityId[key] = [
                    {
                        name: this.translate('All'),
                        company_entity_value_id: 'all',
                        company_entity_id: key,
                    },
                    ...values.sort((a, b) => a.name.localeCompare(b.name)),
                ].map((v) => ({ ...v, label: v.name }));
            }

            return valuesByEntityId;
        },
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.$store.state.team.filters.entities, 'company_entity_id');

            this.entities.forEach((entity) => {
                entities[entity.id] = valuesByEntityId[entity.id] || [{
                    label: this.translate('All'),
                    company_entity_value_id: 'all',
                    company_entity_id: entity.id,
                }];
            });

            return entities;
        },
        otherFiltersCount() {
            const values = this.$store.state.team.filters.entities.filter((value) => this.entities.map((e) => e.id).includes(value.company_entity_id) === false);

            return values.length;
        },
        otherFiltersLabel() {
            return this.otherFiltersCount === 0
                ? this.translate('More filters')
                : this.translate('{count} filter | {count} filters', { count: this.otherFiltersCount });
        },
    },

    watch: {
        teamView(tv) {
            this.$emit('viewChange', tv.value);
        },
    },

    methods: {
        updateEntitiesFilter(entity, value) {
            const values = value.company_entity_value_id === 'all' ? [] : [value];
            this.$store.dispatch('team/setEntitiesFilter', { company_entity_id: entity.id, values });
        },
        updateQueryFilter: debounce(function (query) {
            this.$store.dispatch('team/setQueryFilter', query);
        }, 200),
    },
};
</script>

<style lang="scss" scoped>
.team-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
}
.more-filter-btn {
    margin-right: auto;
    ::v-deep .ht-button-inner {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
</style>
