<template>
    <IndicatorTemplate
        icon="⚠️"
        :keyfigure="keyfigure"
        :label="label"
        :loading="loading"
        @click="goToPrograms"
    />
</template>

<script>
import IndicatorTemplate from '@/components/pages/piloting/widgets/IndicatorTemplate.vue';

export default {
    name: 'LateProgramsCountWidget',
    components: {
        IndicatorTemplate,
    },
    computed: {
        keyfigure() {
            return this.$store.getters['dashboard/statistics/lateProgramsCount'];
        },
        label() {
            return this.translate('late programs');
        },
        loading() {
            return !this.$store.state.dashboard.statistics.lateProgramsCountLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchLateProgramsCount');
                }
            },
            immediate: true,
        },
    },
    methods: {
        goToPrograms() {
            this.$store.dispatch('dashboard/programs/clearAllFilters');
            this.$store.dispatch('dashboard/transferFiltersToPrograms');
            this.$router.push({
                name: 'ProgramsTracking',
                query: {
                    status: ['late'],
                },
            });
        },
    },
};
</script>
