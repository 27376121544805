import { render, staticRenderFns } from "./TasksListByUserItem.vue?vue&type=template&id=565f7501&scoped=true&"
import script from "./TasksListByUserItem.vue?vue&type=script&lang=js&"
export * from "./TasksListByUserItem.vue?vue&type=script&lang=js&"
import style0 from "./TasksListByUserItem.vue?vue&type=style&index=0&id=565f7501&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "565f7501",
  null
  
)

export default component.exports