<template>
    <HtModal ref="modal">
        <template #header>
            {{ header }}
        </template>
        <template>
            <component
                :is="component"
                v-if="integration"
                ref="integration-component"
                v-model="integration"
                @on-save="onSave"
                @on-close-modal="close"
            />
        </template>
        <template #footer-actions>
            <component
                :is="componentFooter"
                :integration="integration"
                @on-cancel="onCancel"
                @on-make-api-call="onMakeApiCall"
                @on-validate="onValidate"
            />
        </template>
    </HtModal>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import DocuSignEdit from '@/components/resources/integration/DocuSignEdit.vue';
import IntegrationFooterEdit from '@/components/resources/integration/IntegrationFooterEdit.vue';
import DocuSignFooterEdit from '@/components/resources/integration/DocuSignFooterEdit.vue';
import GSuiteEdit from '@/components/resources/integration/GSuiteEdit.vue';
import SmartRecruitersEdit from './SmartRecruitersEdit.vue';
import LeverEdit from './LeverEdit.vue';

export default {
    name: 'IntegrationEditModal',
    data() {
        return {
            integration: null,
        };
    },
    computed: {
        header() {
            return this.integration.key;
        },
        component() {
            const components = {
                'API.SMARTRECRUITERS.CLIENT-CREDENTIALS': SmartRecruitersEdit,
                'API.DOCUSIGN.CLIENT-CREDENTIALS': DocuSignEdit,
                'API.LEVER.CLIENT-CREDENTIALS': LeverEdit,
                'API.GSUITE.CLIENT-CREDENTIALS': GSuiteEdit,
            };

            return components[this.integration.type_key];
        },
        componentFooter() {
            const components = {
                'API.DOCUSIGN.CLIENT-CREDENTIALS': DocuSignFooterEdit,
            };

            return components[this.integration.type_key] || IntegrationFooterEdit;
        },
        integrations() {
            return this.$store.getters['configuration/configurations/getConfigurations'];
        },
    },
    methods: {
        open(integrationId) {
            this.integration = clonedeep(this.integrations.find((c) => c.id === integrationId));

            this.$refs.modal.open();
        },
        close() {
            this.$emit('on-fetch-config');
            this.$refs.modal.close();
        },
        onSave() {
            this.$store.dispatch('configuration/configurations/update', this.integration).then(() => {
                this.close();
            });
        },
        onValidate() {
            this.$refs['integration-component'].onValidate();
        },
        onCancel() {
            this.$refs['integration-component'].onCancel();
        },
        onMakeApiCall() {
            this.$refs['integration-component'].onMakeApiCall();
        },
    },
};
</script>
