import { render, staticRenderFns } from "./NewUserTrainingProfile.vue?vue&type=template&id=175dd0bb&scoped=true&"
import script from "./NewUserTrainingProfile.vue?vue&type=script&lang=js&"
export * from "./NewUserTrainingProfile.vue?vue&type=script&lang=js&"
import style0 from "../ressourceItem.scss?vue&type=style&index=0&id=175dd0bb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175dd0bb",
  null
  
)

export default component.exports