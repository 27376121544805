import AllActions from '@/router/pages/actions/AllActions.vue';
import Actions from '@/router/pages/Actions.vue';
import ProgramActions from '@/router/pages/actions/ProgramActions.vue';

export default [
    {
        path: '/Actions',
        name: 'Actions',
        component: Actions,
        children: [
            {
                path: 'Program/:program_id',
                name: 'ProgramActions',
                component: ProgramActions,
                props: (route) => ({ companyProgramId: Number(route.params.program_id) }),
            },
            {
                path: 'All',
                name: 'AllActions',
                component: AllActions,
            },

        ],
    },
];
