<template>
    <div
        id="historyWidget"
        class="history"
        data-cy="history"
    >
        <div class="history-header">
            <div class="ht-heading-3">
                <t>Actions History</t>
            </div>
            <div class="history-sort">
                <HtSelectSimple
                    :value="sortingType"
                    :label="translate('Sort by')"
                    :options="sortingTypes"
                    @input="onChangeView"
                />
            </div>
        </div>
        <div>
            <Component
                :is="`NewHistoryBy${$Utils.capitalize(sortingType.value)}`"
                v-if="sortTypeLoaded"
            />
        </div>
    </div>
</template>
<script>
import HtSelectSimple from '@/components/globals/HtSelectSimple.vue';
import NewHistoryByDate from './NewHistoryByDate.vue';
import NewHistoryByAction from './NewHistoryByAction.vue';
import NewHistoryByUser from './NewHistoryByUser.vue';
import tasksSortingMixin from '../listTasks/tasksSortingMixin';

export default {
    name: 'NewHistoryWidget',
    components: {
        NewHistoryByDate,
        NewHistoryByAction,
        NewHistoryByUser,
        HtSelectSimple,
    },
    mixins: [tasksSortingMixin],
    data() {
        return {
            sortKeyLocalStorage: 'defaultSortedHistory',
        };
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.history-header{
    display: flex;
    justify-content: space-between;
    .ht-heading-3 {
        margin-bottom: 12px;
    }
}

@media(min-width: $tablet-large) {
    .history-header{
        .ht-heading-3 {
            margin-bottom: 20px;
        }
    }
}
</style>
