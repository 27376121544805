import Model from '@tony.caron/node-model-proxy/Model';
import Vue from 'vue';
import get from 'lodash.get';
import Notifier from '@/Notifier';
import HelloSign from 'hellosign-embedded';
import Env from '@/Env';
import CompanyUser from './CompanyUser';
import CompanyFile from './CompanyFile';
import CompanyRequirement from './CompanyRequirement';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import I18n from '../modules/i18n/I18n';
import CompanyUserRequirementCollection from './CompanyUserRequirementCollection';

export default class CompanyUserRequirement extends Model {
    static encoded_type = {
        multiple_select: 1, single_select: 1, iban: 1, address: 1,
    };

    static TYPE_MULTIPLE_SELECT = 'multiple_select';

    static TYPE_SINGLE_SELECT = 'single_select';

    static TYPE_IBAN = 'iban';

    static TYPE_ADDRESS = 'address';

    static TYPE_HEXAPOST = 'hexapost';

    static TYPE_DYNAMIC_DOCUMENT = 'dynamic_document';

    static TYPE_FILE_TO_FILL = 'file_to_fill';

    static TYPE_FILE_TO_SIGN = 'file_to_sign';

    static STATUS_DONE = 'done';

    modelConfig() {
        return {
            controller: 'CompanyUserRequirementController',
            collection: CompanyUserRequirementCollection,
        };
    }

    modelDefaultValues() {
        return {
            question_multiple_value: [],
            type: 'text',
            is_heyteam: 0,
        };
    }

    modelCustomAttributes() {
        return {
            company_files: [],
        };
    }

    modelAccessors() {
        return {
            name: (n) => (this.is_heyteam ? I18n.translate(n) : n),
        };
    }

    modelInConvertor() {
        return {
            question_multiple_value: (n) => JSON.parse(n),
            can_fill_document: (n) => Boolean(n),
            value: (value) => {
                if (value !== null && this.type in CompanyUserRequirement.encoded_type) {
                    return JSON.parse(value);
                }
                switch (this.type) {
                case CompanyUserRequirement.TYPE_MULTIPLE_SELECT:
                    return this.question_multiple_value.constructor === Array ? this.question_multiple_value.map((item) => ({ value: item.value, selected: false })) : null;
                case CompanyUserRequirement.TYPE_IBAN:
                    return {
                        owner: '',
                        iban: '',
                        bic: '',
                    };
                case CompanyUserRequirement.TYPE_ADDRESS:
                    return {
                        google: {
                            address_components: [],
                            formatted_address: '',
                            place_id: '',
                        },
                        value: '',
                    };
                default:
                    return value;
                }
            },
        };
    }

    modelOutConvertor() {
        return {
            company_files: (n) => {
                if (n) {
                    return n.filter((company_file) => company_file);
                }

                return n;
            },
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyRequirement: () => this.belongsTo(CompanyRequirement, 'company_requirement_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyFileSecondary: () => this.belongsTo(CompanyFile, 'company_file_secondary_id', 'id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'resource_id', 'id'),
        };
    }

    isCompleted() {
        return CompanyUserRequirement.isRequirementCompleted(this);
    }

    static isRequirementCompleted(requirement) {
        switch (requirement.type) {
        case CompanyUserRequirement.TYPE_MULTIPLE_SELECT:
            return requirement.value && requirement.value.some((item) => item.selected === true);
        case CompanyUserRequirement.TYPE_IBAN:
            return !!(requirement.value && requirement.value.iban && requirement.value.owner);
        case CompanyUserRequirement.TYPE_ADDRESS:
            return !!(requirement.value && requirement.value.value && requirement.value.google.address_components.length > 0);
        case CompanyUserRequirement.TYPE_DYNAMIC_DOCUMENT:
            return !!(requirement.company_file);
        default:
            return !!(requirement.status === 'done' || requirement.value || requirement.company_file || requirement.company_file_id);
        }
    }

    isTextRequirement() {
        const types = ['string', 'text', 'social security number', 'phone'];
        return types.includes(this.type);
    }

    isAddressRequirement() {
        return this.type === 'address';
    }

    isDateRequirement() {
        return this.type === 'date';
    }

    isIbanRequirement() {
        return this.type === 'iban';
    }

    isSingleSelectRequirement() {
        return this.type === 'single_select';
    }

    isMultipleSelectRequirement() {
        return this.type === 'multiple_select';
    }

    isFileRequirement() {
        return this.type === 'file';
    }

    isFileToFillRequirement() {
        return this.type === 'file_to_fill';
    }

    canDownload() {
        return 'company_file_id' in this && this.company_file_id !== null && typeof this.company_file_id !== 'undefined';
    }

    /**
     * @returns {string} url de l'instance d'HelloSign à ouvrir
     */
    async loadHelloSignUrl() {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post(`miscellaneous/hellosign/url/${this.id}`).then((response) => {
                resolve(response.data.signature_url);
            }).catch(({ response }) => {
                const message = get(response, 'data.message', null);

                if (message) Notifier.getInstance('App').showError(message);

                reject();
            });
        });
    }

    async updateHelloSignSignature() {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post(`miscellaneous/hellosign/update/${this.id}`).then(() => {
                resolve();
            }).catch(({ response }) => {
                const message = get(response, 'data.message', null);

                if (message) Notifier.getInstance('App').showError(message);

                reject();
            });
        });
    }

    signDocument(clientId, onSignCallback) {
        return new Promise((resolve, reject) => {
            if (this.date_signed) {
                reject();
            }

            this.loadHelloSignUrl().then((url) => {
                const client = new HelloSign({
                    clientId,
                    skipDomainVerification: Env.get('APP_ENV') !== 'production',
                    debug: Env.get('APP_ENV') !== 'production',
                });

                client.open(url, {
                    testMode: Env.get('APP_ENV') !== 'production',
                });

                client.on('sign', () => {
                    this.updateHelloSignSignature();
                    onSignCallback();
                });

                client.on('error', () => {
                    client.close();
                });

                resolve();
            });
        });
    }
}
