<template>
    <div
        :class="[
            { 'success': width === 100 },
            'progress-bar',
        ]"
        :style="{ 'width': `${width}%` }"
    />
</template>
<script>
export default {
    props: {
        /**
         * largeur de la barre de progression
         */
        width: {
            type: Number,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
.progress-bar{
    height: 2px;
    background: var(--branding-color);
    transition: $transition;
}
.success{
    background: var(--fill-success);
    transition: background $transition, opacity $transition 0.3s;
}

</style>
