<template>
    <div v-if="companyUserDocCollection && companyUserDocCollection.isLoaded()">
        <div

            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#FFE2E2' }"
                >
                    <HtIcon
                        name="submit-document"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#B73E23' }"
                    />
                </HtEmojiIcon>
                {{ translate('Documents') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ filteredUserDoc.length }}
                </HtBadge>
                <HtIcon
                    v-if="filteredUserDoc.length"
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <button
                    v-for="item in filteredUserDoc"
                    :key="item.id"
                    class="item"
                    @click="openItemModal(item.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ item.name }}
                        </div>
                        <div class="item-program">
                            {{ translate('Program "{programName}"', { programName: item.company_user_program.company_program.name_translated }) }}
                        </div>
                    </div>

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[item.company_user_program_task.status]" />
                        <t>
                            {{ item.company_user_program_task.statusText }}
                        </t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>

        <Modalable
            ref="modalableItem"
            :mode="2"
            class="modalable-1 small"
        >
            <NewUserDocItem
                :id="id"
                :company-user-id="companyUserId"
                :status-on-toggle="statusOnToggle"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import NewUserDocItem from '@/components/pages/dashboard/modals/doc/NewUserDocItem.vue';
import CompanyUserDocCollection from '@/models/CompanyUserDocCollection';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'NewUserDocumentProfile',
    components: {
        NewUserDocItem,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        statusOnToggle: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
        statusOnCreate: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
    },

    data() {
        return {
            open: false,
            badgeStates: {
                done: 'success',
                pending: 'neutral',
                draft: 'warning',
                cancelled: 'error',
            },
            id: null,
            companyUserDocCollection: new CompanyUserDocCollection([
                'id', 'company_user_id', 'company_doc_id', 'name', 'is_heyteam',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'resource_id', 'resource', 'status', 'datetime_availability']);
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]).orderBy([['name', 'asc']]),
        };
    },

    computed: {
        canModify() {
            return this.$canUpdate('ModelCompanyUserDoc', { company_user_id: this.companyUserId });
        },

        filteredUserDoc() {
            return this.companyUserDocCollection.$.models.filter((item) => ((this.shared.session.companyUser.id === this.companyUserId)
                ? this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability))
                : true));
        },
    },

    created() {
        this.companyUserDocCollection.get().then(() => {
            this.$emit('on-load', this.companyUserDocCollection.$.models.length);
        });
    },

    methods: {
        toggle() {
            if (this.filteredUserDoc.length) this.open = !this.open;
        },
        onDelete() {
            this.companyUserDocCollection.get();
        },

        onUpdate() {
            this.companyUserDocCollection.get();
        },

        openItemModal(id) {
            this.id = id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>
<style lang="scss" scoped src="../ressourceItem.scss" />
