<template>
    <div>
        <NewFileIsAlreadySetMode
            v-if="fileAlreadySet"
            :requirement="companyUserRequirement"
            @on-remove="onRemove"
        />

        <NewNoFileSetMode
            v-else
            :requirement="companyUserRequirement"
            :is-participant="isParticipant"
            :is-disabled="isDisabled"
            @on-remove="onRemove"
            @on-upload-end="onUploadEnd(...arguments)"
        />

        <small
            v-if="errorMessage"
            class="ht-form-error"
        >
            {{ errorMessage }}
        </small>
    </div>
</template>

<script>
import UserRequirementEditMixin from '../UserRequirementEditMixin';
import NewFileIsAlreadySetMode from './NewFileIsAlreadySetMode.vue';
import NewNoFileSetMode from './NewNoFileSetMode.vue';

export default {
    name: 'NewUserRequirementFileEdit',

    components: {
        NewNoFileSetMode, NewFileIsAlreadySetMode,
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hasChanged: false,
            errorMessage: null,
        };
    },

    computed: {
        /**
         * un fichier est-il déjà set sur ce requirement
         * @returns {boolean}
         */
        fileAlreadySet() {
            return Boolean(this.companyUserRequirement.company_file?.id);
        },
    },

    created() {
        this.companyUserRequirement = { ...this.companyUserRequirement, company_files: [] };
    },

    methods: {
        saveRequirementUploads() {
            this.errorMessage = null;
            this.updateRequirement().then(() => {
                this.hasChanged = false;
            });
        },

        /**
         * @returns {void}
         */
        onRemove() {
            if (this.requirement.is_mandatory) {
                this.hasChanged = Boolean(this.requirement.company_file?.id)
                    && !this.companyUserRequirement.company_file;
            } else {
                this.hasChanged = false;
            }

            this.$emit('onChangeValue', this.companyUserRequirement.id, false);
            this.saveRequirementUploads();
        },
        /**
         * @param {any[]} uploadedFiles
         * @returns {void}
         */
        onUploadEnd(uploadedFiles) {
            this.hasChanged = true;
            this.$emit('onChangeValue', this.companyUserRequirement.id, uploadedFiles.length > 0);

            this.saveRequirementUploads();
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementFileEdit.scss' scoped />
