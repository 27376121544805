<template>
    <div
        class="mainbar"
        :class="{collapsed}"
    >
        <div class="mainbar-inner">
            <div class="wrapper-collapse">
                <button
                    role="button"
                    class="btn-collapse"
                    @click="collapse"
                >
                    <HtIcon name="sidebar-collapse" />
                </button>
            </div>
            <div class="mainbar-company">
                <router-link
                    class="mainbar-company-link"
                    :to="{name: 'Dashboard'}"
                >
                    <img
                        class="full-logo"
                        :src="companyLogo"
                    >
                </router-link>
            </div>
            <div class="wrapper-collapse-collapsed">
                <button
                    role="button"
                    class="btn-collapse"
                    @click="uncollapse"
                >
                    <HtIcon name="sidebar-collapse" />
                </button>
            </div>
            <div class="mainbar-company-collapsed">
                <img
                    class="collapsed-logo"
                    :src="companyLogo"
                >
            </div>
            <HtMainBarMenu />
        </div>
        <HtMainBarUserDropdown class="mainbar-profile-wrapper" />
    </div>
</template>

<script>
import HtMainBarUserDropdown from './HtMainBarUserDropdown.vue';
import HtMainBarMenu from './HtMainBarMenu.vue';

export default {
    name: 'HtMainBar',
    components: {
        HtMainBarMenu,
        HtMainBarUserDropdown,
    },
    shared: {
        session: {},
    },
    data() {
        return {
            showUserMenu: false,
            collapsed: false,
        };
    },
    computed: {
        companyLogo() {
            return this.$Utils.resolveS3PublicImage(this.shared.session.company.image_square);
        },
    },
    methods: {
        collapse() {
            this.collapsed = true;
        },
        uncollapse() {
            this.collapsed = false;
        },
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
    },

};
</script>

<style lang="scss" scoped src="./HtMainBar.scss"></style>
