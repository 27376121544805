<template>
    <div class="wrapper">
        <div
            :class="['file-name', {'is-clickable': hasPreviewer}]"
            @click="togglePreviewer"
        >
            {{ file.original_name }}
        </div>
        <div style="display: flex">
            <HtIcon
                v-if="isAllowedToDownload()"
                name="download"
                size="20"
                stroke-width="2"
                @click.native="download"
            />
            <HtIcon
                v-if="withEdit"
                name="edit-pencil"
                size="20"
                stroke-width="2"
                @click.native="$emit('on-edit', file)"
            />
            <HtIcon
                v-if="withTrash"
                name="trash"
                size="20"
                stroke-width="2"
                @click.native="destroy"
            />
        </div>
        <FilePreviewer
            v-if="hasPreviewer"
            ref="file-previewer"
            :files="[file]"
        />
    </div>
</template>

<script>
import FilePreviewer from '@/components/globals/FilePreviewer/FilePreviewer.vue';
import get from 'lodash.get';
import DownloadUploadFileMixin from '@/components/mixins/DownloadUploadFileMixin';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: 'NewFilledBarZone',
    components: {
        FilePreviewer,
        FontAwesomeIcon,
    },

    mixins: [DownloadUploadFileMixin],

    inject: ['multipleFilesUpload'],

    props: {
        file: {
            type: Object,
            required: true,
        },
        withEdit: {
            type: Boolean,
            default: false,
        },
        withTrash: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        hasPreviewer() {
            return Boolean(get(this.file, 'detected_mime_type'))
                && Boolean(get(this.file, 'link_preview'))
                && this.isAllowedToDownload();
        },
    },

    methods: {
        download() {
            this.downloadCompanyFile(this.file.id, this.multipleFilesUpload.isRequirement);
        },

        destroy() {
            this.removeFile(this.file.size);
            this.multipleFilesUpload.handleFileDeleted(this.file);
        },

        removeFile(fileSize) {
            this.multipleFilesUpload.widthProgressBar = 0;
            this.multipleFilesUpload.$emit('update:fileSize', -fileSize);
        },

        togglePreviewer() {
            if (!this.isAllowedToDownload()) {
                return;
            }

            const extension = get(this.file, 'detected_extension');

            if (extension === 'pdf') {
                window.open(this.file.link_preview, '_blank', 'fullscreen=yes');
                return;
            }
            this.$refs['file-previewer'].togglePreviewer();
        },

        isAllowedToDownload() {
            return this.shared.session.can_onboardee_download_requirements;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.wrapper {
    display: flex;
    justify-content: space-between;
}

.file-name {
    max-width: 29rem;
    white-space: nowrap;
    word-wrap: break-word;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;

    &.is-clickable {
        text-decoration: underline;
        cursor: pointer;
    }
}

.ht-icon, .icon {
    cursor: pointer;
    margin-left: 1.5rem;
}
</style>
