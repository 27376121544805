var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-tasks"},[(_vm.companyPlanningEvents && (!_vm.loading || _vm.tasksByWeek.length))?[(_vm.tasksByWeek.length)?_c('div',{staticClass:"list-tasks-timeline"},[_c('div',{staticClass:"list-tasks-content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getTitleWeek(_vm.tasksToShow.week))+" ")]),_c('TasksListRecap',{attrs:{"tasks":_vm.tasksToShow.tasks,"events":_vm.tasksToShow.events,"select-mode":_vm.showMultiSelect,"rest-to-load":0,"loading":_vm.loading},on:{"loadMore":_vm.getUserProgramTasks,"select":_vm.selectTasks,"update":_vm.onUpdate,"updateStatus":_vm.onUpdateTask,"delete":_vm.deleteTask}})],1)]):_c('div',{staticClass:"list-tasks-no-task"},[_c('span',{staticClass:"no-tasks-icon"},[_vm._v("🙌")]),_c('span',{staticClass:"ht-heading-3"},[_c('t',[_vm._v("You are up to date on this week's actions")])],1),_c('HtButton',{staticClass:"no-tasks-btn",attrs:{"size":"large","type":"primary","to":{
                    label: 'Programs',
                    name: 'ProfileProgram',
                    params: {
                        company_user_id: _vm.shared.session.companyUser.id,
                    },
                }}},[_c('t',[_vm._v("Discover my programs")])],1),_c('img',{staticClass:"no-tasks-placeholder",attrs:{"src":"/static/images/no-tasks.png","alt":"No tasks placeholder"}})],1)]:_c('div',[_c('HtIconLoading')],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }