<template>
    <div
        v-if="permissionsLoaded"
        id="programTask"
        ref="myTaskProgram"
        class="bloc-dashboard my-actions"
        data-cy="my-actions"
    >
        <div
            v-if="!hideMultiselect"
            class="my-actions-header"
        >
            <button
                v-if="canMultiSelect"
                type="button"
                class="actions-multiselect-toggle"
                @click="toggleMultiSelect"
            >
                <t v-if="showMultiSelect === false">
                    Select
                </t>
                <t v-else>
                    Cancel
                </t>
            </button>
        </div>
        <div>
            <ActionsRecap
                :show-multi-select="showMultiSelect"
                :selected-tasks="selectedTasks"
                @select="selectTasks"
                @tasksUpdate="$emit('tasksUpdate')"
            />
        </div>
        <HtBannerSelect
            v-if="!hideMultiselect"
            :show="showMultiSelect"
            :count="selectedTasks.length"
            :can-delete="canDeleteAll"
            :button-state-delete="buttonStateDelete"
            :button-state-validate="buttonStateValidate"
            :can-select-all="false"
            @onCancel="cancelSelected"
            @onDelete="deleteTasks"
            @onValidate="validateTasks"
        />
    </div>
</template>

<script>
import HtBannerSelect from '@/components/globals/HtBannerSelect.vue';
import ActionsRecap from '@/components/pages/dashboard/recap/ActionsRecap.vue';
import tasksSortingMixin from '@/components/pages/actions/all/tasksSortingMixin';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'ActionsWidget',
    components: {
        HtBannerSelect,
        ActionsRecap,
    },
    mixins: [tasksSortingMixin],
    props: {
        hideMultiselect: {
            type: Boolean,
            default: false,
        },
    },
    permissions: [
        'ModelCompanyUserRequirementCategory',
        'ModelCompanyPlanningEvent',
        'ModelCompanyUserDoc',
        'ModelCompanyUserDocument',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserNudge',
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserQuiz',
        'ModelCompanyUserSoftware',
        'ModelCompanyUserSurvey',
    ],

    data() {
        return {
            buttonStateDelete: 'idle',
            buttonStateValidate: 'idle',
            selectedTasks: [],
            showMultiSelect: false,
            sortKeyLocalStorage: 'defaultSortedDashboard',
        };
    },

    shared: {
        dashboard: {
            removeIncrementTask: {
                type: Number,
                default: 0,
            },
            statusIncrementTask: {
                type: Number,
                default: 0,
            },
        },
    },
    computed: {
        /**
         * @returns {boolean}
         */
        canMultiSelect() {
            // Si l'utilisateur peut supprimer toutes les ressources (remplacement de l'ancien is_admin)
            if (this.canDeleteAll) {
                return true;
            }

            if (!this.shared.session.companyUser.is_onboardee) {
                return true;
            }

            return false;
        },
        /**
         * L'utilisateur peut delete toutes les ressources du dashboard
         * @returns {boolean}
         */
        canDeleteAll() {
            return (
                this.$canDelete('ModelCompanyUserRequirementCategory', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserEquipment', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserSoftware', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserQuiz', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserNudge', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyPlanningEvent', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserDoc', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserDocument', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserSurvey', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserProgramTask', {
                    company_user_id: this.shared.session.companyUser.id,
                    resource: CompanyUserProgramTask.TASK_CUSTOM,
                })
            );
        },
    },
    methods: {
        toggleMultiSelect() {
            this.selectedTasks = [];
            this.showMultiSelect = !this.showMultiSelect;
        },
        cancelSelected() {
            this.selectedTasks = [];
            this.showMultiSelect = false;
        },

        deleteTasks() {
            this.buttonStateDelete = 'loading';
            this.$deleteTasks(this.selectedTasks).then(() => {
                this.selectedTasks = [];
                this.shared.dashboard.removeIncrementTask++;
                this.buttonStateDelete = 'idle';
            });
        },

        /**
         * Valide les actions que l'utilisateur a multi-selectionné
         * @returns {void}
         */
        validateTasks() {
            this.buttonStateValidate = 'loading';
            this.$handleValidationTasks(
                this.selectedTasks.map((_id) => ({
                    id: _id,
                    status: CompanyUserProgramTask.STATUS_DONE,
                })),
            ).then(() => {
                this.selectedTasks = [];
                this.shared.dashboard.statusIncrementTask++;
                this.buttonStateValidate = 'idle';
            }).catch(() => {
                this.buttonStateValidate = 'idle';
            });
        },
        selectTasks(taskIds) {
            if (taskIds.length > 1) {
                if (taskIds.some((oneTaskId) => this.selectedTasks.includes(oneTaskId))) {
                    for (let i = 0; i < taskIds.length; i += 1) {
                        const indexTaskId = this.selectedTasks.indexOf(taskIds[0]);
                        this.selectedTasks.splice(indexTaskId, 1);
                    }
                } else {
                    for (let i = 0; i < taskIds.length; i += 1) {
                        if (!this.selectedTasks.includes(taskIds[i])) {
                            this.selectedTasks.push(taskIds[i]);
                        }
                    }
                }
            } else {
                const taskId = taskIds[0];

                if (this.selectedTasks.includes(taskId)) {
                    this.selectedTasks.splice(this.selectedTasks.indexOf(taskId), 1);
                } else {
                    this.selectedTasks.push(taskId);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.my-actions-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
}
.actions-multiselect-toggle {
    @include ht-body;
    text-decoration: underline;
}
</style>
