<template>
    <HtCard>
        <template #title>
            <HtCardTitleBar
                :title="translate('Choose a program template')"
                :show-close="true"
                :show-search="false"
                :show-back="true"
                @on-close="$router.push({ name: 'Programs' })"
                @on-back="$router.push({ name: 'NewProgram' })"
            />
        </template>
        <template
            v-if="allLoaded"
            #default
        >
            <HtFormCard class="programs">
                <div
                    v-for="program in templates"
                    :key="program.title"
                    class="program"
                    @click="
                        $router.push({
                            name: 'ProgramTemplatePreview',
                            params: { slug: program.slug }
                        })
                    "
                >
                    <div class="program-title">
                        {{ name(program) }}
                    </div>
                    <div class="program-description">
                        {{ description(program) }}
                    </div>
                    <div class="program-cta branding-color">
                        <t>See the template</t>
                        <FontAwesomeIcon
                            icon="arrow-right"
                            class="arrow"
                        />
                    </div>
                </div>
            </HtFormCard>
        </template>
    </HtCard>
</template>

<script>
import HtCard from '@/components/globals/HtCard.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import HtProgramTypeCollection from '@/models/HtProgramTypeCollection';
import HtProgramKeyDateCollection from '@/models/HtProgramKeyDateCollection';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import CompanyGroupCollection from '@/models/CompanyGroupCollection';
import CompanyLanguageCollection from '@/models/CompanyLanguageCollection';
import getProgramsModelsData from '@/router/pages/programs/TemplatesData';

export default {
    name: 'TemplateSelection',
    components: {
        HtCard,
        HtCardTitleBar,
        HtFormCard,
    },
    data() {
        return {
            allLoaded: false,
            templates: [],
        };
    },
    created() {
        Promise.all([
            new HtProgramTypeCollection(['id', 'slug']).get(),
            new HtProgramKeyDateCollection(['id'])
                .with({
                    locales: (query) => {
                        query.select(['id', 'name', 'language_key']);
                    },
                })
                .get(),
            new CompanyRoleCollection(['id', 'name']).get(),
            new CompanyGroupCollection(['id', 'name']).get(),
            new CompanyLanguageCollection(['id', 'key']).get(),
        ]).then(([programTypes, keyDates, roles, groups, languages]) => {
            this.templates = getProgramsModelsData(
                programTypes.models,
                keyDates.models,
                roles.models,
                groups.models,
                languages.models,
            );
        }).finally(() => {
            this.allLoaded = true;
        });
    },
    methods: {
        name(template) {
            return template.locales.find(
                (locale) => locale.language_key === this.shared.session.companyUser.company_language.key,
            ).name;
        },
        description(template) {
            return template.locales.find(
                (locale) => locale.language_key === this.shared.session.companyUser.company_language.key,
            ).description;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.programs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.6rem;
    flex-wrap: wrap;
    max-width: 84.8rem;
    margin: auto;
}

.program {
    background-color: white;
    width: 18.4rem;
    border-radius: 0.8rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.8rem;

    padding: 1.2rem;

    cursor: pointer;
}

.program-title {
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    line-height: 2.2rem;
}

.program-description {
    font-size: 1.3rem;
    line-height: 1.6rem;
}

.program-cta {
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    font-size: 1.4rem;

    // Aligning the cta to the bottom of the card
    flex-grow: 1;

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.4rem;

    .arrow {
        height: 1rem;
        // aligning the arrow icon with the text
        margin-bottom: 0.18rem;
    }
}
</style>
