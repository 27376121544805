<template>
    <div v-if="getLabel">
        {{ getLabel }}
    </div>
    <div v-else>
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
</template>

<script>

export default {
    name: 'NewUserRequirementHexapostRead',
    props: {
        UserRequirement: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getLabel() {
            if (this.UserRequirement.value) {
                return JSON.parse(this.UserRequirement.value).label;
            }
            return null;
        },
    },
};
</script>
