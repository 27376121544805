<template>
    <div class="list-tasks">
        <TasksListItem
            v-for="(task) in tasks"
            :key="`task-${task.id}`"
            :task="task"
            class="task-item"
            :select-mode="selectMode"
            @select="selectTasks"
            @update="updateTask"
            @updateStatus="updateTaskStatus"
            @delete="deleteTask"
        />

        <button
            v-if="restToLoad > 0"
            key="loading-button"
            type="button"
            class="task-item load-more-button"
            @click="$emit('loadMore')"
        >
            <div class="icon-wrapper">
                <HtIcon
                    v-if="loading"
                    name="system-restart"
                />
                <HtIcon
                    v-else
                    name="plus-circle-solid"
                />
            </div>
            <div class="button-text">
                <t>See more actions</t>
                <div class="count">
                    {{ restToLoad }}
                </div>
            </div>
        </button>
    </div>
</template>

<script>
import TasksListItem from '../item/TasksListItem.vue';
import listTasksMixin from '../listTasksMixin';

export default {
    name: 'TasksListByPeriod',
    components: {
        TasksListItem,
    },
    mixins: [listTasksMixin],
    props: {
        tasks: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        events: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false,
        },
        restToLoad: {
            type: Number,
            required: true,
            default: 0,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        selectTasks(payload) {
            this.$emit('select', payload);
        },
        updateTask(payload) {
            this.$emit('update', payload);
        },
        updateTaskStatus(payload) {
            this.$emit('updateStatus', payload);
        },
        deleteTask(payload) {
            this.$emit('delete', payload);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.load-more-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 11px 20px;
    border-radius: var(--radius);
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
    .ht-icon, .count {
        color: var(--branding-color);
    }
    .ht-icon-system-restart {
        animation: loader 2s linear infinite;
    }
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 100%;
    background-color: var(--branding-highlight);
}

.task-item:not(:last-child){
    margin-bottom: 12px;
}

.button-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    @include ht-body;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>
