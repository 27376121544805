<template>
    <NewPageWithSidebar v-if="!isOldUi">
        <template #sidebar>
            <NewHtPageSidebar :pages="items" />
        </template>

        <template>
            <router-view />
        </template>
    </NewPageWithSidebar>
    <PageWithSidebar v-else-if="isOldUi">
        <template #sidebar>
            <HtPageSidebar :pages="items" />
        </template>

        <template>
            <router-view />
        </template>
    </PageWithSidebar>
</template>

<script>
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import NewHtPageSidebar from '@/components/globals/NewHtPageSidebar.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';

export default {
    name: 'HoldingResources',
    components: {
        HtPageSidebar,
        NewHtPageSidebar,
        PageWithSidebar,
        NewPageWithSidebar,
    },

    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
        items() {
            return [
                {
                    label: this.translate('Documents'),
                    name: 'HoldingResourcesDocumentsCount',
                },
                {
                    label: this.translate('Trainings'),
                    name: 'HoldingResourcesTrainingsCount',
                },
                {
                    label: this.translate('Surveys'),
                    name: 'HoldingResourcesSurveysCount',
                },
                {
                    label: this.translate('Quizzes'),
                    name: 'HoldingResourcesQuizzesCount',
                },
                {
                    label: this.translate('Events'),
                    name: 'HoldingResourcesEventsCount',
                },
                {
                    label: this.translate('Software'),
                    name: 'HoldingResourcesSoftwareCount',
                },
                {
                    label: this.translate('Equipments'),
                    name: 'HoldingResourcesEquipmentsCount',
                },
                {
                    label: this.translate('Requirements'),
                    name: 'HoldingResourcesRequirementsCount',
                },
                {
                    label: this.translate('Emails'),
                    name: 'HoldingResourcesEmailsCount',
                },
                {
                    label: this.translate('Actions'),
                    name: 'HoldingResourcesActionsCount',
                },
                {
                    label: 'Nudges',
                    name: 'HoldingResourcesNudgesCount',
                },
            ];
        },
    },
};
</script>
