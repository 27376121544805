<template>
    <div>
        <div
            v-if="companyList.length"
            class="settings-menu-item"
            :data-cy="'headbar-switch-account'"
            @click="openChangeAccountModal(toggleDropdown)"
        >
            <div
                class="mainbar-menu-item-link"
            >
                <HtIcon
                    name="chat-bubble-empty"
                    size="18"
                />
                <span class="mainbar-menu-item-label"><t>Change Account</t></span>
            </div>
        </div>
        <div
            v-if="!isHolding"
            class="settings-menu-item"
            :data-cy="'headbar-settings'"
            @click="goToAccount"
        >
            <div
                class="mainbar-menu-item-link"
            >
                <HtIcon
                    name="settings"
                    size="18"
                />
                <span class="mainbar-menu-item-label"><t>Settings</t></span>
            </div>
        </div>
        <div
            v-if="isHolding || shared.session.companyUser.isAdmin()"
            class="settings-menu-item"
            :data-cy="'headbar-online-guide'"
            @click="openGuide"
        >
            <div
                class="mainbar-menu-item-link"
            >
                <HtIcon
                    name="book"
                    size="18"
                />
                <span class="mainbar-menu-item-label"><t>Online guide</t></span>
            </div>
        </div>
        <div
            v-if="shared.session.company.support_link"
            class="settings-menu-item"
            :data-cy="'headbar-contact-us'"
            @click="openContactUs"
        >
            <div
                class="mainbar-menu-item-link"
            >
                <HtIcon
                    name="help-circle"
                    size="18"
                />
                <span class="mainbar-menu-item-label"><t>Support</t></span>
            </div>
        </div>
        <div
            class="settings-menu-item"
            :data-cy="'headbar-logout'"
            @click="onLogout"
        >
            <div
                class="mainbar-menu-item-link"
            >
                <HtIcon
                    name="log-out"
                    size="18"
                />
                <span class="mainbar-menu-item-label"><t>Logout</t></span>
            </div>
        </div>
        <HtModal
            v-if="companyList.length > 0"
            ref="modalSwitchAccount"
        >
            <template #header>
                <div class="modal-header">
                    <t>Change account</t>
                    <div class="subtitle">
                        <FontAwesomeLayers class="icon">
                            <span style="color:#BDF2E2;">
                                <FontAwesomeIcon
                                    icon="circle"
                                    class="icon-circle"
                                />
                            </span>
                            <span style="color:#099468;">
                                <FontAwesomeIcon
                                    :icon="'check'"
                                    transform="shrink-10"
                                    class="icon-second"
                                />
                            </span>
                        </FontAwesomeLayers>
                        <t :company="shared.session.company.name">
                            Logged in on {company}
                        </t>
                    </div>
                </div>
            </template>
            <div class="switch-account-modal">
                <div>
                    <t>Change company</t>
                    <div
                        v-for="company in childCompanies"
                        :key="company.id"
                        class="switch-account-modal-button"
                        @click="switchToAccount(company.company_id)"
                    >
                        <span>{{ company.company_name }}</span>
                        <FontAwesomeIcon
                            :icon="'arrow-right'"
                            style="width: 9px; height: 9px;"
                        />
                    </div>
                </div>
                <div>
                    <t>Connect</t>
                    <div
                        class="switch-account-modal-button"
                        @click="switchToAccount(getHoldingCompany.company_id)"
                    >
                        <span>{{ getHoldingCompany.company_name }}</span>
                        <FontAwesomeIcon
                            :icon="'arrow-right'"
                            style="width: 9px; height: 9px;"
                        />
                    </div>
                </div>
            </div>
        </HtModal>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import Utils from '@/Utils';
import HtModal from '../../globals/HtModal.vue';

export default {
    name: 'HtMobileSecondarySettings',
    components: {
        HtModal,
    },
    shared: {
        session: {},
    },
    computed: {
        ...mapState('companies', [
            'companyList',
        ]),
        childCompanies() {
            return this.companyList.filter((line) => (!line.company_is_holding));
        },
        getHoldingCompany() {
            return this.companyList.find((line) => line.company_is_holding);
        },
        isHolding() {
            return this.shared.session.company.is_holding;
        },
    },
    created() {
        this.$store.dispatch('companies/fetchCompanyList');
    },
    methods: {
        goToAccount() {
            this.$router.push({ name: 'Account' });
        },
        openGuide() {
            window.open('https://support.heyteam.com/knowledge', '_blank');
        },
        openContactUs() {
            window.open(this.shared.session.company.support_link, '_blank');
        },
        onLogout() {
            this.$http.post('auth/logout').then(() => {
                this.$Auth.logOut();
                this.$router.push({ name: 'Login' });
            });
        },
        openChangeAccountModal(toggleDropdown) {
            toggleDropdown();
            this.$refs.modalSwitchAccount.open();
        },
        switchToAccount(companyId) {
            Utils.autologin(companyId)
                .then((url) => {
                    url.searchParams.append('redirect', '/');
                    window.location = url.toString();
                });
        },
    },
};
</script>

<style lang="scss" scoped>

.settings-menu-item {
    position: relative;
    ::v-deep .mainbar-menu-item-link {
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 14px 16px;
        color: var(--text-secondary);
        font-size: var(--text-medium);
        line-height: 23px;
        z-index: 1;
        cursor: pointer;
        overflow: hidden;
        transition: padding-left var(--transition-s);
    }
    ::v-deep .mainbar-menu-item-label{
        transition: opacity var(--transition-s);
    }
    ::v-deep .ht-icon {
        margin-right: 8px;
    }
    ::v-deep .ht-badge {
        margin-left: auto;
    }
    ::v-deep .ht-tooltip-target {
        display: none;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--radius);
        opacity: 0;
        background: currentColor;
        transform: scale(0.9);
        transition: var(--transition-s);
    }
}

.switch-account-modal {
    & > div {
        padding-top: 2.9rem;
        padding-left: 1.55rem;
        padding-right: 1.55rem;
        padding-bottom: 2.4rem;
        border-radius: 8px;
        background-color: #F5F7F8;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
    &-button {
        border: 1px solid #E5E6E6;
        background-color: white;
        margin: 0.4rem 0;
        height: 4.8rem;
        line-height: 1.7rem;
        padding: 1.55rem 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: normal;
        font-size: 1.4rem;
    }
}

.modal-header {
    display:flex;
    flex-direction: column;
    font-size: 2.8rem;
    .subtitle {
        font-size: 1.4rem;
        font-weight: normal;
        display:flex;
        align-items: center;
        gap: 0.8rem;
        .icon svg{
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}

</style>
