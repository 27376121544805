// src/helpers/feature.js

import store from '@/bootstrap/declare-store';

const FEATURE_NEW_UI = 'new_ui';

/**
* @type {string} featureKey
* @return {bool} if feature is absent in database, we return false by default
*/
export function hasFeatureEnabled(featureKey) {
    const feature = store.state.config.features.find((feature) => feature.key === featureKey);

    return feature && feature.is_enabled;
}

export { FEATURE_NEW_UI };

// hasFeatureEnabled('newUi') returns false => c'est ce qu'on veut (faudrait par contre ne pas mettre 'newUi' en dur car magic string)
// !! WARNING !! hasFeature('oldUi') returns false => car non présente en base
