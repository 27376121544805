<template>
    <div
        :class="`size-${size}`"
        class="ht-avatar-group"
    >
        <HtAvatar
            v-for="user in users"
            :key="user.id"
            :user="user"
            :size="size"
        />
    </div>
</template>

<script>
export default {
    props: {
        users: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: 'xs',
            validator(value) {
                return ['xs', 's', 'm'].includes(value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.ht-avatar-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.size-xs {
        .ht-avatar {
            &:not(:first-child) {
                margin-left: -8px;
            }
        }
    }
    &.size-s {
        .ht-avatar {
            &:not(:first-child) {
                margin-left: -12px;
            }
        }
    }
    &.size-m {
        .ht-avatar {
            &:not(:first-child) {
                margin-left: -20px;
            }
        }
    }
}
.ht-avatar {
    flex: 0 0 auto;
    &:not(:first-child) {
        outline: 1px solid var(--border-inversed);
    }
}
</style>
