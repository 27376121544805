<template>
    <div>
        <div
            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#D7F5F3' }"
                >
                    <HtIcon
                        name="bookmark-book"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#4F7075' }"
                    />
                </HtEmojiIcon>
                {{ translate('Trainings') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ filteredUserTraining.length }}
                </HtBadge>
                <HtIcon
                    v-if="filteredUserTraining.length"
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <button
                    v-for="item in filteredUserTraining"
                    :key="item.id"
                    class="item"
                    @click="openItemModal(item.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ item.name }}
                        </div>
                        <div class="item-program">
                            {{ translate('Program "{programName}"', { programName: item.company_user_program.company_program.name_translated }) }}
                        </div>
                    </div>

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[item.company_user_program_task.status]" />
                        <t>
                            {{ item.company_user_program_task.statusText }}
                        </t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>

        <Modalable
            ref="modalableItem"
            :mode="2"
            class="modalable-1 small"
        >
            <NewUserTrainingItem
                :id="id"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUserTrainingCollection from '@/models/CompanyUserTrainingCollection';
import NewUserTrainingItem from '@/components/pages/dashboard/modals/training/NewUserTrainingItem.vue';

export default {
    name: 'NewserTrainingProfile',
    components: {
        NewUserTrainingItem,
    },

    props: {
        companyUserId: { type: Number, required: true },
    },

    data() {
        return {
            open: false,
            badgeStates: {
                done: 'success',
                pending: 'neutral',
                draft: 'warning',
                cancelled: 'error',
            },
            id: null,
            companyUserTrainingCollection: new CompanyUserTrainingCollection([
                'id', 'company_user_id', 'name', 'status',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['id', 'status', 'resource_id', 'resource', 'status', 'datetime_availability']);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]).orderBy([
                ['name', 'asc'],
            ]),
        };
    },

    computed: {
        filteredUserTraining() {
            return this.companyUserTrainingCollection.$.models.filter((item) => ((this.shared.session.companyUser.id == this.companyUserId)
                ? this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability))
                : true));
        },
    },

    created() {
        this.companyUserTrainingCollection.company_user_id = this.companyUserId;
        this.companyUserTrainingCollection.get().then(() => {
            this.$emit('on-load', this.companyUserTrainingCollection.$.models.length);
        });
    },

    methods: {
        toggle() {
            if (this.filteredUserTraining.length) this.open = !this.open;
        },
        onDelete() {
            this.companyUserTrainingCollection.get();
        },

        onUpdate() {
            this.companyUserTrainingCollection.get();
        },

        openItemModal(id) {
            this.id = id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>
<style lang="scss" scoped src="../ressourceItem.scss" />
