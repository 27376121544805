<template>
    <NewHtBanner v-show="show">
        <div class="ht-banner-select-wrapper">
            <div class="ht-banner-select-count">
                <t :count="count">
                    {count} Element selected | {count} Elements selected
                </t>
            </div>
        </div>
        <div class="ht-banner-select-button-wrapper">
            <HtButton
                size="large"
                type="secondary"
                data-cy="cancel-selection"
                @click="onCancel"
            >
                <t>Cancel selection</t>
            </HtButton>
            <HtButton
                v-if="canSelectAll"
                size="large"
                type="secondary"
                data-cy="select-all"
                @click="onSelectAll"
            >
                <t>Select all</t>
            </HtButton>
            <HtButton
                v-if="canDelete"
                size="large"
                type="secondary"
                :state="getStateDelete"
                data-cy="delete-selected"
                @click="onDelete"
            >
                <t>Delete</t>
            </HtButton>
            <HtButton
                size="large"
                type="branding"
                :state="getStateValidate"
                data-cy="validate-selected"
                @click="onValidate"
            >
                <t>Validate</t>
            </HtButton>
        </div>
    </NewHtBanner>
</template>
<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';
import NewHtBanner from './NewHtBanner.vue';

export default {
    name: 'NewHtBannerSelect',
    components: {
        NewHtBanner,
        HtButton,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
            required: true,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        canSelectAll: {
            type: Boolean,
            default: true,
        },
        buttonStateDelete: {
            type: String,
            default: 'idle',
        },
        buttonStateValidate: {
            type: String,
            default: 'idle',
        },
    },

    computed: {
        getStateDelete() {
            return this.count > 0 ? this.buttonStateDelete : 'disabled';
        },

        getStateValidate() {
            return this.count > 0 ? this.buttonStateValidate : 'disabled';
        },
    },

    methods: {
        onCancel() {
            this.$emit('onCancel');
        },

        onSelectAll() {
            this.$emit('onSelectAll');
        },

        onDelete() {
            this.$emit('onDelete');
        },

        onValidate() {
            this.$emit('onValidate');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.ht-banner-select {
    &-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        font-size: 1.4rem;
    }

    &-count {
        font-size: var(--text-small);
        font-weight: 600;
        line-height: var(--line-height-medium);
        text-transform: uppercase;
        color: rgba(33, 40, 42, 0.60);
    }
    &-cancel {
        flex: 0 0 auto;
        font-weight: 500;
        text-decoration: underline;
    }

    &-button-wrapper {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 12px;
    }
}

@media (min-width: $tablet) {
    .ht-banner-select {
        &-wrapper {
            flex-direction: row;
            align-items: center;
            gap: 30px;
        }
    }
}
@media (max-width: $tablet) {
    .ht-banner-select {
        &-all {
            display: none;
        }
    }
}
</style>
