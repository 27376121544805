<template>
    <div class="mainbar-mobile">
        <div
            class="navbar-mobile"
            :class="{'menu-opened': menuOpened}"
        >
            <HtMobileSecondaryBarMenu />
            <div
                v-if="isLoaded"
                class="mobile-mainbar-menu"
            >
                <div
                    class="mobile-overlay"
                    @click="closeMenu"
                />
                <div class="mobile-mainbar-menu-links">
                    <div class="mobile-mainbar-menu-item branding-color">
                        <router-link
                            :to="{
                                name: 'Dashboard',
                            }"
                            :data-cy="'menu-Dashboard'"
                            class="mainbar-menu-item-link"
                        >
                            <HtIcon
                                name="home-simple-door"
                                size="24"
                            />
                            <span class="mainbar-menu-item-label"><t>Dashboard</t></span>
                        </router-link>
                    </div>
                    <div class="mobile-mainbar-menu-item branding-color">
                        <router-link
                            :to="{ name: 'Actions'}"
                            class="mainbar-menu-item-link"
                        >
                            <HtIcon
                                name="flash"
                                size="24"
                            />
                            <span class="mainbar-menu-item-label"><t>Actions</t></span>
                        </router-link>
                    </div>
                    <button
                        v-if="canSeeQuickActionButton"
                        type="button"
                        class="mobile-btn-notif branding-bg"
                        @click="openQuickActionModal"
                    >
                        <HtIcon
                            name="plus"
                            size="24"
                        />
                    </button>
                    <div
                        class="mobile-mainbar-menu-item branding-color"
                        :class="{active:shared.socket.count>0}"
                        @click="onViewChat"
                    >
                        <div
                            class="mainbar-menu-item-link"
                        >
                            <HtIcon
                                name="chat-bubble-empty"
                                size="24"
                            />
                            <span class="mainbar-menu-item-label"><t>Messages</t></span>
                        </div>
                    </div>
                    <div
                        class="mobile-mainbar-menu-item branding-color"
                        @click="openMenu"
                    >
                        <div class="mainbar-menu-item-link">
                            <HtIcon
                                :name="'menu'"
                                size="24"
                            />
                            <span class="mainbar-menu-item-label"><t>{{ 'Menu' }}</t></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalMixin from '@/components/globals/modals/modalMixin';
import QuickActionsModal from '@/components/globals/modals/QuickActions/QuickActionsModal.vue';
import HtMobileSecondaryBarMenu from './HtMobileSecondaryBarMenu.vue';

export default {
    name: 'HtMobileMainBar',
    shared: {
        session: {},
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
            count: {
                type: Number,
                default: 0,
            },
        },
    },
    components: {
        HtMobileSecondaryBarMenu,
    },
    mixins: [
        ModalMixin,
    ],
    data() {
        return {
            menuOpened: false,
        };
    },
    computed: {
        isLoaded() {
            return this.shared.session.company && this.shared.session.companyUser;
        },
        goToLogin() {
            return {
                name: 'Login',
            };
        },
        chatCountLabel() {
            return this.shared.socket.count < 10 ? this.shared.socket.count : '+9';
        },
        canSeeQuickActionButton() {
            return this.$can('PageBulkEnrolling') || this.$canRead('AbstractCanSelfEnroll');
        },
    },
    watch: {
        $route() {
            this.closeMenu();
        },
    },

    methods: {
        onViewChat() {
            this.shared.socket.chatOpened = !this.shared.socket.chatOpened;
            this.$emit('on-view');
        },
        openMenu() {
            this.menuOpened = true;
        },
        closeMenu() {
            this.menuOpened = false;
        },
        toggleMenu() {
            this.menuOpened = !this.menuOpened;
        },
        openQuickActionModal() {
            this.openCenterModal(QuickActionsModal);
        },
    },
};
</script>

<style lang="scss" scoped src="./HtMobileMainBar.scss"></style>
