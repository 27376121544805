<template>
    <div v-if="tasks.length">
        <button
            class="button-timeline-title"
            :class="{'opened': isTimelineToggled}"
            type="button"
            @click="isTimelineToggled = !isTimelineToggled"
        >
            <ActionProgramTimelineTitle
                :title="periodLabel"
                :count-all-tasks="tasks.length"
                :count-task="countPendingTasks"
                :show-multi-select="showMultiSelect"
                :is-selected="isSelected"
                :program-id="programId"
                data-cy="period-element-title"
                :show-done="showDone"
                @onSelectAll="onSelectAll"
            />
        </button>

        <div
            v-if="isTimelineToggled"
            :key="periodLabel"
            class="wrapper-timeline-items"
        >
            <div
                v-for="(task, index) in tasks"
                :key="index"
                class="wrapper-timeline-item"
            >
                <ActionProgramTaskItem
                    v-if="!task.is_grouped"
                    :key="index"
                    :from="from"
                    :item="task"
                    :selected-action-list="selectedActionList"
                    :show-multi-select="showMultiSelect"
                    cypress="period-element-task-item"
                    @onToggleStatus="onToggleStatus"
                />
                <ActionProgramTaskWrapper
                    v-else-if="task.groupedTasks.length"
                    :key="index"
                    :from="from"
                    :item="task"
                    :selected-action-list="selectedActionList"
                    :show-multi-select="showMultiSelect"
                    cypress="period-element-task-wrapper"
                />
            </div>
            <HtButton
                v-if="canAdd"
                type="secondary"
                class="add-task"
                data-cy="period-element-add-button"
                @click="openAddElement"
            >
                <HtIcon
                    name="plus-circle"
                    size="16"
                    class="add-task-icon"
                />
                <div class="add-task-title">
                    <t>Add an action</t>
                </div>
            </HtButton>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';
import ActionProgramTaskItem from './ActionProgramTaskItem.vue';
import ActionProgramTimelineTitle from './ActionProgramTimelineTitle.vue';
import ActionProgramTaskWrapper from './ActionProgramTaskWrapper.vue';

export default {
    name: 'ActionProgramTimelineItem',
    components: {
        ActionProgramTimelineTitle,
        ActionProgramTaskItem,
        ActionProgramTaskWrapper,
        HtButton,
    },
    props: {
        from: {
            type: String,
            required: true,
        },
        periodLabel: {
            type: String,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        /**
         * Can the user add a resource
         */
        canAdd: {
            type: Boolean,
            required: true,
        },
        showDone: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
        selectedTimelineList: {
            type: Array,
            required: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        programId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            isTimelineToggled: true,
        };
    },

    computed: {
        countPendingTasks() {
            return this.tasks.filter((task) => task.groupedTasks.length === 0 && ['pending', 'draft'].includes(task.status)).length;
        },
        isSelected() {
            return this.selectedTimelineList.includes(this.periodLabel);
        },
    },

    methods: {
        onToggleStatus(item) {
            this.$emit('onToggleStatus', item);
        },

        openAddElement() {
            this.$emit('onAddAction');
        },

        onSelectAll(isChecked) {
            // Add or remove timeline
            if (isChecked && !this.selectedTimelineList.includes(this.periodLabel)) {
                this.selectedTimelineList.push(this.periodLabel);
            } else if (!isChecked && this.selectedTimelineList.includes(this.periodLabel)) {
                this.selectedTimelineList.splice(this.selectedTimelineList.indexOf(this.periodLabel), 1);
            }

            // Add or remove each task of timeline
            this.tasks.forEach((task) => {
                if (task.is_grouped) {
                    task.groupedTasks.forEach((taskGrouped) => {
                        if (this.canSelect(taskGrouped)) {
                            this.checkIfAddOrRemove(isChecked, taskGrouped.id);
                        }
                    });
                } else if (this.canSelect(task)) {
                    this.checkIfAddOrRemove(isChecked, task.id);
                }
            });
        },

        checkIfAddOrRemove(isChecked, taskId) {
            if (isChecked && !this.selectedActionList.includes(taskId)) {
                this.selectedActionList.push(taskId);
            } else if (!isChecked && this.selectedActionList.includes(taskId)) {
                this.selectedActionList.splice(this.selectedActionList.indexOf(taskId), 1);
            }
        },

        canSelect(task) {
            const { resource } = task;

            if (task.validator_type === 'system') {
                return false;
            }

            return resource !== 'company_survey'
                && resource !== 'company_requirement_category'
                && resource !== 'company_user_quiz'
                && resource !== 'company_email_custom';
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.add-task {
    ::v-deep .ht-button-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
}
.add-task-title {
    position: relative;
    top: 1px;
}

.wrapper-timeline-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 16px 16px;
}
.wrapper-timeline-item {
    &:empty {
        display: none;
    }
}

.button-timeline-title {
    display: block;
    width: 100%;
    text-align: left;
}

@media (min-width: $tablet){
    .wrapper-timeline-items {
        padding: 0 20px 20px;
    }
}
</style>
