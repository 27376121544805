<template>
    <div>
        <div class="mb-5">
            <NewHistoryWidget />
        </div>
    </div>
</template>

<script>
import NewHistoryWidget from '@/components/pages/dashboard/history/NewHistoryWidget.vue';

export default {
    name: 'NewHistory',
    components: {
        NewHistoryWidget,
    },
};
</script>
