import Piloting from '@/router/pages/Piloting.vue';
import PilotingStatistics from '@/router/pages/piloting/Statistics.vue';
import PilotingReport from '@/router/pages/dashboard/Report.vue';
import PilotingSpecificReports from '@/router/pages/dashboard/SpecificReports.vue';
import SurveyResults from '@/router/pages/SurveyResults.vue';
import SurveyResultDetail from '@/router/pages/surveyResults/SurveyResultDetail.vue';

export default [
    {
        path: 'Piloting',
        name: 'Piloting',
        component: Piloting,
        children: [
            {
                path: 'Statistics',
                name: 'PilotingStatistics',
                component: PilotingStatistics,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Report',
                name: 'PilotingReport',
                component: PilotingReport,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'SpecificReports',
                name: 'PilotingSpecificReports',
                component: PilotingSpecificReports,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'SurveyResults',
                name: 'PilotingSurveyResults',
                component: SurveyResults,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'SurveyResults/:surveyId',
                name: 'PilotingSurveyResultDetail',
                component: SurveyResultDetail,
                props: (route) => ({ surveyId: Number(route.params.surveyId) }),
                meta: {
                    permission: true,
                },
            },
        ],
    },
];
