<template>
    <div v-if="companyUserSoftwares.isLoaded()">
        <div

            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#D0FFF4' }"
                >
                    <HtIcon
                        name="pc-mouse"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#31B59E' }"
                    />
                </HtEmojiIcon>
                {{ translate('Softwares') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ companyUserSoftwares.$.models.length }}
                </HtBadge>
                <HtIcon
                    v-if="companyUserSoftwares.$.models.length"
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <button
                    v-for="item in companyUserSoftwares.$.models"
                    :key="item.id"
                    class="item"
                    @click="openItemModal(item.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ item.company_software.resource.name }}
                        </div>
                    </div>

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[item.company_user_program_task.status]" />
                        <t>
                            {{ item.company_user_program_task.status }}
                        </t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>

        <modalable
            ref="modalableUserItem"
            class="modalable-1 small"
            :mode="2"
        >
            <NewUserSoftwareItem
                :id="currentId"
                ref="userItem"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>
<script>
import CompanyUserSoftwareCollection from '@/models/CompanyUserSoftwareCollection';
import NewUserSoftwareItem from '@/components/pages/dashboard/modals/software/NewUserSoftwareItem.vue';

export default {
    name: 'NewUserSoftwareProfile',
    components: {
        NewUserSoftwareItem,
    },
    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            open: false,
            badgeStates: {
                done: 'success',
                pending: 'neutral',
                draft: 'warning',
                cancelled: 'error',
            },
            currentId: null,
            companyUserSoftwares: new CompanyUserSoftwareCollection([
                'id',
                'company_user_id',
                'company_software_id',
                'status',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['id', 'datetime_start', 'status', 'datetime_end']);
                },
                companySoftware: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select([
                                'id',
                                'name',
                                'description',
                            ]);
                        },
                    }).withTrashed(true);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]).orderBy([['name', 'asc']]),
        };
    },

    created() {
        this.companyUserSoftwares.get().then(() => {
            this.$emit('on-load', this.companyUserSoftwares.$.models.length);
        });
    },

    methods: {
        toggle() {
            if (this.companyUserSoftwares.$.models.length) this.open = !this.open;
        },
        onUpdate() {
            this.companyUserSoftwares.get();
        },

        onDelete() {
            this.companyUserSoftwares.get();
        },

        openItemModal(id) {
            this.currentId = id;
            this.$refs.modalableUserItem.open();
        },
    },
};
</script>
<style lang="scss" scoped src="../ressourceItem.scss" />
