<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
        :tooltip="tooltip"
    >
        <div
            v-if="isWithCalendarIcon"
            class="position-relative"
        >
            <FontAwesomeIcon
                class="schedule-tag-icon position-absolute"
                :style="{top: '50%', transform: 'translateY(-50%)', zIndex: 1, right: '1.5rem'}"
                :icon="['fad', 'calendar-alt']"
            />

            <flat-pickr
                :name="name"
                :class="style"
                :value="value"
                :config="config"
                :placeholder="text"
                :data-cy="cypress"
                :disabled="disabled"
                @input="change"
            />
        </div>
        <flat-pickr
            v-else
            :name="name"
            :class="style"
            :value="value"
            :config="config"
            :placeholder="text"
            :data-cy="cypress"
            :disabled="disabled"
            @input="change"
        />

        <div
            v-if="$slots.extra"
            class="ht-form-flat-pickr-extra"
        >
            <slot name="extra" />
        </div>
    </HtFormGroup>
</template>

<script>
import Locale from 'flatpickr/dist/l10n/fr';
import HtFormMixin from '@/components/globals/HtFormMixin';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';

export default {
    name: 'HtFormFlatPickr',
    components: {
        HtFormGroup,
    },
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: String,
            default: () => null,
        },

        minDate: {
            type: String,
            default: () => null,
        },

        maxDate: {
            type: String,
            default: () => null,
        },

        inline: {
            type: Boolean,
            default: () => false,
        },

        isWorkingDay: {
            type: Boolean,
            default: () => true,
        },

        dateFormat: {
            type: String,
            default: () => 'Y-m-d',
        },

        mode: {
            type: String,
            default: () => 'single',
        },

        isWithCalendarIcon: {
            type: Boolean,
            default: () => false,
        },

        static: {
            type: Boolean,
            default: true,
        },

        enableTime: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        text() {
            return this.placeholder || this.defaultPlaceholder;
        },

        locale() {
            if (this.shared.session.companyUser.company_language.key === 'fr') {
                return Locale.fr;
            }

            return null;
        },

        altFormat() {
            if (this.shared.session.companyUser.company_language.key === 'fr') {
                return `d/m/Y${this.enableTime ? ' H:i' : ''}`;
            }

            return `Y/m/d${this.enableTime ? ' H:i' : ''}`;
        },

        defaultPlaceholder() {
            return this.shared.session.companyUser.company_language.key === 'fr'
                ? 'DD/MM/YYYY'
                : 'YYYY/MM/DD';
        },

        config() {
            return {
                inline: this.inline,
                locale: this.locale,
                minDate: this.minDate,
                maxDate: this.maxDate,
                altFormat: this.altFormat,
                dateFormat: this.dateFormat,
                workingDay: this.isWorkingDay,
                enableTime: this.enableTime,

                static: this.static,
                animate: false,
                altInput: true,
                mode: this.mode,

                onChange: (dates) => this.$emit('change', dates),

                disable: [
                    this.weDisabled,
                ],
            };
        },

        style() {
            return {
                'flatpickr-input-none': this.inline,
            };
        },
    },

    methods: {
        weDisabled(date) {
            if (this.isWorkingDay) {
                return date.getDay() === 0 || date.getDay() === 6;
            }

            return false;
        },

        change(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.flatpickr-input-none {
    display: none;
}
</style>

<style lang="scss">
@import '~@/styles/var';

.flatpickr-wrapper > input {
    width: 100%;
    font-size: 1.4rem;
    padding: 15px;
    border: 1px solid $grey-ultra-light;
    border-radius: 5px;
    transition: $transition;
    // We have to specify the font for the input, because the global rule is ignored
    font-family: $lato;
    body:not(.old-ui) &{
        font-family: var(--poppins);
    }

    &:focus {
        border-color: $primary-light;
        outline: 0;
    }

    &:disabled {
        color: $text;
        background: $grey-ultra-light;
    }
}

.ht-form-flat-pickr-extra {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.4rem;
}
</style>
