<template>
    <div
        class="ht-icon"
        :style="{
            width: `${size}px`,
            height: `${size}px`,
            '--icon-stroke-width': `${strokeWidth}`,
        }"
        :class="[`ht-icon-${name}`]"
    >
        <Icon
            :icon="`${set}:${name}`"
            :width="size"
            :height="size"
            :inline="inline"
        />
    </div>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: 'key-command',
        },
        set: {
            type: String,
            default: 'iconoir',
        },
        size: {
            type: String,
            default: '16',
        },
        inline: {
            type: Boolean,
            default: false,
        },
        strokeWidth: {
            type: String,
            default: '1.5',
        },
    },
};
</script>
<style lang="scss" scoped>
.ht-icon {
    ::v-deep path {
        stroke-width: var(--icon-stroke-width);
    }
}
</style>
