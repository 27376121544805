<template>
    <div class="wrapper-items">
        <slot />
    </div>
</template>
<script>
export default {
    name: 'NewHtStepperVertical',
    provide() {
        return {
            stepper: this,
        };
    },

    props: {
        currentStep: {
            type: Number,
            default: 0,
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            activeIndex: this.currentStep,
        };
    },

    watch: {
        currentStep(value) {
            this.steps.forEach((step, index) => {
                if (Boolean(step.disabled) && index <= value) {
                    step.disabled = false;
                }
            });
            this.activeIndex = value;
        },
    },

    methods: {
        setActive(step) {
            const newStep = step.step;
            this.$emit('input', newStep);
        },
    },
};

</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.wrapper-items {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
    width: 100%;
}

@media (min-width: $tablet-large) {
    .wrapper-items {
        justify-content: flex-start;
        flex-direction: column;
        width: auto;
    }
}
</style>
