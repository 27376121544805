<template>
    <div class="extra-display">
        <div class="extra-header">
            <div class="title">
                <t>Additional information</t>
            </div>
        </div>

        <div class="extra-content">
            {{ localize(extra.template.translations.text) }}
        </div>
    </div>
</template>

<script>
import HtChip from '@/components/globals/HtChip.vue';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import SurveyAnswerHistory from '@/components/resources/user/surveys/questions/SurveyAnswerHistory.vue';

export default {
    name: 'SurveyExtraDisplay',

    components: {
        SurveyAnswerHistory,
        HtFormTextarea,
        HtChip,
    },

    props: {
        extra: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/design-system-colors.scss';

.title {
    font-family: $lato;
    body:not(.old-ui) &{
        font-family: var(--poppins);
    }
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: $neutral-black;
}

.extra-display {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 530px;
    margin: 0 auto;
}
</style>
