<template>
    <div
        v-if="showBloc"
        class="profile-block-wrapper"
    >
        <HtButton
            v-if="canEdit"
            type="tertiary"
            class="btn-edit"
            @click="onEdit"
        >
            <HtIcon
                name="edit-pencil"
                size="16"
                stroke-width="2.5"
            />
            <t>Edit</t>
        </HtButton>

        <slot :name="'default'" />
        <div
            v-show="isEdit"
            class="action-buttons"
        >
            <HtButton
                type="secondary"
                @click="onCancel"
            >
                <t>Cancel</t>
            </HtButton>
            <HtButton
                type="primary"
                :loading="buttonState === 'loading'"
                @click="onSave"
            >
                <t>Save modifications</t>
            </HtButton>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';

export default {
    name: 'NewProfileBloc',
    components: {
        HtButton,
    },
    inject: ['$validator'],
    props: {
        isEdit: {
            type: Boolean,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        buttonState: {
            type: String,
            default: 'idle',
        },
        showBloc: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        onCancel() {
            this.$emit('update:isEdit', false);
            this.$emit('on-cancel');
        },

        onSave() {
            this.$emit('on-save');
        },

        onEdit() {
            this.$emit('update:isEdit', !this.isEdit);
        },
    },
};
</script>

<style lang="scss" scoped>

.profile-block-wrapper {
    position: relative;
    padding: 60px 20px 20px;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
}
.btn-edit {
    position: absolute;
    top: 20px;
    right: 20px;
    ::v-deep .ht-button-inner {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
.action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
}
</style>
