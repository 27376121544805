<template>
    <NewHtModal
        ref="modal"
        @beforeOpen="beforeOpen"
        @beforeClose="beforeClose"
    >
        <template #header>
            <div
                v-if="isLoaded"
                class="header-modal"
            >
                <div class="title">
                    {{ companyPlanningEvent.name }}
                </div>
                <div class="subtitle">
                    <t>Event</t>
                </div>
            </div>
        </template>
        <template #default>
            <div v-if="isLoaded">
                <div v-if="companyPlanningEvent.id">
                    <div class="wrapper-description">
                        <DescLine v-if="companyPlanningEvent.owner">
                            <template #title>
                                <t>Organized by</t>
                            </template>
                            <template #content>
                                <HtAvatar
                                    :user="$Utils.getAvatarData(companyPlanningEvent.owner)"
                                    size="s"
                                />
                                <span>{{ companyPlanningEvent.owner.firstname }} {{ companyPlanningEvent.owner.lastname }}</span>
                            </template>
                        </DescLine>
                        <DescLine>
                            <template #title>
                                <t>Participants</t>
                            </template>
                            <template #content>
                                <HtAvatarGroup
                                    :users="participants"
                                    size="s"
                                />
                                <span
                                    v-if="participants.length===1"
                                >
                                    {{ participants[0].firstname }} {{ participants[0].lastname }}
                                </span>
                            </template>
                        </DescLine>
                        <DescLine v-if="companyPlanningEvent.datetime_start">
                            <template #title>
                                <t>Date</t>
                            </template>
                            <template #content>
                                <span>{{ eventDateTime }}</span>
                                <span v-if="companyPlanningEvent.place">| {{ companyPlanningEvent.place }}</span>
                            </template>
                        </DescLine>
                        <DescLine v-if="companyPlanningEvent.place">
                            <template #title>
                                <t>Location</t>
                            </template>
                            <template #content>
                                {{ companyPlanningEvent.place }}
                            </template>
                        </DescLine>
                        <DescLine>
                            <template #title>
                                <t>Status</t>
                            </template>
                            <template #content>
                                {{ statusText }}
                            </template>
                        </DescLine>
                        <DescLine v-if="computedDescription">
                            <template #title>
                                <t>Description</t>
                            </template>
                            <template #content>
                                <div v-dompurify-html="computedDescription" />
                            </template>
                        </DescLine>
                        <DescLine v-if="companyPlanningEvent.link">
                            <template #title>
                                <t>Link</t>
                            </template>
                            <template #content>
                                {{ companyPlanningEvent.link }}
                            </template>
                        </DescLine>
                    </div>
                    <div
                        v-if="companyPlanningEvent.companyFile && companyPlanningEvent.companyFile.original_name"
                        class="d-flex align-items-center event-item-other"
                    >
                        <span class="mr-10"><t>Attachment</t> :</span>
                        <NewDownloadable
                            class="flex-grow-1"
                            :company-file="companyPlanningEvent.companyFile"
                        />
                    </div>
                    <div v-if="companyPlanningEvent.is_delegated">
                        <ErrorDisplay
                            :append-errors="'This event has not been planned yet'"
                        />
                    </div>
                    <div
                        v-if="companyPlanningEvent.notes.length > 0"
                        class="wrapper-notes"
                    >
                        <HtRecap
                            v-for="(note, index) in companyPlanningEvent.notes"
                            :key="index"
                            class="note"
                            :data-cy="`note-${index}`"
                            icon="📝"
                        >
                            <template #infos>
                                <div>{{ translate('Note {name}', { name: note.name }) }}</div>
                                <div>{{ translate('Created by {firstname} {lastname}', { firstname: note.owner.firstname, lastname: note.owner.lastname }) }}</div>
                            </template>
                            <template #button>
                                <HtButton
                                    size="large"
                                    type="secondary"
                                    @click="openNoteModal(note)"
                                >
                                    <t>Open note</t>
                                </HtButton>
                            </template>
                        </HtRecap>
                    </div>
                    <div class="wrapper-new-note">
                        <div
                            v-if="companyPlanningEvent.notes.length === 0"
                            class="wrapper-new-note-title"
                        >
                            <span class="new-note-title"><t>No notes present</t></span>
                            <span
                                v-if="$canCreate('ModelCompanyUserNote', {resource_id: companyPlanningEvent.id, resource_name: 'company_planning'})"
                                class="new-note-subtitle"
                            ><t>You can add a note to the event</t></span>
                        </div>
                        <HtButton
                            v-if="$canCreate('ModelCompanyUserNote', {resource_id: companyPlanningEvent.id, resource_name: 'company_planning'})"
                            type="secondary"
                            size="large"
                            data-cy="note-add-button"
                            @click="openNoteModal(null)"
                        >
                            <span class="content-add-button">
                                <HtIcon
                                    name="plus-circle"
                                    size="16"
                                    class="icon-add-button"
                                />
                                <span><t>Add a note</t></span>
                            </span>
                        </HtButton>
                    </div>

                    <modalable
                        ref="modalableUserEdit"
                        class="modalable-1 medium"
                        :mode="2"
                    >
                        <template #header>
                            <div class="edit-header">
                                <FontAwesomeIcon
                                    icon="chevron-left"
                                    class="back-button"
                                    @click="$refs.modalableUserEdit.close()"
                                />
                                <div class="labels">
                                    <h2><t>Edit</t> “{{ companyPlanningEvent.name }}”</h2>
                                    <div
                                        v-if="eventDateTime !== '-'"
                                        class="datetime"
                                    >
                                        {{ eventDateTime }}
                                    </div>
                                    <div
                                        v-if="companyPlanningEvent.owner"
                                        class="organizer"
                                    >
                                        <span><t>Organized by</t> :</span>
                                        <UserBlock
                                            :key="companyPlanningEvent.owner_id"
                                            class="avatar"
                                            :item="{ company_user: companyPlanningEvent.owner }"
                                            :size="16"
                                        />
                                        <span>{{ companyPlanningEvent.owner.firstname }}
                                            {{ companyPlanningEvent.owner.lastname }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <UserPlanningEventEdit
                            :id="companyPlanningEvent.id"
                            ref="userEdit"
                            :value="companyPlanningEvent"
                            :from="from"
                            :intelligent-selection="intelligentSelection"
                            :company-user-id="companyUserId"
                            :company-user-program-id="companyPlanningEvent.company_user_program_id"
                            @onUpdate="onUpdate(...arguments)"
                            @onDelete="onListenDelete(...arguments)"
                        />
                    </modalable>

                    <modalable
                        ref="modalableUserNote"
                        class="modalable-1 small"
                        :mode="2"
                    >
                        <UserNoteModal
                            v-model="currentNote"
                            :collection="companyPlanningEvent.notes"
                            :resource-name="'company_planning'"
                            :resource-id="companyPlanningEvent.id"
                            @onCreateNote="(note) => onCreateNote(note)"
                            @onDeleteNote="(note) => onDeleteNote(note)"
                        />
                    </modalable>
                </div>
                <div v-else>
                    <div class="empty-component">
                        <strong>
                            <t>This event does not exist anymore</t>
                        </strong>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <HtButton
                v-if="isLoaded && !isCancelled && companyPlanningEvent.permissions.delete"
                type="destructive"
                size="large"
                data-cy="event-delete-button"
                :loading="isDeleteLoading"
                @click="onDelete()"
            >
                <t>Delete</t>
            </HtButton>
            <HtButton
                v-if="isLoaded && !isCancelled && companyPlanningEvent.permissions.update"
                type="primary"
                size="large"
                data-cy="event-edit-button"
                :loading="isModifyLoading"
                @click.stop="openEditModal()"
            >
                <t>Edit</t>
            </HtButton>
        </template>
    </NewHtModal>
</template>

<script>
import CompanyPlanningEventCollection from '@/models/CompanyPlanningEventCollection';
import Modalable from '@/Modalable';
import moment from 'moment';
import 'moment-timezone';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import api from '@/store/api';
import DescLine from '@/components/pages/dashboard/modals/DescLine.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import UserNoteModal from './UserNoteModal.vue';
import UserPlanningEventEdit from './UserPlanningEventEdit.vue';

export default {
    name: 'NewUserPlanningEventItem',
    permissions: ['ModelCompanyUserNote'],

    components: {
        UserPlanningEventEdit,
        UserNoteModal,
        DescLine,
        HtButton,
    },

    props: {
        from: { type: String, required: true },
        intelligentSelection: { type: Array, required: false },
        collection: { type: CompanyPlanningEventCollection, required: false },
    },

    data() {
        return {
            currentNote: null,
            companyPlanningEventId: null,
            companyUserId: null,
            companyPlanningEvent: null,
            isModifyLoading: false,
            isDeleteLoading: false,
        };
    },

    computed: {
        getEventTimeZone() {
            if (!this.companyPlanningEvent.timezone) {
                return this.companyPlanningEvent.company_user.timezone;
            }

            return this.companyPlanningEvent.timezone;
        },

        eventDateTime() {
            if (this.companyPlanningEvent.task.datetime_start && this.companyPlanningEvent.task.datetime_end) {
                const date = moment(this.companyPlanningEvent.task.datetime_start).format('Do MMMM YYYY');
                const startTime = this.companyPlanningEvent.time_start;
                const endTime = this.companyPlanningEvent.time_end;
                return `${date} | ${startTime} - ${endTime} ${this.getEventTimeZone}`;
            }
            return '-';
        },

        isLoaded() {
            return this.companyPlanningEvent;
        },

        isCancelled() {
            return this.companyPlanningEvent.task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },

        statusText() {
            switch (this.companyPlanningEvent.task.status) {
            case 'done':
                return this.translate('Done');
            case 'on_error':
                return this.translate('Error');
            default:
                if (moment(this.companyPlanningEvent.task.datetime_start) > moment()) {
                    return this.translate('Forthcoming');
                }
                return this.translate('Event passed');
            }
        },

        computedDescription() {
            let job = this.translate('No job');
            if (this.companyPlanningEvent.user.job) {
                job = this.companyPlanningEvent.user.job.is_heyteam
                    ? this.translate(this.companyPlanningEvent.user.job.name)
                    : this.companyPlanningEvent.user.job.name;
            }

            let department = this.translate('No department');
            if (this.companyPlanningEvent.user.department) {
                department = this.companyPlanningEvent.user.department.is_heyteam
                    ? this.translate(this.companyPlanningEvent.user.department.name)
                    : this.companyPlanningEvent.user.department.name;
            }

            let contract = this.translate('No contract');
            if (this.companyPlanningEvent.user.contract) {
                contract = this.companyPlanningEvent.user.contract.is_heyteam
                    ? this.translate(this.companyPlanningEvent.user.contract.name)
                    : this.companyPlanningEvent.user.contract.name;
            }

            const variables = [
                ['{enrollee_firstname}', this.companyPlanningEvent.user.firstname],
                ['{enrollee_lastname}', this.companyPlanningEvent.user.lastname],
                [
                    '{enrollee_starting_date}',
                    this.$Utils
                        .moment(this.companyPlanningEvent.user.arrival_date)
                        .format(
                            (this.companyPlanningEvent.user.company_language.key === 'fr')
                                ? 'DD/MM/YYYY' : 'MM/DD/YYYY',
                        ),
                ],
                ['{enrollee_job_title}', job],
                ['{enrollee_department}', department],
                ['{enrollee_contract}', contract],
            ];

            return variables.reduce(
                // fixme: replaceAll function arrived in ES12+, we need to use replace with "/g" regex to be compatible with chrome browser <=v79 (used by Kiloutou)
                (description, [key, value]) => {
                    const regex = new RegExp(key, 'g');
                    return description.replace(regex, value);
                },
                this.companyPlanningEvent.description || '',
            );
        },

        participants() {
            return this.$Utils.getAvatarGroupData(
                this.companyPlanningEvent.attendees.map(
                    (a) => a.company_user,
                ),
            );
        },
    },

    methods: {
        /**
         * @param {CompanyUserNote} note
         * @returns {void}
         */
        onDownloadNote(note) {
            document.location.href = note.companyFile.link;
        },

        openNoteModal(note) {
            this.currentNote = note;
            this.$refs.modalableUserNote.open();
        },

        onCreateNote(note) {
            this.companyPlanningEvent.notes.push(note);
        },

        onDeleteNote(noteId) {
            const index = this.companyPlanningEvent.notes.findIndex((item) => item.id === noteId);
            this.companyPlanningEvent.notes.splice(index, 1);
        },

        beforeOpen(resolve) {
            api.user.planningEvents.get(this.companyPlanningEventId).then((response) => {
                this.companyPlanningEvent = response.data.data;
            });

            resolve();
        },

        beforeClose(resolve) {
            this.companyPlanningEvent = null;
            resolve();
        },

        onUpdate() {
            this.$refs.modal.close();
            this.$emit('onUpdate');
        },

        /**
     * Quand on clique sur delete depuis la modale enfant
     * @returns {void}
     */
        onListenDelete() {
            this.$refs.modal.close();
            this.$emit('onDelete');
        },

        openEditModal() {
            this.$refs.modalableUserEdit.open();
        },

        open(event_id, companyUserId) {
            this.companyUserId = companyUserId;
            this.companyPlanningEventId = event_id;
            this.$refs.modal.open();
        },

        async onDelete() {
            this.isDeleteLoading = true;
            const didUserClickDelete = await Modalable.openRegisteredWindow(
                'Deletewindow',
                {
                    content: this.translate(
                        'Are you sure you want to delete this event ?',
                    ),
                },
            );
            if (didUserClickDelete === true) {
                await api.user.planningEvents.delete(this.companyPlanningEventId);

                this.isDeleteLoading = false;
                this.$refs.modal.close();
                this.$emit('onDelete');
            } else this.isDeleteLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped src="./NewUserPlanningEventItem.scss"></style>
