<template>
    <NewHtModal
        ref="modal"
        class="user-requirement-category-edit-modal"
    >
        <template #header>
            <div class="header-modal">
                <div class="title">
                    {{ getRequirementCategoryName }}
                </div>
                <div class="subtitle">
                    <t>Complete my administrative file</t>
                </div>
            </div>
        </template>
        <template
            v-if="permissionsLoaded"
            #default
        >
            <HtFormCard
                v-if="refusalReason"
                class="reason-card mb-4"
            >
                <div class="reason-card-title mb-1">
                    <t>Requirement refused</t>
                </div>
                <p>
                    <t>Your requirement has been refused for the following reason</t> : "{{ refusalReason }}"
                </p>
            </HtFormCard>

            <NewUserRequirementCategoryEdit
                v-if="category"
                :is-participant="true"
                :requirement-category="category"
                :is-modal="true"
                :is-disabled="isDisabled"
                :editable="!canValidate && validatedTaskCount === 0 && modeEdit"
                :is-related-task-executor="isRelatedTaskExecutor"
                :signer-task="isRelatedTaskSigner || isRelatedTaskExecutor ? currentTask : null"
                :user-id="userId"
                :show-generate-pdf="showGeneratePdf"
                :can-update="canUpdate"
                @on-reload="reloadCategoryAndTask"
                @onChangeValue="onChangeValue(...arguments)"
                @addGroup="onAddGroup"
                @removeGroup="onRemoveGroup"
            />

            <div
                v-if="isRelatedTaskValidation && relatedValidationTaskList.length > 0"
            >
                {{ validatedTaskCount }} / <t :count="relatedValidationTaskList.length">
                    {count} validation | {count} validations
                </t>
            </div>

            <Modalable
                ref="validationConfirmationModal"
                class="modal modalable-4"
            >
                <h2><t>Confirm validation</t></h2>

                <t>Please, confirm the validation of the requirement</t>

                <div class="row-line middle">
                    <HtButton
                        size="large"
                        type="primary"
                        data-cy="requirement-validation-cancel"
                        @click="$refs.validationConfirmationModal.close()"
                    >
                        <t>Cancel</t>
                    </HtButton>
                    <HtButton
                        size="large"
                        type="secondary"
                        data-cy="requirement-validation-confirm"
                        @click="onValidation()"
                    >
                        <t>Confirm</t>
                    </HtButton>
                </div>
            </Modalable>
            <Modalable
                ref="refusalConfirmationModal"
                class="modal modalable-4"
            >
                <h2><t>Indicate refusal reason</t></h2>
                <t>Please, explain to the requirement recipient why it has been refused</t>

                <HtFormInput
                    id="reason"
                    ref="reasonInput"
                    v-model="refusalReasonSent"
                    name="reason"
                    cypress="reason"
                    :label="translate('Reason')"
                    :show-optional="false"
                    :max-length="500"
                    class="mt-4"
                />

                <div class="row-line middle">
                    <HtButton
                        size="large"
                        type="primary"
                        data-cy="requirement-refusal-cancel"
                        @click="$refs.refusalConfirmationModal.close()"
                    >
                        <t>Cancel</t>
                    </HtButton>
                    <HtButton
                        size="large"
                        type="secondary"
                        data-cy="requirement-refusal-confirm"
                        :disabled="!refusalReasonSent"
                        @click="onValidation(refusalReasonSent)"
                    >
                        <t>Send refusal</t>
                    </HtButton>
                </div>
            </Modalable>
        </template>
        <template
            v-if="permissionsLoaded && isLoaded"
            #footer
        >
            <NewModalFooter
                v-if="modeEdit && !isCancelled"
                class="button-container"
                :show-validation-actions="canValidate"
                :show-save="showSave"
                :show-not-concerned="showNotConcerned"
                :show-delete="canDelete"
                :button-loading="buttonLoading"
                @on-click-validate="$refs.validationConfirmationModal.open()"
                @on-click-refuse="$refs.refusalConfirmationModal.open()"
                @on-click-not-concerned="onNotConcern()"
                @on-click-save="onSave()"
                @on-click-delete="onDelete()"
                @on-reload="reloadCategoryAndTask"
            />
        </template>
    </NewHtModal>
</template>

<script>

import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import Modalable from '@/components/globals/Modalable.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import CompanyResourceProgramParticipant from '@/models/CompanyResourceProgramParticipant';
import CompanyUserProgramTaskValidation from '@/models/CompanyUserProgramTaskValidation';
import get from 'lodash.get';
import api from '@/store/api';
import modalMixin from '@/components/globals/modals/modalMixin';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import NewModalFooter from './NewModalFooter.vue';
import uploadBarEventBus from '../../../eventBus/uploadBar';
import NewUserRequirementCategoryEdit from './NewUserRequirementCategoryEdit.vue';

export default {
    name: 'NewUserRequirementCategoryEditModal',
    permissions: [
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserProgramTaskValidation',
    ],
    components: {
        HtFormCard,
        HtFormInput,
        Modalable,
        NewUserRequirementCategoryEdit,
        NewModalFooter,
        HtButton,
    },
    mixins: [
        modalMixin,
    ],

    props: {
        modeEdit: {
            type: Boolean,
            default: true,
        },
        showGeneratePdf: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            userId: null,
            categoryId: null,
            currentTask: {},
            requirementUpdated: {},
            refusalReason: '',
            refusalReasonSent: '',
            buttonLoading: false,
        };
    },

    computed: {
        category() {
            return this.$store.getters['requirementCategoryEditModal/category'](this.categoryId);
        },

        currentTaskParticipant() {
            const participants = get(this.currentTask, 'participants', [{ type: null }]);

            return participants[0];
        },

        isRelatedTaskPreparer() {
            return this.currentTaskParticipant.type === CompanyResourceProgramParticipant.TYPE_PREPARER;
        },

        isRelatedTaskExecutor() {
            return this.currentTaskParticipant.type === CompanyResourceProgramParticipant.TYPE_EXECUTOR;
        },

        isRelatedTaskSigner() {
            return this.currentTaskParticipant.type === CompanyResourceProgramParticipant.TYPE_SIGNER;
        },

        isRelatedTaskValidation() {
            return this.currentTaskParticipant.type === CompanyResourceProgramParticipant.TYPE_VALIDATOR;
        },

        validatedTaskCount() {
            return this.relatedValidationTaskList.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE).length;
        },

        hasDynamicDocumentInCategory() {
            return this.$store.getters['requirementCategoryEditModal/hasDynamicDocument'](this.categoryId);
        },

        relatedValidationTaskList() {
            const tasks = get(this.category, 'tasks', []);

            return tasks.filter((task) => {
                const participants = get(task, 'participant', [{ type: null }]);
                return participants[0].type === CompanyResourceProgramParticipant.TYPE_VALIDATOR;
            });
        },

        isParticipantFromRelatedTask() {
            return !!this.currentParticipantRelatedTask;
        },

        currentParticipantRelatedTask() {
            const participants = get(this.currentTask, 'participants', []);
            return participants.find((participant) => participant.company_user_id === this.shared.session.companyUser.id);
        },

        /**
         * @return {string}
         */
        getSaveMethod() {
            const participantType = get(this.currentParticipantRelatedTask, 'type', null);

            switch (participantType) {
            case CompanyResourceProgramParticipant.TYPE_PREPARER:
                return 'handlePreparatorValidation';
            case CompanyResourceProgramParticipant.TYPE_EXECUTOR:
                return 'handleExecutorValidation';
            case CompanyResourceProgramParticipant.TYPE_SIGNER:
                return 'handleSignerValidation';
            default:
                return null;
            }
        },

        /**
         * @returns {boolean}
         */
        canUpdate() {
            return this.$canUpdate(
                'ModelCompanyUserRequirementCategory',
                {
                    company_user_id: this.category.tasks[0].user.id,
                    id: this.category.id,
                },
            );
        },

        canDelete() {
            return this.$canDelete(
                'ModelCompanyUserRequirementCategory',
                {
                    company_user_id: this.category.tasks[0].user.id,
                    id: this.category.id,
                },
            );
        },

        isDisabled() {
            return !(this.canSign || this.canUpdate);
        },

        isCancelled() {
            return get(this.currentTask, 'status') === 'cancelled';
        },

        /**
         * @returns {boolean}
         */
        canSign() {
            return this.$can('ModelCompanyUserProgramTask', { id: get(this.currentTask, 'id') }, 'handleSignerValidation')
                || this.$can('ModelCompanyUserProgramTask', { id: get(this.currentTask, 'id') }, 'handleExecutorValidation');
        },

        /**
         * @returns {boolean}
         */
        showSave() {
            /* @EdgeCase ugly, but we need to prevent the user from setting the category task to done
            *  when editing a dynamic document
            * cf.https://heyt.atlassian.net/browse/LIN-2113 */
            if (this.hasDynamicDocumentInCategory && this.isRelatedTaskExecutor) {
                return this.isParticipantFromRelatedTask;
            }

            return this.canUpdate || this.canSign;
        },

        /**
         * @returns {boolean}
         */
        showNotConcerned() {
            return !get(this.category, 'is_mandatory', true) && this.canUpdate && !this.isRelatedTaskPreparer;
        },

        /**
         * @returns {boolean}
         */
        canValidate() {
            return this.$canCreate('ModelCompanyUserProgramTaskValidation') && this.isRelatedTaskValidation;
        },

        getParticipantsRelatedTask() {
            return get(this.relatedTask, 'participants', []);
        },

        isLoaded() {
            return Boolean(this.category);
        },

        getRequirementCategoryName() {
            const { name } = this.category;

            return this.category.is_heyteam ? this.translate(name) : name;
        },
    },

    created() {
        // Si un fichier est en cours d'upload, alors on doit mettre le bouton en loading
        // Quand le fichier a fini d'être uploadé, on peut remettre le bouton en idle
        uploadBarEventBus.$on('upload-start', this.setLoadingStatus);
        uploadBarEventBus.$on('upload-end', this.setIdleStatus);
    },

    beforeDestroy() {
        uploadBarEventBus.$off('upload-start', this.setLoadingStatus);
        uploadBarEventBus.$off('upload-end', this.setIdleStatus);
    },

    methods: {
        /**
         * @param {function} callback
         */
        async performAsyncAction(callback, eventName = 'on-save', callVeeValidate = false) {
            this.setLoadingStatus();
            try {
                if (callVeeValidate) {
                    const isValid = await this.$validator.validateAll();
                    if (!isValid) {
                        return;
                    }
                }
                await callback();
            } finally {
                this.setIdleStatus();
            }
            this.$emit(eventName);
            this.$store.dispatch('requirementCategoryEditModal/stop');
            this.$refs.modal.close();
        },

        setLoadingStatus() {
            this.buttonLoading = true;
        },

        setIdleStatus() {
            this.buttonLoading = false;
        },

        async onChangeValue(id, hasChanged) {
            this.requirementUpdated[id] = hasChanged;
        },

        reloadCategoryAndTask() {
            this.loadCategoryAndTask(this.userId, this.category.id).then(() => {
                this.initRequirementUpdated();
            });
        },

        loadCategoryAndTask(userId, resourceId) {
            return new Promise((resolve, reject) => {
                api.user.requirementCategories.get(userId, resourceId).then((res) => {
                    this.categoryId = res.data.data.id;
                    this.$store.dispatch('requirementCategoryEditModal/addCategory', res.data.data);
                    this.currentTask = this.category.tasks.find((task) => task.id === this.currentTask?.id);
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        open(userId, resourceId = null, task = null) {
            this.refusalReason = '';
            this.refusalReasonSent = '';
            this.currentTask = task;
            this.userId = userId;

            this.$store.dispatch('requirementCategoryEditModal/start');

            this.loadCategoryAndTask(userId, resourceId).then(() => {
                this.$refs.modal.open();
                this.initRequirementUpdated();
                this.getRefusalReason();
                if (!this.currentTask) {
                    this.setDefaultCurrentTask();
                }
            });
        },

        initRequirementUpdated() {
            this.category.groups.forEach((group) => {
                group.requirements.forEach((requirement) => {
                    const requirementUpdateId = get(this.requirementUpdated, requirement.id, false);
                    this.$set(this.requirementUpdated, requirement.id, requirementUpdateId);
                });
            });
        },

        async onSave() {
            let taskId = null;
            if (this.shouldSendTask()) {
                taskId = get(this.currentTask, 'id');
            }

            // A cause du fait que le delegue (company_user_id) puisse etre null
            if (!taskId) {
                const taskPreparer = this.category.tasks.find((task) => task.status === 'pending' && task.participants.find((participant) => participant.type === 'preparer'));
                taskId = get(taskPreparer, 'id');
            }

            this.performAsyncAction(async () => {
                await api.user.requirementCategories.update(
                    this.userId,
                    this.category.id,
                    {
                        resource: this.category,
                        taskId,
                    },
                );
                this.$refreshPermissions(['ModelCompanyUserProgramTaskValidation'], true);
            }, 'on-save', true);
        },

        async onNotConcern() {
            this.performAsyncAction(async () => {
                await api.user.requirementCategories.setNotConcerned(this.userId, this.category.id);
            });
        },

        async onValidation(reason = null) {
            this.performAsyncAction(async () => {
                this.$refs.validationConfirmationModal.close();
                this.$refs.refusalConfirmationModal.close();
                const taskValidation = new CompanyUserProgramTaskValidation(['company_user_program_task_id', 'reason']);
                taskValidation.company_user_program_task_id = this.currentTask.id;
                taskValidation.reason = reason;
                await taskValidation.save();
            });
        },

        onDelete() {
            if (this.category.has_archives) {
                this.genericConfirm(
                    this.translate('You are about to delete the category: {categoryName}', { categoryName: this.category.name }),
                    `
                    ${this.translate('Would you like to keep these archives?')} <br>
                    ${this.translate('If not, they will no longer be available for consultation.')}
                    `,
                    null,
                    this.translate('Keep'),
                    this.translate('Delete'),
                ).then(() => {
                    this.performAsyncAction(
                        async () => {
                            await api.user.requirementCategories.delete(this.userId, this.category.id, {
                                params: { delete_archives: false },
                            });
                        },
                        'on-delete',
                    );
                }).catch((err) => {
                    if (err === 'decline') {
                        this.performAsyncAction(
                            async () => {
                                await api.user.requirementCategories.delete(this.userId, this.category.id, {
                                    params: { delete_archives: true },
                                });
                            },
                            'on-delete',
                        );
                    }
                });
            } else {
                this.genericConfirm(
                    this.translate('You are about to delete the category: {categoryName}', { categoryName: this.category.name }),
                    this.translate('Are you sure you want to delete this requirement category ?'),
                ).then(() => {
                    this.performAsyncAction(
                        async () => {
                            await api.user.requirementCategories.delete(this.userId, this.category.id, {
                                params: { delete_archives: false },
                            });
                        },
                        'on-delete',
                    );
                });
            }
        },

        getRefusalReason() {
            this.$http.get(`task/validation/refusal-reason/${this.category.id}`).then(({ data }) => {
                if (data.resource) {
                    this.refusalReason = data.resource.reason;
                }
            });
        },

        onAddGroup(group) {
            this.$store.dispatch('requirementCategoryEditModal/addGroup', { categoryId: this.categoryId, group });
        },

        onRemoveGroup(groupId) {
            this.$store.dispatch('requirementCategoryEditModal/removeGroup', { categoryId: this.categoryId, groupId });
        },

        /**
         * @edgeCase LIN-2580
         * @description If the category contains a requirement file_to_sign where current user is not executor,
         *   we should avoid setting defaut task to the first pending task to avoid category task validation before signature
         * @returns {Boolean}
         */
        shouldSendTask() {
            return !(this.category.groups.some((group) => group.requirements.some(
                (requirement) => requirement.type === 'file_to_sign'
                    && requirement.tasks.some((task) => task.status === 'pending' && task.participants.some(
                        (p) => p.type === 'executor'
                            && p.company_user_id !== this.shared.session.companyUser.id,
                    )),
            )));
        },

        setDefaultCurrentTask() {
            [this.currentTask] = this.category.tasks
                .filter(
                    (task) => task.status === 'pending'
                        && task.participants.some((p) => p.type === 'executor'),
                );
        },
    },
};
</script>

<style lang="scss" src="./NewUserRequirementCategoryEditModal.scss" scoped />
