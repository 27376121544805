<template>
    <div>
        <form
            v-if="item"
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="modal-box">
                <InputField
                    v-model="item.translations.title[editingLang]"
                    :label="translate('Title')"
                    :type="'text'"
                    :name="'title'"
                    :validate="'required'"
                    :validate-as="translate('title')"
                    :placeholder="translate('Write the carousel title')"
                />
            </div>
        </form>

        <div class="modal-actions right">
            <Button
                v-show="item.id && editingLang === defaultLanguageKey"
                class="negative"
                @click="onDelete()"
            >
                <t>Delete</t>
            </Button>
            <Button
                :state="button_state"
                @click="onValidate()"
            >
                {{ labelButton }}
            </Button>
        </div>
    </div>
</template>

<script>

import CompanyCustomizeCollection from '@/models/CompanyCustomizeCollection';
import api from '@/store/api';

export default {
    name: 'CarouselEdit',
    inject: ['modal'],

    props: {
        value: { type: Object, default: null },
        collection: { type: CompanyCustomizeCollection, required: true },
        editingLang: { type: String, required: true },
    },

    data() {
        return {
            item: null,
            button_state: 'idle',
        };
    },

    computed: {
        labelTitle() {
            return this.translate(this.item.id === null ? 'Add carousel' : 'Edit carousel');
        },
        labelButton() {
            return this.translate(this.item.id === null ? 'Add' : 'Save');
        },
        defaultLanguageKey() {
            return this.shared.session.company.company_languages.models
                .find((language) => language.is_default).key;
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
    },

    created() {
        if (this.value) this.item = this.value;
        else {
            this.item = {};
            this.item.title = '';
            this.item.translations = { title: {} };
            this.item.is_enabled = true;
        }
        this.modal.setTitle(this.labelTitle);
    },

    methods: {
        async onValidate() {
            if (!await this.$validator.validateAll()) return;

            await this.save();

            this.modal.close();
            this.$emit('onReload');
        },

        async onDelete() {
            if (await this.modal.deleteWindow()) {
                await this.delete();
                this.modal.close();
                this.$emit('onReload');
            }
        },

        async save() {
            const method = this.item.id ? 'put' : 'post';

            return new Promise((resolve, reject) => {
                this.$http[method](
                    api.endpoints.CAROUSELS,
                    { carousel: this.item },
                )
                    .then((response) => {
                        this.item.id = response.data.id;
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        async delete() {
            return this.$http.delete(`${api.endpoints.CAROUSELS}${this.item.id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
	@import "~@/styles/var";

	.input-removable {
		display: flex;
		justify-content: space-between;
		align-items: center;

		input {
			width: calc(100% - 35px);
		}
	}

	.add-options {
		text-align: right;
		text-decoration: underline;
		font-family: $lato-bold;
        body:not(.old-ui) &{
            font-family: var(--poppins);
            font-weight: 600;
        }
	}

</style>
