<template>
    <HtModal ref="modal">
        <template #full-header>
            <div class="header">
                <div
                    class="icon"
                    @click="close"
                >
                    <font-awesome-icon icon="chevron-left" />
                </div>
                <div class="details">
                    <div class="title">
                        <t>E-mail</t>
                    </div>
                    <div>{{ sendDateTimeLabel }}</div>
                    <div>à {{ email.email_sent_to }}</div>
                </div>
            </div>
        </template>
        <template #default>
            <div class="email-notification-display">
                <fieldset>
                    <label><t>E-mail subject</t></label>
                    <div class="subject">
                        {{ email.generated_subject || email.subject }}
                    </div>
                </fieldset>

                <fieldset v-if="email.generated_content">
                    <label><t>E-mail content</t></label>
                    <iframe ref="content" />
                </fieldset>
            </div>
        </template>
    </HtModal>
</template>

<script>
import CompanyUserNotification from '@/models/CompanyUserNotification';
import moment from 'moment';

export default {
    name: 'EmailNotificationDisplayModal',
    props: {
        email: {
            type: CompanyUserNotification,
            required: true,
        },
    },
    computed: {
        sendDateTimeLabel() {
            return this.translate('Sent on {date} at {time}', {
                date: new Date(this.email.schedule_at).toLocaleDateString(),
                time: moment(this.email.schedule_at).format('HH:mm'),
            });
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
            this.$nextTick(() => {
                if (this.email.generated_content) {
                    this.$refs.content.srcdoc = this.email.generated_content;
                }
            });
        },
        close() {
            this.$refs.modal.close();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.email-notification-display {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    color: $text;
}

.header {
    display: flex;
    gap: 1.6rem;

    padding: 3.2rem 1.6rem;
    background-color: $blue-light;

    .details {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .title {
            font-family: $lato-bold;
            body:not(.old-ui) &{
                font-family: var(--poppins);
                font-weight: 600;
            }
            font-size: 2.2rem;
            line-height: 2.6rem;
        }
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: white;
        border: 1px solid $grey-ultra-light;

        border-radius: 50%;
        // Chrome distorts the size if we use width
        min-width: 3.4rem;
        height: 3.4rem;
    }
}

fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

label {
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    font-size: 1.6rem;
    line-height: 1.9rem;
}

iframe {
    width: 100%;
    height: 40rem;

    border: 1px solid $grey-ultra-light;
    box-sizing: border-box;
    border-radius: 5px;
}
</style>
