<template>
    <div
        class="card"
        :class="[configs.type, { 'scale' : configs.scale , 'no-background' : !configs.background}]"
        :style="{'width': configs.perRow !== 'auto' ? `calc(${100/configs.perRow}% - 8px)` : 'auto'}"
        @click="$emit('click')"
    >
        <div class="card-body">
            <!-- SQUARE -->
            <div
                v-if="configs.type === 'square'"
                class="container-wrapper"
            >
                <div
                    v-if="$slots.action"
                    class="action"
                    @click="$refs.actions.open()"
                >
                    <slot name="action" />
                    <span class="menu-container">
                        <Popin
                            v-if="$slots.action"
                            ref="actions"
                            :uniq-class="'actions'"
                            class="action-popin"
                        >
                            <ul class="dropdown-menu table-popin-menu">
                                <slot name="action-menu" />
                            </ul>
                        </Popin>
                    </span>
                </div>

                <div
                    v-if="$slots.image"
                    class="container-top"
                >
                    <div class="card-image">
                        <slot name="image" />
                    </div>
                </div>
                <div class="container-bottom">
                    <div
                        v-if="hasStatus"
                        class="card-status"
                    >
                        <div
                            class="status-dot"
                            :class="hasStatus"
                        />

                        <div class="card-status-label">
                            <slot :name="hasStatus" />
                        </div>
                    </div>
                    <div
                        v-if="$slots.title"
                        class="title"
                    >
                        <slot name="title" />
                    </div>

                    <div
                        v-if="$slots.description"
                        class="description"
                    >
                        <slot name="description" />
                    </div>

                    <slot name="extra" />
                </div>
            </div>
            <!-- !SQUARE -->

            <!-- LONG -->
            <div
                v-if="configs.type === 'long'"
                class="container-wrapper"
            >
                <div
                    v-if="$slots.image"
                    class="container-left"
                >
                    <div class="image">
                        <slot name="image" />
                    </div>
                </div>
                <div class="container-right">
                    <div
                        v-if="$slots.title"
                        class="title"
                    >
                        <slot name="title" />
                    </div>

                    <div
                        v-if="$slots.description"
                        class="description"
                    >
                        <slot name="description" />
                    </div>
                </div>
            </div>

            <!-- SMALL -->
            <div
                v-if="configs.type === 'small'"
                class="container-wrapper"
            >
                <div
                    v-if="$slots.image"
                    class="container-left"
                >
                    <div class="image">
                        <slot name="image" />
                    </div>
                </div>

                <div
                    v-if="$slots['container-left']"
                    class="container-left"
                >
                    <slot name="container-left" />
                </div>

                <div
                    v-if="$slots['extra-right'] || $slots.description || $slots.title"
                    class="container-right"
                >
                    <div
                        v-if="$slots.title"
                        class="title"
                    >
                        <slot name="title" />
                    </div>

                    <div
                        v-if="$slots.description"
                        class="description"
                    >
                        <slot name="description" />
                    </div>

                    <div class="extra-right">
                        <slot name="extra-right" />
                    </div>
                </div>
            </div>

            <div
                v-if="$slots.extra && (configs.type === 'long' || configs.type === 'small')"
                class="extra"
            >
                <slot name="extra" />
            </div>
            <!-- !LONG -->

            <!-- DEFAULT SLOT -->
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',

    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            // Default configs
            configs: {
                perRow: 3,
                type: 'square', // ['long' ,'square']
                scale: true, // scale on hover
                background: true, // has background or is transparent
            },
        };
    },

    computed: {
        hasStatus() {
            return Object.keys(this.$slots).find((key) => key.includes('status')) || false;
        },
    },

    created() {
        this.configs = { ...this.configs, ...this.options };
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';
	.dropdown-menu {
		z-index: 2 !important;
		top: 30px !important;
		left: -102px !important;

		div {
			display: flex;
			flex-flow: column;
		}
	}

	.card {

        &.item-state:after {
            position: absolute;
            content: url('/static/icons/checked.svg');
            top: -15px;
            right: -15px;
            z-index: 2;
        }

		display: -ms-flexbox;
		-ms-flex-pack: center;
		-ms-flex-align: center;
		flex-direction: column;
		background: $white;
		flex-flow: column;
		position: relative;
		align-items: center;
		border: 1px solid $border-grey-light;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s ease-in-out;
		z-index: 1;
		will-change: transform;
		margin-bottom: 1em;
		// max-width: calc(50% - 1em);
		// overflow: hidden;

		&.no-background{
			background: transparent;
			border:none
		}

		&.selected {
			border: 2px solid $yellow !important;

			.card-body:after {
				position: fixed;
				content: url('/static/icons/checked.svg');
				top: -15px;
				right: -15px;
				z-index: 2;
			 }
		}
		&.selected-red {
			border: 2px solid $primary;
		}

		// TYPES
		&.square {
			justify-content: center;

			.card-body {
				min-height: 275px;
				height: 100%;
			}

			.container-wrapper {
				height: 100%;
				width: 100%;

				.container-top {
					height: 100px;
				}

				.container-bottom {
					height: calc(100% - 100px);
					padding: 1em 1.5em;
					display: flex;
					align-items: center;
					flex-flow: column;
				}
			}

			.action {
				position: absolute;
				top: 5px;
				right: 5px;
				background: white;
				border-radius: 50%;
				padding: 0.5em;
				width: 25px;
				height: 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $grey-light;

				.menu-container {
					position: relative;
				}
			}

			.title {
				text-align:center;
				font-size: 2rem;
				font-family: $lato-bold;
				body:not(.old-ui) &{
					font-family: var(--poppins);
					font-weight: 600;
				}
				padding: 0.25em 0;
				> div {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.description {
				text-align:center;
				font-size: 1.35rem;
				color: $grey;
			}

			.card-action {
				width: 100%;
				height: 50px;
				margin-top: 0.75em;
				line-height: 50px;
				text-align: center;
				border-top: 1px solid $border-grey-light;
				font-family: $lato-bold;
				body:not(.old-ui) &{
					font-family: var(--poppins);
					font-weight: 600;
				}

				div {
					transition: all 0.3s;

					&:hover {
						transform: scale(1.1);
					}
				}
			}

			.card-status {
				display: flex;
				align-items: center;

				.card-status-label {
					font-size: 1.35rem;
					text-transform: uppercase;
				}

				.status-dot {
					margin-right: 0.5em;
					width: 5px;
					height: 5px;
					border-radius: 100%;

					&.status-green {
						background: $green;
					}
					&.status-yellow {
						background: $yellow;
					}
					&.status-red {
						background: $primary;
					}
					&.status-purple {
						background: $purple;
					}
				}
			}

			.card-image {
				height: 100%;

				> div {
					background-size: cover;
					background-repeat: no-repeat;
				}
			}
		}
		&.long {
			justify-content: space-between;
			padding: 1.5em;

			.lock-wrapper {
				position: absolute;
				right: 15px;
				top: 5px;
				background: #efefef;
				border-radius: 50%;
				padding: 0.25em;
				display: flex;
				justify-content: center;
				align-items: center;

				.icon-lock {
					width: 0.75em;
					height: 0.75em;
				}
			}
			.container-wrapper {
				display: flex;
			}

			.container-left {
				width: 50px;
				min-width: 50px;
				height: 100%;

				.image {
					width:50px;
					height: 50px;
				}
			}
			.container-right {
				width: 100%;
				min-height: 50px;
				height: 100%;
				display: flex;
				// justify-content: space-between;
				justify-content: center;
				flex-flow: column;

				.title {
					font-family: $lato-bold;
					body:not(.old-ui) &{
						font-family: var(--poppins);
						font-weight: 600;
					}
					font-size: 1.6rem;
					padding-top: 0.25em;
					padding-bottom: 0.25em;
					padding-left: 1em;
					> div {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.description {
					font-size: 1.4rem;
					padding-bottom: 0.5em;
					color: $grey;
					padding-left: 1.25em;
				}

				.extra {
					border:1px solid black;
					margin-top: 0.5em;
					margin-bottom: -1em;
				}
			}
			.extra {
				margin-top: 0.5em;
				margin-bottom: -0.75em;
			}
		}

		&.small {
			justify-content: flex-start;
			padding: 15px 25px;
			margin-bottom: 10px;
			font-size: 1.4rem;
			will-change: auto;
			transform: scale(1.001, 1.001);
			&:hover{
				transform: scale(1.05, 1.05);
			}
			.lock-wrapper {
				position: absolute;
				right: 15px;
				top: 5px;
				background: #efefef;
				border-radius: 50%;
				padding: 0.25em;
				display: flex;
				justify-content: center;
				align-items: center;

				.icon-lock {
					width: 0.75em;
					height: 0.75em;
				}
			}
			.container-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.container-left {
				margin: 0 5px;
				.image {
					width: 25px;
					height: 25px;
				}
			}
			.container-right {
				margin: 0 5px;

				.extra {
					border:1px solid black;
				}
				.extra-right{
					margin-left: 20px;
				}
				.members{
					margin-left: 10px;
				}
			}
			.title {
				> div {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			.description {
				margin: 5px 0 0;
				font-size: 1.2rem;
				color: $grey;
			}
			.card-image{
				height: 20px;
				background-size: cover;
			}
		}

		&.empty {
			border: none;
			height: none;
			background: none;
            cursor: default;
		}
	}

	.modalable-1 {
		@media (max-width: $desktop-small + 300px) {
			.card:not(.small) {
				width: calc(50% - 1em) !important; //override inline styling
			}
		}

		@media (max-width: $phone + 300px) {
			.card:not(.small) {
				width: calc(100% - 2em) !important; //override inline styling
			}
		}
	}

	@media (max-width: $desktop-small) {
		.card:not(.small) {
			width: calc(50% - 1em) !important; //override inline styling
		}
	}
	@media (max-width: $phone) {
		.card:not(.small) {
			width: calc(100% - 2em) !important; //override inline styling
		}
	}
</style>

<style lang="scss">
	@import '~@/styles/var';

	.card.long{
		.image {
			.item-image {
				margin-right: 2em;
				height: 50px;
				width: 50px;
				min-width: 50px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;

				&.image-small {
					border: 1px solid $border-grey-light;
					border-radius: 10px;
					display: flex;
					justify-content: center;
					align-items: center;

					.small-image {
						width: 30px;
						height: 30px;
						background-position: center;
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}

	.card.small{

		position: relative;

		.image {

			.item-resource-icon{
				display: flex;
	   			align-items: center;
	   			padding: 0;
				border-radius: 0;
				height: 25px;
				width: 25px;
				margin: 0;
				color:#383838;
			}

			.item-image {
				padding: 0;
				border-radius: 0;
				height: 25px;
				width: 25px;
				margin: 0;
				&.image-small {
					.small-image {
						width: 25px;
						height: 25px;
						background-position: center;
						background-size: contain;
						background-repeat: no-repeat;

						// background-blend-mode: luminosity;
    					background-color: #ffffff;
					}
				}
			}
		}
	}
</style>
