<template>
    <div
        class="desc-line"
    >
        <div class="wrapper-title">
            <slot name="title" />
        </div>
        <div class="wrapper-content">
            <slot name="content" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'DescLine',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";

.desc-line {
    display: flex;
    align-items: center;
    @include ht-body;
}
.wrapper-title {
    min-width: 120px;
    color: var(--text-secondary);
    margin-right: 16px;
}
.wrapper-content {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-primary);
    p {
        color: currentColor;
    }
    .ht-avatar {
        flex: 0 0 auto;
    }
}

</style>
