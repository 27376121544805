<template>
    <div>
        <div
            class="friezes multiple"
        >
            <div
                v-for="(program, programIndex) in shownPrograms"
                :key="program.id"
                class="frieze"
            >
                <div class="wrapper-heading">
                    <div class="heading">
                        <div class="ht-heading-4">
                            <t>My progress in my program</t>
                        </div>
                        <div class="ht-heading-3">
                            {{ getProgramDescription(program) }}
                        </div>
                    </div>
                    <!-- to decomment when we have the data for late tasks -->
                    <!-- <div
                        v-if="!isMobile && shownPrograms.length === 1"
                        class="late-tasks"
                    >
                        <span>{{ translate('{nbtasks} late tasks', {
                            nbtasks: 2,
                        }) }}</span>
                    </div> -->
                </div>
                <div class="wrapper-body">
                    <HtTooltip
                        position="block-end"
                        align="center"
                        :offset="-4"
                    >
                        <div class="frieze-progress-bar">
                            <div class="progress-bar-placeholder" />
                            <div
                                class="progress-bar-indicator"
                                :style="
                                    `width: calc(${programs[programIndex].enrollee_completion}*1%);`
                                "
                            />
                        </div>
                        <template #target>
                            {{ translate('{completion}% of tasks completed', {
                                completion: programs[programIndex].enrollee_completion,
                            }) }}
                        </template>
                    </HtTooltip>
                    <!-- to decomment when we have the data for late tasks -->
                    <!-- <div
                        v-if="isMobile || shownPrograms.length > 1"
                        class="late-tasks"
                    >
                        <HtIcon
                            name="warning-triangle"
                            size="16"
                        />
                        <span>{{ translate('{nbtasks} late tasks', {
                            nbtasks: 2,
                        }) }}</span>
                    </div> -->
                </div>
            </div>
            <div
                v-if="isMobile"
                class="wrapper-other-links"
            >
                <router-link
                    :to="{ name: 'TeamMyTeam', label: 'My team'}"
                    class="other-link"
                >
                    <HtEmojiIcon
                        size="30"
                        font-size="16"
                        class="other-link-icon"
                    >
                        👥
                    </HtEmojiIcon>
                    <span class="other-link-text"><t>Discover my team</t></span>
                    <HtIcon
                        class="other-link-arrow"
                        name="nav-arrow-right"
                        size="24"
                    />
                </router-link>
            </div>
        </div>
        <router-link
            class="other-programs"
            :to="{
                label: 'Programs',
                name: 'ProfileProgram',
                params: {
                    company_user_id: shared.session.companyUser.id,
                },
            }"
        >
            <span>{{ programs.length > 2 ? getRemainingPrograms : translate('View my program') }}</span>
        </router-link>
    </div>
</template>

<script>

export default {
    name: 'FriezeMultiple',
    props: {
        programs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            currentProgramIndex: 0,
            isMobile: false,
        };
    },
    computed: {
        shownPrograms() {
            return this.programs.length >= 2 ? this.programs.slice(0, 2) : this.programs;
        },
        getRemainingPrograms() {
            return this.programs.length > 2 ? this.translate('You have {requests} other request(s) in progress', {
                requests: this.programs.length - 2,
            }) : '';
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        getProgramPhase(program) {
            const diffDays = this.$Utils.moment().diff(this.$Utils.moment(program.starts_at), 'days');

            if (diffDays < 0) {
                const keyDates = this.$store.getters['config/getKeyDatesBySlug'];
                const keyDateName = this.translate(
                    keyDates[program.program_key_date_slug]?.name,
                );
                return this.translate('before {key_date_name}', { key_date_name: keyDateName.toLowerCase() });
            }
            if (diffDays === 0) return this.translate('1st day');
            if (diffDays > 0 && diffDays <= 7) return this.translate('1st week');
            if (diffDays > 7 && diffDays <= 30) return this.translate('1st month');
            if (diffDays > 30) return this.translate('after 1st month');
            return '';
        },
        getProgramDescription(program) {
            if (program.enrollee_completion !== 100) {
                return this.translate('Current phase : {phase}', {
                    phase: this.getProgramPhase(program),
                });
            }
            return this.translate('Program completed and finalized.');
        },
        onResize() {
            this.isMobile = window.innerWidth < 1024;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";

.friezes {
    padding: 20px 30px 15px 20px;
    &.multiple {
        .frieze {
            padding: 20px 25px 30px 20px;
            border: 1px solid var(--border-primary);
            border-radius: var(--radius-block);
            box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}
.frieze {
    cursor: pointer;
}
.wrapper-heading {
    display: flex;
    flex-direction: column;
}
.wrapper-body {
    .late-tasks {
        margin-top: 10px;
    }
}
.frieze-progress-bar {
    position: relative;
    height: 20px;
    margin-top: 12px;
}
.progress-bar-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: repeating-linear-gradient(
    -45deg,
    #FFFFFF,
    #FFFFFF 4px,
    var(--branding-color) 4px,
    var(--branding-color) 8px
    );
    opacity: 0.1;
}
.progress-bar-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 20px;
    background: var(--branding-color);
}
.late-tasks {
    display: flex;
    align-items: center;
    font-size: var(--text-small);
    font-weight: 500;
    letter-spacing: var(--letter-spacing-small);
    color: var(--text-error);
    .ht-icon {
        flex: 0 0 auto;
        margin-right: 8px;
    }
}
.other-programs {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--border-primary);
    padding: 16px 20px;
    text-align: center;
    color: var(--text-primary);
    @include ht-body;
    > span {
        &::after {
            content: '\00a0›';
        }
    }
}
.wrapper-other-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.other-link {
    @include ht-body;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    max-width: 350px;
    padding: 8px 0;
    text-decoration: none;
    color: var(--text-primary);
}
.other-link-icon {
    flex: 0 0 auto;
}
.other-link-text {
    flex: 1 1 auto;
}
.other-link-arrow {
    flex: 0 0 auto;
}

@media (min-width: $tablet) {
    .friezes {
        &:not(.multiple) {
            padding: 20px 30px 32px 20px;
        }
    }
    .frieze {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .wrapper-heading {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        .late-tasks {
            position: relative;
            top: 3px;
            flex: 0 0 auto;
            margin-left: 30px;
        }
    }
    .frieze-progress-bar {
        margin-top: 28px;
    }
}

@media (min-width: $desktop-small) {
    .friezes {
        &.multiple {
            display: flex;
            justify-content: space-between;
            .frieze {
                flex: 0 0 auto;
                width: calc(50% - 6px);
                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
