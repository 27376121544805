<template>
    <div class="ht-sidebar">
        <div
            v-if="hasDefaultSlot"
            class="ht-sidebar-default"
        >
            <slot />
        </div>
        <div
            v-if="hasFooterSlot"
            class="ht-sidebar-footer"
        >
            <slot :name="'footer'" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewHtSidebar',
    computed: {
        hasDefaultSlot() {
            return 'default' in this.$slots;
        },
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.ht-sidebar {
    width: 100%;
    margin: 0 0 32px;
    display: flex;
    justify-content: flex-start;
    border-radius: 0;
    &-default {
        width: 100%;
        padding: 0;
        border-bottom: 1px solid #F2F2F4 ;
    }
    &.ht-stepper-sticky {
        width: 360px;
        .ht-sidebar-default {
            display: flex;
            justify-content: center;
            transform: rotate(-90deg);
            border-bottom: none;
            margin-top: -15rem;
            margin-bottom: -15rem;
        }

        .ht-sidebar-footer {
            display: none;
        }
    }
    &-footer {
        border-top: 1px solid var(--border-primary);
        padding: 16px;
    }
}
</style>
