<template>
    <h3>
        <slot />
    </h3>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
@import "~@/styles/bootstrap-custom";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

h3 {
    line-height: 120%;
    font-family: 'lato';
    font-size: 2.1rem;
    font-weight: bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
}

@include media-breakpoint-down(xs) {
    h3 {
        font-size: 1.8rem;
    }
}
</style>
