<template>
    <div>
        <div v-if="viewCompanyUserTaskCollection.isLoaded()">
            <div v-if="getAvailableResource.length > 0">
                <div
                    v-for="(resource, index) in getAvailableResource"
                    :key="index"
                    class="my-actions-content"
                >
                    <div
                        class="my-actions-title-h2 mb-20 clickable"
                        @click="toggleCategory(resource)"
                    >
                        {{ resources[resource].label }}
                        <FontAwesomeIcon
                            size="1x"
                            class="icon"
                            :icon="resources[resource].opened ? 'chevron-down' : 'chevron-right'"
                        />
                    </div>
                    <ListActionsByAction
                        v-if="resources[resource].opened"
                        :actions="wrappedTaskByResource[resource]"
                        :resource="resource"
                        :show-multi-select="showMultiSelect"
                        :selected-action-list="selectedTasks"
                        :is-history="isHistory"
                    />
                </div>
            </div>
            <div
                v-else
                class="my-actions-no-task"
            >
                <t v-if="isHistory">
                    You haven't completed any actions
                </t>
                <template v-else>
                    <template v-if="isOnboardee">
                        <div class="mb-20">
                            <img
                                width="70"
                                src="/static/images/dashboard/man-grey.svg"
                            >
                        </div>
                        <t>You are up to date on your program!</t>
                        <t class="mt-10">
                            More actions are coming soon...
                        </t>
                    </template>
                    <template v-else>
                        <t>You are up to date on your actions</t>
                    </template>
                </template>
            </div>
        </div>
        <div v-else>
            <IconLoading />
        </div>
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import CompanyResourceProgramParticipant from '@/models/CompanyResourceProgramParticipant';
import ViewCompanyUserTaskCollection from '@/models/ViewCompanyUserTaskCollection';
import groupBy from 'lodash.groupby';
import { arrayToTree } from 'performant-array-to-tree';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import resourceEventBus from '@/eventBus/resourceEventBus';
import ListActionsByAction from './ListActionsByAction.vue';

export default {
    name: 'ListTasksByAction',
    components: {
        ListActionsByAction,
    },
    props: {
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedTasks: {
            type: Array,
            required: false,
            default: () => [],
        },
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            resources: {
                company_doc: {
                    label: this.translate('Documents'),
                    opened: false,
                },
                company_document: {
                    label: this.translate('Trainings'),
                    opened: false,
                },
                company_survey: {
                    label: this.translate('Surveys'),
                    opened: false,
                },
                company_user_quiz: {
                    label: this.translate('Quiz'),
                    opened: false,
                },
                custom: {
                    label: this.translate('Actions'),
                    opened: false,
                },
                company_planning: {
                    label: this.translate('Events to organize'),
                    opened: false,
                },
                company_requirement_category: {
                    label: this.translate('Requirements'),
                    opened: false,
                },
                company_nudge: {
                    label: this.translate('Nudge'),
                    opened: false,
                },
                company_software: {
                    label: this.translate('Softwares to install'),
                    opened: false,
                },
                company_equipment: {
                    label: this.translate('Equipments'),
                    opened: false,
                },
            },
            wrappedTaskByResource: null,
            viewCompanyUserTaskCollection: new ViewCompanyUserTaskCollection([
                'company_user_program_task_id', 'company_user_id',
            ]).where([
                ['company_user_id', '=', this.shared.session.companyUser.id],
            ]).with({
                companyUserProgramTask: (query) => {
                    query
                        .select([
                            'id',
                            'title',
                            'resource',
                            'resource_id',
                            'datetime_end',
                            'status',
                            'tag',
                            'company_user_program_id',
                            'company_user_id',
                            'full_title',
                            'validator_type',
                        ]).with({
                            dependents: (query) => {
                                query.select(['id', 'status']);
                            },
                            companyUserProgramTaskParticipant: (query) => {
                                query.select(['company_user_id', 'type']).with({
                                    companyUser: (query) => {
                                        query.select(['id', 'firstname', 'lastname', 'image']);
                                    },
                                });
                            },
                            companyUser: (query) => {
                                query.select(['id', 'firstname', 'lastname']);
                            },
                            companyUserDocument: (query) => {
                                query.select(['id', 'company_user_id', 'name', 'company_file_id', 'description', 'specific_information', 'order']).with({
                                    companyFile: (query) => {
                                        query.select(UtilsCompanyFile.allowedFullFields());
                                    },
                                }).orderBy([
                                    ['order', 'asc'],
                                ]);
                            },
                            companyPlanningEvent: (query) => {
                                query.select(['id', 'is_delegated']).with({
                                    companyPlanningEventAttendee: (query) => {
                                        query.select(['id', 'company_user_id']).with({
                                            companyUser: (query) => {
                                                query.select(['id', 'firstname', 'lastname', 'image']);
                                            },
                                        });
                                    },
                                });
                            },
                            companyUserSoftware: (query) => {
                                query.select(['id', 'type']);
                            },
                            companyUserDoc: (query) => {
                                query.select(['id', 'company_user_id', 'company_doc_id', 'name', 'description', 'specific_information', 'company_file_id', 'is_heyteam']).with({
                                    companyFile: (query) => {
                                        query.select(UtilsCompanyFile.allowedFullFields());
                                    },
                                });
                            },
                            companyUserProgram: (query) => {
                                query.select(['id', 'company_program_id']).with({
                                    companyProgram: (query) => {
                                        query.select(['id']).with({
                                            locales: (query) => {
                                                query.select(['id', 'name', 'language_key']);
                                            },
                                        });
                                    },
                                });
                            },
                            companyUserRequirementCategory: (query) => {
                                query.select(['id', 'display_survey']);
                            },
                        });
                },
            }).whereHas({
                companyUserProgramTask: (query) => {
                    query.where([
                        ['status', '=', this.isHistory ? CompanyUserProgramTask.STATUS_DONE : CompanyUserProgramTask.STATUS_PENDING],
                        ['datetime_availability', '<', this.$Utils.moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
                    ]);
                },
            })
                .orderBy([['datetime_end', this.isHistory ? 'desc' : 'asc']]),
        };
    },

    computed: {
        isOnboardee() {
            return this.shared.session.companyUser.is_onboardee;
        },

        getAvailableResource() {
            return Object.keys(this.resources).filter((resource) => this.wrappedTaskByResource && resource in this.wrappedTaskByResource);
        },
    },

    watch: {
        'shared.dashboard.removeIncrementTask': function () {
            this.getTasks();
        },

        'shared.dashboard.statusIncrementTask': function () {
            this.getTasks();
        },
    },

    created() {
        this.getTasks();
        resourceEventBus.$on('survey-done', () => {
            this.getTasks();
        });
        resourceEventBus.$on('nudge-accepted', () => {
            this.getTasks();
        });
    },

    methods: {
        toggleCategory(resource) {
            this.resources[resource].opened = !this.resources[resource].opened;
        },
        getTasks() {
            this.viewCompanyUserTaskCollection.get().then(() => {
                const tasks = arrayToTree(this.viewCompanyUserTaskCollection.tasksGrouped, {
                    id: 'company_user_program_task.id',
                    parentId: 'dependentId',
                    dataField: null,
                    childrenField: 'dependenciesTasks',
                });

                const taskGroupedByResource = groupBy(tasks, 'company_user_program_task.resource');
                const taskGroupedByResourceKey = Object.keys(taskGroupedByResource);

                this.wrappedTaskByResource = Object.values(taskGroupedByResource).reduce((accum, taskGrouped, resource) => {
                    let groupedTaskByTitle = [];
                    let groupedTaskFiltered = taskGrouped;

                    if (taskGroupedByResourceKey[resource] === 'company_planning') {
                        groupedTaskFiltered = groupedTaskFiltered.filter((task) => task.company_user_program_task.company_planning_event.is_delegated === true);
                    } else if (taskGroupedByResourceKey[resource] === 'company_requirement_category') {
                        groupedTaskFiltered = groupedTaskFiltered.filter((task) => (
                            task.company_user_program_task.company_user_requirement_category.display_survey === false
                            || task.company_user_program_task.company_user_program_task_participant.models[0].type === CompanyResourceProgramParticipant.TYPE_SIGNER
                        ));
                    }

                    groupedTaskByTitle = Object.values(groupBy(groupedTaskFiltered, 'company_user_program_task.title'));

                    if (groupedTaskByTitle.length > 0) {
                        accum[taskGroupedByResourceKey[resource]] = groupedTaskByTitle.reduce((accum, taskGrouped) => {
                            accum.push({
                                title: taskGrouped[0].company_user_program_task.title,
                                resource: taskGrouped[0].company_user_program_task.resource,
                                actions: taskGrouped,
                                datetime_end_wrapped: null,
                            });

                            return accum;
                        }, []);
                    }
                    return accum;
                }, {});
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

@media (max-width: $desktop-small) {
    .dashboard {
        &-block {
            &-wrapper {

                &.bp,
                &.mp {
                    padding: 30px;
                }
            }
        }
    }
}

@media (max-width: $tablet) {
    .dashboard {
        &-block {
            &-wrapper {

                &.bp,
                &.mp {
                    padding: 25px;
                }
            }
        }
    }
}

@media (max-width: $phone) {
    .dashboard {
        &-block {
            &-wrapper {

                &.bp,
                &.mp {
                    padding: 20px;
                }
            }
        }
    }
}

.bloc-dashboard {
    &-title {
        font-family: $lato-bold;
        body:not(.old-ui) &{
            font-family: var(--poppins);
            font-weight: 600;
        }
        font-size: 1.8rem;
    }
}

.my-actions {
    position: relative;

    .empty-actions {
        border: 1px solid $grey-ultra-light;
        border-radius: $border-radius-8;
        padding: 25px;
        color: $color-grey;
        text-align: center;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &-multiselect-toggle {
            font-weight: bold;
            color: $color-text-grey;
            text-decoration: underline;
            font-size: 14px;
            line-height: 18px;
            padding: 2px;
            cursor: pointer;
        }
    }

    &-multiselect-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $black;
        opacity: 0.9;
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0;
        padding: 15px 0 15px 0;
        z-index: 2;

        &-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &-count {
            color: $white;
            font-weight: 700;
            margin-left: 50px;
        }
        &-cancel {
            color: $white;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 30px;
        }
        &-button-wrapper {
            margin-right: 120px;
        }
    }

    &-timeline {
        align-self: start;
    }

    &-content {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .has-button-collapse {
        overflow-y: hidden;
        max-height: 700px;
    }

    &-button {
        cursor: pointer;
        text-align: center;
        padding: 20px;
        border-top: 1px solid #e1e1e1;
        font-weight: bold;
    }

    &-title-h2 {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        &.clickable {
            cursor:pointer;
        }
    }

    &-no-task {
        display: flex;
        flex-direction: column;
        padding: 80px 100px;
        text-align: center;
        background-image: url('/static/images/dashboard/dashes.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .collapse-button {
        text-align: center;

        > span {
            text-decoration: underline;
        }

        &::after {
            content: '›';
            flex: 0 0 auto;
            position: relative;
            top: 1px;
            display: block;
            margin-left: 7px;
            transform: rotate(90deg);
            transform-origin: 50% 50%;
        }

        &.open {
            &::after {
                transform: rotate(270deg) translate3d(0, -4px, 0);
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

@media (max-width: $phone) {
    .my-actions-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.8rem;

        .my-actions-header-multiselect-toggle {
            display: none;
        }
    }
}

</style>
