<template>
    <div
        class="uploadable-zone-wrapper"
    >
        <NewUploadableZone
            ref="uploadableZone"
            :media="uploadBarZone.media"
            :extension="uploadBarZone.extension"
            ressource="company_file"
            :max-size="uploadBarZone.maxSize"
            :multiple="true"
            :is-requirement="uploadBarZone.isRequirement"
            @onUploadStart="onUploadStart"
            @onUploadProgress="onUploadProgress"
            @onUploadEnd="onUploadEnd"
            @onUploadComplete="onUploadComplete"
            @onUploadError="onUploadError"
            @onUploadCancel="onUploadCancel"
        />
    </div>
</template>
<script>
import uploadBarZoneEventBus from '@/eventBus/uploadBarZone';
import NewUploadableZone from '../NewUploadableZone.vue';

export default {
    components: { NewUploadableZone },
    inject: ['uploadBarZone'],
    props: {
        label: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            uploadedFiles: [],
        };
    },

    methods: {
        onUploadStart() {
            this.uploadBarZone.widthProgressBar = 0;
            this.uploadBarZone.$emit('onUploadStart');
            uploadBarZoneEventBus.$emit('upload-start');
        },

        onUploadProgress(uploadInfo) {
            this.uploadBarZone.widthProgressBar = 0.9 * uploadInfo.percent;
            this.uploadBarZone.$emit('onUploadProgress');
        },

        /**
         * @param {Object} uploadInfo données du navigateur sur le fichier
         * @param {Object} data données du back-end sur le fichier
         * @returns {void}
         */
        onUploadEnd(uploadInfo, data) {
            this.uploadBarZone.$emit('update:fileSize', uploadInfo.size);
            this.uploadBarZone.widthProgressBar = 100;
            const file = {
                id: data.id,
                size: uploadInfo.size,
                uuid: data.uuid,
                original_name: data.original_name,
                detected_extension: data.detected_extension,
                detected_mime_type: data.detected_mime_type,
                link: data.link,
                link_preview: data.link_preview,
                content: data.content,
            };

            this.uploadBarZone.$emit('input', file);
            uploadBarZoneEventBus.$emit('upload-end');
            this.uploadedFiles.push(file);
        },
        onUploadComplete() {
            this.uploadBarZone.$emit('onUploadComplete', this.uploadedFiles);
        },

        onUploadError(code) {
            if (code === 415) {
                this.$Notifier('App').showError(this.translate('This media is not currently supported.'));
            }
            this.uploadBarZone.$emit('onUploadEnd');
        },

        onUploadCancel() {
            this.uploadBarZone.$emit('onUploadEnd');
        },
    },
};
</script>
<style lang="scss" scoped>
.uploadable-zone-wrapper {
    display: flex;
    cursor: pointer;
}
</style>
