<template>
    <div class="container pl-0 pr-0">
        <NewUploadBarZone
            v-model="currentFile"
            class="upload-bar"
            :media="media ? media : ['office', 'pdf', 'image']"
            :show-media-legend="showMediaLegend"
            :max-size="getMaxSize"
            :show-file-size-legend="showFileSizeLegend"
            :disabled="disabled"
            :cypress="cypress"
            :is-requirement="isRequirement"
            @onUploadComplete="onUploadComplete"
            @onRemove="onRemove"
        />

        <!-- Display the filled bars for uploaded files -->
        <div
            v-if="companyFiles.length"
            class="filled-bars"
        >
            <NewFilledBarZone
                v-for="file in companyFiles"
                :key="file.id"
                ref="filledBarZone"
                :file="file"
                @on-edit="$emit('on-edit')"
                @file-deleted="handleFileDeleted"
            />
        </div>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';
import NewUploadBarZone from './UploadBarZone/NewUploadBarZone.vue';
import NewFilledBarZone from './UploadBarZone/NewFilledBarZone.vue';

export default {
    name: 'NewMultipleFilesUpload',
    components: {
        NewUploadBarZone,
        NewFilledBarZone,
    },
    mixins: [CypressMixin],

    provide() {
        return {
            multipleFilesUpload: this,
        };
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        media: {
            type: Array,
            required: true,
        },
        maxSize: {
            type: Number,
            default: 20,
        },
        showMediaLegend: {
            type: Boolean,
            default: false,
        },
        showFileSizeLegend: {
            type: Boolean,
            default: false,
        },
        isRequirement: {
            type: Boolean,
            default: false,
        },
        value: {},
        disabled: {
            type: Boolean,
            default: () => false,
        },
        cypress: {
            type: String,
            default: 'upload-bar-zone',
        },
    },

    data() {
        return {
            currentFile: {}, // Single file object for UploadBarZone
            companyFiles: this.item.company_files,
            totalFileSize: 0,
        };
    },

    computed: {
        getMaxSize() {
            if (this.maxSize) {
                return this.maxSize - this.totalFileSize;
            }
            return 20 - this.totalFileSize;
        },
    },

    watch: {
        companyFiles(newCompanyFiles) {
            // on ne revoie au component parent que les files qui n'ont pas été remove
            // Comment savoir si un file est remove ? => si un file est remove, alors son id est null
            newCompanyFiles = newCompanyFiles.filter((companyFile) => companyFile && companyFile.id !== null);

            this.$emit('update:company-files', newCompanyFiles);
        },
    },

    methods: {
        updateFileSize(v) {
            this.totalFileSize += v;
        },
        onRemove() {
            this.companyFiles = this.companyFiles.filter((file) => file !== null);

            this.$emit('onRemove');
        },
        onUploadComplete(filesUploaded) {
            this.companyFiles = filesUploaded.map((file) => ({ id: Date.now(), ...file }));
        },
        handleFileDeleted(file) {
            this.companyFiles = this.companyFiles.filter((f) => f.id !== file.id);
            this.$emit('file-deleted', file);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.file-list {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #fff;
}

.remove-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #dc3545;
    font-size: 16px;
}

.filled-bars {
    margin-top: 20px;
}

.upload-bar {
    margin-bottom: 20px;
}
</style>
