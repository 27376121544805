<template>
    <div
        v-if="is_loaded"
        id="preboarding-container"
        class="wrapper-has-sidebar bg-grey-2"
    >
        <div
            class="intro"
            :class="{'end': !is_animated}"
            :style="backgroundImage"
        >
            <div class="user-info">
                <div class="container-logo">
                    <div class="sub-container-logo">
                        <img
                            class="logo"
                            :src="$Utils.resolveS3PublicImage(shared.session.company.image_square)"
                        >
                    </div>
                </div>
                <div
                    v-if="companyUser.isLoaded()"
                    class="user-name"
                >
                    <t :name="companyUser.$.firstname">
                        Hello {name}
                    </t>&nbsp;!
                </div>
                <template v-if="!isChatbotSignOnly">
                    <div class="user-name">
                        &
                    </div>
                    <div class="user-name">
                        <t :company="shared.session.company.name">
                            Welcome to {company}
                        </t>&nbsp;!
                    </div>
                </template>
            </div>
        </div>
        <aside
            class="sidebar-preboarding begin-animation"
            :class="{'end-animation': !is_animated, 'text-hidden': !showInfo}"
        >
            <div
                class="content-sidebar begin-animation"
                :class="{'end-animation': !is_animated}"
                :style="backgroundImage"
            >
                <div class="user-info">
                    <div class="container-logo">
                        <div class="sub-container-logo">
                            <img
                                class="logo"
                                :src="$Utils.resolveS3PublicImage(shared.session.company.image_square)"
                            >
                        </div>
                    </div>
                    <div class="user-identities">
                        <div
                            v-if="companyUser.isLoaded()"
                            class="user-name"
                        >
                            {{ companyUser.$.firstname }}
                        </div>
                        <div
                            v-if="companyUser.$.company_job_position && companyUser.$.company_department"
                            class="user-title"
                        >
                            {{ companyUser.$.company_job_position.name }}, {{ companyUser.$.company_department.name }}
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <transition name="fade">
            <div
                v-if="!showInfo && companyFlow.isLoaded() && companyUser.isLoaded()"
                id="flow-container"
                class="content-info"
            >
                <router-view
                    ref="routerView"
                    :key="$route.fullPath"
                    v-model="companyUserFlow"
                    :is_forward="is_forward"
                    :is_last="is_last"
                    :bubble-user="bubbleUser"
                    :company-user="companyUser"
                    :contents="getCurrentPageContent"
                    @reloadPage="reloadPage"
                    @onLoadRow="scrollToTheEnd"
                    @onLoadPage="showBottomButtons"
                    @onNextPage="changePage(true)"
                    @onPreviousPage="changePage(false)"
                    @onFail="onFail()"
                />
                <div
                    :class="{'visible': is_visible}"
                    class="bottom-navbar"
                >
                    <div class="navbar-wrapper align-center">
                        <Button
                            v-show="canBack && is_visible"
                            :state="buttonState"
                            class="negative"
                            @click="changePage(false)"
                        >
                            <t>Back</t>
                        </Button>
                        <Button
                            v-show="is_visible"
                            :state="buttonState"
                            @click="$refs.routerView.onValidate()"
                        >
                            <t>Continue</t>
                        </Button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import Company from '@/models/Company';
import CompanyCustomizeCollection from '@/models/CompanyCustomizeCollection';
import CompanyFlow from '@/models/CompanyFlow';
import CompanyFlowCollection from '@/models/CompanyFlowCollection';
import CompanyUser from '@/models/CompanyUser';
import CompanyUserFlow from '@/models/CompanyUserFlow';
import HtProgramType from '@/models/HtProgramType';
import groupBy from 'lodash.groupby';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyChatbot from '@/models/CompanyChatbot';

export default {
    name: 'Flow',
    data() {
        return {
            is_animated: true,
            is_loaded: false,
            is_forward: true,
            is_last: false,
            is_visible: false,
            showInfo: true,
            height: 0,
            buttonState: 'idle',

            // User for the bubble
            bubbleUser: null,
            pages: [],
            allPages: [],

            companyFlow: new CompanyFlowCollection([
                'id',
                'company_chatbot_id',
                'subtype',
                'content',
                'is_enabled',
                'is_enabled_editabled',
                'page',
                'order',
                'company_file_id',
                'content',
            ]).with({
                chatbot: (query) => {
                    query.select([
                        'id',
                        'company_id',
                        'slug',
                    ]);
                },
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'uuid',
                        'path',
                        'name',
                    ]);
                },
            }),
            companyUserFlow: new CompanyUserFlow(
                ['id', 'company_chatbot_id', 'company_user_id', 'company_user_program_id', 'state', 'status', 'has_sign'],
            ).where([
                ['status', '!=', CompanyUserFlow.STATUS_DONE],
                ['company_user_id', '=', this.shared.session.companyUser.id],
            ]).with({
                chatbot: (query) => {
                    query.select(['id', 'company_id', 'slug']);
                },
            }),
            companyCustomize: new CompanyCustomizeCollection([
                'id', 'company_id', 'type', 'subtype', 'content', 'company_file_id',
            ]).with({
                companyFile: (query) => {
                    query.select(['id', 'original_name', 'uuid', 'path', 'name']);
                },
            }).where([
                ['type', '=', 'speaker'],
            ]),
            companyUser: new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'about',
                'email',
                'arrival_date',
                'end_date',
                'image',
                'language',
                'status',
                'gender',
                'company_department_id',
                'company_job_position_id',
                'company_office_id',
                'company_language_id',
                'date_of_birth',
                'is_onboardee',
                'company_file_id',
                'state',
                'ht_nationality_id',
                'password',
                'timezone',
            ]).with({
                companyLanguage: (query) => {
                    query.select(['id', 'key', 'name']);
                },
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'detected_extension',
                        'uuid',
                    ]);
                },
                companyJobPosition: (query) => {
                    query.select(['id', 'name', 'is_heyteam']);
                },
                companyDepartment: (query) => {
                    query.select(['id', 'name', 'is_heyteam']);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_user_id', 'status', 'company_user_owner', 'owner_id'])
                        .with({
                            companyUserOwner: (query) => {
                                query.select(['id', 'firstname', 'lastname', 'image']);
                            },
                            companyProgram: (query) => {
                                query.select(['id', 'ht_program_type_id', 'company_chatbot_id'])
                                    .with({
                                        htProgramType: (query) => {
                                            query.select(['id', 'slug']);
                                        },
                                    });
                            },
                        })
                        .whereHas({
                            companyProgram: (query) => {
                                query.whereHas({
                                    htProgramType: (query) => {
                                        query.where([
                                            ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                        ]);
                                    },
                                });
                            },
                        });
                },
                companyUserSoftware: (query) => {
                    query.select(['id']).with({
                        companySoftware: (query) => {
                            query
                                .select(['id'])
                                .with({
                                    resource: (query) => {
                                        query.select(['id', 'name']);
                                    },
                                });
                        },
                    });
                },
                companyUserEquipment: (query) => {
                    query.select(['id']).with({
                        companyEquipment: (query) => {
                            query
                                .select(['id', 'image'])
                                .with({
                                    resource: (query) => {
                                        query.select(['id', 'name']);
                                    },
                                });
                        },
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname'])
                                .with({
                                    companyLanguage: (query) => {
                                        query.select(['id', 'key']);
                                    },
                                });
                        },
                    });
                },
                companyUserMetadata: (query) => {
                    query.select(['id', 'type', 'value', 'company_user_id']);
                },
            }).where([
                ['id', '=', this.shared.session.companyUser.id],
            ]),
        };
    },

    computed: {
        isChatbotSignOnly() {
            if (this.companyUserFlow.isLoaded()) {
                return this.companyUserFlow.chatbot.slug === CompanyChatbot.SLUG_SIGN_ONLY;
            }
            return false;
        },
        blockTitle() {
            if (this.companyFlow.isLoaded()) {
                return this.companyFlow.models.find((m) => (m.page === this.getCurrentPageIndex) && m.subtype.endsWith('title'));
            }
            return null;
        },

        backgroundImage() {
            let imageUrl = `/static/images/preboarding/image${this.getCurrentPageIndex}.jpg`;

            if (this.blockTitle && this.blockTitle.company_file_id) {
                imageUrl = this.$Utils.resolveS3PublicImage(
                    this.blockTitle.company_file.path + this.blockTitle.company_file.name,
                );
            }

            const backgroundImage = `url(${imageUrl})`;

            return { backgroundImage };
        },

        canBack() {
            const currentIndex = this.pages.findIndex((item) => item.name === this.$route.name);

            let isVisible = false;

            if (currentIndex > 0 && this.companyFlow.isLoaded()) {
                this.companyFlow.models.forEach((item) => {
                    if (item.page - 1 <= currentIndex) {
                        if (!item.subtype.includes('_title')
                            && !item.subtype.includes('_signup')
                            && item.is_enabled
                            && !isVisible) isVisible = true;
                    }
                });
            }

            return isVisible;
        },

        getCurrentPage() {
            let page = this.pages.find((item) => this.companyUserFlow.state === item.state);
            if (!page) {
                [page] = this.pages;
            }
            return page;
        },

        getCurrentPageIndex() {
            const currentPage = this.pages.find((item) => item.name === this.$route.name);
            return currentPage ? currentPage.page : 1;
        },

        getCurrentPageContent() {
            const currentPage = this.pages.find((item) => item.name === this.$route.name);
            return currentPage.content;
        },

        activeProgram() {
            return this.companyUser
                .company_user_program
                .models
                .find((program) => program.status === CompanyUserProgram.STATUS_ACTIVE);
        },
    },

    async created() {
        this.companyUser.setLookupMethod('lookupPersonal');
        await this.companyUser.get();

        await this.loadUserFlows();
        this.companyFlow.where([
            ['company_chatbot_id', '=', this.companyUserFlow.company_chatbot_id],
        ]);

        this.companyFlow.setLookupMethod('lookupFilled');
        await this.companyFlow.get();
        this.buildAllPages(groupBy(this.companyFlow.models, 'page'));
        this.pages = this.allPages.filter((item) => !item.types || (item.types && item.types.includes(this.companyUserFlow.chatbot.slug)));
        this.doAnimation();
        this.$router.push(this.getCurrentPage);

        await this.companyCustomize.get();
        if (this.companyCustomize.$.models[0]) {
            this.bubbleUser = this.convertSpeakerIntoUser();
        } else {
            const oOnboardingProgram = (this.companyUser.company_user_program.models.length)
                ? this.companyUser.company_user_program.models[0]
                : null;

            this.bubbleUser = (oOnboardingProgram) ? oOnboardingProgram.company_user_owner : this.shared.session.superadmin;
        }

        this.is_loaded = true;
    },

    shared: {
        session: {
            company: {
                type: Company,
                default: null,
            },
        },
    },

    mounted() {
        this.height = window.innerHeight;
    },

    methods: {
        buildAllPages(companyFlowCollection) {
            Object.entries(companyFlowCollection).forEach(([, pages]) => {
                let page = null;
                const titleBloc = pages.filter((bloc) => bloc.subtype.match(/title/g));
                const activeBloc = pages.filter((bloc) => !bloc.subtype.match(/title/g) && bloc.is_enabled);
                const blocs = pages.filter((bloc) => !bloc.subtype.match(/title/g));

                if (titleBloc.length > 0 && activeBloc.length > 0) {
                    const defaultPages = CompanyFlow.PAGES[this.companyUserFlow.chatbot.slug];

                    switch (pages[0].page) {
                    case defaultPages.welcome:
                        page = {
                            name: 'FlowHello', state: 'hello', types: [CompanyChatbot.SLUG_FULL, CompanyChatbot.SLUG_LIGHT], content: blocs, page: defaultPages.welcome,
                        };
                        break;
                    case defaultPages.sign:
                        page = {
                            name: 'FlowSign', state: 'sign', content: blocs, page: defaultPages.sign,
                        };
                        break;
                    case defaultPages.company:
                        page = {
                            name: 'FlowCompany', state: 'company', types: [CompanyChatbot.SLUG_FULL], content: blocs, page: defaultPages.company,
                        };
                        break;
                    case defaultPages.team:
                        page = {
                            name: 'FlowTeamPackage', state: 'team', types: [CompanyChatbot.SLUG_FULL], content: blocs, page: defaultPages.team,
                        };
                        break;
                    case defaultPages.profile:
                        page = {
                            name: 'FlowProfile', state: 'profile', types: [CompanyChatbot.SLUG_FULL, CompanyChatbot.SLUG_LIGHT], content: blocs, page: defaultPages.profile,
                        };
                        break;
                    case defaultPages.requirement:
                        page = {
                            name: 'FlowRequirement', state: 'requirement', types: [CompanyChatbot.SLUG_FULL, CompanyChatbot.SLUG_LIGHT], content: blocs, page: defaultPages.requirement,
                        };
                        break;
                    }
                    // le bloc titre ne doit pas être pris en compte pour le calcul du nombre de bloc actif mais il doit tout de même faire partie de la liste des bloc de la page
                    page.content.push(titleBloc[0]);
                    this.allPages.push(page);
                }
            });
        },
        scrollToTheEnd() {
            const container = document.getElementById('flow-container');
            container.scrollTop = container.scrollHeight;
        },

        convertSpeakerIntoUser() {
            const speaker = this.companyCustomize.models[0];

            const fullname = speaker.content.fullname.split(' ');

            const user = {
                firstname: fullname[0],
                lastname: fullname.length > 1 ? fullname[1] : null,
                image: (speaker.company_file) ? speaker.company_file.path + speaker.company_file.name : this.$Utils.getDefaultUserImage(),
                company_job_position: {
                    name: speaker.job_position,
                },
            };

            return user;
        },

        showBottomButtons(flag) {
            if (flag === false) this.is_visible = false;
            else {
                setTimeout(() => {
                    this.is_visible = true;
                }, 2000);
            }
            return false;
        },

        doAnimation() {
            if (this.$route.name === 'Flow') {
                setTimeout(() => {
                    this.is_animated = false;
                    setTimeout(() => {
                        this.showInfo = false;
                    }, 3000);
                }, 500);
            } else {
                this.is_animated = false;
                this.showInfo = false;
            }
        },

        loadUserFlows() {
            let whereValue = null;
            const companyChatbotId = this.activeProgram?.company_program.company_chatbot_id;

            if (companyChatbotId) {
                whereValue = [['company_chatbot_id', '=', companyChatbotId]];
            } else {
                whereValue = [['company_user_id', '=', this.companyUser.id]];
            }

            return new Promise((resolve) => {
                this.companyUserFlow
                    .where(whereValue)
                    .get()
                    .then(() => {
                        resolve();
                    });
            });
        },

        changePage(is_forward = true) {
            let page = null;
            this.is_forward = is_forward;

            this.pages.forEach((item, index) => {
                if (item.name === this.$route.name) {
                    page = (is_forward) ? this.pages[index + 1] : this.pages[index - 1];
                    if (typeof page !== 'undefined') this.is_last = true;
                }
            });

            this.buttonState = 'loading';
            if (typeof page === 'undefined') {
                this.companyUserFlow.status = 'done';

                Promise.all([
                    this.companyUserFlow.save(),
                    this.companyUser.save({ method: 'finaliseInvited' }),
                ]).then(() => {
                    this.buttonState = 'idle';
                    this.$router.push({ name: 'Dashboard', query: { from: 'flow' } });
                });
            } else {
                this.companyUserFlow.state = page.state;
                this.companyUserFlow.save().then(() => {
                    this.buttonState = 'idle';
                    this.$router.push(page);
                    this.showBottomButtons(false);
                });
            }
        },

        onFail() {
            this.buttonState = 'idle';
        },

        reloadPage() {
            this.is_loaded = false;

            this.$nextTick(() => {
                this.is_loaded = true;
            });
        },
    },
};
</script>
