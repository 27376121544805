<template>
    <div
        v-if="items.length || loading"
        class="piloting-widget"
    >
        <header class="widget-header">
            <span class="widget-title"><t>Delay by action</t></span>
            <span class="widget-subtitle">
                <t>Track statistical details by type of action</t>
            </span>
        </header>
        <div class="widget-body">
            <div v-if="items.length">
                <div
                    class="widget-list"
                >
                    <div
                        v-for="(item, index) in items"
                        :key="item.requirement_id"
                        class="widget-list-item"
                    >
                        <div class="list-item-left">
                            <div class="item-infos item-auto">
                                <div class="item-infos-title">
                                    {{ item.requirement_name }}
                                </div>
                                <div class="item-infos-subtitle">
                                    <span>
                                        {{ translate(`{nbTasks} late tasks`, {
                                            nbTasks: item.statuses.late,
                                        }) }}
                                    </span>
                                    <HtTooltip
                                        align="start"
                                        position="block-start"
                                        :offset="10"
                                        :cross-offset="-11"
                                        class="item-tooltip"
                                    >
                                        <HtIcon
                                            size="12"
                                            name="info-circle"
                                            class="item-tooltip-icon"
                                        />
                                        <template #target>
                                            <t>Remind late employees</t>
                                        </template>
                                        <template #supporting>
                                            <t>To send a reminder to employees who are overdue for this action, simply click on 'Remind'.</t>
                                        </template>
                                    </HtTooltip>
                                </div>
                            </div>
                        </div>
                        <div class="list-item-right">
                            <HtButton
                                :loading="remindLoading[index]"
                                @click="remind({
                                    id: item.requirement_id,
                                    index
                                })"
                            >
                                <t>Remind</t>
                            </HtButton>
                            <HtButton
                                type="secondary"
                                :to="{
                                    name: 'ProgramsTracking',
                                    query: { task: item.requirement_id },
                                }"
                            >
                                <t>See</t>
                            </HtButton>
                        </div>
                    </div>
                </div>
            </div>
            <HtIconLoading v-else />
        </div>
        <button
            type="button"
            class="widget-footer"
            @click="goToAll"
        >
            <t>Discover the rest</t>
        </button>
    </div>
</template>

<script>

import API from '@/store/api';
import I18n from '@/modules/i18n/I18n';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import HtTabs from '@/components/miscellaneous/HtTabs.vue';

export default {
    name: 'ActionsTracking',
    components: {
        HtButton,
        HtTabs,
    },

    data() {
        return {
            items: [],
            remindLoading: [],
            perPage: 6,
            filters: {
                statuses: [{
                    name: I18n.translate('Late'),
                    slug: 'late',
                }],
                entities: [],
                programs: [],
                search: '',
                only_where_i_am_participant: false,
            },
            loading: true,
            selectedTab: 'action',
        };
    },

    async created() {
        await this.loadData();
    },

    methods: {
        async loadData(page = 1) {
            this.loading = true;
            const response = await API.dashboard.getRequirementsToRemind(page, this.perPage, this.filters);
            this.items = this.formatItems(response.data.data);
            this.remindLoading = this.items.map(() => false);
            this.loading = false;
        },

        formatItems(items) {
            return items.map((item) => ({
                ...item,
                completion: Math.floor((item.statuses.done / item.statuses.total) * 100),
            }));
        },

        remind({ id, index }) {
            this.$set(this.remindLoading, index, true);

            API.dashboard
                .sendRequirementsReminders({
                    resources: [id],
                })
                .then(() => {
                    this.loadData();
                    this.$Notifier('App')
                        .showInfo(this.translate('The employees you selected have received a reminder to complete their tasks.'));
                })
                .finally(() => {
                    this.$set(this.remindLoading, index, false);
                });
        },
        goToAll() {
            this.$router.push({
                name: 'ProgramsTracking',
                query: {
                    status: ['late'],
                    task: 'all',
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./PilotingWidget.scss" />
<style lang="scss" scoped src="./PilotingWidgetList.scss" />
