<template>
    <div
        class="wrapper-informations"
    >
        <h3 class="informations-title">
            <t>Information for my arrival</t>
        </h3>
        <h4 class="informations-subtitle">
            <t>Everything you need for your arrival</t>
        </h4>
        <div class="informations">
            <div
                class="information"
            >
                <HtEmojiIcon
                    size="30"
                    font-size="16"
                    class="information-icon"
                >
                    📅
                </HtEmojiIcon>
                <div class="information-text">
                    <div class="information-title">
                        <t>Date</t>
                    </div>
                    <div class="information-description">
                        {{ translate('See you on {arrivaldate}', { arrivaldate: $Utils.moment(companyUserData.arrival_date).format(language === 'fr' ? 'Do MMMM YYYY' : 'MMMM Do, YYYY') }) }}
                    </div>
                </div>
            </div>
            <div
                class="information"
            >
                <HtEmojiIcon
                    size="30"
                    font-size="16"
                    class="information-icon"
                >
                    📍
                </HtEmojiIcon>
                <div class="information-text">
                    <div class="information-title">
                        <t>Location</t>
                    </div>
                    <div class="information-description">
                        <span>{{ userOffice.address }}</span>,&nbsp;
                        <span>{{ userOffice.zipcode }} {{ userOffice.city }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HtEmojiIcon from '@/components/miscellaneous/HtEmojiIcon.vue';

export default {
    name: 'InformationsWidget',
    components: {
        HtEmojiIcon,
    },
    computed: {
        companyUserData() {
            return {
                arrivalDate: this.shared.session.companyUser.arrival_date,
                language: this.shared.session.companyUser.company_language,
                officeId: this.shared.session.companyUser.company_office_id,
            };
        },
        officeEntities() {
            return this.$store.getters['entities/officeEntity'];
        },
        userOffice() {
            return this.officeEntities.values.find(
                (entity) => entity.id === this.companyUserData.officeId,
            );
        },
        language() {
            return this.companyUserData.language.key;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";

.wrapper-informations {
    position: relative;
    padding: 20px;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    background-color: var(--fill-primary);
}
.informations-title {
    @include ht-heading-4;
    margin: 0;
}
.informations-subtitle {
    @include ht-caption;
    color: var(--text-secondary);
}
.informations {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 28px;
}
.information {
    @include ht-body;
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    color: var(--text-primary);
}
.information-icon {
    flex: 0 0 auto;
}
.information-text {
    flex: 1 1 auto;
}
.information-title {
    color: var(--text-primary);
}
.information-description {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    color: var(--text-secondary);
}
</style>
