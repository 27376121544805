<template>
    <div class="profil-metadata">
        <div class="profile-item-title">
            {{ sectionTitle }}
        </div>
        <div class="profile-item-content form-2">
            <div
                v-if="!isEdit"
                class="wrapper-meta-items"
            >
                <template v-if="companyUserMetadataCollection.isLoaded() && getSavedMetadata.length > 0">
                    <HtBadge
                        v-for="(item, index) in getSavedMetadata"
                        :key="index"
                        class="meta-item"
                        size="large"
                    >
                        {{ item.value }}
                    </HtBadge>
                </template>
            </div>

            <div v-else>
                <input
                    id="hobbies"
                    v-model="outputValue"
                    type="text"
                    class="full"
                    name="hobbies"
                    :placeholder="translate('Separated by commas')"
                    @input="$emit('input', outputValue)"
                >

                <div class="wrapper-meta-items">
                    <HtBadge
                        v-for="(item, index) in getCurrentMetadata"
                        :key="index"
                        class="meta-item"
                        size="large"
                    >
                        <span>{{ item.value }}</span>
                        <button
                            type="button"
                            class="meta-item-delete"
                            @click="deleteMeta(item)"
                        >
                            <HtIcon
                                name="xmark"
                                size="12"
                            />
                        </button>
                    </HtBadge>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyUserMetadataCollection from '@/models/CompanyUserMetadataCollection';

export default {
    name: 'NewProfileMetadata',
    props: {
        companyUserMetadataCollection: {
            type: CompanyUserMetadataCollection,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        sectionTitle: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            validator: (val) => ['hobby', 'skill'].indexOf(val) !== -1,
            required: true,
        },
    },

    data() {
        return {
            outputValue: this.value,
        };
    },

    computed: {
        getSavedMetadata() {
            return this.companyUserMetadataCollection.$.models.filter((meta) => meta.type === this.type);
        },

        getCurrentMetadata() {
            return this.companyUserMetadataCollection.models.filter((meta) => meta.type === this.type);
        },
    },

    watch: {
        isEdit() {
            if (this.isEdit === false) {
                this.outputValue = '';
            } else {
                this.companyUserMetadataCollection.fromSaveState();
            }
        },
    },

    methods: {
        deleteMeta(metadataToDelete) {
            console.log('oui');
            const indexCollection = this.companyUserMetadataCollection.models.findIndex((metadata) => metadata.id === metadataToDelete.id);
            this.companyUserMetadataCollection.models.splice(indexCollection, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.wrapper-meta-items {
    margin-top: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}
// .meta-item {
//     padding: 0.25em 0.75em;
//     background: $grey-light;
//     margin-bottom: 0.5em;
//     border-radius: 15px;
//     font-weight: bold;

//     &:not(:last-child) {
//         margin-right: 1em;
//     }

//     .icon {
//         cursor: pointer;
//         font-size: 10px;
//         margin-left: 5px;

//         &:hover {
//             color: $black-darker;
//         }
//     }
// }
</style>
