<template>
    <div
        class="team-list"
        :class="`${view}-view`"
    >
        <button
            v-for="user in users"
            :key="user.id"
            class="team-list-user"
            @click="goToProfile(user.id)"
        >
            <HtAvatar
                :user="$Utils.getAvatarData(user)"
                :size="view === 'list' ? 'xl' : 'xxl'"
            />

            <div class="user-infos">
                <div class="user-name">
                    {{ user.firstname }} {{ user.lastname }}
                </div>

                <div
                    v-if="user.company_job_position"
                    class="user-job"
                >
                    {{ user.company_job_position.name }}
                </div>

                <div class="user-email">
                    {{ user.professional_email }}
                </div>

                <div class="user-action">
                    <HtIcon
                        class="user-chat"
                        name="chat-bubble-empty"
                        size="24"
                        @click.native.stop="onDiscuss(user.id)"
                    />
                </div>
            </div>
        </button>

        <div
            v-if="users.length === 0"
            class="empty-component"
        >
            <t>No results for your search / selection</t>
        </div>
    </div>
</template>

<script>
// TODO: prendre celui de QA quand QA sera merge sur demo. Voir model CompanyChatChannel
const CHANNEL_TYPE_DUO = 'duo';

export default {
    name: 'NewTeamList',

    props: {
        users: {
            type: Array,
            required: true,
        },
        view: {
            type: String,
            required: false,
            default: 'card',
            validator(value) {
                return ['list', 'card'].includes(value);
            },
        },
    },

    methods: {
        goToProfile(id) {
            this.$router.push({
                name: 'Profile',
                params: { company_user_id: id },
            });
        },
        onDiscuss(userId) {
            const oldChannel = this.shared.socket.channelCollection.models.find((channel) => channel.type === CHANNEL_TYPE_DUO && channel.company_chat_channel_user.models.some((channelUser) => channelUser.company_user_id === userId));

            if (oldChannel) {
                this.shared.socket.channelCollection.setActive(oldChannel.id);
                this.shared.socket.chatOpened = true;
                return;
            }

            const channel = this.shared.socket.channelCollection.new();

            const channelUser1 = channel.company_chat_channel_user.new();
            channelUser1.company_user_id = userId;

            const channelUser2 = channel.company_chat_channel_user.new();
            channelUser2.company_user_id = this.shared.session.companyUser.id;

            channel.company_chat_channel_user.models.push(channelUser1);
            channel.company_chat_channel_user.models.push(channelUser2);
            channel.created_by_company_user_id = this.shared.session.companyUser.id;

            channel.save().then((data) => {
                const interlocutor = data.company_chat_channel_user.channelUserInterlocutor;
                interlocutor.company_user.isConnected = interlocutor.company_user_id in this.shared.socket.connectedUsers;
                this.shared.socket.channelCollection.setActive(data.id);
                this.shared.socket.chatOpened = true;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";
@import "~@/styles/var";

.team-list {
    display: flex;
    flex-wrap: wrap;
    &.card-view {
        .team-list-user {
            flex-direction: column;
            align-items: stretch;
            .ht-avatar {
                align-self: center;
                margin-bottom: 16px;
            }
        }
    }
}
.team-list-user {
    display: flex;
    align-items: center;
    text-align: left;
    width: calc(100% - 12px);
    margin: 6px;
    padding: 20px;
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
    .ht-avatar {
        flex: 0 0 auto;
        margin-right: 20px;
    }
}

.user-infos {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-name {
    @include ht-body;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user-job, .user-email {
    @include ht-caption;
    color: var(--text-secondary);
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
}

@media (min-width: $phone) {
    .team-list.card-view .team-list-user  {
        width: calc(50% - 12px);
    }

}

@media (min-width: $tablet-large) {
    .team-list-user {
        width: calc(50% - 12px);
        .team-list.card-view & {
            width: calc(33.33% - 12px);
        }
    }
}

@media (min-width: $desktop-medium) {
    .team-list-user {
        width: calc(33.33% - 12px);
        .team-list.card-view & {
            width: calc(25% - 12px);
        }
    }
}

</style>
