import Api from '@/services/Api';
import Notifier from './Notifier';
import I18n from './modules/i18n/I18n';

const HTTP_RESPONSE_CONFLICT = 409;
const HTTP_RESPONSE_UNAUTHORIZED = 401;
const HTTP_RESPONSE_SERVICE_UNAVAILABLE = 503;

const ModelConfig = {
    // Allow a child to update the parent foreign key when his localkey changed
    isSyncForeignAllowed: true,
    // isCascadingUpdate : false,
    defaultPrimaryKey: 'id',

    endPointPrefix: 'lookable/',

    post({
        url, data, onSuccess, onFail,
    }) {
        Api.getInstance('default').post(url, data, {
            200: ({ resource }) => {
                onSuccess(resource);
            },
            onError: (data, code) => {
                onFail(code, data);
            },
        });
    },

    modelEvents() {
        return {
            saved() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('Successfully added'),
                );
            },

            updated() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('Successfully updated'),
                );
            },

            deleted() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('Successfully deleted'),
                );
            },

            failed(code, data) {
                let message = '';

                if (code === HTTP_RESPONSE_UNAUTHORIZED) {
                    message = I18n.translate('Your session has expired. Please log in again.');
                } else if (code === HTTP_RESPONSE_CONFLICT && data.message) {
                    message = I18n.translate(data.message);
                } else if (code === HTTP_RESPONSE_SERVICE_UNAVAILABLE) {
                    message = I18n.translate('The service is unavailable.');
                    window.location = '/Maintenance';
                } else {
                    message = I18n.translate('A problem occurred while trying to update this resource!');
                }

                Notifier.getInstance('App').showError(message);
            },
        };
    },
};

export default ModelConfig;
