<template>
    <div>
        <Actions v-if="isOldUi" />
        <NewActions v-else />
    </div>
</template>
<script>
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';
import Actions from './Actions.vue';
import NewActions from './NewActions.vue';

export default {
    name: 'ActionsPage',
    components: {
        NewActions,
        Actions,
    },
    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
    },
};
</script>
