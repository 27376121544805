<template>
    <div v-if="userRequirement.value">
        {{ userRequirement.value }}
    </div>
    <div v-else>
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
</template>

<script>

export default {
    name: 'NewUserRequirementStringRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
};
</script>
