<template>
    <div class="list-tasks">
        <TasksListItem
            v-for="(task) in tasks"
            :key="`task-${task.id}`"
            :task="task"
            class="task-item"
            :select-mode="selectMode"
            @select="selectTasks"
            @update="updateTask"
            @updateStatus="updateTaskStatus"
            @delete="deleteTask"
        />
        <button
            class="task-item all-actions-link"
            type="button"
            @click="seeAll"
        >
            <div class="icon-wrapper">
                <HtIcon
                    name="plus-circle-solid"
                />
            </div>
            <div class="link-text">
                <t>See all actions</t>
            </div>
        </button>
    </div>
</template>

<script>
import TasksListItem from '@/components/pages/actions/all/item/TasksListItem.vue';
import listTasksMixin from '@/components/pages/actions/all/listTasksMixin';

export default {
    name: 'TasksListRecap',
    components: {
        TasksListItem,
    },
    mixins: [listTasksMixin],
    props: {
        tasks: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        events: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false,
        },
        restToLoad: {
            type: Number,
            required: true,
            default: 0,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        selectTasks(payload) {
            this.$emit('select', payload);
        },
        updateTask(payload) {
            this.$emit('update', payload);
        },
        updateTaskStatus(payload) {
            this.$emit('updateStatus', payload);
        },
        deleteTask(payload) {
            this.$emit('delete', payload);
        },
        seeAll() {
            window.localStorage.setItem('defaultSortedDashboard', 'date');
            this.$router.push({
                name: 'Actions',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.all-actions-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 11px 20px;
    text-decoration: none;
    border-radius: var(--radius);
    color: var(--text-primary);
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
    .ht-icon {
        color: var(--branding-color);
    }
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 100%;
    background-color: var(--branding-highlight);
}

.task-item:not(:last-child){
    margin-bottom: 12px;
}

.link-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    @include ht-body;
}
</style>
