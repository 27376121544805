/* eslint-disable import/order */

import Router from 'vue-router';
import Auth from '@/services/Auth';
import Api from '@/services/Api';
import Branding from '@/Branding';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import Company from '@/models/Company';
import CompanyUser from '@/models/CompanyUser';
import Env from '@/Env';
import HtNationalityCollection from '@/models/HtNationalityCollection';
import CompanyProgramCollection from '@/models/CompanyProgramCollection';
import I18n from '@/modules/i18n/I18n';
import Vue from 'vue';
import holdingRoutes from '../../config/routes/holding';
import flowRoutes from '../../config/routes/flow';
import indexRoutes from '../../config/routes';
import authenticationRoutes from '../../config/routes/auth';
import userflow from 'userflow.js';

Vue.use(Router);
const router = new Router({
    linkActiveClass: 'active',

    historyTrack: [],

    mode: 'history',
    routes: [
        ...holdingRoutes,
        ...indexRoutes,
        ...flowRoutes,
        ...authenticationRoutes,
    ],
});

const history = [];
const protectRoute = async (to, from, next) => {
    if (to.matched.length === 0) {
        next({ name: 'Forbidden' });
        return;
    }

    if (to.meta.public === true) {
        next();
        return;
    }

    if (Auth.isLogged() === true) {
        // Holding companies
        if (router.app.shared.session.company.is_holding) {
            if (to.matched.some((record) => record.meta.holding)) {
                next();
                return;
            }
            next({ name: 'HoldingDashboard' });
            return;
        }

        // Classic platform match holding routes
        if (to.matched.some((record) => record.meta.holding)) {
            next({ name: 'Dashboard' });
        }

        // Permission
        if (to.meta.permission === true) {
            // Quick fix pour avoir des permissions pour les nouvelles pages de pilotage.
            // A supprimer une fois les permissions ajoutées dans le back
            const permissionsEquivalent = {
                PilotingStatistics: 'DashboardStatistics',
                PilotingReport: 'DashboardReport',
                PilotingSpecificReports: 'DashboardSpecificReports',
                PilotingSurveyResults: 'SurveyResults',
                PilotingSurveyResultDetail: 'SurveyResultDetail',
                ProgramsTracking: 'DashboardPrograms',
            };

            const equivalent = permissionsEquivalent[to.name];

            const name = equivalent ? `Page${equivalent}` : `Page${to.name}`;

            // A décommenter une fois les permissions Piloting ajoutées dans le back
            // Nom de la permission
            // const name = `Page${to.name}`;

            // Chargement de la permission
            await router.app.$store.dispatch('permissions/load', { names: [name] });

            // Vérification de l'accès à la page
            if (!router.app.$store.getters['permissions/can'](name, to.params)) {
                next({ name: 'Dashboard' });
                return;
            }

            next();
            return;
        }

        next();
        return;
    }

    if (Auth.isLogged() === false) {
        Api.getInstance('default').get('refresh_data', {
            200: (data) => {
                // Enregistrement des données de configuration
                router.app.$store.dispatch('config/registerResources', data.config.resources);

                // Enregistrement des locales des keydates
                router.app.$store.dispatch('config/registerKeyDates', data.config.key_dates);

                // Enregistrement des status de programmes
                router.app.$store.dispatch('config/registerProgramStatuses', data.config.program_statuses);

                // Save company tag templates into companies store
                router.app.$store.dispatch('companies/setCompanyTagTemplates', data.company.tag_templates);

                // Enregistrement des apis
                router.app.$store.dispatch('config/registerApis', data.config.apis);

                // Register settings
                router.app.$store.dispatch('config/registerSettings', data.config.settings);

                // Register features
                router.app.$store.dispatch('config/registerFeatures', data.company.features);

                // SET LANGUAGE
                window.localStorage.setItem('language', data.company_user.company_language.key);

                // Set User role perimeter entities
                router.app.$store.dispatch('user/setPerimeterEntities', data.user_role_perimeter_entities);

                // SET BRANDING
                const branding = data.company_customize.find((c) => c.type === 'branding' && c.subtype === 'primary');

                Branding.setPrimaryBranding(branding || {});

                // if PREBOARDING
                if (data.company_user.state === 'preboarding') {
                    if (to.matched.some((record) => record.meta.holding)) {
                        if (!to.name.includes('Chatbot')) {
                            return next({ name: 'HoldingWelcomeChatbotCreatePassword' });
                        }
                    } else if (to && !to.name.includes('Flow')) {
                        return next({ name: 'Flow' });
                    }
                }

                Auth.logUser(data.company_user);
                RtwSharedSpace.share('session', 'have_gsuite', {
                    default: data.have_gsuite,
                    overwrite: true,
                });

                // Store de la compagnie
                const company = new Company([
                    'id', 'name', 'domain', 'company_language_id', 'is_tracking_enabled',
                    'cover', 'image', 'url', 'image_square', 'is_holding', 'timezone', 'support_link', 'ai_bot_company_user_id',
                ]).with({
                    security: (query) => {
                        query.select([
                            'id', 'is_two_factor_auth_enabled',
                        ]);
                    },
                    companyLanguage: (query) => {
                        query.select([
                            'id', 'key', 'name', 'is_default', 'is_enabled',
                        ]);
                    },
                    companyLanguages: (query) => {
                        query.select([
                            'id', 'key', 'name', 'is_default', 'is_enabled',
                        ]);
                    },
                    companyKnowledgeSettings: (query) => {
                        query.select([
                            'id', 'company_id', 'is_ai_feature_enabled', 'is_ai_bot_enabled',
                        ]);
                    },
                    companySso: (query) => {
                        query.select([
                            'name',
                        ]);
                    },
                    companyDms: (query) => {
                        query.select([
                            'company_id',
                        ]);
                    },
                    customFieldTemplates: (query) => {
                        query.select([
                            'id',
                            'key',
                            'name',
                            'type',
                            'text',
                            'boolean',
                            'table_name',
                        ]);
                    },
                });
                company.fill(data.company);
                company.store();

                // Set Favicon
                router.app.$store.dispatch('faviconTitle/setFavicon', data.favicon);

                RtwSharedSpace.share('session', 'company', {
                    type: Company,
                    default: company,
                    overwrite: true,
                });

                // Multi-cloud
                RtwSharedSpace.share('session', 'storageMode', {
                    type: String,
                    default: data.storage_mode,
                    overwrite: true,
                });

                RtwSharedSpace.share('session', 'numspotSsoLoginUrl', {
                    type: Array,
                    default: data.numspot_sso_login_url,
                    overwrite: true,
                });

                RtwSharedSpace.share('session', 'can_onboardee_download_requirements', {
                    type: Boolean,
                    default: data.features.can_onboardee_download_requirements,
                    overwrite: true,
                });

                // Store de l'utilisateur connecté
                const companyUserStore = new CompanyUser([
                    'id', 'company_id', 'firstname', 'lastname', 'main_email', 'image', 'email',
                    'is_onboardee', 'company_language_id', 'has_sso', 'arrival_date', 'state',
                    'timezone', 'logins_count', 'company_department_id', 'company_office_id',
                    'role',
                ]).with({
                    programs: (query) => {
                        query
                            .select(['id', 'status', 'company_user_id', 'company_program_id'])
                            .with({
                                program: (query) => {
                                    query.with({
                                        type: (query) => {
                                            query.select('id', 'slug');
                                        },
                                        locales: (query) => {
                                            query.select(['id', 'name', 'company_program_id', 'language_key']);
                                        },
                                    });
                                },
                            });
                    },
                    entities: (query) => {
                        query.with({
                            entity: (query) => {
                                query.select(['id', 'slug']);
                            },
                            value: (query) => {
                                query.with({
                                    jobPosition: (query) => query.select(['id', 'name']),
                                    contract: (query) => query.select(['id', 'name']),
                                    office: (query) => query.select(['id', 'name']),
                                    department: (query) => query.select(['id', 'name']),
                                    custom: (query) => query.select(['id', 'name']),
                                });
                            },
                        });
                    },
                    companyLanguage: (query) => {
                        query.select(['id', 'key', 'name', 'code', 'is_default', 'is_enabled']);
                    },
                    companyRoleUser: (query) => {
                        query.select([
                            'id',
                        ]).with({
                            role: (query) => query.select(['id', 'name', 'default_sort_dashboard', 'is_actions_tab_displayed_at_login']),
                        });
                    },
                });

                // Store du superadmin (pour le composant Flow.vue)

                const superadminStore = new CompanyUser([
                    'id',
                    'firstname',
                    'lastname',
                    'image',
                ]);
                superadminStore.fill(data.superadmin);

                companyUserStore.fill(data.company_user);
                companyUserStore.store();

                RtwSharedSpace.share('session', 'companyUser', {
                    type: CompanyUser,
                    default: companyUserStore,
                    overwrite: true,
                });

                RtwSharedSpace.share('session', 'superadmin', {
                    type: CompanyUser,
                    default: superadminStore,
                    overwrite: true,
                });

                RtwSharedSpace.share('session', 'capiUser', {
                    type: CompanyUser,
                    default: data.capi_user,
                    overwrite: true,
                });

                RtwSharedSpace.share('session', 'capiToken', {
                    type: String,
                    default: data.capi_token,
                    overwrite: true,
                });

                // Store du statut du chat
                RtwSharedSpace.share('config', 'allowChat', {
                    type: Boolean,
                    default: Env.get('WEB_SOCKET_BACK_ALLOW_CHAT'),
                    overwrite: true,
                });

                // Store de la requête des nationalités
                RtwSharedSpace.share('queries', 'htNationalityCollection', {
                    overwrite: false,
                    type: HtNationalityCollection,
                    default: new HtNationalityCollection([
                        'id', 'name_en', 'name_fr',
                    ]),
                });

                const programs = new CompanyProgramCollection([
                    'id', 'ht_program_type_id',
                ]).with({
                    locales: (query) => {
                        query.select(['id', 'company_program_id', 'language_key', 'name']);
                    },
                    htProgramType: (query) => query.select(['id', 'slug']),
                    keyDate: (query) => query.with({
                        locales: (query) => query
                            .select(['id', 'name', 'language_key']),
                    }),
                });

                // Store de la requête des program
                RtwSharedSpace.share('queries', 'companyProgramCollection', {
                    overwrite: true,
                    type: CompanyProgramCollection,
                    default: programs,
                });

                RtwSharedSpace.share('data', 'companyProgramCollection', {
                    overwrite: true,
                    type: CompanyProgramCollection,
                    default: null,
                });

                // TODO company user lang
                I18n.getTranslationPack(data.company_user.company_language.key).then(() => {
                    protectRoute(to, from, next);
                });

                const userFlowToken = process.env.VUE_APP_USERFLOW_TOKEN;
                const roles = data.company_user.company_role_user
                    .map((roleUser) => roleUser.role.name);

                if (userFlowToken && roles.includes('admin')) {
                    const filterFeature = (feats, keepEnabled) => feats.reduce((acc, item) => {
                        if (keepEnabled === item.is_enabled) {
                            acc.push(item.key);
                        }
                        return acc;
                    }, []);

                    const featuresEnabled = filterFeature(data.company.features, true);
                    const featuresDisabled = filterFeature(data.company.features, false);

                    userflow.init(userFlowToken);
                    userflow.identify(data.company_user.id, {
                        locale_code: data.company_user.company_language.code,
                        roles,
                        features_enabled: featuresEnabled,
                        features_disabled: featuresDisabled,
                        signed_up_at: data.company_user.join_date,
                    });
                }
            },

            // Wrong cookie value or cookie not present
            400: () => {
                next({ path: '/login' });
            },

            403: () => {
                history.push(to);
                next({ path: '/login' });
            },

            onError: () => {
                next({ path: '/login' });
            },
        });
    }
};

router.beforeEach(protectRoute);
router.afterEach((to, from) => {
    if (!router.history.length) {
        router.historyTrack = history;
    }

    router.historyTrack.push(from);
    router.hasHistory = router.historyTrack.length > 1;
});
router.goBack = () => {
    if (router.hasHistory) {
        router.back();
        return;
    }

    router.push('/');
};

export default router;
