<template>
    <NewHtModal
        ref="modalItem"
        @beforeOpen="beforeOpen"
        @beforeClose="beforeClose"
    >
        <template #header>
            <div
                v-if="isLoaded"
                class="header-modal"
            >
                <div
                    class="title"
                >
                    {{ companyUserProgramTask.$.title }}
                </div>
                <div class="subtitle">
                    <t>Action</t>
                </div>
            </div>
        </template>
        <template #default>
            <div v-if="isLoaded">
                <div class="wrapper-description">
                    <DescLine v-if="companyUserProgramTask.company_user_program_task_participant.models.length">
                        <template #title>
                            <t>Done by</t>
                        </template>
                        <template #content>
                            <HtAvatarGroup
                                size="s"
                                :users="users"
                            />
                            <span
                                v-if="users.length===1"
                            >
                                {{ users[0].firstname }} {{ users[0].lastname }}
                            </span>
                        </template>
                    </DescLine>
                    <DescLine v-if="shouldShowAvailabilityDate">
                        <template #title>
                            <t>Date</t>
                        </template>
                        <template #content>
                            {{ $Utils.moment(companyUserProgramTask.datetime_end).format((shared.session.companyUser.company_language.key === 'fr') ? 'DD MMMM YYYY' : 'MMMM DD, YYYY') }}
                        </template>
                    </DescLine>
                    <DescLine>
                        <template #title>
                            <t>Status</t>
                        </template>
                        <template #content>
                            <StatusLabel
                                :status="companyUserProgramTask.status"
                            />
                        </template>
                    </DescLine>
                    <DescLine v-if="companyUserProgramTask.description">
                        <template #title>
                            <t>Description</t>
                        </template>
                        <template #content>
                            <p v-dompurify-html="companyUserProgramTask.description" />
                        </template>
                    </DescLine>
                    <DescLine v-if="companyUserProgramTask.recurrence?.id">
                        <template #title>
                            <t>Occures every</t>
                        </template>
                        <template #content>
                            <div>{{ companyUserProgramTask.recurrence.recurrence_frequence_value }} <t>{{ companyUserProgramTask.recurrence.recurrence_frequence_unit }}</t></div>
                        </template>
                    </DescLine>
                </div>

                <Modalable
                    ref="modalEdit"
                    class="modalable-1 medium"
                    :mode="2"
                >
                    <UserActionEdit
                        :id="companyUserProgramTaskId"
                        :company-user-id="companyUserId"
                        :should-show-availability-date="shouldShowAvailabilityDate"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </Modalable>
            </div>
        </template>
        <template #footer>
            <HtButton
                v-if="isLoaded && companyUserProgramTask.permissions?.delete === true && !isCancelled"
                type="secondary"
                size="large"
                data-cy="action-delete-button"
                @click="onRemove()"
            >
                <t>Remove</t>
            </HtButton>
            <HtButton
                v-if="isLoaded && companyUserProgramTask.permissions?.update === true && !isCancelled"
                type="secondary"
                size="large"
                data-cy="action-edit-button"
                @click="onEdit()"
            >
                <t>Edit</t>
            </HtButton>
            <HtButton
                v-if="isLoaded && companyUserProgramTask.permissions?.can_validate && canValidate"
                type="branding"
                size="large"
                data-cy="action-toggle-status-button"
                @click="toggleStatus()"
            >
                {{ translate(isDone ? 'Mark as undone' : 'Mark as done') }}
            </HtButton>
        </template>
    </NewHtModal>
</template>

<script>
import Modalable from '@/Modalable';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import DescLine from '@/components/pages/dashboard/modals/DescLine.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import StatusLabel from '@/components/pages/dashboard/modals/StatusLabel.vue';
import UserActionEdit from './UserActionEdit.vue';

export default {
    name: 'NewUserActionItem',
    components: {
        UserActionEdit,
        DescLine,
        HtButton,
        StatusLabel,
    },

    props: {
        canValidate: {
            type: Boolean,
            default: true,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
            companyUserId: null,
            companyUserProgramTaskId: null,
            companyUserProgramTask: null,
        };
    },

    computed: {
        isLoaded() {
            return this.companyUserProgramTask && this.companyUserProgramTask.isLoaded();
        },

        isDone() {
            return this.companyUserProgramTask.status === CompanyUserProgramTask.STATUS_DONE;
        },

        isCancelled() {
            return this.companyUserProgramTask.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },

        users() {
            return this.$Utils.getAvatarGroupData(
                this.companyUserProgramTask.company_user_program_task_participant.models.map(
                    (p) => p.company_user,
                ),
            );
        },
    },

    methods: {
        beforeOpen(resolve) {
            this.companyUserProgramTask = this.newCompanyUserProgramTask();
            this.companyUserProgramTask.get();
            resolve();
        },

        beforeClose(resolve) {
            this.item = null;
            resolve();
        },

        open(task_id, companyUserId) {
            this.companyUserProgramTaskId = task_id;
            this.companyUserId = companyUserId;
            this.$refs.modalItem.open();
        },

        onEdit() {
            this.$refs.modalEdit.open();
        },

        toggleStatus() {
            this.companyUserProgramTask.status = this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE;
            this.$handleValidationTasks(this.companyUserProgramTask).then(() => {
                this.$refs.modalItem.close();
                this.$emit('onUpdate');
            });
        },

        onRemove() {
            Modalable.openRegisteredWindow('Deletewindow', { content: this.translate('Are you sure you want to delete this action ?') }).then((didUserClickDelete) => {
                if (didUserClickDelete) {
                    this.companyUserProgramTask.delete().then(() => this.onDelete());
                }
            });
        },

        onUpdate() {
            this.$refs.modalItem.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.$refs.modalItem.close();
            this.$emit('onDelete');
        },

        newCompanyUserProgramTask() {
            return new CompanyUserProgramTask([
                'id',
                'title',
                'resource',
                'company_user_id',
                'status',
                'datetime_end',
                'description',
                'permissions',
                'validator_type',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname']);
                },
                companyUserProgramTaskParticipant: (query) => {
                    query.select(['company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['firstname', 'lastname', 'image', 'id']);
                        },
                    });
                },
                recurrence: (query) => {
                    query.select(['id', 'company_user_program_task_id', 'recurrence_frequence_value', 'recurrence_frequence_unit', 'recurrence_end_date']);
                },
            }).where([
                ['id', '=', this.companyUserProgramTaskId],
            ]);
        },
    },
};
</script>
