import keyBy from 'lodash.keyby';
import Vue from 'vue';

const SET_APIS = 'set_apis';
const SET_SETTINGS = 'set_settings';
const SET_RESOURCES = 'set_resources';
const SET_KEY_DATES = 'set_key_dates';
const SET_PROGRAM_STATUSES = 'set_program_statuses';
const SET_FEATURES = 'set_features';
const SET_FEATURE = 'set_feature';

export default {
    namespaced: true,

    state() {
        return {
            apis: [],
            keyDates: [],
            resources: [],
            program_statuses: [],
            features: [],
        };
    },

    getters: {
        getResources(state) {
            return state.resources;
        },
        getKeyDatesBySlug(state) {
            return keyBy(state.keyDates, 'slug');
        },
        getSmartRecruitersConfig(state) {
            return state.apis.smartrecruiters;
        },
        getHelloSignConfig(state) {
            return state.apis.hellosign;
        },
        getDocuSignConfig(state) {
            return state.apis.docusign;
        },
        getLeverConfig(state) {
            return state.apis.lever;
        },
        getGSuiteConfig(state) {
            return state.apis.gsuite;
        },
        getKeyDateBySlug: (state) => (slug) => state.keyDates.find((keyDate) => keyDate.slug === slug),

        isFeatureEnabled: (state) => (featureKey) => {
            const feature = state.features.find((f) => f.key === featureKey);
            return feature && feature.is_enabled;
        },
    },

    actions: {
        registerResources({ commit }, resources) {
            commit(SET_RESOURCES, resources);
        },
        registerKeyDates({ commit }, keyDates) {
            commit(SET_KEY_DATES, keyDates);
        },
        registerProgramStatuses({ commit }, statuses) {
            commit(SET_PROGRAM_STATUSES, statuses);
        },
        registerApis({ commit }, apis) {
            commit(SET_APIS, apis);
        },
        registerSettings({ commit }, settings) {
            commit(SET_SETTINGS, settings);
        },
        registerFeatures({ commit }, features) {
            commit(SET_FEATURES, features);
        },
        registerFeature({ state, commit }, feature) {
            const index = state.features.findIndex((f) => f.key === feature.key);
            commit(SET_FEATURE, { index, feature });
        },
    },

    mutations: {
        [SET_RESOURCES](state, resources) {
            state.resources = resources;
        },
        [SET_KEY_DATES](state, keyDates) {
            state.keyDates = keyDates;
        },
        [SET_PROGRAM_STATUSES](state, statuses) {
            state.program_statuses = statuses;
        },
        [SET_APIS](state, apis) {
            state.apis = apis;
        },
        [SET_SETTINGS](state, settings) {
            state.settings = settings;
        },
        [SET_FEATURES](state, features) {
            state.features = features;
        },
        [SET_FEATURE](state, params) {
            const { index, feature } = params;

            Vue.set(state.features, index, feature);
        },
    },
};
