<template>
    <div
        class="ht-badge"
        :class="[theme, size]"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        theme: {
            type: String,
            required: false,
            default: 'default',
            validator(value) {
                return ['default', 'neutral', 'error', 'warning', 'success', 'primary', 'white', 'branding'].includes(value);
            },
        },
        size: {
            type: String,
            required: false,
            default: 'small',
            validator: (value) => ['small', 'large'].includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-badge {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    border-radius: var(--radius);
    @include ht-caption;
    font-weight: 500;
    &.default {
        border: 1px solid var(--border-primary);
        &.small {
            padding: 2px 7px;
        }
    }
    &.neutral {
        background-color: var(--fill-secondary);
    }
    &.error {
        background-color: var(--fill-error-warm);
        color: var(--fill-error);
    }
    &.warning {
        background-color: var(--fill-warning-warm);
        color: var(--fill-warning);
    }
    &.success {
        background-color: var(--fill-success-warm);
        color: var(--fill-success);
    }
    &.primary {
        background-color: var(--fill-inversed-primary);
        color: var(--text-inversed-primary);
    }
    &.white {
        background-color: var(--fill-primary);
    }
    &.branding {
        color: var(--branding-contrast);
        background-color: var(--branding-color);
    }
    &.small {
        padding: 3px 8px;
    }
    &.large {
        padding: 3px 12px;
    }
}
</style>
