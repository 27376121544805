<template>
    <div v-if="userRequirement.value">
        {{ getValues }}
    </div>
    <div v-else>
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
</template>

<script>

export default {
    name: 'NewUserRequirementMultipleSelectRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        getValues() {
            if (!this.userRequirement.value) {
                return [];
            }
            const items = this.userRequirement.value.filter((v) => v.selected);
            return items.length ? items.map((v) => v.value).join(', ') : '-';
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementMultipleSelectEdit.scss' scoped />
