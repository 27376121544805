import customFields from './CustomFields';
import enterprisePages from './EnterprisePages';
import securityAndPrivacy from './SecurityAndPrivacy';
import newUi from './NewUi';

export default {
    customFields,
    enterprisePages,
    securityAndPrivacy,
    newUi,
};
