<template>
    <div class="requirements-widget">
        <div class="widget-label">
            <t>To be done as soon as possible</t> 🚨
        </div>
        <button
            class="widget-task"
            @click="openRequirements"
        >
            <HtEmojiIcon>🚨</HtEmojiIcon>
            <div class="widget-infos">
                <div class="ht-heading-h4">
                    <t v-if="percent=== 100">
                        Send my finalized administrative file
                    </t>
                    <t v-else>
                        Fill my administrative file
                    </t>
                </div>
                <div
                    v-if="percent !== 100"
                    class="ht-caption"
                >
                    {{ translate('You still have {nbRequirements} requirements to provide', {
                        nbRequirements: total-countDone
                    }) }}
                </div>
            </div>
            <HtButton
                class="widget-btn"
                size="large"
                :type="isMobile ? 'tertiary' : 'branding'"
            >
                <t v-if="percent=== 100">
                    Send
                </t>
                <t v-else>
                    Fill in
                </t>
            </HtButton>
            <NewUserRequirementCategoryListModal ref="modalRequirement" />
        </button>
    </div>
</template>

<script>
import NewUserRequirementCategoryListModal from '@/components/pages/onboarding/UserRequirementCategoryListModal/NewUserRequirementCategoryListModal.vue';
import resourceEventBus from '@/eventBus/resourceEventBus';
import HtButton from '@/components/miscellaneous/HtButton.vue';

export default {
    name: 'RequirementsWidget',
    components: {
        NewUserRequirementCategoryListModal,
        HtButton,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    computed: {
        countDone() {
            return this.$store.state.dashboard.actions.countDoneRequirementCategories;
        },
        total() {
            return this.$store.state.dashboard.actions.countTotalRequirementCategories;
        },
        percent() {
            return this.$store.getters['dashboard/actions/percentRequirementCategoriesDone'];
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    created() {
        resourceEventBus.$on('requirement-category-done', () => {
            this.$store.dispatch('dashboard/actions/forceFetchRequirementCategories');
        });
    },
    methods: {
        openRequirements() {
            this.$refs.modalRequirement.open();
        },
        onResize() {
            this.isMobile = window.innerWidth < 1024;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.requirements-widget {
    margin-bottom: 32px;
}

.widget-label {
    margin-bottom: 16px;
    font-size: var(--text-small);
    font-weight: 600;
    line-height: 21px;
    color: var(--text-secondary);
    text-transform: uppercase;
}

.widget-task {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 24px 20px;
    border-radius: var(--radius);
    background-color: var(--branding-color);
    text-align: left;
    .ht-emoji-icon {
        flex-shrink: 0;
        margin-right: 12px;
        background-color: #FFEAE1;
    }
}

.widget-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    width: calc(100% - 92px);
}

.widget-btn {
    width: 100%;
    margin-top: 16px;
}

@media (min-width: $tablet-large) {
    .widget-infos {
        width: auto;
        color: var(--text-primary);
        margin-right: 12px;
        .ht-caption {
            color: var(--text-error);
        }
    }
    .widget-btn {
        margin-left: auto;
        width: auto;
        margin-top: 0;
        flex-shrink: 0;
    }
    .widget-task {
        flex-wrap: nowrap;
        padding: 20px;
        border: 1px solid var(--border-primary);
        background-color: var(--fill-primary);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
        transition: var(--transition-s);
        transition-property: transform, box-shadow;
        .ht-emoji-icon {
            margin-right: 12px;
            background-color: var(--fill-secondary);
        }
        &:hover {
            transform: scale(1.01);
            box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
        }
    }
}
</style>
