<template>
    <div>
        <div
            v-if="(!loading || getAvailableResource.length) & !forceLoading"
            class="actions-by-action"
        >
            <div v-if="getAvailableResource.length">
                <div
                    v-for="(resource, index) in getAvailableResource"
                    :key="index"
                    class="my-actions-content"
                    :class="{opened: resources[resource].opened}"
                >
                    <button
                        type="button"
                        class="my-actions-title"
                        @click="toggleCategory(resource)"
                    >
                        <HtEmojiIcon
                            size="32"
                            font-size="13"
                            :style="{backgroundColor: resources[resource].color }"
                        >
                            <HtIcon
                                :name="resources[resource].iconName || 'task-list'"
                                stroke-width="2"
                                size="16"
                                :style="{color: resources[resource].iconColor }"
                            />
                        </HtEmojiIcon>
                        {{ resources[resource].label }}
                        <HtIcon
                            size="20"
                            stroke-width="2"
                            name="nav-arrow-right"
                        />
                    </button>
                    <TasksListByAction
                        v-if="resources[resource].opened"
                        :tasks="tasksByAction[resource].tasks"
                        :rest-to-load="restToLoad"
                        :select-mode="showMultiSelect"
                        :selected-action-list="selectedTasks"
                        :is-history="isHistory"
                        @loadMore="getUserProgramTasks"
                        @select="selectTasks"
                        @update="onUpdate"
                        @updateStatus="onUpdate"
                    />
                </div>
            </div>
            <div
                v-else
                class="my-actions-no-task"
            >
                <t v-if="isHistory">
                    You haven't completed any actions
                </t>
                <template v-else>
                    <template v-if="isOnboardee">
                        <div class="mb-20">
                            <img
                                width="70"
                                src="/static/images/dashboard/man-grey.svg"
                            >
                        </div>
                        <t>You are up to date on your program!</t>
                        <t class="mt-10">
                            More actions are coming soon...
                        </t>
                    </template>
                    <template v-else>
                        <t>You are up to date on your actions</t>
                    </template>
                </template>
            </div>
        </div>
        <div v-else>
            <HtIconLoading />
        </div>
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import resourceEventBus from '@/eventBus/resourceEventBus';
import TasksListByAction from './TasksListByAction.vue';
import listTasksMixin from '../listTasksMixin';

export default {
    name: 'ActionsByAction',
    components: {
        TasksListByAction,
    },
    mixins: [listTasksMixin],
    props: {
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedTasks: {
            type: Array,
            required: false,
            default: () => [],
        },
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            resources: {
                company_doc: {
                    label: this.translate('Documents'),
                    opened: true,
                    color: '#FFE2E2',
                    iconName: 'submit-document',
                    iconColor: '#B73E23',
                },
                company_document: {
                    label: this.translate('Trainings'),
                    opened: true,
                    color: '#D7F5F3',
                    iconName: 'bookmark-book',
                    iconColor: '#4F7075',
                },
                company_survey: {
                    label: this.translate('Surveys'),
                    opened: true,
                    color: '#FFDCCE',
                    iconName: 'chat-bubble-question',
                    iconColor: '#9A3F21',
                },
                company_user_quiz: {
                    label: this.translate('Quiz'),
                    opened: true,
                    color: '#EDE5DE',
                    iconName: 'chat-lines',
                    iconColor: '#694F4F',
                },
                custom: {
                    label: this.translate('Actions'),
                    opened: true,
                    color: '#FFE3AC',
                    iconName: 'task-list',
                    iconColor: '#997328',
                },
                company_planning: {
                    label: this.translate('Events to organize'),
                    opened: true,
                    color: '#D9EEFF',
                    iconName: 'group',
                    iconColor: '#305E95',
                },
                company_requirement_category: {
                    label: this.translate('Requirements'),
                    opened: true,
                    color: '#FFF9C7',
                    iconName: 'journal',
                    iconColor: '#A69712',
                },
                company_nudge: {
                    label: this.translate('Nudge'),
                    opened: true,
                    color: '#F5F4F4',
                    iconName: 'playstation-gamepad',
                    iconColor: '#504F4F',
                },
                company_software: {
                    label: this.translate('Softwares to install'),
                    opened: true,
                    color: '#D0FFF4',
                    iconName: 'pc-mouse',
                    iconColor: '#31B59E',
                },
                company_equipment: {
                    label: this.translate('Equipments'),
                    opened: true,
                    color: '#E3DCFF',
                    iconName: 'computer',
                    iconColor: '#51397A',
                },
            },
            userProgramTasks: [],
            loading: false,
            forceLoading: false,
            currentPage: 1,
            restToLoad: 0,
        };
    },

    computed: {
        isOnboardee() {
            return this.shared.session.companyUser.is_onboardee;
        },
        tasksByAction() {
            const tasksByAction = {};
            for (let i = 0; i < this.userProgramTasks?.length; i += 1) {
                const task = this.userProgramTasks[i];
                const taskType = task.resource;
                if (!tasksByAction[taskType]) {
                    tasksByAction[taskType] = {
                        resource: taskType,
                        tasks: [],
                    };
                }

                // if the task is pending, has dependencies and no parents
                // we get all their dependencies
                if (!this.hasParentPendingTask(task)
                    && task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.dependencies?.length) {
                    tasksByAction[taskType].tasks.push(this.getAllDependentTasks(task));

                    // if the task has a pending parent it should be carried by the parent
                } else if (!this.hasParentPendingTask(task)) {
                    tasksByAction[taskType].tasks.push(task);
                }
            }

            return Object.values(tasksByAction).reduce((acc, taskOneAction) => {
                const action = {
                    ...taskOneAction,
                    tasks: this.handleTasks(taskOneAction.tasks),
                };
                acc[action.resource] = action;
                return acc;
            }, {});
        },

        getAvailableResource() {
            return Object.keys(this.resources).filter((resource) => this.tasksByAction && resource in this.tasksByAction);
        },
    },

    watch: {
        isHistory() {
            this.forceLoading = true;
            this.getUserProgramTasks(true);
        },
    },

    created() {
        this.getUserProgramTasks();

        resourceEventBus.$on('survey-done', (surveyId) => {
            const surveyTask = this.userProgramTasks.find((task) => task.resource === 'company_survey'
                && task.resource_id === surveyId);
            this.updateTask(surveyTask.id);
        });

        resourceEventBus.$on('nudge-accepted', (taskId) => {
            this.updateTask(taskId);
        });
    },

    methods: {
        onUpdate(taskId) {
            this.getUserProgramTasks(true);

            this.updateTask(taskId);
        },

        onUpdateTask({ taskId, status }) {
            this.getUserProgramTasks(true);

            this.updateTaskStatus({ taskId, status });
        },
        toggleCategory(resource) {
            this.resources[resource].opened = !this.resources[resource].opened;
        },
        async getUserProgramTasks(refresh = false) {
            this.loading = true;

            if (refresh) {
                this.currentPage = 1;
            }

            await this.$store.dispatch(this.isHistory ? 'tasks/fetchHistoryTasks' : 'tasks/fetchCurrentTasks', { params: { page: this.currentPage } })
                .then((result) => {
                    this.userProgramTasks = refresh ? this.userProgramTasks = result.data.data : this.userProgramTasks.concat(result.data.data);
                    this.currentPage = result.data.meta.current_page + 1;
                    const nbTasksLoaded = (result.data.meta.current_page
                        * result.data.meta.per_page);
                    this.restToLoad = result.data.meta.total - nbTasksLoaded;
                    this.loading = false;
                    this.forceLoading = false;
                });
        },
        selectTasks(payload) {
            this.$emit('select', payload);
        },
    },
};
</script>

<style lang="scss" scoped>
.actions-by-action {
    padding: 16px;
    border-radius: var(--radius-block);
    background-color: var(--fill-secondary);
}

.my-actions-content {
    padding: 20px;
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    &:not(:last-child) {
        margin-bottom: 12px;
    }
    &.opened {
        .ht-icon-nav-arrow-right {
            transform: rotate(-90deg);
        }
    }
}

.my-actions-title {
    display: flex;
    align-items: center;
    width: 100%;
    .ht-icon-nav-arrow-right {
        margin-left: auto;
        flex-shrink: 0;
        transition: transform var(--transition-s);
    }
    .ht-emoji-icon {
        margin-right: 12px;
        flex-shrink: 0;
    }
}

.list-actions {
    margin-top: 20px;
}
</style>
