<template>
    <div>
        <NewHtModal
            ref="modal"
            class="user-requirement-category-list-modal"
            @beforeOpen="beforeOpen"
        >
            <template #header>
                <div class="header-modal">
                    <div class="title">
                        <t>Complete my administrative file</t>
                    </div>
                    <div class="subtitle">
                        <t>Administrative</t>
                    </div>
                </div>
            </template>
            <template #default>
                <div>
                    <div
                        v-if="categoryList.length"
                        class="wrapper-recaps"
                    >
                        <HtRecap
                            v-for="category in categoryList"
                            :key="category.id"
                            class="recap"
                        >
                            <template #infos>
                                <t
                                    v-if="category.is_heyteam"
                                >
                                    {{ category.name }}
                                </t>
                                <span
                                    v-else
                                >{{ category.name }}</span>
                                <span
                                    class="recap-status"
                                    :class="category.progress_status"
                                >
                                    {{ translate(
                                        statuses[category.progress_status],
                                        { missingInfos: category.progress_status === 'to_complete' ? getMissingInfos(category.company_user_requirement_group) : ''}
                                    ) }}
                                </span>
                            </template>
                            <template #button>
                                <HtButton
                                    v-if="!cannotOpen(category)"
                                    size="large"
                                    :type="category.progress_status === 'to_complete' ? 'primary' : 'secondary'"
                                    @click.native="openEdit(category)"
                                >
                                    <t v-if="category.progress_status === 'to_complete'">
                                        Complete
                                    </t>
                                    <t v-else>
                                        See
                                    </t>
                                </HtButton>
                            </template>
                        </HtRecap>
                    </div>
                </div>
            </template>
            <template #footer>
                <HtButton
                    v-tooltip="translate('You must complete all the documents to finalize the form. Some parts may not yet be available, preventing validation at this time')"
                    size="large"
                    type="primary"
                    :disabled="!canValidateAdministrativeFile"
                    :loading="buttonLoading"
                    @click.native="finalizeFile()"
                >
                    <t>Send my finalized administrative file</t>
                </HtButton>
            </template>
        </NewHtModal>
        <NewUserRequirementCategoryEditModal
            ref="modalEdit"
            @on-save="onSave()"
        />
        <UserWrapperModal
            ref="userWrapperModal"
            size="medium"
            is-fill
        />
    </div>
</template>
<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';
import UserWrapperModal from '@/components/miscellaneous/UserWrapperModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import NewUserRequirementCategoryEditModal from '../NewUserRequirementCategoryEditModal.vue';

export default {
    name: 'NewUserRequirementCategoryListModal',
    components: {
        NewUserRequirementCategoryEditModal,
        UserWrapperModal,
        HtButton,
    },
    props: {
        ids: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            buttonLoading: false,
            statuses: {
                refused: 'Refused',
                done: 'Done',
                to_complete: '{missingInfos} missing information(s)',
                available_soon: 'Available soon',
                not_concerned: 'Not concerned',
                display_survey: 'Via survey',
                pending: 'Pending',
                cancelled: 'Cancelled',
            },
        };
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    computed: {
        categoryList() {
            return this.$store.state.dashboard.actions.requirementCategories;
        },
        /**
         * @returns {number}
         */
        totalCategories() {
            return this.categoryList.length;
        },
        /**
         * @returns {number}
         */
        doneCategories() {
            const filtered = this.categoryList.filter((category) => [
                CompanyUserRequirementCategory.STATUS_DONE,
                CompanyUserRequirementCategory.STATUS_NOT_CONCERNED,
            ].includes(category.progress_status));
            return filtered.length;
        },
        /**
         * @returns {number}
         */
        completionPercentage() {
            return ((this.doneCategories / this.totalCategories) * 100);
        },
        canValidateAdministrativeFile() {
            return this.$store.state.dashboard.actions.canValidateAdministrativeFile;
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        async beforeOpen(resolve) {
            await this.$store.dispatch('dashboard/actions/fetchRequirementCategories');
            resolve();
        },
        async onSave() {
            await this.$store.dispatch('dashboard/actions/forceFetchRequirementCategories');
            this.$emit('on-save');
        },
        openEdit(category) {
            if (category.display_survey && category.userSurvey.task.status !== 'done') {
                this.$refs.userWrapperModal.open({
                    id: category.userSurvey.task.id,
                    company_user_id: category.userSurvey.task.user.id,
                    company_user_program_id: category.userSurvey.task.user_program.id,
                    resource: 'company_survey',
                    resource_id: category.userSurvey.id,
                });
                return;
            }
            this.$nextTick(() => this.$refs.modalEdit.open(
                this.$Auth.getLoggedUser().id,
                category.id,
                this.getUserTask(category),
            ));
        },
        cannotOpen(category) {
            return (category.display_survey && category.userSurvey.task.status === 'pending') || (!category.task_id && category.progress_status === 'available_soon');
        },
        async finalizeFile() {
            if (!this.canValidateAdministrativeFile) {
                return;
            }
            this.buttonLoading = true;
            const companyUser = new CompanyUser();

            try {
                await companyUser.save({ method: 'validateAdministrativeFile' });
                this.$set(this.shared.session.companyUser, 'is_administrative_file_validated', true);
                this.$refs.modal.close();
            } finally {
                this.buttonLoading = false;
            }
        },
        getUserTask(category) {
            if (category.progress_status === CompanyUserRequirementCategory.STATUS_NOT_CONCERNED) {
                return category.tasks
                    .find((task) => task.participants[0].company_user_id === this.shared.session.companyUser.id);
            }

            return category.tasks
                .filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING)
                .find((task) => task.participants[0].company_user_id === this.shared.session.companyUser.id);
        },
        getMissingInfos(requirementsgroup) {
            const numberOfPendingRequirement = requirementsgroup.reduce((acc, group) => {
                group.company_user_requirement.forEach((requirement) => {
                    if (requirement.status === 'pending') acc += 1;
                });
                return acc;
            }, 0);

            return numberOfPendingRequirement;
        },
    },
};
</script>

<style lang="scss" src="./NewUserRequirementCategoryListModal.scss" scoped />
