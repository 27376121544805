<template>
    <div
        :class="['ht-stepper-item', {'clickable': isClickable}]"
        @click="changeStep"
    >
        <div
            class="ht-stepper-item-wrapper"
            :class="[{'current': isCurrent, 'disabled': disabled, 'succeed': isSucceed}]"
        >
            <span class="ht-stepper-item-dot">
                <HtIcon
                    v-show="showCheckIcon"
                    name="check"
                    size="12"
                    class="icon-check"
                />
            </span>
            <span class="ht-stepper-item-label">
                {{ label }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewHtStepperItem',
    inject: ['stepper'],

    props: {
        step: {
            Number,
            required: true,
        },
        steps: {
            Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isCurrent() {
            return this.step === this.stepper.activeIndex;
        },

        showCheckIcon() {
            return this.disabled === false && !this.isCurrent && this.isSucceed;
        },

        isClickable() {
            return !this.disabled && !this.isCurrent;
        },

        isSucceed() {
            const hasNextStepEnabled = this.stepper.steps.slice(this.step + 1).some((step) => step.disabled === false);

            return !this.disabled && !this.isCurrent && hasNextStepEnabled;
        },

        isLastActive() {
            if (this.step === this.steps.length - 1) {
                return !this.disabled;
            }

            const nextStep = this.steps[this.step + 1];
            return !this.disabled && nextStep.disabled;
        },

        hasTopBar() {
            return this.step > 0;
        },

        hasBottomBar() {
            return !(this.step === this.steps.length - 1);
        },
    },

    methods: {
        changeStep() {
            if (this.isClickable) {
                this.stepper.setActive(this);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/tokens";
@import "~@/styles/ds/typography";

.ht-stepper {
    &-item {
        color: var(--text-inactive);
        &-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        &-bar {
            width: 2px;
            height: 21px;
            background-color: $blue-light;
            margin-left: 5px;
            margin-top: -2px;
            margin-bottom: -2px;
        }

        &-dot {
            border-radius: 50%;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: currentColor;
            border: 1px solid currentColor;
            transition: background 0.4s, border 0.4s;

            .icon-check {
                display: block;
                color: white;
                ::v-deep svg {
                    display: block;
                }
            }
        }

        &-label {
            display: none;
        }
    }
}

.current {
    color: var(--branding-color);
}

.succeed {
    color: var(--text-success);
    &:hover {
        .ht-stepper-item-dot {
            background-color: white;
        }
    }
}

.clickable {
    cursor: pointer;
}

@media (min-width: $tablet-large) {
    .ht-stepper-item-label{
        display: block;
        @include ht-heading-4;
        margin: 0;
        transition: color 0.4s;
    }
}
</style>
