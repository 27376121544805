import { render, staticRenderFns } from "./SectionList.vue?vue&type=template&id=5fe32084&scoped=true&"
import script from "./SectionList.vue?vue&type=script&lang=js&"
export * from "./SectionList.vue?vue&type=script&lang=js&"
import style0 from "./SectionList.vue?vue&type=style&index=0&id=5fe32084&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe32084",
  null
  
)

export default component.exports