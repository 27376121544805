<template>
    <div
        class="ht-tabs"
        :style="{
            '--indicator-offset': `${indicatorOffset}px`,
            '--indicator-width': `${itemsWidth[currentItemIndex]}px`
        }"
    >
        <slot
            :on-mouse-enter="mouseEnter"
            :on-mouse-leave="mouseLeave"
        />
    </div>
</template>

<script>

export default {
    name: 'HtTabs',

    data() {
        return {
            hoverItemIndex: -1,
            isHoveringItem: false,
            itemsWidth: [],
        };
    },

    computed: {
        currentItemIndex() {
            return Math.max(
                this.hoverItemIndex > -1 ? this.hoverItemIndex : this.activeLinkIndex,
                0,
            );
        },
        activeLinkIndex() {
            // return this.$el.children.findIndex((p) => p.className.contains('active'));
            return 0;
        },
        indicatorOffset() {
            return this.itemsWidth.reduce((offset, itemWidth, index) => {
                // 20 is the padding-right
                if (index < this.currentItemIndex) offset += itemWidth + 20;
                return offset;
            }, 0);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.itemsWidth = this.getItemsWidth();
        });
    },

    methods: {
        mouseEnter(index) {
            this.hoverItemIndex = index;
        },
        mouseLeave() {
            this.hoverItemIndex = -1;
        },
        getItemsWidth() {
            const items = this.$el.children;
            // 20 is the padding-right
            return [...items].map((i) => i.offsetWidth - 20);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-tabs {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 -1px;
    padding: 0;
    overflow-y: scroll;
    // Hiding the scrollbar
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        // width: var(--indicator-width);
        height: 3px;
        // transform: translateX(var(--indicator-offset));
        background-color: var(--branding-color);
        transition: 0.3s cubic-bezier(0.34, 1.4, 0.64, 1);
        pointer-events: none;
    }
    .tab {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 12px 0;
        @include ht-body;
        text-decoration: none;
        &:not(:last-child){
            margin-right: 20px;
        }
        &.active {
            color: var(--branding-color);
            &::before {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0px;
                right: 0px;
                height: 3px;
                background-color: var(--branding-color);
            }
        }
    }
}
</style>
