var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"programs-monitoring-widget"},[_c('div',{staticClass:"programs-monitoring"},[_c('div',{staticClass:"programs-monitoring-top"},[_c('div',{staticClass:"programs-monitoring-title"},[_c('span',[_c('t',[_vm._v("Monitoring my team's programs")])],1),(_vm.nbPrograms > 0)?_c('HtBadge',{attrs:{"theme":"branding"}},[_vm._v(" "+_vm._s(_vm.nbPrograms)+" ")]):_vm._e()],1),(_vm.loading)?_c('HtIconLoading'):(_vm.programs.length > 0)?_c('div',{staticClass:"wrapper-programs"},_vm._l((_vm.programs),function(program){return _c('router-link',{key:program.id,staticClass:"program-monitored",attrs:{"to":{
                        name: 'ProfileProgramDetail',
                        params: {
                            company_user_id: program.user.id,
                            company_user_program_id: program.id,
                        },
                    }}},[_c('HtAvatar',{attrs:{"user":_vm.$Utils.getAvatarData(program.user)}}),_c('div',{staticClass:"program-text"},[_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(program.user.fullname)+" ")]),_c('div',{staticClass:"program-name"},[_vm._v(" "+_vm._s(program.program.name)+" ")])]),_c('HtBadge',{staticClass:"program-status",attrs:{"size":"large"}},[_c('HtIndicator',{attrs:{"status":program.lateTasks > 0 ? 'error' : 'success'}}),(program.lateTasks > 0)?_c('t',[_vm._v(" Late ")]):_c('t',[_vm._v(" On time ")])],1),_c('HtIcon',{attrs:{"name":"nav-arrow-right","size":"18"}})],1)}),1):_c('div',{staticClass:"empty-monitoring"},[_c('span',{staticClass:"empty-title"},[_c('t',[_vm._v("You are up-to-date")])],1),_c('span',{staticClass:"empty-description"},[_c('t',[_vm._v("You have no action to perform in your team member's programs")])],1)])],1),_c('button',{staticClass:"view-all",attrs:{"type":"button"},on:{"click":_vm.seeAll}},[_c('span',[_c('t',[_vm._v("View all programs")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }