<template>
    <div>
        <FileToFillEditor
            v-if="requirement.can_fill_document"
            :requirement="requirement"
            :user-id="userId"
            @onChangeValue="$emit('onChangeValue', ...arguments)"
            @on-update-requirement="$emit('on-update-requirement', ...arguments)"
        />
        <NewFileToFillUpload
            v-else
            :requirement="requirement"
            :is-disabled="isDisabled"
            :user-id="userId"
            @onChangeValue="$emit('onChangeValue', ...arguments)"
            @on-update-requirement="$emit('on-update-requirement', ...arguments)"
        />
    </div>
</template>

<script>
import FileToFillEditor from './FileToFillEditor.vue';
import NewFileToFillUpload from './NewFileToFillUpload.vue';

export default {
    name: 'NewUserRequirementFileToFillEdit',

    components: {
        NewFileToFillUpload,
        FileToFillEditor,
    },

    inject: ['$validator'],

    props: {
        requirement: {
            type: Object,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: Number,
            required: true,
        },
    },
};
</script>
