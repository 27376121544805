<template>
    <button
        class="ht-dropdown-item"
        role="button"
    >
        <HtIcon
            v-if="leadIcon"
            class="ht-dropdown-item-lead-icon"
            :name="leadIcon"
            :size="doubleLine && supText ? '34' : '20'"
        />

        <HtAvatar
            v-if="avatar && !leadIcon"
            class="ht-dropdown-item-avatar"
            :user="avatar"
            :size="doubleLine && supText ? 'm' : 's'"
        />
        <span
            class="ht-dropdown-item-label ht-body"
            :class="{ double: doubleLine }"
        ><slot /><span
            v-if="supText"
            class="ht-dropdown-item-sup-text ht-body"
        >{{ supText }}</span>
        </span>
        <span
            v-if="caption && !tailIcon"
            class="ht-dropdown-item-caption ht-body"
        >{{ caption }}</span>
        <HtIcon
            v-if="tailIcon"
            class="ht-dropdown-item-tail-icon"
            :name="tailIcon"
            size="20"
        />
    </button>
</template>

<script>

export default {
    props: {
        leadIcon: {
            type: String,
            required: false,
            default: '',
        },
        tailIcon: {
            type: String,
            required: false,
            default: '',
        },
        avatar: {
            type: Object,
            required: false,
            default() {
                return null;
            },
        },
        caption: {
            type: String,
            required: false,
            default: '',
        },
        supText: {
            type: String,
            required: false,
            default: '',
        },
        doubleLine: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-dropdown-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 16px;
    background-color: transparent;
    overflow: hidden;
    cursor: pointer;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--fill-secondary);
        opacity: 0;
        z-index: -1;
    }

    &:hover,
    &:focus {
        &::after {
            opacity: 1;
        }
        .ht-dropdown-item-sup-text,
        .ht-dropdown-item-tail-icon {
            color: var(--text-primary);
        }
        .ht-dropdown-item-caption {
            color: var(--text-secondary);
        }
    }
}

.ht-dropdown-item-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include ht-body;
    &.double {
        flex-direction: column;
        align-items: flex-start;
        .ht-dropdown-item-sup-text {
            margin-left: 0;
        }
    }
}

.ht-dropdown-item-lead-icon {
    margin-right: 8px;
    flex-shrink: 0;
    color: var(--icon-secondary);
}

.ht-dropdown-item-tail-icon {
    margin-left: auto;
    flex-shrink: 0;
    color: var(--icon-secondary);
}

.ht-dropdown-item-sup-text {
    margin-left: 8px;
    @include ht-body;
    color: var(--text-secondary);
}

.ht-dropdown-item-avatar {
    margin-right: 8px;
    flex-shrink: 0;
}

.ht-dropdown-item-caption {
    @include ht-body;
    color: var(--text-disabled);
    margin-left: auto;
}
</style>
