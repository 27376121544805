<template>
    <div class="list-tasks">
        <template v-if="(!loading || userProgramTasks.length)">
            <div
                v-if="userProgramTasks.length"
                class="list-tasks-timeline"
            >
                <div
                    v-for="(periodOfTasks, periodId) in tasksByPeriod"
                    :key="`week-${periodId}`"
                    class="list-tasks-content"
                >
                    <div class="title">
                        {{ periodOfTasks.label }}
                    </div>
                    <TasksListByPeriod
                        :events="[]"
                        :tasks="periodOfTasks.tasks"
                        :rest-to-load="periodId === tasksByPeriod.length-1 ? restToLoad : 0"
                        :loading="loading"
                        @loadMore="getUserProgramTasks"
                        @update="updateTask"
                        @updateStatus="updateTaskStatus"
                        @delete="deleteTask"
                    />
                </div>
            </div>
            <div
                v-else
                class="list-tasks-no-task"
            >
                <t>You haven't completed any actions</t>
            </div>
        </template>
        <div v-else>
            <IconLoading />
        </div>
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import TasksListByPeriod from '../listTasks/ByPeriod/TasksListByPeriod.vue';
import listTasksMixin from '../listTasks/listTasksMixin';

export default {
    name: 'NewHistorySortByDate',
    components: {
        TasksListByPeriod,
    },
    mixins: [listTasksMixin],
    data() {
        return {
            loading: false,
            currentPage: 1,
            restToLoad: 0,
            userProgramTasks: [],
            periods: [
                {
                    label: this.translate('This week'),
                    from: this.$Utils.moment().startOf('isoWeek'),
                },
                {
                    label: this.translate('Last week'),
                    from: this.$Utils.moment().startOf('isoWeek').subtract(1, 'weeks'),
                    to: this.$Utils.moment().startOf('isoWeek'),
                },
                {
                    label: this.translate('This month'),
                    from: this.$Utils.moment().startOf('month'),
                    to: this.$Utils.moment().startOf('isoWeek').subtract(1, 'weeks'),
                },
                {
                    label: this.translate('Last month'),
                    from: this.$Utils.moment().startOf('month').subtract(1, 'months'),
                    to: this.$Utils.moment().startOf('month'),
                },
                {
                    label: this.translate('Over a month ago'),
                    to: this.$Utils.moment().startOf('month').subtract(1, 'months'),
                },
            ],
        };
    },
    computed: {
        tasksByPeriod() {
            const tasksByPeriod = [];
            for (let i = 0; i < this.userProgramTasks?.length; i += 1) {
                const task = this.userProgramTasks[i];
                const taskDate = this.$Utils.moment(task.datetime_end);
                const taskPeriodIndex = this.periods.findIndex((period) => {
                    if ((taskDate <= period.to || !period.to)
                        && (taskDate >= period.from || !period.from)) return true;
                    return false;
                });
                if (taskPeriodIndex !== -1) {
                    if (!tasksByPeriod[taskPeriodIndex]) {
                        tasksByPeriod[taskPeriodIndex] = {
                            label: this.periods[taskPeriodIndex].label,
                            tasks: [],
                        };
                    }

                    // if the task is pending, has dependencies and no parents
                    // we get all their dependencies
                    if (!this.hasParentPendingTask(task)
                        && task.status === CompanyUserProgramTask.STATUS_PENDING
                        && task.dependencies?.length) {
                        tasksByPeriod[taskPeriodIndex].tasks
                            .push(this.getAllDependentTasks(task));

                        // if the task has a pending parent it should be carried by the parent
                    } else if (!this.hasParentPendingTask(task)) {
                        tasksByPeriod[taskPeriodIndex].tasks.push(task);
                    }
                }
            }

            return tasksByPeriod
                .filter((tasksOnePeriod) => tasksOnePeriod.tasks?.length)
                .map((tasksOnePeriod) => ({
                    ...tasksOnePeriod,
                    tasks: this.handleTasks(tasksOnePeriod.tasks),
                }));
        },
    },
    created() {
        this.getUserProgramTasks();
    },
    methods: {
        getUserProgramTasks() {
            this.loading = true;

            this.$store.dispatch('tasks/fetchHistoryTasks', { params: { page: this.currentPage } })
                .then((result) => {
                    this.userProgramTasks = this.userProgramTasks.concat(result.data.data);
                    this.currentPage = result.data.meta.current_page + 1;
                    const nbTasksLoaded = (result.data.meta.current_page * result.data.meta.per_page);
                    this.restToLoad = result.data.meta.total - nbTasksLoaded;
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" src="../listTasks/tasksList.scss" scoped>
