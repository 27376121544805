<template>
    <div
        class="ht-indicator"
        :class="[status, size]"
    />
</template>

<script>
export default {
    name: 'HtIndicator',
    props: {
        status: {
            type: String,
            required: false,
            default: 'neutral',
            validator: (value) => ['success', 'warning', 'error', 'info', 'neutral'].includes(value),
        },
        size: {
            type: String,
            required: false,
            default: 'default',
            validator: (value) => ['default', 'large'].includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &.neutral {
        background-color: var(--fill-inactive);
    }
    &.success {
        background-color: var(--fill-success);
    }
    &.warning {
        background-color: var(--fill-warning);
    }
    &.error {
        background-color: var(--fill-error);
    }
    &.info {
        background-color: var(--fill-info);
    }
    &.large {
        width: 16px;
        height: 16px;
    }
}
</style>
