<template>
    <ListTasksByUser is-history />
</template>

<script>
import ListTasksByUser from '../listTasks/ByUser/TasksListByUser.vue';

export default {
    name: 'NewHistoryByUser',
    components: { ListTasksByUser },
};
</script>
