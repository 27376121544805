<template>
    <HtSidebar>
        <ul class="menu-list branding-list">
            <li
                v-for="(page, index) in pages"
                :key="index"
            >
                <router-link
                    :to="{ name: page.name }"
                    :data-cy="'sidebar-'+page.name"
                    @click.native="emitUpdatePage(page.name)"
                >
                    {{ page.label }}
                </router-link>
                <div
                    v-if="page.count > 0"
                    class="indicator__header-icon todo-counter"
                >
                    {{ page.count }}
                </div>
            </li>
        </ul>
    </HtSidebar>
</template>

<script>
import HtSidebar from './HtSidebar.vue';

export default {
    name: 'HtPageSidebar',
    components: {
        HtSidebar,
    },

    props: {
        pages: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        emitUpdatePage(name) {
            this.$emit('update-page', name);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

li {
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    position: relative;
    cursor: pointer;
    margin: 1em 1em 1em 0;
    white-space: nowrap;
    display: flex;
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    a {
        text-decoration: none;
        font-size: 1.65rem;
        color: $grey;
        margin-left: 1em;
        transition: color 0.3s;
        &:hover {
            color: darken($text, 20%);
        }
        &.active {
            &:before {
                content: "\2022";
                font-size: 0.75em;
                position: absolute;
                left: 0;
                top: 8px;
                border-radius: 50%;
                line-height: 0px;
                box-shadow: 0px 0px 10px 5px #fff7f6;
            }
        }
    }
}
.icon-lock {
    margin-left: 0.5em;
    fill: $grey-text;
}

.indicator__header-icon {
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 24px;
    padding: 5px 6px;
    border-radius: 4px;
    font-size: 13px;
    color: #FFFFFF;
    text-align: center;
    background-color: var(--branding-color);

    &.todo-counter {
        bottom: -.3rem;
    }
}

@media (max-width: $tablet) {
    .menu-list {
        display: flex;
        margin: 0;
        padding: 0 2rem;
        gap: 2.4rem;

        li {
            margin: 0;

            &:last-child:after {
                content: "";
                display: block;
                width: 2rem;
                height: 1px;
            }

            a {
                font-size: 1.4rem;
                margin: 0;
                padding: 1.2rem 0;
                font-family: $lato;
                body:not(.old-ui) &{
                    font-family: var(--poppins);
                }

                &::before {
                    content: none;
                }

                &.active {
                    font-family: $lato-bold;
                    body:not(.old-ui) &{
                        font-family: var(--poppins);
                        font-weight: 600;
                    }
                    border-bottom-width: 3px;
                    border-bottom-style: solid;
                }
            }
        }
    }

    .indicator__header-icon {
        position: relative;
        right: unset;
        bottom: unset;
        margin-left: 1em;
        margin-top: .5em;
        height: 24px;
        padding: 5px 6px;
        border-radius: 4px;
        font-size: 13px;
        color: #FFFFFF;
        text-align: center;
        background-color: var(--branding-color);
    }
}
</style>
