<template>
    <div>
        <form
            v-if="item.isLoaded()"
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="modal-box">
                <InputField
                    :value="name"
                    :label="translate('Value name')"
                    :type="'text'"
                    :name="'name'"
                    :validate="'required|max:64'"
                    :validate-as="translate('name')"
                    :placeholder="translate('Write the Value name')"
                    :disabled="(item.is_heyteam === 1)"
                    @input="v => item.translations.name[editingLang] = v"
                />

                <InputField
                    :value="description"
                    :label="translate('Value description')"
                    :type="'textarea'"
                    :name="'description'"
                    :placeholder="translate('Please specify')"
                    :disabled="(item.is_heyteam === 1)"
                    @input="v => item.translations.description[editingLang] = v"
                />
            </div>
        </form>

        <div class="modal-actions right">
            <Button
                v-show="!item.isNew()"
                class="negative"
                @click="$refs.valueDelete.open()"
            >
                <t>Delete</t>
            </Button>
            <Button
                :state="button_state"
                @click="onValidate()"
            >
                {{ labelButton }}
            </Button>
        </div>

        <modalable
            ref="valueDelete"
            class="modal modalable-4"
        >
            <div class="modal-title">
                <t>Delete this element ?</t>
            </div>
            <p><t>Are you sure you want to delete this value? Once you click on delete, you will no longer be able to access this value for next onboardings.</t></p>
            <div class="row-line middle">
                <Button
                    class="black"
                    @click="$refs.valueDelete.close()"
                >
                    <t>Cancel</t>
                </Button>
                <Button
                    class="red"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
            </div>
        </modalable>
    </div>
</template>

<script>

import CompanyValueCollection from '@/models/CompanyValueCollection';
import CompanyValue from '@/models/CompanyValue';

export default {
    name: 'ValueEdit',
    inject: ['modal'],

    props: {

        value: { type: CompanyValue },
        collection: { type: CompanyValueCollection, required: true },
        editingLang: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            item: null,
            button_state: 'idle',
        };
    },

    computed: {
        name() {
            if (!(this.editingLang in this.item.translations.name)) {
                this.$set(this.item.translations.name, this.editingLang, '');
            }
            return this.item.translations.name[this.editingLang];
        },
        description() {
            if (Array.isArray(this.item.translations.description)) {
                this.item.translations.description = {};
            }
            if (!(this.editingLang in this.item.translations.description)) {
                this.$set(this.item.translations.description, this.editingLang, '');
            }
            return this.item.translations.description[this.editingLang];
        },
        CompanyValue: () => CompanyValue,

        labelTitle() {
            return this.translate(this.item.isNew() ? 'Add Value' : 'Edit Value');
        },
        labelButton() {
            return this.translate(this.item.isNew() ? 'Add' : 'Save');
        },

    },

    watch: {
        'item._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
    },

    created() {
        if (this.value) this.item = this.value.fromSaveState();
        else {
            this.item = this.collection.new();
            this.$set(this.item, 'translations', {});
            this.$set(this.item.translations, 'name', {});
            this.$set(this.item.translations, 'description', {});
        }

        this.modal.setTitle(this.labelTitle);
    },

    methods: {

        changeTo(type) {
            this.item.type = type;
        },

        async onValidate() {
            if (!await this.$validator.validateAll()) return;
            await this.item.save();

            this.modal.close();
        },

        async onDelete() {
            await this.item.delete();
            this.modal.close();
        },

    },
};
</script>

<style lang="scss" scoped>
	@import "~@/styles/var";

	.input-removable {
		display: flex;
		justify-content: space-between;
		align-items: center;

		input {
			width: calc(100% - 35px);
		}
	}

	.add-options {
		text-align: right;
		text-decoration: underline;
		font-family: $lato-bold;
        body:not(.old-ui) &{
            font-family: var(--poppins);
            font-weight: 600;
        }
	}

</style>
