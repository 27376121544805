<template>
    <div class="piloting-widget">
        <header class="widget-header">
            <span class="widget-title"><t>Details per program</t></span>
            <span class="widget-subtitle">
                <t>Track statistical details by program</t>
            </span>
        </header>
        <div class="widget-body">
            <div v-if="!loading && currentProgram">
                <HtSelect
                    v-model="currentProgram"
                    :label="translate('Selected program')"
                    :options="selectOptions"
                    :fullwidth="true"
                />
                <div class="detail-section">
                    <span class="section-label"><t>Global</t></span>
                    <div class="section-detail">
                        <span class="detail-title">
                            <span class="detail-icon">⏳</span>
                            <span class="detail-label"><t>In progress</t></span>
                        </span>
                        <span class="detail-content">
                            {{ currentProgramDetails.user_programs_in_progress_count }}
                        </span>
                    </div>
                    <div class="section-detail">
                        <span class="detail-title">
                            <span class="detail-icon">⚠️</span>
                            <span class="detail-label"><t>Late</t></span>
                        </span>
                        <span class="detail-content">
                            {{ currentProgramDetails.user_programs_late_count }}
                        </span>
                    </div>
                </div>
            </div>
            <HtIconLoading v-else />
        </div>
    </div>
</template>

<script>

export default {
    name: 'ProgramsDetails',

    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        programs: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            currentProgram: null,
        };
    },

    computed: {
        currentProgramDetails() {
            return this.programs.find((p) => p.id === this.currentProgram.value);
        },
        selectOptions() {
            return this.programs.map((p) => ({
                label: p.name_translated,
                value: p.id,
            }));
        },
        totalNumberOfUserInAProgram() {
            return this.programs.reduce((total, program) => {
                total += program.user_programs_in_progress_count;
                return total;
            }, 0);
        },

    },

    watch: {
        selectOptions: {
            immediate: true,
            handler() {
                this.currentProgram = this.selectOptions[0];
            },
        },
    },
};
</script>

<style lang="scss" scoped src="./PilotingWidget.scss" />
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.detail-section {
    margin-top: 20px;
}
.section-label {
    display: block;
    font-size: var(--text-small);
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
    color: var(--text-secondary);
}
.section-detail {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 12px;
}
.detail-title {
    display: flex;
    align-items: center;
    @include ht-body;
}
.detail-content {
    @include ht-body-semi;
}
.detail-icon {
    margin-right: 6px;
}
.detail-content {
    margin-left: 20px;
}
</style>
