<template>
    <div class="profile-item">
        <div class="profile-item-title">
            {{ title }}
        </div>
        <div class="profile-item-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewProfileItem',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
