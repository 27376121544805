<template>
    <div class="programs-monitoring-widget">
        <div class="programs-monitoring">
            <div class="programs-monitoring-top">
                <div class="programs-monitoring-title">
                    <span><t>Monitoring my team's programs</t></span>
                    <HtBadge
                        v-if="nbPrograms > 0"
                        theme="branding"
                    >
                        {{ nbPrograms }}
                    </HtBadge>
                </div>
                <HtIconLoading v-if="loading" />
                <div
                    v-else-if="programs.length > 0"
                    class="wrapper-programs"
                >
                    <router-link
                        v-for="program in programs"
                        :key="program.id"
                        class="program-monitored"
                        :to="{
                            name: 'ProfileProgramDetail',
                            params: {
                                company_user_id: program.user.id,
                                company_user_program_id: program.id,
                            },
                        }"
                    >
                        <HtAvatar :user="$Utils.getAvatarData(program.user)" />
                        <div class="program-text">
                            <div class="user-name">
                                {{ program.user.fullname }}
                            </div>
                            <div class="program-name">
                                {{ program.program.name }}
                            </div>
                        </div>
                        <HtBadge
                            size="large"
                            class="program-status"
                        >
                            <HtIndicator :status="program.lateTasks > 0 ? 'error' : 'success'" />
                            <t v-if="program.lateTasks > 0">
                                Late
                            </t>
                            <t v-else>
                                On time
                            </t>
                        </HtBadge>
                        <HtIcon
                            name="nav-arrow-right"
                            size="18"
                        />
                    </router-link>
                </div>
                <div
                    v-else
                    class="empty-monitoring"
                >
                    <span class="empty-title"><t>You are up-to-date</t></span>
                    <span class="empty-description">
                        <t>You have no action to perform in your team member's programs</t>
                    </span>
                </div>
            </div>
            <button
                class="view-all"
                type="button"
                @click="seeAll"
            >
                <span><t>View all programs</t></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgramsMonitoringWidget',
    props: {
        programs: {
            type: Array,
            required: true,
        },
        nbPrograms: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        seeAll() {
            window.localStorage.setItem('defaultSortedDashboard', 'user');
            this.$router.push({
                name: 'AllActions',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";

.programs-monitoring-widget {
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    margin-bottom: 20px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    background-color: var(--fill-primary);
}
.programs-monitoring-top {
    padding: 20px 20px 24px;
}
.programs-monitoring-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.wrapper-programs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
}
.program-monitored {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 20px;
    color: var(--text-primary);
    border: 1px solid #EFEFEF;
    border-radius: var(--radius);
    background-color: var(--fill-primary);
    box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    text-decoration: none;
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
}
.ht-avatar {
    flex: 0 0 auto;
}
.program-text {
    flex: 1 1 auto;
    min-width: 0;
}
.user-name {
    font-size: var(--text-medium);
    font-weight: 500;
    line-height: var(--line-height-auto);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.program-name {
    font-size: var(--text-medium);
    font-weight: 400;
    line-height: var(--line-height-auto);
    color: var(--text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ht-label {
    flex: 0 0 auto;
}
.ht-icon {
    flex: 0 0 auto;
    margin-left: 8px;
}
.empty-monitoring {
    padding: 20px;
    text-align: center;
    > span {
        display: block;
    }
    .empty-title {
        @include ht-heading-4;
        margin-bottom: 5px;
    }
    .empty-description {
        @include ht-body;
    }
}
.view-all {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid var(--border-primary);
    padding: 16px 20px;
    text-align: center;
    text-decoration: underline;
    color: var(--text-primary);
    @include ht-body;
    > span {
        &::after {
            content: '\00a0›';
        }
    }
}
</style>
