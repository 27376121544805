<template>
    <div v-if="permissionsLoaded">
        <div v-if="!isOldUi">
            <template v-if="companyRoleUserCollection.isLoaded()">
                <NewTeamList :users="users" />
            </template>
            <HtIconLoading v-else />
        </div>
        <div v-else>
            <template v-if="companyRoleUserCollection.isLoaded()">
                <template v-if="!isEdit">
                    <div
                        v-if="getUserTeam.length"
                        class="card-list flex-container card-onboarding onboarding-team"
                    >
                        <div
                            v-for="(item, index) in getUserTeam"
                            :key="`current-${index}`"
                            v-tooltip="item.user.firstname + ' ' + item.user.lastname"
                            class="item transparent user-selected"
                            @click="$router.push(`/Profile/${item.user.id}`)"
                        >
                            <div
                                :key="`${item.company_user_id}-${index}`"
                                class="item-content"
                            >
                                <div class="item-title">
                                    {{ item.user.firstname }}
                                    <div class="team-role">
                                        {{ item.role }}
                                    </div>
                                    <div v-if="hasRelevantActiveDelegation(item)">
                                        <FontAwesomeIcon icon="info-circle" />
                                        <t>Active delegation</t>
                                    </div>
                                </div>
                                <div
                                    v-user-image="{ image: item.user.image,
                                                    firstname: item.user.firstname,
                                                    lastname: item.user.lastname,
                                                    size: 5}"
                                    class="item-img full"
                                />
                                <div class="team-member-overlay" />
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="empty-component mb-30"
                    >
                        <strong>
                            <t>No supervisors on this user</t>
                        </strong>
                    </div>
                </template>
                <template v-else>
                    <div class="profile-onboarding-team-body">
                        <div class="flex-container card-onboarding onboarding-team">
                            <div
                                v-for="(item, index) in companyRoleUserCollection.models"
                                :key="`change-${index}`"
                                :class="['item transparent', {'user-selected': item.company_user_id}]"
                                @click="onClick(item)"
                            >
                                <div
                                    v-if="!item.company_user_id"
                                    :key="index"
                                    class="item-content"
                                >
                                    <div class="icon-wrapper">
                                        <span class="icon icon-plus">+</span>
                                    </div>
                                    <div
                                        v-if="item.role"
                                        class="item-title"
                                    >
                                        <span>{{ item.role.alias_translated }}</span>
                                    </div>

                                    <div class="item-img" />
                                </div>

                                <div
                                    v-else
                                    :key="`${item.company_user_id}-${index}`"
                                    class="item-content"
                                >
                                    <div class="icons-wrapper">
                                        <HtButton
                                            type=""
                                            size="small"
                                            class="icon-button"
                                        >
                                            <IconChange class="change" />
                                        </HtButton>
                                        <HtButton
                                            type=""
                                            size="small"
                                            class="icon-button"
                                            @click.native="remove($event, item)"
                                        >
                                            <FontAwesomeIcon
                                                icon="trash"
                                                class="delete"
                                            />
                                        </HtButton>
                                    </div>
                                    <div class="item-title">
                                        {{ item.user.firstname }}

                                        <template v-if="item.role">
                                            <div class="team-role">
                                                {{ item.role.alias_translated }}
                                            </div>
                                        </template>
                                    </div>

                                    <div
                                        v-user-image="{
                                            image: item.user.image,
                                            firstname: item.user.firstname,
                                            lastname: item.user.lastname,
                                            size: 5}"
                                        class="item-img full"
                                    />
                                    <div class="team-member-overlay" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <IconLoading />
            </template>
        </div>
    </div>
</template>
<script>

import HtButton from '@/components/globals/HtButton.vue';
import IconChange from '@/components/icons/IconChange.vue';
import SupervisorMixin from '@/mixins/SupervisorMixin';
import modalMixin from '@/components/globals/modals/modalMixin';
import NewTeamList from '@/components/pages/team/NewTeamList.vue';
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';

export default {
    name: 'MyTeam',
    permissions: [
        'ModelCompanyUser',
    ],
    components: { IconChange, HtButton, NewTeamList },
    mixins: [
        SupervisorMixin,
        modalMixin,
    ],
    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
        getUserTeam() {
            const userTeam = [];
            if (this.companyRoleUserCollection.isLoaded()) {
                this.companyRoleUserCollection.$.models.each((companyRoleUser) => {
                    const member = userTeam.find((s) => s.company_user_id === companyRoleUser.company_user_id);
                    if (member) {
                        const role = companyRoleUser.role.alias_translated;
                        member.role += `, ${role}`;
                    } else {
                        const role = companyRoleUser.role.alias_translated;
                        userTeam.push({ ...companyRoleUser, role });
                    }
                });
            }
            return userTeam;
        },
        users() {
            return this.getUserTeam.map((item) => {
                const { user } = item;
                user.company_job_position = { name: item.role };
                return user;
            });
        },
    },
    watch: {
        isEdit() {
            this.companyRoleUserCollection.fromSaveState();
            this.mergeEmptyRolesIntoSupervisors();
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            await Promise.all([
                this.getSupervisors(this.companyUserId),
                this.getRoles(),
            ]);
            this.mergeEmptyRolesIntoSupervisors();
        },
        onClick(item) {
            this.$emit('change', item);
        },
        remove(e, item) {
            e.stopPropagation();
            this.genericConfirm(
                this.translate('Are you sure you want to delete this role ?'),
                this.translate(
                    'By deleting it, all pending task attributed to this role will be deleted. You will still be able to manually add them.',
                ),
            ).then(() => {
                this.$emit('remove', item);
            });
        },
        async onValidate() {
            try {
                await this.updateSupervisors(this.companyUserId);
                this.$emit('update:isEdit', false);
                this.$Notifier('App').showInfo(this.translate('Successfully updated'));
                this.loadData();
            } catch (error) {
                this.$Notifier('App').showError(this.translate('An error occured'));
                this.buttonState = 'idle';
            }
        },
        hasRelevantActiveDelegation(roleUser) {
            return roleUser.user.from_delegations?.models.some((delegation) => {
                const startDate = new Date(delegation.start_date);
                const endDate = delegation.end_date ? new Date(delegation.end_date) : null;
                return startDate <= new Date() && (!endDate || endDate >= new Date()) && delegation.role_id === roleUser.role_id;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
.card-onboarding {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    width: calc(100% - 20px);
    .item {
        width: 100%;
        height: 100%;
        padding: 0;
        aspect-ratio: 1;
    }
    .item-content {
        overflow: hidden;
        .icon-wrapper {
            box-shadow: 0px 0px 20px $border-grey-light;

            .icon {
                width: 18px;
                height: 18px;
            }
        }

		.icons-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 4px;

            .icon-button {
                box-shadow: 0px 0px 20px $border-grey-light;
                z-index: 100;
                background: $neutral-white;
                border-radius: 100%;
                color: $text;
                height: 35px;
                width: 35px;

                .delete, .change {
                    width: 18px;
                    height: 18px;
                }
            }
		}
    }

    .item-img.full {
        border-radius: 20px;
        border-top-left-radius: 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
    }

    .team-member-overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,0.5816701680672269) 0%, rgba(0,0,0,0.48363095238095233) 3%, rgba(0,0,0,0.41360294117647056) 11%, rgba(0,0,0,0.3071603641456583) 24%, rgba(0,0,0,0.08867296918767509) 39%, rgba(0,0,0,0) 60%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.5816701680672269) 0%, rgba(0,0,0,0.48363095238095233) 3%, rgba(0,0,0,0.41360294117647056) 11%, rgba(0,0,0,0.3071603641456583) 24%, rgba(0,0,0,0.08867296918767509) 39%, rgba(0,0,0,0) 60%, rgba(255,255,255,0) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,0.5816701680672269) 0%, rgba(0,0,0,0.48363095238095233) 3%, rgba(0,0,0,0.41360294117647056) 11%, rgba(0,0,0,0.3071603641456583) 24%, rgba(0,0,0,0.08867296918767509) 39%, rgba(0,0,0,0) 60%, rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
    }
    .team-role {
        font-weight: bold;
        font-style: italic;
    }
}

@media (max-width: $tablet) {
    .card-onboarding {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: $phone) {
    .card-onboarding.flex-container  {
        gap: 0;
    }
}
</style>
