<template>
    <div>
        <HtModalable
            ref="modalableAddElement"
            class="modalable-1 medium"
            :options="{ title: translate('Add an element') }"
        >
            <div class="content-modal">
                <HtFormSelector
                    v-if="availablePrograms.length > 1"
                    id="program-id"
                    v-model="selectedProgramId"
                    name="program-id"
                    :options="availablePrograms"
                    is-single
                    track-by="id"
                    specific-key="id"
                    label-options="label"
                    :allow-empty="false"
                    :show-optional="false"
                    :label="translate('Target Program')"
                />
                <div class="elements">
                    <button
                        v-for="(item, index) in availableElements"
                        :key="index"
                        type="button"
                        class="element"
                        @click="callAction(item)"
                    >
                        <HtEmojiIcon
                            size="40"
                            font-size="16"
                            :style="{backgroundColor: item.color }"
                        >
                            <HtIcon
                                :name="item.iconName || 'task-list'"
                                stroke-width="2"
                                size="20"
                                :style="{color: item.iconColor }"
                            />
                        </HtEmojiIcon>
                        <span class="element-name"><t>{{ item.name }}</t></span>
                        <HtIcon
                            name="nav-arrow-right"
                            size="20"
                            class="element-arrow"
                        />
                    </button>
                </div>
            </div>
        </HtModalable>

        <!-- Modals -->
        <template v-if="companyUser.isLoaded()">
            <HtModalable
                v-if="checkAvailableElement('Require information')"
                ref="modalableUserRequirementCreate"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserRequirementCategoryCreateModal
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        @on-create="onUpdate"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Training')"
                ref="modalableUserTrainingEdit"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserTrainingSelect
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Equipment')"
                ref="modalableUserEquipmentSelect"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserEquipmentSelect
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </htmodalable>

            <HtModalable
                v-if="checkAvailableElement('Software')"
                ref="modalableUserSoftwareSelect"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserSoftwareSelect
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Event')"
                ref="modalableUserPlanningEventEdit"
                class="modalable-1 medium"
                :mode="2"
                :options="{title: translate('Add an event')}"
            >
                <div class="content-modal">
                    <UserPlanningEventEdit
                        :from="from"
                        :company-user-id="companyUserId"
                        :intelligent-selection="intelligentSelection"
                        :company-user-program-id="selectedProgramId"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Email')"
                ref="modalableUserEmailEdit"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserEmailCustomEdit
                        ref="userEmailEdit"
                        :from="'cop'"
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        :intelligent-selection="intelligentSelection"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Survey')"
                ref="modalableSurveyListSelect"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserSurveySelect
                        :company-user-id="companyUserId"
                        :intelligent-selection="intelligentSelection"
                        :company-user-program-id="selectedProgramId"
                        :is-draft="from === 'bop' || from === 'foc'"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Quiz')"
                ref="modalableQuizListSelect"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserQuizSelect
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        :intelligent-selection="intelligentSelection"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Action')"
                ref="modalableUserTaskSelect"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserActionSelect
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        :exhaustive-selection="intelligentSelection"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>

            <HtModalable
                v-if="checkAvailableElement('Document')"
                ref="modalableUserDocumentSelect"
                class="modalable-1 medium"
                :mode="2"
            >
                <div class="content-modal">
                    <UserDocSelect
                        :company-user-id="companyUserId"
                        :company-user-program-id="selectedProgramId"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </div>
            </HtModalable>
            <!-- Modals End -->
        </template>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyRole from '@/models/CompanyRole';

import UserRequirementCategoryCreateModal from '@/components/pages/onboarding/UserRequirementCategoryCreateModal.vue';
import UserTrainingSelect from '@/components/pages/dashboard/modals/training/UserTrainingSelect.vue';
import UserEquipmentSelect from '@/components/pages/dashboard/modals/equipment/UserEquipmentSelect.vue';
import UserSoftwareSelect from '@/components/pages/dashboard/modals/software/UserSoftwareSelect.vue';
import UserPlanningEventEdit from '@/components/pages/dashboard/modals/planningEvent/UserPlanningEventEdit.vue';
import UserEmailCustomEdit from '@/components/pages/dashboard/modals/emailCustom/UserEmailCustomEdit.vue';
import UserSurveySelect from '@/components/pages/dashboard/modals/survey/UserSurveySelect.vue';
import UserQuizSelect from '@/components/pages/dashboard/modals/quiz/UserQuizSelect.vue';
import UserActionSelect from '@/components/pages/dashboard/modals/action/UserActionSelect.vue';
import UserDocSelect from '@/components/pages/dashboard/modals/doc/UserDocSelect.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';

import CompanyUserProgramTask from '../../models/CompanyUserProgramTask';

export default {
    name: 'NewModalAddUserElement',
    permissions: [
        'ModelCompanyUserRequirementCategory',
        'ModelCompanyUserDocument',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserSoftware',
        'ModelCompanyPlanningEvent',
        'ModelCompanyUserEmailCustom',
        'ModelCompanyUserSurvey',
        'ModelCompanyUserQuiz',
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserDoc',
    ],

    components: {
        UserRequirementCategoryCreateModal,
        UserTrainingSelect,
        UserEquipmentSelect,
        UserSoftwareSelect,
        UserPlanningEventEdit,
        UserEmailCustomEdit,
        UserSurveySelect,
        UserQuizSelect,
        UserActionSelect,
        UserDocSelect,
        HtFormSelector,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: true,
        },
        companyUserPrograms: {
            type: Array,
            default: () => [],
        },
        from: {
            type: String,
            default: 'cop',
        },
        selectedIds: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            selectedProgramId: this.companyUserProgramId,
            companyRoleEnrollee: new CompanyRole(
                ['id', 'name', 'alias', 'is_heyteam'],
            ).where([
                ['name', '=', 'enrollee'],
            ]),
            companyUser: new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'image',
            ]).with({
                companyRoleUserEmployee: (query) => {
                    query.select(['id', 'company_role_user_id']).with({
                        roleUser: (query) => {
                            query.select(['id', 'company_user_id', 'company_role_id']).with({
                                role: (query) => {
                                    query.select(['id', 'alias', 'name', 'is_heyteam']);
                                },
                                user: (query) => {
                                    query.select(['id', 'firstname', 'lastname', 'image']);
                                },
                            });
                        },
                    });
                },
            }).where([
                ['id', '=', this.companyUserId],
            ]),
            elements: [
                {
                    name: 'Training',
                    color: '#D7F5F3',
                    iconName: 'bookmark-book',
                    iconColor: '#4F7075',
                    route: { name: 'ProfileResourceTraining', params: { company_user_id: this.companyUserId } },
                    action2: () => {
                        this.$refs.modalableUserTrainingEdit.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserDocument', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Event',
                    color: '#D9EEFF',
                    iconName: 'group',
                    iconColor: '#305E95',
                    route: { name: 'ProfileResourcePlanning', params: { company_user_id: this.companyUserId } },
                    action2: () => {
                        this.$refs.modalableUserPlanningEventEdit.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyPlanningEvent', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Action',
                    color: '#FFE3AC',
                    iconName: 'task-list',
                    iconColor: '#997328',
                    action2: () => {
                        this.$refs.modalableUserTaskSelect.open();
                    },
                    permissionCheck: () => this.$canCreate(
                        'ModelCompanyUserProgramTask',
                        {
                            company_user_id: this.companyUserId,
                            resource: CompanyUserProgramTask.TASK_CUSTOM,
                        },
                    ),
                },
                {
                    name: 'Software',
                    color: '#D0FFF4',
                    iconName: 'pc-mouse',
                    iconColor: '#31B59E',
                    route: { name: 'ProfileResourceSoftware', params: { company_user_id: this.companyUserId } },
                    action2: () => {
                        this.$refs.modalableUserSoftwareSelect.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserSoftware', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Equipment',
                    color: '#E3DCFF',
                    iconName: 'computer',
                    iconColor: '#51397A',
                    action2: () => {
                        this.$refs.modalableUserEquipmentSelect.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserEquipment', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Document',
                    color: '#FFE2E2',
                    iconName: 'submit-document',
                    iconColor: '#B73E23',
                    action2: async () => {
                        this.$refs.modalableUserDocumentSelect.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserDoc', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Require information',
                    color: '#FFF9C7',
                    iconName: 'journal',
                    iconColor: '#A69712',
                    route: { name: 'ProfileAdministrative', params: { company_user_id: this.companyUserId } },
                    action2: () => {
                        this.$refs.modalableUserRequirementCreate.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserRequirementCategory', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Email',
                    color: '#FDF2DD',
                    iconName: 'mail',
                    iconColor: '#E6A456',
                    route: { name: 'ProfileEmail', params: { company_user_id: this.companyUserId } },
                    action2: () => {
                        this.$refs.modalableUserEmailEdit.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserEmailCustom', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Survey',
                    color: '#FFDCCE',
                    iconName: 'chat-bubble-question',
                    iconColor: '#9A3F21',
                    route: { name: 'ProfileSurvey', params: { company_user_id: this.companyUserId } },
                    action2: async () => {
                        this.$refs.modalableSurveyListSelect.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserSurvey', { company_user_id: this.companyUserId }),
                },
                {
                    name: 'Quiz',
                    color: '#EDE5DE',
                    iconName: 'chat-lines',
                    iconColor: '#694F4F',
                    action2: async () => {
                        this.$refs.modalableQuizListSelect.open();
                    },
                    permissionCheck: () => this.$canCreate('ModelCompanyUserQuiz', { company_user_id: this.companyUserId }),
                },
            ],
        };
    },

    computed: {
        availablePrograms() {
            return this.companyUserPrograms.map((p) => ({ id: p.id, label: p.program.name }));
        },
        availableElements() {
            if (this.permissionsLoaded) {
                return this.elements.filter((item) => (item.permissionCheck ? item.permissionCheck() : false));
            }
        },

        intelligentSelection() {
            let userList = [];
            userList = this.companyUser.company_role_user_employee.models.map((supervisor) => ({
                company_role: {
                    id: supervisor.role_user.role.id,
                    name: supervisor.role_user.role.name,
                    alias: supervisor.role_user.role.alias,
                    is_heyteam: supervisor.role_user.role.is_heyteam,
                },
                company_user: {
                    firstname: supervisor.role_user.user.firstname,
                    lastname: supervisor.role_user.user.lastname,
                    image: supervisor.role_user.user.image,
                    id: supervisor.role_user.user.id,
                },
                firstname: supervisor.role_user.user.firstname,
                lastname: supervisor.role_user.user.lastname,
                image: supervisor.role_user.user.image,
                id: supervisor.role_user.user.id,
                company_user_id: supervisor.role_user.user.id,
            }));
            return [
                {
                    company_role: {
                        id: this.companyRoleEnrollee.id,
                        name: this.companyRoleEnrollee.name,
                        alias: this.companyRoleEnrollee.alias,
                        is_heyteam: this.companyRoleEnrollee.is_heyteam,
                    },
                    company_user: {
                        firstname: this.companyUser.firstname,
                        lastname: this.companyUser.lastname,
                        image: this.companyUser.image,
                        id: this.companyUser.id,
                    },
                    firstname: this.companyUser.firstname,
                    lastname: this.companyUser.lastname,
                    image: this.companyUser.image,
                    id: this.companyUser.id,
                    company_user_id: this.companyUser.id,
                },
                ...userList,
            ];
        },
    },

    created() {
        this.companyUser.get();
        this.companyRoleEnrollee.get();
    },

    methods: {
        callAction(item) {
            item.action2();

            this.close();
        },

        checkAvailableElement(element) {
            return this.availableElements.some((item) => element === item.name);
        },

        onUpdate() {
            this.$emit('onUpdate');
        },

        onDelete() {
            this.$emit('onDelete');
        },

        // SIMPLE ACTIONS
        open() { this.$refs.modalableAddElement.open(); },
        close() { this.$refs.modalableAddElement.close(); },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.elements {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
    border-radius: var(--radius-block);
    background-color: var(--fill-secondary);
}
.element {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px 16px;
    text-align: left;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius);
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    &:hover {
        transform: scale(1.02);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
}
.element-icon {
    flex: 0 0 auto;
}
.element-name {
    flex: 1 1 auto;
}
.element-arrow {
    flex: 0 0 auto;
}

.content-modal {
    padding: 20px;
}
</style>
