<template>
    <div>
        <HtTooltip
            inverted
            position="inline-end"
        >
            <button
                :data-cy="'menu-'+item.name"
                type="button"
                class="mainbar-menu-item-link"
                @click="$emit('onChangeSubbar', item.name)"
            >
                <HtIcon
                    :name="item.icon"
                    size="18"
                />
                <span class="mainbar-menu-item-label"><t>{{ item.label }}</t></span>
            </button>
            <template #target>
                {{ item.label }}
            </template>
        </HtTooltip>
    </div>
</template>

<script>

export default {
    name: 'HtMainBarMenuSub',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            open: false,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
