<template>
    <div class="list-tasks">
        <template v-if="!loading">
            <div v-if="users.length">
                <TasksListByUserItem
                    v-for="user in users"
                    v-show="filteredUsers.includes(user)"
                    :key="`user-${user.id}`"
                    :user="user"
                    class="user-item"
                    :add-button="canCreateResourceForUser(user.id)"
                    :is-history="isHistory"
                />
            </div>
            <div
                v-else
                class="list-tasks-no-task"
            >
                <t v-if="isHistory">
                    You haven't completed any actions
                </t>
                <t v-else>
                    You are up to date on your actions
                </t>
            </div>
        </template>
        <div v-else>
            <HtIconLoading />
        </div>
    </div>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import TasksListByUserItem from './TasksListByUserItem.vue';

export default {
    name: 'ActionsByUser',
    permissions: [
        'ModelCompanyPlanningEvent',
        'ModelCompanyUserDoc',
        'ModelCompanyUserDocument',
        'ModelCompanyUserEmailCustom',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserNudge',
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserQuiz',
        'ModelCompanyUserRequirementCategory',
        'ModelCompanyUserSoftware',
        'ModelCompanyUserSurvey',
    ],
    components: { TasksListByUserItem, HtSearchField },
    props: {
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            users: [],
            loading: false,
            search: '',
        };
    },
    computed: {
        filteredUsers() {
            const search = this.$Utils.removeDiacritics(this.search).toLowerCase();
            return this.users.filter((user) => {
                const firstname = this.$Utils.removeDiacritics(user.firstname).toLowerCase();
                const lastname = this.$Utils.removeDiacritics(user.lastname).toLowerCase();
                return `${firstname} ${lastname}`.includes(search)
                    || `${lastname} ${firstname}`.includes(search);
            });
        },
    },
    watch: {
        isHistory() {
            this.getUsers();
        },
    },
    created() {
        this.getUsers();
    },
    methods: {
        async getUsers() {
            this.loading = true;
            await this.$store.dispatch(this.isHistory ? 'tasks/fetchHistoryUsersAndEmployees' : 'tasks/fetchCurrentUsersAndEmployees')
                .then((result) => {
                    this.users = result.data.data;
                    this.loading = false;
                });
        },
        canCreateResourceForUser(userId) {
            for (let i = 0; i < this.$options.permissions?.length; i += 1) {
                const permission = this.$options.permissions[i];
                if (this.$canCreate(permission, { company_user_id: userId })) return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.user-item:not(:last-child) {
    margin-bottom: 16px;
}

@media (min-width: $phone) {
    .list-tasks {
        padding: 16px;
        border-radius: var(--radius-block);
        background-color: var(--fill-secondary);
    }
}

</style>
