import Model from '@tony.caron/node-model-proxy/Model';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import CompanyKnowledgeSettings from '@/models/CompanyKnowledgeSettings';
import CompanyDms from './CompanyDms';
import CompanySso from './CompanySso';
import CompanyOffice from './CompanyOffice';
import CompanyContract from './CompanyContract';
import CompanyDepartment from './CompanyDepartment';
import CompanySecurity from './CompanySecurity';
import CompanyJobPosition from './CompanyJobPosition';
import CompanyLanguage from './CompanyLanguage';
import CompanyUser from './CompanyUser';
import CompanyFieldTemplate from './CompanyFieldTemplate';
import CompanyBenefit from './CompanyBenefit';
import CompanyValue from './CompanyValue';
import CompanyRole from './CompanyRole';
import CompanyEntityValue from './CompanyEntityValue';
import CompanyProgramTask from './CompanyProgramTask';
import CompanyTraining from './CompanyTraining';
import CompanyRequirementCategory from './CompanyRequirementCategory';
import CompanyEquipment from './CompanyEquipment';
import CompanyEmailCustomTemplate from './CompanyEmailCustomTemplate';
import CompanySurvey from './CompanySurvey';
import CompanyQuiz from './CompanyQuiz';
import CompanyPlanningEventTemplate from './CompanyPlanningEventTemplate';
import CompanySoftware from './CompanySoftware';
import CompanySharedDocument from './CompanySharedDocument';
import CompanyNudge from './CompanyNudge';
import CompanyFile from './CompanyFile';

export default class Company extends Model {
    static heyteam_support_link = 'https://support.heyteam.com/knowledge/kb-tickets/new';

    modelCustomAttributes() {
        return {
            hasSso: false,
            hasDms: false,
        };
    }

    modelAccessors() {
        return {
            hasSso: () => typeof this.company_sso === 'object' && this.company_sso !== null,
            hasDms: () => typeof this.company_dms === 'object' && this.company_dms !== null,
            about: () => this.translations.about[RtwSharedSpace.store.session.companyUser.company_language.key],
            mission: () => this.translations.mission[RtwSharedSpace.store.session.companyUser.company_language.key],
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                about: {},
                mission: {},
            },
        };
    }

    modelRelations() {
        return {
            companyDms: () => this.belongsTo(CompanyDms),
            customFieldTemplates: () => this.hasMany(CompanyFieldTemplate),
            companySso: () => this.belongsTo(CompanySso),
            entityValues: () => this.hasMany(CompanyEntityValue),
            companyDepartment: () => this.hasMany(CompanyDepartment),
            companyOffice: () => this.hasMany(CompanyOffice),
            companyContract: () => this.hasMany(CompanyContract),
            companyJobPosition: () => this.hasMany(CompanyJobPosition),
            companyLanguages: () => this.hasMany(CompanyLanguage),
            companyKnowledgeSettings: () => this.hasOne(CompanyKnowledgeSettings),
            companyLanguage: () => this.belongsTo(CompanyLanguage, 'company_language_id', 'id'),
            companyUser: () => this.hasMany(CompanyUser),
            companyBenefit: () => this.hasMany(CompanyBenefit),
            companyValue: () => this.hasMany(CompanyValue),
            companyRole: () => this.hasMany(CompanyRole),
            security: () => this.hasOne(CompanySecurity),
            // resources
            actions: () => this.hasMany(CompanyProgramTask),
            documents: () => this.hasMany(CompanySharedDocument),
            emails: () => this.hasMany(CompanyEmailCustomTemplate),
            equipments: () => this.hasMany(CompanyEquipment),
            events: () => this.hasMany(CompanyPlanningEventTemplate),
            quizzes: () => this.hasMany(CompanyQuiz),
            nudges: () => this.hasMany(CompanyNudge),
            requirements: () => this.hasMany(CompanyRequirementCategory),
            surveys: () => this.hasMany(CompanySurvey),
            software: () => this.hasMany(CompanySoftware),
            trainings: () => this.hasMany(CompanyTraining),
            faviconFile: () => this.belongsTo(CompanyFile, 'favicon_company_file_id', 'id'),
            loginCoverFile: () => this.belongsTo(CompanyFile, 'login_cover_company_file_id', 'id'),
            loginLogoFile: () => this.belongsTo(CompanyFile, 'login_logo_company_file_id', 'id'),
        };
    }
}
