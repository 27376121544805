<template>
    <div
        v-if="!isOldUi"
        class="profile-activities"
    >
        <HtSelect
            v-model="activityType"
            class="activities-filter"
            :label="translate('Type')"
            :options="activityTypeOptions"
        />
        <div
            v-if="sortedItems.length"
            class="activities"
        >
            <div
                v-for="item in sortedItems"
                :key="item.id"
                class="activity"
            >
                <div
                    v-if="item.media"
                    class="activity-item activity-email"
                >
                    <HtEmojiIcon
                        size="40"
                        :style="{backgroundColor: '#FDF2DD' }"
                        class="activity-item-start"
                    >
                        <HtIcon
                            name="mail"
                            stroke-width="2"
                            size="16"
                            :style="{color: '#E6A456' }"
                        />
                    </HtEmojiIcon>

                    <div class="activity-infos">
                        <div
                            class="activity-title"
                        >
                            <span v-html="formatSubject(item)" />
                            <span v-if="item.email_sent_to">
                                {{ translate('has been sent to') }} {{ item.email_sent_to }}
                            </span>
                        </div>
                        <div class="activity-extra">
                            <span>{{ formatDate(item.schedule_at) }}</span>
                            <span v-if="item.status !== undefined"> • {{ translate(item.status === 'to_deliver' ? 'Not delivered yet' : item.status) }}</span>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="activity-item activity-flow"
                >
                    <HtAvatar
                        :user="$Utils.getAvatarData(item.from)"
                        class="activity-item-start"
                    />
                    <div class="activity-infos">
                        <div
                            class="activity-title"
                            v-html="formatMessage(item)"
                        />
                        <div class="activity-extra">
                            {{ formatDate(item.created_at) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HtIconLoading v-else />
        <EmailNotificationDisplayModal
            v-if="selectedEmailNotification"
            ref="emailModal"
            :email="selectedEmailNotification"
        />
    </div>
    <div
        v-else
        class="h-100"
    >
        <div class="container-tabs">
            <HtTabs v-if="isLoaded">
                <HtTab :title="translate('Flow of activity')">
                    <NotificationsActivities :company_user_id="companyUserId" />
                </HtTab>
                <HtTab :title="translate('Emails')">
                    <EmailsActivities :company_user_id="companyUserId" />
                </HtTab>
            </HtTabs>
        </div>
    </div>
</template>

<script>
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';
import CompanyUserAuditCollection from '@/models/CompanyUserAuditCollection';
import CompanyUserNotificationCollection from '@/models/CompanyUserNotificationCollection';

import EmailNotificationDisplayModal from './activities/EmailNotificationDisplayModal.vue';

import EmailsActivities from './activities/EmailsActivities.vue';
import NotificationsActivities from './activities/NotificationsActivities.vue';

export default {
    name: 'PageActivities',
    components: {
        NotificationsActivities,
        EmailsActivities,
        EmailNotificationDisplayModal,
    },
    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isLoaded: false,
            selectedEmailNotification: null,
            activityType: {
                label: this.translate('Flow of activity & Emails'),
                value: 'both',
            },
            activityTypeOptions: [
                {
                    label: this.translate('Flow of activity & Emails'),
                    value: 'both',
                },
                {
                    label: this.translate('Flow of activity'),
                    value: 'flow',
                },
                {
                    label: this.translate('Emails'),
                    value: 'emails',
                },
            ],
            companyUserAuditCollection: new CompanyUserAuditCollection([
                'id',
                'message',
                'param',
                'created_at',
                'permissions',
            ]).where([
                ['to_company_user_id', '=', this.companyUserId],
            ]).with({
                to: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']).withTrashed(true);
                },
                from: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']).withTrashed(true);
                },
            }).orderBy([
                ['id', 'desc'],
            ])
                .limit(20),
            companyUserNotification: new CompanyUserNotificationCollection([
                'id',
                'company_id',
                'to_company_user_id',
                'related_company_user_id',
                'media',
                'template',
                'schedule_at',
                'subject',
                'generated_subject',
                'generated_content',
                'status',
                'delivered_at',
                'email_sent_to',
                'permissions',
            ])
                .where([
                    ['related_company_user_id', '=', this.companyUserId],
                    ['to_company_user_id', '=', this.companyUserId, 'or'],
                ])
                .where([
                    ['status', '=', 'delivered'],
                    ['status', '=', 'to_deliver', 'or'],
                ])
                .where([
                    ['media', '!=', 'web_ui'],
                ])
                .orderBy([
                    ['id', 'desc'],
                ])
                .limit(20),
        };
    },
    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
        itemsToShow() {
            if (this.activityType.value === 'both' && this.companyUserNotification.isLoaded() && this.companyUserAuditCollection.isLoaded()) {
                return [
                    ...this.companyUserNotification.models,
                    ...this.companyUserAuditCollection.models,
                ];
            } if (this.activityType.value === 'emails' && this.companyUserNotification.isLoaded()) {
                return this.companyUserNotification.models;
            } if (this.activityType.value === 'flow' && this.companyUserAuditCollection.isLoaded()) {
                return this.companyUserAuditCollection.models;
            }
            return [];
        },
        sortedItems() {
            return [...this.itemsToShow].sort((a, b) => {
                const dateA = a.created_at || a.schedule_at;
                const dateB = b.created_at || b.schedule_at;
                return new Date(dateB) - new Date(dateA);
            });
        },
    },

    created() {
        this.companyUserAuditCollection.get();
        this.companyUserNotification.get();
        this.isLoaded = true;
    },
    methods: {
        // TODO : refactor this if perf issues
        formatMessage(item) {
            return this
                .translate(item.message)
                .replace('{from->fullName}', `<strong>${item.from.firstname} ${item.from.lastname}</strong>`)
                .replace('{from->fullname}', `<strong>${item.from.firstname} ${item.from.lastname}</strong>`)
                .replace('{related->fullName}', `<strong>${item.from.firstname} ${item.from.lastname}</strong>`)
                .replace('{related->fullname}', `<strong>${item.from.firstname} ${item.from.lastname}</strong>`)
                .replace('{to->fullName}', `<strong>${item.to.firstname} ${item.to.lastname}</strong>`)
                .replace('{to->fullname}', `<strong>${item.to.firstname} ${item.to.lastname}</strong>`)
                .replace('{param->name}', Object.prototype.hasOwnProperty.call(item.param, 'name') ? `<strong>${item.param.name}</strong>` : '');
        },

        formatSubject(item) {
            const subject = item.generated_subject || item.subject;
            return this.translate('Email <strong>{subject}</strong>', {
                subject: subject ? `“${subject}”` : '',
            });
        },

        formatDate(date) {
            return this.$Utils.moment(date).format('LLL');
        },
        displayEmail(item) {
            this.selectedEmailNotification = item;
            this.$nextTick(() => this.$refs.emailModal.open());
        },

    },

};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.profile-activities {
    padding: 16px;
    background-color: var(--fill-secondary);
    border-radius: var(--radius-block);
}
.activities-filter {
    margin-bottom: 12px;
}

.activities {
    padding: 20px;
    border-radius: var(--radius-block);
    border: 1px solid var(--border-primary);
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
}

.activity {
    &:not(:last-child){
        margin-bottom: 20px;
    }
}

.activity-item {
    display: flex;
    align-items: center;
}
.activity-item-start {
    flex-shrink: 0;
    margin-right: 12px;
}

.activity-infos  {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.activity-title  {
    @include ht-body;
    color: var(--text-secondary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ::v-deep strong {
        font-family: var(--poppins);
        @include ht-body;
        color: var(--text-primary);
        font-weight: 500;
    }
}

.activity-extra  {
    @include ht-caption;
    color: var(--text-secondary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
