<template>
    <div
        class="ht-recap"
    >
        <HtEmojiIcon
            size="42"
            font-size="19"
            theme="primary"
            class="recap-icon"
        >
            {{ icon }}
        </HtEmojiIcon>
        <div class="recap-infos">
            <slot name="infos" />
        </div>
        <div class="recap-button">
            <slot name="button" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtRecap',
    props: {
        icon: {
            type: String,
            required: false,
            default: '📖',
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-recap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 16px 20px;
    border-radius: var(--radius);
    background: var(--fill-secondary);
}
.recap-icon {
    flex: 0 0 auto;
}
.recap-infos {
    flex: 1 1 auto;
    @include ht-body-semi;
    line-height: 1;
    > span {
        display: block;
    }
}
.recap-button {
    flex: 0 0 auto;
}
</style>
