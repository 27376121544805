<!-- THIS CODE WAS COPIED FROM CARON TONY PERSONAL REPOSITORY -->
<!-- CARON TONY ALLOW HEYTEAM TO USE IT, MODIFY OR SELL IT WITHIN IT'S APPLICATIONS BUT NOT AS A STANDALONE PRODUCT -->
<!-- CARON TONY HAVE FULL RIGHTS ON THIS CODE AND HEYTEAM CANT CLAIM SOMETHING ABOUT THIS -->
<!-- THIS CODE CAN BE PORTED TO OPEN SOURCE ONLY BY CARON TONY -->
<!-- THIS CODE WAS INITIALY CREATED BY CARON TONY : december 2016 -->
<template>
    <div
        class="main_container croppable-wrapper"
        :class="{'no-bg': noBg}"
        @click="onOpenFileSelector"
        @dragover.prevent
        @drop="onDrop"
    >
        <form
            ref="fileSelectorForm"
            class="fileSelectorForm"
        >
            <input
                ref="fileSelector"
                type="file"
                accept="image/*"
                @change="onChange"
            >
        </form>

        <label
            v-if="!image && showLabel"
            class="btn display-inline"
            style="display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;"
        >
            <t>SELECT OR DROP AN IMAGE</t>
        </label>

        <canvas
            ref="origin"
            class="canvas"
            style="display:none"
        />

        <div
            class="canvas_show_container"
            :class="{saving:saving}"
        >
            <div :style="`border-radius:${radius}px;width:${show_width}px; height:${show_height}px; position:relative; overflow:hidden;`">
                <canvas
                    :ref="actived_first_canvas_show? 'canvas_show_active' : 'canvas_show_inactive'"
                    class="canvas show_1"
                    :class="actived_first_canvas_show? 'canvas_show_active' : 'canvas_show_inactive'"
                />
                <canvas
                    :ref="!actived_first_canvas_show? 'canvas_show_active' : 'canvas_show_inactive'"
                    class="canvas show_2"
                    :class="!actived_first_canvas_show? 'canvas_show_active' : 'canvas_show_inactive'"
                />
            </div>
        </div>

        <canvas
            ref="scaled"
            class="canvas"
            style="display:none"
        />

        <canvas
            ref="final"
            class="canvas"
            style="display:none"
        />
    </div>
</template>

<script>
export default {
    name: 'Croppable',

    props: {
        showWidth: { type: Number, default: 250 },
        showHeight: { type: Number, default: 250 },
        destWidth: { type: Number, default: 250 },
        destHeight: { type: Number, default: 250 },
        image: { type: String, default: null },
        saveUrl: { type: String, default: null },
        radius: { type: Number, default: 0 },
        autoScale: { type: Boolean, default: false },
        showLabel: { type: Boolean, default: true },
        noBg: { type: Boolean, default: false },
    },

    data() {
        return {

            debug: true,
            show_width: 475, // TODO
            show_height: 225, // TODO
            img_orig_width: 0,
            img_orig_height: 0,
            img_dest_width: 250,
            img_dest_height: 250,
            img_type: '',

            current_show_canvas: 1,
            class_1: { class: '' }, // to allow ref
            class_2: { class: '' }, // to allow ref

            config: {
                center: true,
            },

            actived_first_canvas_show: true, // dynamic ref swapping

            uploadInfo: {},

            uploadObj: null,

            uploadInfoTpl: {
                name: '',
                type: '',
                loaded: 0,
                total: 0,
                percent: 0,
            },

            saving: false,
        };
    },

    mounted() {
        // Init user data
        this.show_width = this.showWidth;
        this.show_height = this.showHeight;

        this.img_dest_width = this.destWidth;
        this.img_dest_height = this.destHeight;

        // Init canvas
        let showCanvas = this.getNextShowCanvas();
        showCanvas.width = this.show_width;
        showCanvas.height = this.show_height;

        showCanvas = this.getCurrentShowCanvas();
        showCanvas.width = this.show_width;
        showCanvas.height = this.show_height;

        if (this.image) {
            const img = new Image();
            img.src = this.image;

            img.onload = () => {
                const scale = this.getMinScale(img.width, img.height, showCanvas.width, showCanvas.height);
                this.scaleInCanvas(img, showCanvas, scale);
            };
        }
    },

    methods: {
        onDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            const { files } = e.dataTransfer;
            this.createFile(files[0]);
        },

        onSave() {
            if (this.saving) return;
            this.saving = true;
            return new Promise((resolve, reject) => {
                const finalCanvas = this.$refs.final;
                const imageStr = finalCanvas.toDataURL(this.img_type, 0.8);

                this.cancelUpload();

                this.uploadInfo = { ...this.uploadInfoTpl };

                // this.uploadInfo.name = files[0].name;

                this.$emit('onUploadStart', this.uploadInfo);

                const fd = new FormData();
                fd.append('file', this.$Utils.dataURItoBlob(imageStr));

                this.uploadObj = this.API.post(this.saveUrl, fd, {
                    onUploadProgress: (progressEvent) => {
                        this.uploadInfo.total = progressEvent.total;
                        this.uploadInfo.loaded = progressEvent.loaded;
                        this.uploadInfo.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        this.$emit('onUploadProgress', this.uploadInfo);
                    },

                    onCancel: () => {
                        this.$emit('onUploadCancel', this.uploadInfo);
                        this.saving = false;
                        reject();
                    },

                    200: (data) => {
                        this.$emit('onUploadEnd', this.uploadInfo, data);
                        this.saving = false;
                        resolve();
                    },

                    // ERROR
                    onError: (data, code) => {
                        this.$emit('onUploadError', this.uploadInfo, data, code);
                        this.saving = false;
                        reject();
                    },
                });
            });
        },

        cancelUpload() {
            if (this.uploadObj) this.uploadObj.cancel();
        },

        onOpenFileSelector() {
            this.$refs.fileSelector.click();
        },

        onChange(e) {
            const { files } = e.target;
            this.createFile(files[0]);
        },

        createFile(file) {
            if (!file || !file.type.match('image.*')) {
                alert('Select an image');
                return;
            }
            this.img_type = file.type;

            const img = new Image();

            this.resetOrientation(file).then((base64) => {
                img.src = base64;

                img.onload = () => {
                    this.fitInContainer(img); // TODO THEN
                    this.$emit('onChange');
                };
            });
        },

        resetOrientation(file) {
            return new Promise((resolve, reject) => {
                this.fileToDataUrl(file).then((dataUrl) => {
                    resolve(dataUrl);
                }).catch((e) => reject(e));
            });
        },

        fileToDataUrl(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    resolve(e.target.result);
                };

                try {
                    reader.readAsDataURL(file);
                } catch (e) {
                    reject(e);
                }
            });
        },

        fileToArrayBuffer(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    resolve(e.target.result);
                };

                try {
                    reader.readAsArrayBuffer(file);
                } catch (e) {
                    reject(e);
                }
            });
        },

        fitInContainer(img) {
            // Original size
            const showCanvas = this.getNextShowCanvas();
            // let originCanvas = this.$refs.origin;
            const finalCanvas = this.$refs.final;
            const scaledCanvas = this.$refs.scaled;

            let scale;

            // Check if autoScale
            if (this.autoScale) {
                this.img_dest_width = Math.round(this.destWidth * (img.width / img.height));
            }

            // 1 get scale
            scale = this.getMaxScale(img.width, img.height, this.img_dest_width, this.img_dest_height);

            // 2 / Apply scale to destination canvas
            // this.applyScaleFrom(img, scaledCanvas, scale)
            this.resizeCanvas(scaledCanvas, img.width * scale, img.height * scale);

            // 3 Scale in scale canvas
            // Allow to have the full image with the good scale
            this.scaleInCanvas(img, scaledCanvas, scale, false);

            // 4 resize the final canvas
            this.resizeCanvas(finalCanvas, this.img_dest_width, this.img_dest_height);

            // Crop in the final canvas
            const { x, y } = this.getToCenterizeOffset(scaledCanvas.width, scaledCanvas.height, finalCanvas.width, finalCanvas.height);
            finalCanvas.getContext('2d').drawImage(scaledCanvas, x, y, this.img_dest_width, this.img_dest_height, 0, 0, this.img_dest_width, this.img_dest_height);

            scale = this.getMinScale(finalCanvas.width, finalCanvas.height, showCanvas.width, showCanvas.height);

            const currentShowCanvas = this.getCurrentShowCanvas();

            this.applyStartTransition(showCanvas, currentShowCanvas);
            this.scaleInCanvas(finalCanvas, showCanvas, scale, true);

            this.applyTransition(showCanvas, currentShowCanvas).then(() => {
                this.clearCanvas(currentShowCanvas);
                this.swapShowCanvas();
            });
        },

        getToCenterizeOffset(srcWidth, srcHeight, destWidth, destHeight) {
            const x = (srcWidth - destWidth) / 2;
            const y = (srcHeight - destHeight) / 2;
            return { x, y };
        },

        resizeCanvas(canvas, width, height) {
            canvas.width = width;
            canvas.height = height;
        },

        clearCanvas(canvas) {
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        },

        // Note : These are not sync with the vue vitual dom
        // When vue update element, These classes are lost
        applyStartTransition(newCanvas) {
            newCanvas.classList.add('effect_fade_start');
        },
        // Note : These are not sync with the vue vitual dom
        // When vue update element, These classes are lost
        applyTransition(newCanvas, oldCanvas) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    oldCanvas.classList.add('effect_fadeOut');
                    newCanvas.classList.add('effect_fadeIn');

                    // Counterpart, This allow effects only for 1.5s
                    setTimeout(() => {
                        resolve();
                    }, 1500);
                }, 15);
            });
        },

        _applyTransition() {
            let currentShowedClassPointer; let
                nextShowedClassPointer;

            currentShowedClassPointer.class = 'effect_fadeOut';
            nextShowedClassPointer.class = 'effect_fade_start';// effect_fadeIn
            setTimeout(() => {
                nextShowedClassPointer.class = 'effect_fade_start effect_fadeIn';
            }, 15);
        },

        scaleInCanvas(src, canvas, scale, center = false) {
            let x = 0;
            let y = 0;

            const scaledWidth = src.width * scale;
            const scaledHeight = src.height * scale;

            if (center) {
                x = (scaledWidth - canvas.width) / 2;
                y = (scaledHeight - canvas.height) / 2;
            }

            this.clearCanvas(canvas);

            /* if (0) {
                // TODO FORN NOW CANT CENTER IMAGE HERE
                let srcImgData = this.getImageData(src);
                let destImgData = canvas.getContext('2d').createImageData(canvas.width, canvas.height);
                this.applyBilinearInterpolation(srcImgData, destImgData, scale);
                canvas.getContext('2d').putImageData(destImgData, 0, 0);
            } else {
                canvas.getContext('2d').drawImage(src, 0, 0, src.width, src.height, -x, -y, scaledWidth, scaledHeight);
            } */

            canvas.getContext('2d').drawImage(src, 0, 0, src.width, src.height, -x, -y, scaledWidth, scaledHeight);
        },

        // Return img / canvas raw data
        getImageData(src) {
            // We can only read data from a canvas
            if (src.nodeName !== 'CANVAS') {
                let data = '';
                const tmpCanvas = document.createElement('CANVAS');
                tmpCanvas.width = src.width;
                tmpCanvas.height = src.height;
                tmpCanvas.getContext('2d').drawImage(src, 0, 0);
                data = tmpCanvas.getContext('2d').getImageData(0, 0, tmpCanvas.width, tmpCanvas.height);
                return data;
            }

            return src.getContext('2d').getImageData(0, 0, src.width, src.height);
        },

        getCurrentShowCanvas() {
            return this.$refs.canvas_show_active;
        },

        getNextShowCanvas() {
            return this.$refs.canvas_show_inactive;
        },

        swapShowCanvas() {
            this.actived_first_canvas_show = !this.actived_first_canvas_show;
        },

        // Return the bigger scale between two image
        getMaxScale(srcWidth, srcHeight, destWidth, destHeight) {
            const scaleX = destWidth / srcWidth;
            const scaleY = destHeight / srcHeight;

            return Math.max(scaleX, scaleY);
        },

        // Return the smaller scale between two image
        getMinScale(srcWidth, srcHeight, destWidth, destHeight) {
            const scaleX = destWidth / srcWidth;
            const scaleY = destHeight / srcHeight;

            return Math.min(scaleX, scaleY);
        },

        removeFile() {
            this.image = '';
        },

        // https://stackoverflow.com/questions/10333971/html5-pre-resize-images-before-uploading/24775332
        applyBilinearInterpolation(srcCanvasData, finalCanvasData, scale) {
            function inner(f00, f10, f01, f11, x, y) {
                const un_x = 1.0 - x;
                const un_y = 1.0 - y;
                return (f00 * un_x * un_y + f10 * x * un_y + f01 * un_x * y + f11 * x * y);
            }
            let i; let
                j;
            let iyv; let iy0; let iy1; let ixv; let ix0; let
                ix1;
            let idxD; let idxS00; let idxS10; let idxS01; let
                idxS11;
            let dx; let
                dy;
            let r; let g; let b; let
                a;
            for (i = 0; i < finalCanvasData.height; ++i) {
                iyv = i / scale;
                iy0 = Math.floor(iyv);
                // Math.ceil can go over bounds
                iy1 = (Math.ceil(iyv) > (srcCanvasData.height - 1) ? (srcCanvasData.height - 1) : Math.ceil(iyv));
                for (j = 0; j < finalCanvasData.width; ++j) {
                    ixv = j / scale;
                    ix0 = Math.floor(ixv);
                    // Math.ceil can go over bounds
                    ix1 = (Math.ceil(ixv) > (srcCanvasData.width - 1) ? (srcCanvasData.width - 1) : Math.ceil(ixv));
                    idxD = (j + finalCanvasData.width * i) * 4;
                    // matrix to vector indices
                    idxS00 = (ix0 + srcCanvasData.width * iy0) * 4;
                    idxS10 = (ix1 + srcCanvasData.width * iy0) * 4;
                    idxS01 = (ix0 + srcCanvasData.width * iy1) * 4;
                    idxS11 = (ix1 + srcCanvasData.width * iy1) * 4;
                    // overall coordinates to unit square
                    dx = ixv - ix0;
                    dy = iyv - iy0;
                    // I let the r, g, b, a on purpose for debugging
                    r = inner(srcCanvasData.data[idxS00], srcCanvasData.data[idxS10], srcCanvasData.data[idxS01], srcCanvasData.data[idxS11], dx, dy);
                    finalCanvasData.data[idxD] = r;

                    g = inner(srcCanvasData.data[idxS00 + 1], srcCanvasData.data[idxS10 + 1], srcCanvasData.data[idxS01 + 1], srcCanvasData.data[idxS11 + 1], dx, dy);
                    finalCanvasData.data[idxD + 1] = g;

                    b = inner(srcCanvasData.data[idxS00 + 2], srcCanvasData.data[idxS10 + 2], srcCanvasData.data[idxS01 + 2], srcCanvasData.data[idxS11 + 2], dx, dy);
                    finalCanvasData.data[idxD + 2] = b;

                    a = inner(srcCanvasData.data[idxS00 + 3], srcCanvasData.data[idxS10 + 3], srcCanvasData.data[idxS01 + 3], srcCanvasData.data[idxS11 + 3], dx, dy);
                    finalCanvasData.data[idxD + 3] = a;
                }
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.main_container{
	xwidth: 400px;
	xoverflow: hidden;
	xheight: 200px;
	background: #B2B2B2;
	position: relative;
    &.no-bg {
        background: none;
    }

	.img_container{
		position:relative;
		width: 10000px;
		height: 1000px;
	}

	.show_1, .show_2{
		position: absolute;
	}

	.canvas_show_active{
		z-index: 1;
	}

	.canvas_show_inactive{
		z-index: 0;
	}

	.effect_fade_start{

		transition:all .0s;
		transform: scale(1.1);
		opacity: 0;
			filter: blur(20px);
	}

	.effect_fadeIn{
		transition:all .4s cubic-bezier(0.4, 0, 0.2, 1);;

		opacity: 1;
		transform: scale(1);
			filter: blur(0px);
	}

	.effect_fadeOut{
		transition:all .3s;
		opacity: 0;
	}
}

.fileSelectorForm{
	position: absolute;
	left: -100000;
	visibility: hidden;
}

.canvas{
	display:block;
	margin:0 auto;
}

.canvas_show_container.saving:before{
	background: url(/static/Spinner.gif) 50% 50% no-repeat #b2b2b2;
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	text-align: center;
	left: 0;
	top: 0;
	z-index: 2;
	opacity: 0.8;
}
</style>
