<template>
    <div class="list-tasks">
        <template v-if="companyPlanningEvents && (!loading || tasksByWeek.length)">
            <div
                v-if="tasksByWeek.length"
                class="list-tasks-timeline"
            >
                <div
                    class="list-tasks-content"
                >
                    <div class="title">
                        {{ getTitleWeek(tasksToShow.week) }}
                    </div>
                    <TasksListRecap
                        :tasks="tasksToShow.tasks"
                        :events="tasksToShow.events"
                        :select-mode="showMultiSelect"
                        :rest-to-load="0"
                        :loading="loading"
                        @loadMore="getUserProgramTasks"
                        @select="selectTasks"
                        @update="onUpdate"
                        @updateStatus="onUpdateTask"
                        @delete="deleteTask"
                    />
                </div>
            </div>
            <div
                v-else
                class="list-tasks-no-task"
            >
                <span class="no-tasks-icon">🙌</span>
                <span class="ht-heading-3"><t>You are up to date on this week's actions</t></span>
                <HtButton
                    class="no-tasks-btn"
                    size="large"
                    type="primary"
                    :to="{
                        label: 'Programs',
                        name: 'ProfileProgram',
                        params: {
                            company_user_id: shared.session.companyUser.id,
                        },
                    }"
                >
                    <t>Discover my programs</t>
                </HtButton>
                <img
                    src="/static/images/no-tasks.png"
                    alt="No tasks placeholder"
                    class="no-tasks-placeholder"
                >
            </div>
        </template>
        <div v-else>
            <HtIconLoading />
        </div>
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import resourceEventBus from '@/eventBus/resourceEventBus';
import api from '@/store/api';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import TasksListRecap from '@/components/pages/dashboard/recap/TasksListRecap.vue';
import listTasksMixin from '@/components/pages/actions/all/listTasksMixin';

export default {
    name: 'ActionsRecap',
    components: {
        TasksListRecap,
        HtButton,
    },
    mixins: [listTasksMixin],
    props: {
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedTasks: {
            type: Array,
            required: true,
        },
    },
    shared: {
        dashboard: {
            removeIncrementEvent: {
                type: Number,
                default: 0,
            },
        },
    },
    data() {
        return {
            userProgramTasks: [],
            loading: false,
            currentPage: 1,
            restToLoad: 0,
            companyPlanningEvents: null,
        };
    },
    computed: {
        isOnboardee() {
            return this.shared.session.companyUser.is_onboardee;
        },
        tasksToShow() {
            return this.tasksByWeek[0];
        },
        tasksByWeek() {
            const tasksByWeek = [];
            const now = this.$Utils.moment();
            let lastWeekLoaded = 0;
            for (let i = 0; i < this.userProgramTasks?.length; i += 1) {
                const task = this.userProgramTasks[i];
                const taskDate = this.$Utils.moment(task.datetime_end);
                const taskWeek = taskDate < now ? 0 : taskDate.diff(now.startOf('isoWeek'), 'week');
                if (!tasksByWeek[taskWeek]) {
                    tasksByWeek[taskWeek] = {
                        week: taskWeek,
                        events: [],
                        tasks: [],
                    };
                }

                // if the task is pending, has dependencies and no parents
                // we get all their dependencies
                if (!this.hasParentPendingTask(task)
                    && task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.dependencies?.length) {
                    tasksByWeek[taskWeek].tasks.push(this.getAllDependentTasks(task));

                // if the task has a pending parent it should be carried by the parent
                } else if (!this.hasParentPendingTask(task)) {
                    tasksByWeek[taskWeek].tasks.push(task);
                }

                // gets last week for events loop
                lastWeekLoaded = taskWeek;
            }

            // get related events for each week
            for (let i = 0; i <= lastWeekLoaded; i += 1) {
                const eventsWeekI = this.companyPlanningEvents
                    .filter((event) => this.$Utils.moment(event.task.datetime_start).format('YYYYWW') === this.$Utils.moment().add(i, 'week').format('YYYYWW'));
                if (eventsWeekI?.length) {
                    if (!tasksByWeek[i]) {
                        tasksByWeek[i] = {
                            week: i,
                            events: [],
                        };
                    }
                    tasksByWeek[i].events = eventsWeekI;
                }
            }
            return Object.values(tasksByWeek).map((taskOneWeek) => ({
                ...taskOneWeek,
                tasks: this.handleTasks(taskOneWeek.tasks),
            }));
        },
    },

    watch: {
        // if selectedTasks list is emptied, we update all the tasks previously selected
        selectedTasks(newList, oldList) {
            if (!newList.length && oldList.length) {
                for (let i = 0; i < oldList.length; i += 1) {
                    this.updateTask(oldList[i]);
                }
            }
        },
    },

    created() {
        const data = {
            yearWeek: this.$Utils.moment().format('YYYYWW'),
            withoutDelegated: true,
        };

        api.user.planningEvents.getAllByUser(this.shared.session.companyUser.id, data).then((response) => {
            this.companyPlanningEvents = response.data.data;
            this.getUserProgramTasks();
        });

        resourceEventBus.$on('survey-done', (surveyId) => {
            const surveyTask = this.userProgramTasks.find((task) => task.resource === 'company_survey'
                && task.resource_id === surveyId);
            this.updateTask(surveyTask.id);
        });

        resourceEventBus.$on('nudge-accepted', (taskId) => {
            this.updateTask(taskId);
        });
    },

    methods: {
        onUpdate(taskId) {
            this.getUserProgramTasks(true);
            this.$emit('tasksUpdate');
            this.updateTask(taskId);
        },

        onUpdateTask({ taskId, status }) {
            this.getUserProgramTasks(true);
            this.$emit('tasksUpdate');
            this.updateTaskStatus({ taskId, status });
        },

        getTitleWeek(index) {
            switch (index) {
            case 0:
                return this.translate('This week');
            case 1:
                return this.translate('The following week');
            default:
                return this.translate('In {index} weeks', { index });
            }
        },
        async getUserProgramTasks(refresh = false) {
            this.loading = true;

            if (refresh) {
                this.currentPage = 1;
            }

            await this.$store.dispatch('tasks/fetchCurrentTasks', { params: { page: this.currentPage } })
                .then((result) => {
                    this.userProgramTasks = refresh ? this.userProgramTasks = result.data.data : this.userProgramTasks.concat(result.data.data);
                    this.currentPage = result.data.meta.current_page + 1;
                    const nbTasksLoaded = (result.data.meta.current_page
                        * result.data.meta.per_page);
                    this.restToLoad = result.data.meta.total - nbTasksLoaded;
                    this.loading = false;
                });
        },
        selectTasks(payload) {
            this.$emit('select', payload);
        },
    },
};
</script>

<style lang="scss" src="../listTasks/tasksList.scss" scoped>
