<template>
    <div
        class="ht-emoji-icon"
        :class="theme"
        :style="{ width: `${size}px`, height: `${size}px`, fontSize: `${fontSize}px` }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: '40',
        },
        fontSize: {
            type: String,
            default: '18',
        },
        theme: {
            type: String,
            default: 'secondary',
        },
    },
};
</script>
<style lang="scss" scoped>
.ht-emoji-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    line-height: 1;
    &.primary {
        background-color: var(--fill-primary);
    }
    &.secondary {
        background-color: var(--fill-secondary);
    }
    &.blue {
        background-color: #D9EEFF;
    }
}
</style>
