var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.permissionsLoaded)?_c('div',[(!_vm.isOldUi)?_c('div',[(_vm.companyRoleUserCollection.isLoaded())?[_c('NewTeamList',{attrs:{"users":_vm.users}})]:_c('HtIconLoading')],2):_c('div',[(_vm.companyRoleUserCollection.isLoaded())?[(!_vm.isEdit)?[(_vm.getUserTeam.length)?_c('div',{staticClass:"card-list flex-container card-onboarding onboarding-team"},_vm._l((_vm.getUserTeam),function(item,index){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(item.user.firstname + ' ' + item.user.lastname),expression:"item.user.firstname + ' ' + item.user.lastname"}],key:`current-${index}`,staticClass:"item transparent user-selected",on:{"click":function($event){return _vm.$router.push(`/Profile/${item.user.id}`)}}},[_c('div',{key:`${item.company_user_id}-${index}`,staticClass:"item-content"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.user.firstname)+" "),_c('div',{staticClass:"team-role"},[_vm._v(" "+_vm._s(item.role)+" ")]),(_vm.hasRelevantActiveDelegation(item))?_c('div',[_c('FontAwesomeIcon',{attrs:{"icon":"info-circle"}}),_c('t',[_vm._v("Active delegation")])],1):_vm._e()]),_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:({ image: item.user.image,
                                                firstname: item.user.firstname,
                                                lastname: item.user.lastname,
                                                size: 5}),expression:"{ image: item.user.image,\n                                                firstname: item.user.firstname,\n                                                lastname: item.user.lastname,\n                                                size: 5}"}],staticClass:"item-img full"}),_c('div',{staticClass:"team-member-overlay"})])])}),0):_c('div',{staticClass:"empty-component mb-30"},[_c('strong',[_c('t',[_vm._v("No supervisors on this user")])],1)])]:[_c('div',{staticClass:"profile-onboarding-team-body"},[_c('div',{staticClass:"flex-container card-onboarding onboarding-team"},_vm._l((_vm.companyRoleUserCollection.models),function(item,index){return _c('div',{key:`change-${index}`,class:['item transparent', {'user-selected': item.company_user_id}],on:{"click":function($event){return _vm.onClick(item)}}},[(!item.company_user_id)?_c('div',{key:index,staticClass:"item-content"},[_vm._m(0,true),(item.role)?_c('div',{staticClass:"item-title"},[_c('span',[_vm._v(_vm._s(item.role.alias_translated))])]):_vm._e(),_c('div',{staticClass:"item-img"})]):_c('div',{key:`${item.company_user_id}-${index}`,staticClass:"item-content"},[_c('div',{staticClass:"icons-wrapper"},[_c('HtButton',{staticClass:"icon-button",attrs:{"type":"","size":"small"}},[_c('IconChange',{staticClass:"change"})],1),_c('HtButton',{staticClass:"icon-button",attrs:{"type":"","size":"small"},nativeOn:{"click":function($event){return _vm.remove($event, item)}}},[_c('FontAwesomeIcon',{staticClass:"delete",attrs:{"icon":"trash"}})],1)],1),_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.user.firstname)+" "),(item.role)?[_c('div',{staticClass:"team-role"},[_vm._v(" "+_vm._s(item.role.alias_translated)+" ")])]:_vm._e()],2),_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:({
                                        image: item.user.image,
                                        firstname: item.user.firstname,
                                        lastname: item.user.lastname,
                                        size: 5}),expression:"{\n                                        image: item.user.image,\n                                        firstname: item.user.firstname,\n                                        lastname: item.user.lastname,\n                                        size: 5}"}],staticClass:"item-img full"}),_c('div',{staticClass:"team-member-overlay"})])])}),0)])]]:[_c('IconLoading')]],2)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-wrapper"},[_c('span',{staticClass:"icon icon-plus"},[_vm._v("+")])])
}]

export { render, staticRenderFns }