<template>
    <div>
        <template v-if="isDelegated">
            <div v-if="companyUserRequirement.company_user_id !== shared.session.companyUser.id">
                <NewUploadBar
                    v-model="companyUserRequirement.company_file_secondary"
                    :media="['office', 'pdf', 'image']"
                    :disabled="!isParticipant || isDisabled"
                    :is-requirement="true"
                    cypress="requirement-file-to-sign"
                    @input="onUploadEnd"
                />
            </div>
            <div v-else>
                <t>Waiting for file</t>
            </div>
        </template>
        <template v-else>
            <NewDownloadable
                :company-file="getCorrectFile"
                :is-requirement="true"
            />
            <div class="mt-3">
                <HtButton
                    v-if="showSignButton"
                    type="branding"
                    :data-cy="'sign-button'"
                    :disabled="isDisabled"
                    :loading="buttonState === 'loading'"
                    @click.native="onSign"
                >
                    <t>Sign</t>
                </HtButton>
                <div v-else-if="hasCurrentUserSigned">
                    <t>Already signed</t>
                </div>
            </div>
            <div
                v-if="!isSignaturesComplete && nbSignatureTotal > 0"
                class="mt-3"
            >
                <HtTextDescription :text="getSignatureText" />
            </div>
            <div
                v-if="isHellosignProcessing"
                class="mt-3"
            >
                {{ getPendingText }}
            </div>
        </template>
        <IframeContainer
            v-show="showSignIframe"
            :src="iframeSrc"
            @onClose="closeIframe"
        />
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import HtTextDescription from '@/components/globals/HtTextDescription.vue';
import IframeContainer from '@/components/globals/containers/IframeContainer.vue';
import CompanyResourceProgramParticipant from '@/models/CompanyResourceProgramParticipant';
import HellosignHelper from '@/classes/ThirdParty/HellosignHelper';
import NewUploadBar from '@/components/globals/UploadBar/NewUploadBar.vue';
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementFileToSignEdit',
    components: {
        HtButton,
        NewUploadBar,
        HtTextDescription,
        IframeContainer,
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        participants: {
            type: Array,
            required: true,
        },
        isParticipant: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        signerTask: {
            type: CompanyUserProgramTask,
            default: null,
        },
        requirementHasChanged: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            recentlySigned: false,
            buttonState: 'idle',
            iframeSrc: '',
            showSignIframe: false,
        };
    },

    computed: {
        signOrderKey() {
            return this.useDocusign ? 'routing_order' : 'order';
        },

        hideSignButton() {
            return (
                this.companyUserRequirement.is_delegated
                || this.isSignaturesComplete
                || this.hasCurrentUserSigned
                || !this.canCurrentUserSign
            );
        },

        showSignButton() {
            return !this.hideSignButton;
        },

        isDelegated() {
            return !this.companyUserRequirement.company_file_secondary_id && !this.requirementHasChanged;
        },

        getSignatureRequest() {
            return this.companyUserRequirement.config_value?.signature_request;
        },

        isSignaturesComplete() {
            if (!this.getSignatureRequest) {
                return false;
            }

            return this.getSignatureRequest.signers.every(
                (signer) => signer.status_code === this.signedStatus,
            );
        },

        nbSignatureTotal() {
            return this.getSignatureRequest?.signers.length;
        },

        nbSignatureDone() {
            return this.getSignatureRequest?.signers.filter(
                (signer) => signer.status_code === this.signedStatus,
            ).length;
        },

        canDownload() {
            return 'company_file_id' in this.companyUserRequirement && this.companyUserRequirement.company_file_id !== null && typeof this.companyUserRequirement.company_file_id !== 'undefined';
        },

        getCorrectFile() {
            if (this.canDownload) {
                return this.companyUserRequirement.company_file;
            }
            return this.companyUserRequirement.company_file_secondary;
        },

        getSignatureText() {
            return `${this.nbSignatureDone} / ${
                this.nbSignatureTotal
            } ${this.translate('completed signature | completed signatures', {
                count: this.nbSignatureTotal,
            })}`;
        },

        isHellosignProcessing() {
            return (
                this.isSignaturesComplete && !this.canDownload
            );
        },

        canCurrentUserSign() {
            return (!this.getSignatureRequest && this.currentUserParticipantType === CompanyResourceProgramParticipant.TYPE_EXECUTOR) || this.getSignatureRequest?.signers?.some(
                (signer) => parseInt(signer.user_id, 10) === parseInt(this.shared.session.companyUser.id, 10)
                    && parseInt(signer[this.signOrderKey], 10) === parseInt(this.nextSignerRoutingOrder, 10),
            );
        },

        currentUserParticipantType() {
            return this.participants.find(
                (participant) => participant.company_user_id === this.shared.session.companyUser.id
                    && participant.taskStatus !== CompanyUserProgramTask.STATUS_AWAITING,
            )?.type;
        },

        nextSignerRoutingOrder() {
            return parseInt(this.getSignatureRequest.signers
                .filter((signer) => signer.status_code !== this.signedStatus)
                .reduce(
                    (minSigner, signer) => (parseInt(signer[this.signOrderKey], 10) < parseInt(minSigner[this.signOrderKey], 10) ? signer : minSigner),
                )[this.signOrderKey], 10);
        },

        hasCurrentUserSigned() {
            if (this.recentlySigned) {
                return true;
            }

            if (!this.getSignatureRequest) {
                return false;
            }

            return this.getSignatureRequest.signers.some(
                (signer) => parseInt(signer.user_id, 10) === this.shared.session.companyUser.id
                    && signer.status_code === this.signedStatus,
            );
        },

        getPendingText() {
            return this.translate(
                'It may take few minutes to visualize the document',
            );
        },

        getHelloSignConfig() {
            return this.$store.getters['config/getHelloSignConfig'];
        },

        useDocusign() {
            return this.companyUserRequirement.config_type.includes(
                'HtDocuSign',
            );
        },

        signedStatus() {
            return this.useDocusign
                ? 'completed'
                : 'signed';
        },
    },

    methods: {
        onUploadEnd() {
            let checkChanged = false;

            if (
                this.companyUserRequirement.company_file_secondary
                !== this.requirement.company_file_secondary
            ) {
                this.companyUserRequirement.company_file_secondary_id = this.companyUserRequirement.company_file_secondary.id;
                checkChanged = this.companyUserRequirement.company_file_secondary !== null
                    && this.companyUserRequirement.company_file_secondary.id !== null;
            }

            this.updateRequirement();
            this.$emit(
                'onChangeValue',
                this.companyUserRequirement.id,
                checkChanged,
            );
        },

        onSign() {
            this.buttonState = 'loading';
            if (
                this.companyUserRequirement.config_type.includes('HtDocuSign')
            ) {
                const domain = window.location.origin;

                this.$http
                    .post('miscellaneous/docusign/getsignurl', {
                        user_requirement_id: this.companyUserRequirement.id,
                        domain,
                    })
                    .then((response) => {
                        this.iframeSrc = response.data.redirect_url;
                        this.setIframeListener();
                        this.showSignIframe = true;
                        this.buttonState = 'idle';
                    });
            } else {
                const hellosignHelper = new HellosignHelper(
                    this.getHelloSignConfig.client_id,
                    this.companyUserRequirement,
                );

                hellosignHelper.sign().then(() => {
                    this.updateSignedRequirement();
                }).catch((error) => {
                    if (error.status === 409) {
                        this.updateSignedRequirement();
                    }
                }).finally(() => {
                    this.buttonState = 'idle';
                });
            }
        },

        updateSignedRequirement() {
            this.recentlySigned = true;
            this.updateRequirement();
            this.$emit(
                'onChangeValue',
                this.companyUserRequirement.id,
                true,
            );
        },

        closeIframe() {
            this.showSignIframe = false;
        },
        setIframeListener() {
            const callback = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (
                        data.from === 'DocusignRedirectedPage'
                        && data.to === 'UserRequirementFileToSignEdit'
                        && parseInt(data.requirementId, 10) === parseInt(this.companyUserRequirement.id, 10)
                    ) {
                        this.recentlySigned = true;
                        this.$emit(
                            'onChangeValue',
                            this.companyUserRequirement.id,
                            true,
                        );
                        window.removeEventListener('message', callback, false);
                    }
                } catch {
                    // We use a try catch here to avoid poping error on JSON.parse(event.data)
                    // Because the event listener could catch incompatible messages during his short lifetime
                }
            };
            window.addEventListener('message', callback, false);
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementFileToSignEdit.scss' scoped />
