<template>
    <div v-if="companyUserEquipmentCollection.isLoaded()">
        <div

            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#E3DCFF' }"
                >
                    <HtIcon
                        name="computer"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#51397A' }"
                    />
                </HtEmojiIcon>
                {{ translate('Equipments') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ companyUserEquipmentCollection.models.length }}
                </HtBadge>
                <HtIcon
                    v-if="companyUserEquipmentCollection.models.length"
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <button
                    v-for="item in companyUserEquipmentCollection.models"
                    :key="item.id"
                    class="item"
                    @click="openItemModal(item.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ item.name }}
                        </div>
                    </div>
                    <HtAvatarGroup
                        v-if="item.company_user"
                        size="s"
                        :users="$Utils.getAvatarGroupData([item.company_user])"
                    />

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[item.company_user_program_task.status]" />
                        <t>
                            {{ item.company_user_program_task.statusText }}
                        </t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>

        <Modalable
            ref="modalableItem"
            :mode="2"
            class="modalable-1 small"
        >
            <NewUserEquipmentItem
                :id="id"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUserEquipmentCollection from '@/models/CompanyUserEquipmentCollection';
import NewUserEquipmentItem from '@/components/pages/dashboard/modals/equipment/NewUserEquipmentItem.vue';

export default {
    name: 'NewUserEquipmentProfile',
    components: {
        NewUserEquipmentItem,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            open: false,
            badgeStates: {
                done: 'success',
                pending: 'neutral',
                draft: 'warning',
                cancelled: 'error',
            },
            id: null,
            companyUserEquipmentCollection: new CompanyUserEquipmentCollection([
                'id', 'company_user_id', 'company_equipment_id',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyEquipment: (query) => {
                    query.select(['id'])
                        .with({
                            resource: (query) => {
                                query.select([
                                    'id',
                                    'name',
                                ]);
                            },
                        })
                        .withTrashed();
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'status'])
                        .where([
                            ['resource', '=', 'company_equipment'],
                        ]);
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]),
        };
    },

    created() {
        this.companyUserEquipmentCollection.get().then(() => {
            this.$emit('on-load', this.companyUserEquipmentCollection.$.models.length);
        });
    },

    methods: {
        toggle() {
            if (this.companyUserEquipmentCollection.models.length) this.open = !this.open;
        },
        onDelete() {
            this.companyUserEquipmentCollection.get();
        },
        onUpdate() {
            this.companyUserEquipmentCollection.get();
        },
        openItemModal(id) {
            this.id = id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>
<style lang="scss" scoped src="../ressourceItem.scss" />
