<template>
    <div
        class="ht-avatar"
        :class="[`size-${size}`, {'has-initials': !user.image}]"
        :style="{backgroundImage: bgImageStyle}"
    >
        <span v-if="!user.image">{{ initials }}</span>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: 'm',
            validator(value) {
                return ['xs', 's', 'm', 'l', 'xl', 'xxl'].includes(value);
            },
        },
    },
    computed: {
        initials() {
            return [this.user.firstname, this.user.lastname]
                .filter(Boolean)
                .map((s) => s[0].toUpperCase())
                .join('');
        },
        bgImageStyle() {
            return this.user.image ? `url(${this.user.image})` : 'none';
        },
    },
};
</script>

<style lang="scss" scoped>
.ht-avatar {
    border-radius: 100%;
    background-size: cover;
    background-position: center center;
    &.has-initials {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #710E21;
        background-color: #F8C9D2;
        letter-spacing: -0.01em;
    }
    &.size-xs {
        width: 16px;
        height: 16px;
        font-size: 8px;
        line-height: 16px;
        font-weight: 600;
    }
    &.size-s {
        width: 24px;
        height: 24px;
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
    }
    &.size-m {
        width: 40px;
        height: 40px;
        font-size: 16px;
        line-height: 24px;
    }
    &.size-l {
        width: 56px;
        height: 56px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }
    &.size-xl {
        width: 90px;
        height: 90px;
        font-size: 24px;
        line-height: 50px;
        letter-spacing: -0.02em;
    }
    &.size-xxl {
        width: 160px;
        height: 160px;
        font-size: 35px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }
}
</style>
