<template>
    <div class="ht-dropdown-group">
        <div
            v-if="heading"
            class="ht-dropdown-group-heading"
        >
            {{ heading }}
        </div>
        <slot />
        <div
            v-if="divider"
            class="ht-dropdown-divider"
        />
    </div>
</template>

<script>
export default {
    props: {
        heading: {
            type: String,
            required: false,
            default: '',
        },
        divider: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-dropdown-group-heading {
    padding: 4px 16px;
    color: var(--text-secondary);
    @include ht-caption;
}

.ht-dropdown-divider {
    position: relative;
    width: 100%;
    height: 8px;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 3.5px;
        height: 1px;
        background-color: var(--border-primary);
    }
}
</style>
