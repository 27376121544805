<template>
    <div>
        <NewMultipleFilesUpload
            :item="requirement"
            :media="requirement.media"
            :show-media-legend="true"
            :max-size="requirement.file_max_size"
            :show-file-size-legend="requirement.file_max_size !== null"
            :disabled="isNotParticipant || isDisabled"
            cypress="requirement-company-file"
            :is-requirement="true"
            @update:company-files="onUpdateCompanyFiles"
            @onUploadEnd="onUploadEnd"
            @onRemove="onRemove"
        />
        <HtFormInput
            :id="requirement.name"
            :value="getValue"
            :name="requirement.name"
            :cypress="requirement.name"
            :is-required="requirement.is_mandatory"
            :class="'m-1'"
            :type="'hidden'"
        />
    </div>
</template>

<script>
import HtFormInput from '@/components/globals/HtFormInput.vue';

export default {
    name: 'NewNotFileSetMode',

    components: {
        HtFormInput,
    },

    inject: ['$validator'],

    props: {
        requirement: {
            type: Object,
            required: true,
        },
        isParticipant: {
            type: Boolean,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        getValue() {
            return this.requirement.company_files.length > 0 ? 'test' : null;
        },

        isNotParticipant() {
            return this.isParticipant === false;
        },
    },

    methods: {
        onUpdateCompanyFiles(companyFiles) {
            this.requirement.company_files = companyFiles;

            if (companyFiles.length > 0) {
                this.onUploadEnd();
            }
        },

        onUploadEnd() {
            const filesUploaded = this.requirement.company_files.filter((file) => Boolean(file.id));

            this.$emit('on-upload-end', filesUploaded);
        },
        /**
         * @returns {void}
         */
        onRemove() {
            this.$emit('on-remove');
        },
    },
};
</script>

<style lang="scss"
    scoped
>

    @import '~@/styles/var';

    .error-message {
        font-size: 1.4rem;
        color: $primary-darker;
    }
</style>
