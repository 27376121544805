<template>
    <div>
        <HtTooltip
            inverted
            position="inline-end"
        >
            <router-link
                :to="item"
                :data-cy="'menu-'+item.name"
                class="mainbar-menu-item-link"
            >
                <HtIcon
                    :name="item.icon"
                    size="18"
                />
                <span class="mainbar-menu-item-label"><t>{{ item.label }}</t></span>
            </router-link>
            <template #target>
                {{ item.label }}
            </template>
        </HtTooltip>
    </div>
</template>

<script>

export default {
    name: 'HtMainBarMenuPage',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
