import Vue from 'vue';
import VueDragResize from 'vue-drag-resize';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import globals from '@/components/globals';
import icons from '@/components/icons';
import CountryFlag from 'vue-country-flag';
import MaskedInput from 'vue-text-mask';
import Multiselect from 'vue-multiselect';
import Flash from '@/components/Flash.vue';

import HtIcon from '@/components/HtIcon.vue';
import HtAvatar from '@/components/miscellaneous/HtAvatar.vue';
import HtAvatarGroup from '@/components/miscellaneous/HtAvatarGroup.vue';
import HtDropdown from '@/components/miscellaneous/HtDropdown/HtDropdown.vue';
import HtDropdownGroup from '@/components/miscellaneous/HtDropdown/HtDropdownGroup.vue';
import HtDropdownItem from '@/components/miscellaneous/HtDropdown/HtDropdownItem.vue';
import HtTooltip from '@/components/miscellaneous/HtTooltip.vue';
import HtBadge from '@/components/miscellaneous/HtBadge.vue';
import HtEmojiIcon from '@/components/miscellaneous/HtEmojiIcon.vue';
import HtRecap from '@/components/miscellaneous/HtRecap.vue';
import HtSelect from '@/components/miscellaneous/HtSelect.vue';
import HtIconLoading from '@/components/miscellaneous/HtIconLoading.vue';
import HtIndicator from '@/components/miscellaneous/HtIndicator.vue';
import HtTextField from '@/components/miscellaneous/HtTextField.vue';

Vue.component('VueDragResize', VueDragResize);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
// global components
globals.map(([tag, component]) => Vue.component(tag, component));
icons.map(([tag, component]) => Vue.component(tag, component));

Vue.component('CountryFlag', CountryFlag);
Vue.component('MaskedInput', MaskedInput);
Vue.component('Multiselect', Multiselect);
Vue.component('Flash', Flash);

Vue.component('HtIcon', HtIcon);
Vue.component('HtAvatar', HtAvatar);
Vue.component('HtAvatarGroup', HtAvatarGroup);
Vue.component('HtDropdown', HtDropdown);
Vue.component('HtDropdownGroup', HtDropdownGroup);
Vue.component('HtDropdownItem', HtDropdownItem);
Vue.component('HtTooltip', HtTooltip);
Vue.component('HtBadge', HtBadge);
Vue.component('HtEmojiIcon', HtEmojiIcon);
Vue.component('HtRecap', HtRecap);
Vue.component('HtSelect', HtSelect);
Vue.component('HtIconLoading', HtIconLoading);
Vue.component('HtIndicator', HtIndicator);
Vue.component('HtTextField', HtTextField);
