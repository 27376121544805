<template>
    <div class="add-element-bar">
        <h2 class="bar-title">
            <t v-if="title">
                {{ title }}
            </t>
        </h2>

        <div
            v-if="hasAddButton"
            class="bar-item right edit"
        >
            <div
                v-if="$slots.default"
                class="slot-row"
            >
                <slot />
            </div>
            <div
                v-else
                class="button branding-color"
                @click="$emit('click')"
            >
                <FontAwesomeLayers class="fa-lg">
                    <font-awesome-icon icon="circle" />
                    <font-awesome-icon
                        icon="plus"
                        transform="shrink-8"
                        class="color-white"
                    />
                </FontAwesomeLayers>
            </div>
        </div>
        <div
            v-else
            class="bar-item-placeholder"
        />
    </div>
</template>

<script>

export default {
    name: 'SectionBar',
    props: {
        title: { type: String, required: false },
        hasAddButton: { type: Boolean, default: false },
        buttonClass: { type: String, default: 'button-4 small' },
        buttonText: { type: String, required: false },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.add-element-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 0.5em;

	}
	.bar-title {
		font-family: $lato-bold;
        body:not(.old-ui) &{
            font-family: var(--poppins);
            font-weight: 600;
        }
		font-size: 24px;
		margin: 0;
	}

	.bop {
		.bar-title {
			font-size: 1.7rem;
		}
	}

	.slot-row {
		display: flex;

		> div {
			margin-left: 0.5em;
		}
	}

	.bar-item-placeholder {
		min-height: 21px;
	}

    .color-white {
        color: $color-white;
    }
</style>

<style lang="scss" scoped>
	.button {
		cursor: pointer;
	}

	.user-resources {
		.bar-title {
			font-size: 18px;
		}
    }
</style>
