<template>
    <div class="wrapper-main-footer">
        <template v-if="companyUserTraining.isLoaded() && !hasError">
            <div class="modal-wrapper-main">
                <div class="wrapper-description">
                    <DescLine v-if="shouldShowAvailabilityDate">
                        <template #title>
                            <t>Date</t>
                        </template>
                        <template #content>
                            {{
                                $Utils
                                    .moment(companyUserTraining.company_user_program_task.datetime_end)
                                    .format(
                                        (shared.session.companyUser.company_language.key === 'fr') ?
                                            'DD MMMM YYYY' : 'MMMM DD, YYYY'
                                    )
                            }}
                        </template>
                    </DescLine>
                    <DescLine>
                        <template #title>
                            <t>Status</t>
                        </template>
                        <template #content>
                            <StatusLabel
                                :status="companyUserTraining.company_user_program_task.status"
                            />
                        </template>
                    </DescLine>
                    <DescLine v-if="companyUserTraining.description">
                        <template #title>
                            <t>Description</t>
                        </template>
                        <template #content>
                            <p v-dompurify-html="companyUserTraining.description" />
                        </template>
                    </DescLine>
                    <DescLine v-if="companyUserTraining.specific_information">
                        <template #title>
                            <t>Specific information</t>
                        </template>
                        <template #content>
                            <p v-dompurify-html="companyUserTraining.specific_information" />
                        </template>
                    </DescLine>
                </div>
                <HtRecap v-if="companyUserTraining.type === 'link'">
                    <template #infos>
                        <t>Access to my training</t>
                    </template>
                    <template #button>
                        <HtButton
                            size="large"
                            type="branding"
                            :to="companyUserTraining.url"
                        >
                            <t>Access</t>
                        </HtButton>
                    </template>
                </HtRecap>
                <UserTrainingVideo
                    v-if="isVideo"
                    class="video"
                    :company_user_document_id="id"
                />
                <div
                    v-if="companyUserTraining.type === 'file'
                        && companyUserTraining.company_file
                        && isVideo === false"
                >
                    <NewDownloadable :company-file="companyUserTraining.company_file" />
                </div>
            </div>
            <div
                v-if="!isCancelled"
                class="modal-wrapper-footer"
            >
                <HtButton
                    v-if="companyUserTraining.permissions.delete"
                    type="secondary"
                    size="large"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </HtButton>
                <HtButton
                    v-if="companyUserTraining.permissions.update"
                    type="secondary"
                    size="large"
                    @click="edit()"
                >
                    <t>Edit</t>
                </HtButton>
                <HtButton
                    v-if="companyUserTraining.company_user_program_task.permissions.can_validate && canValidate"
                    type="branding"
                    size="large"
                    data-cy="training-toggle-status-button"
                    :loading="isButtonLoading"
                    @click="toggleStatus()"
                >
                    <t>{{ isDone ? 'Mark as undone' : 'Mark as done' }}</t>
                </HtButton>
            </div>

            <Modalable
                ref="modal"
                class="modalable-1 medium"
                :mode="2"
            >
                <UserTrainingEdit
                    :id="id"
                    :company-user-id="companyUserId"
                    :company-user-program-id="companyUserTraining.company_user_program_id"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    @onUpdate="onUpdate"
                    @onDelete="onRemove"
                />
            </Modalable>
        </template>
        <template v-if="hasError">
            <ErrorDisplay :append-errors="'This resource is not accessible'" />
        </template>
    </div>
</template>

<script>
import CompanyUserTraining from '@/models/CompanyUserTraining';
import UserTrainingVideo from '@/components/resources/training/UserTrainingVideo.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import DescLine from '@/components/pages/dashboard/modals/DescLine.vue';
import StatusLabel from '@/components/pages/dashboard/modals/StatusLabel.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';
import UserTrainingEdit from './UserTrainingEdit.vue';

export default {
    name: 'NewUserTrainingItem',
    components: {
        UserTrainingEdit,
        UserTrainingVideo,
        DescLine,
        StatusLabel,
        HtButton,
    },
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            hasError: false,
            isButtonLoading: false,

            companyUserTraining: new CompanyUserTraining([
                'id',
                'company_user_id',
                'company_user_program_id',
                'name',
                'description',
                'type',
                'specific_information',
                'company_file_id',
                'url',
                'permissions',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'arrival_date', 'firstname']);
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'arrival_date',
                        'status',
                        'permissions',
                        'company_user_id',
                        'resource',
                        'validator_type',
                    ]);
                },
                companyFile: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }).where([
                ['id', '=', this.id],
            ]),
        };
    },

    computed: {
        isVideo() {
            return this.companyUserTraining
                && this.companyUserTraining.company_file
                && this.companyUserTraining.company_file.detected_mime_type.startsWith('video');
        },

        /**
             * @returns {Boolean}
             */
        isDone() {
            return this.companyUserTraining.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        isCancelled() {
            return this.companyUserTraining.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    watch: {
        'companyUserTraining._state.isSaving': function (value) {
            this.isButtonLoading = !!value;
        },
    },

    created() {
        this.companyUserTraining.company_user_id = this.companyUserId;
        this.companyUserTraining.get().then(
            (companyUserTraining) => {
                this.modal.setTitle(companyUserTraining.$.name);
                this.modal.setSubtitle(this.translate('Training'));
            },
            () => this.hasError = true,
        );
    },

    methods: {
        edit() {
            this.$refs.modal.open();
        },

        async toggleStatus() {
            await this.$handleValidationTasks({
                id: this.companyUserTraining.company_user_program_task.id,
                status: this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
            });
            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this training? Once you click on delete, you will no longer be able to access this training.') }).then((result) => {
                if (result) {
                    this.companyUserTraining.delete().then(() => {
                        this.onRemove();
                    });
                }
            });
        },

        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onRemove() {
            this.modal.close();
            this.$emit('onDelete');
        },

        onTrainingLinkClick(urlToParse) {
            const url = new URL(urlToParse);
            const stg = url.searchParams.get('stg');

            if (stg) {
                this.$http.get(`users/${this.companyUserTraining.company_user_id}/trainings/mos-redirect?stg=${stg}`).then((response) => {
                    window.open(response.data.url, '_blank');
                });
            } else {
                window.open(url, '_blank');
            }
        },

        downloadFile(file) {
            file.download();
        },
    },
};
</script>
