import Card from './Card.vue';
import CardList from './CardList.vue';
import Checkable from './Checkable.vue';
import CrudCheckable from './CrudCheckable.vue';
import Confirm from './Confirm.vue';
import Deletable from './Deletable.vue';
import Downloadable from './Downloadable.vue';
import NewDownloadable from './NewDownloadable.vue';
import ErrorDisplay from './ErrorDisplay.vue';
import FilterableSearch from './FilterableSearch.vue';
import FilterableSelect from './FilterableSelect.vue';
import InputField from './InputField.vue';
import HtFormRange from './HtFormRange.vue';
import InputAvailability from './InputAvailability.vue';
import InputParticipants from './InputParticipants.vue';
import Modalable from './Modalable.vue';
import HtModalable from './HtModalable.vue';
import ModalableLarge from './ModalableLarge.vue';
import ParticipantList from './ParticipantList.vue';
import Popin from './Popin.vue';
import RichEditable from './RichEditable.vue';
import SearchRolePanel from './SearchRolePanel.vue';
import SectionBar from './SectionBar.vue';
import UploadBar from './UploadBar/UploadBar.vue';
import MultipleFilesUpload from './MultipleFilesUpload.vue';
import NewMultipleFilesUpload from './NewMultipleFilesUpload.vue';
import Uploadable from './Uploadable.vue';
import Button from './Button.vue';
import UserBlock from './UserBlock.vue';
import UserBlock2 from './UserBlock2.vue';
import ProgramResourceFilter from './ProgramResourceFilter.vue';
import LanguageSelector from './LanguageSelector.vue';
import Chronometer from './Chronometer.vue';
import VideoPlayer from './VideoPlayer.vue';
import HtCard from './HtCard.vue';
import HtFormCard from './HtFormCard.vue';
import HtFormGroup from './HtFormGroup.vue';
import HtFormInput from './HtFormInput.vue';
import HtFormMaskedInput from './HtFormMaskedInput.vue';
import HtFormEditor from './HtFormEditor.vue';
import HtAIFormEditor from './HtAIFormEditor.vue';
import HtFormFlatPickr from './HtFormFlatPickr.vue';
import HtFormTextarea from './HtFormTextarea.vue';
import HtFormSelect from './HtFormSelect.vue';
import HtFormMultiSelect from './HtFormMultiSelect.vue';
import HtFormCustomField from './HtFormCustomField.vue';
import HtFormCheckbox from './HtFormCheckbox.vue';
import HtTable from './HtTable.vue';
import HtTab from './HtTab.vue';
import HtTabs from './HtTabs.vue';
import HtTag from './HtTag.vue';
import HtBubbles from './HtBubbles.vue';
import HtBubble from './HtBubble.vue';
import HtSelectDateWithSelect from './HtSelectDateWithSelect.vue';
import NewHtModal from './NewHtModal.vue';
import HtModal from './HtModal.vue';
import HtModalBottom from './HtModalBottom.vue';
import ModalablePersist from './ModalablePersist.vue';
import HtList from './HtList.vue';
import HtPagination from './HtPagination.vue';
import StepperTabLayout from './StepperTabLayout.vue';
import HtLink from './HtLink.vue';

export default Object.entries({
    Card,
    CardList,
    Checkable,
    CrudCheckable,
    Confirm,
    Deletable,
    Downloadable,
    NewDownloadable,
    ErrorDisplay,
    FilterableSearch,
    FilterableSelect,
    InputField,
    HtFormRange,
    InputAvailability,
    InputParticipants,
    Modalable,
    HtModalable,
    ModalableLarge,
    ParticipantList,
    Popin,
    RichEditable,
    SearchRolePanel,
    SectionBar,
    UploadBar,
    MultipleFilesUpload,
    NewMultipleFilesUpload,
    Uploadable,
    Button,
    UserBlock,
    UserBlock2,
    ProgramResourceFilter,
    LanguageSelector,
    Chronometer,
    VideoPlayer,

    // Composants HeyTeam
    HtCard,
    HtFormCard,
    HtFormGroup,
    HtFormInput,
    HtFormMaskedInput,
    HtFormEditor,
    HtAIFormEditor,
    HtFormFlatPickr,
    HtFormTextarea,
    HtFormSelect,
    HtFormMultiSelect,
    HtFormCustomField,
    HtFormCheckbox,
    HtTable,
    HtTab,
    HtTabs,
    HtTag,
    HtBubbles,
    HtBubble,
    HtSelectDateWithSelect,
    NewHtModal,
    HtModal,
    HtModalBottom,
    ModalablePersist,
    HtList,
    HtPagination,
    StepperTabLayout,
    HtLink,
});
