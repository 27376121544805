<template>
    <IndicatorTemplate
        icon="⏳"
        :keyfigure="keyfigure"
        :label="label"
        :loading="loading"
        @click="goToPrograms"
    />
</template>

<script>
import IndicatorTemplate from '@/components/pages/piloting/widgets/IndicatorTemplate.vue';

export default {
    name: 'ActiveProgramsCountWidget',
    components: {
        IndicatorTemplate,
    },
    computed: {
        keyfigure() {
            return this.$store.getters['dashboard/statistics/activeProgramsCount'];
        },
        label() {
            return this.translate('live programs');
        },
        loading() {
            return !this.$store.state.dashboard.statistics.activeProgramsCountLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchActiveProgramsCount');
                }
            },
            immediate: true,
        },
    },
    methods: {
        goToPrograms() {
            this.$store.dispatch('dashboard/programs/clearAllFilters');
            this.$store.dispatch('dashboard/transferFiltersToPrograms');
            this.$router.push(
                {
                    name: 'DashboardPrograms',
                    query: {
                        status: ['active', 'late', 'invited', 'reminded'],
                    },
                },
            );
        },
    },
};
</script>
