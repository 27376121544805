<template>
    <div class="ht-sidebar">
        <div
            v-if="hasDefaultSlot"
            class="ht-sidebar-default"
        >
            <slot />
        </div>
        <div
            v-if="hasFooterSlot"
            class="ht-sidebar-footer"
        >
            <slot :name="'footer'" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewHtStickySidebar',
    computed: {
        hasDefaultSlot() {
            return 'default' in this.$slots;
        },
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/tokens";

.ht-sidebar {
    background-color: white;
    border-radius: var(--radius);
    &-default {
        padding: 25px;
    }
    &-footer {
        display: none;
    }
}

@media (min-width: $tablet-large) {
    .ht-sidebar {
        &-default {
            padding: 16px;
        }
        &-footer {
            display: block;
            padding: 0 16px 16px;
        }
    }
}
</style>
