<template>
    <div v-if="isLoaded">
        <h2 class="piloting-title">
            {{ translate('Piloting') }}
        </h2>
        <NewPageWithSidebar>
            <template #sidebar>
                <NewHtPageSidebar
                    :pages="filteredPages"
                />
            </template>

            <template>
                <router-view
                    :dashboards="metabaseDashboards"
                />
            </template>
        </NewPageWithSidebar>
    </div>
</template>

<script>

import NewHtPageSidebar from '@/components/globals/NewHtPageSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';
import SatismeterMixin from '@/components/mixins/SatismeterMixin';

const pilotingPermissionsEquivalent = {
    PilotingStatistics: 'DashboardStatistics',
    PilotingReport: 'DashboardReport',
    PilotingSpecificReports: 'DashboardSpecificReports',
    PilotingSurveyResults: 'SurveyResults',
    PilotingSurveyResultDetail: 'SurveyResultDetail',
};
export default {
    name: 'PagesDashboard',
    components: {
        NewHtPageSidebar,
        NewPageWithSidebar,
    },
    mixins: [
        SatismeterMixin,
    ],

    data() {
        return {
            isMetabaseLoaded: false,
            metabaseDashboards: [],
        };
    },

    computed: {

        filteredPages() {
            return this.pages.filter((p) => this.$can(`Page${pilotingPermissionsEquivalent[p.name]}`));
        },

        pages() {
            const pages = [
                {
                    name: 'PilotingStatistics',
                    label: this.translate('Overview'),
                },
                {
                    name: 'PilotingReport',
                    label: this.translate('Advanced Reports'),
                },
                { name: 'PilotingSurveyResults', label: this.translate('Surveys') },
            ];

            if (this.metabaseDashboards.length > 0) {
                return pages.concat([
                    {
                        name: 'PilotingSpecificReports',
                        label: this.translate('Specific reports'),
                    },
                ]);
            }

            return pages;
        },

        permissions() {
            const pagesPermissions = this.pages.map((p) => `Page${pilotingPermissionsEquivalent[p.name]}`);

            return [...pagesPermissions, 'PageDashboardSpecificReports'];
        },

        isLoaded() {
            return this.$store.getters['permissions/getPermissionsLoaded'](this.permissions) && this.isMetabaseLoaded;
        },
        todoCount() {
            return this.$store.getters['dashboard/statistics/todoCount'];
        },
    },

    watch: {
        $route() {
            this.redirect();
        },
    },

    created() {
        this.$loadPermissions(this.permissions).then(() => {
            this.redirect();

            if (this.$can('PageDashboardSpecificReports')) {
                this.$http.get('dashboard/report/metabase-dashboards').then((response) => {
                    this.metabaseDashboards = response.data.dashboards;

                    this.isMetabaseLoaded = true;
                });
            } else {
                this.isMetabaseLoaded = true;
            }
        });

        this.$store.dispatch('dashboard/statistics/fetchTodoCount');
    },

    methods: {
        redirect() {
            if (this.filteredPages.length > 0 && this.$route.name === 'Piloting') {
                this.$router.push({ name: this.filteredPages[0].name, query: this.$route.query });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.piloting-title {
    margin-bottom: 20px;
    @include ht-heading-2;
}
</style>
