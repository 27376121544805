<template>
    <button
        v-if="Object.keys(to).length === 0"
        class="ht-btn"
        role="button"
        :class="[size, type, { loading }]"
        :disabled="disabled"
        @click="onClick"
    >
        <div class="ht-button-inner">
            <slot />
        </div>
        <div class="loader">
            <HtIcon
                class="icon-loading"
                name="system-restart"
                :size="loadingSize"
                stroke-width="2"
            />
        </div>
    </button>
    <component
        :is="linkTag"
        v-else
        class="ht-btn"
        :to="linkTag === 'router-link' ? to : ''"
        :href="linkTag === 'a' ? to : ''"
        :target="linkTag === 'a' ? '_blank' : ''"
        :class="[size, type, { disabled }]"
        :disabled="disabled"
    >
        <div class="ht-button-inner">
            <slot />
        </div>
    </component>
</template>
<script>
export default {
    props: {
        size: {
            type: String,
            required: false,
            default: 'default',
            validator(value) {
                return ['default', 'large', 'small'].includes(value);
            },
        },
        type: {
            type: String,
            required: false,
            default: 'primary',
            validator(value) {
                return [
                    'primary',
                    'secondary',
                    'tertiary',
                    'destructive',
                    'branding',
                ].includes(value);
            },
        },
        to: {
            type: [Object, String],
            required: false,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        loadingSize() {
            return this.size === 'large' ? '24' : '16';
        },
        linkTag() {
            if (this.disabled) return 'button';
            if (
                typeof this.to === 'string'
                && (this.to.includes('http://') || this.to.includes('https://'))
            ) {
                return 'a';
            }
            return 'router-link';
        },
    },
    methods: {
        onClick(e) {
            if (!this.loading && !this.disabled) this.$emit('click', e);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";
.ht-btn {
    --ht-button-color: var(--text-primary);
    --ht-button-background: var(--fill-inversed-primary);
    position: relative;
    @include ht-body;
    transition: background-color var(--transition-s);
    text-decoration: none;
    color: var(--ht-button-color);
    &:is(a) {
        display: inline-flex;
    }
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: var(--ht-button-background);
        border-radius: var(--radius);
        box-shadow: var(--shadow-1);
        transition: transform 0.05s ease-in-out;
    }
    // TYPES
    &.primary {
        --ht-button-color: var(--text-inversed-primary);
    }
    &.secondary {
        --ht-button-background: var(--fill-primary);
        &::before {
            border: 1px solid var(--border-primary);
        }
    }
    &.tertiary {
        --ht-button-background: var(--fill-primary);
        &::before {
            box-shadow: none;
        }
    }
    &.destructive {
        --ht-button-background: var(--fill-error);
        --ht-button-color: var(--text-inversed-primary);
    }
    &.branding {
        --ht-button-background: var(--branding-color);
        --ht-button-color: var(--branding-contrast);
    }
    // SIZES
    &.large {
        padding: 12px 16px;
    }
    &.default {
        padding: 8px 16px;
    }
    &.small {
        padding: 6px 12px;
    }
    // STATES
    &:focus:not([disabled]) {
        &::before {
            box-shadow: var(--focus-ring);
            border: none;
        }
    }
    &:hover:not([disabled]) {
        &.secondary {
            --ht-button-background: #f9f9f9;
        }
        &.tertiary {
            --ht-button-background: #f5f5f5;
        }
    }
    &:active:not([disabled]) {
        &::before {
            transform: scale(0.9);
        }
    }
    &:disabled {
        cursor: not-allowed;
        --ht-button-background: var(--fill-disabled);
        --ht-button-color: var(--text-disabled);
        &::before {
            box-shadow: none;
            border: none;
        }
    }
    &.loading {
        .icon-loading {
            animation-play-state: running;
        }
        .loader {
            opacity: 1;
            transform: translateY(-50%) translateX(-50%) scale(1);
        }
        .ht-button-inner {
            opacity: 0;
            transform: scale(0.8);
        }
    }
}
.ht-button-inner {
    display: flex;
    align-items: center;
    position: relative;
    transform: scale(1);
    opacity: 1;
    transition: var(--transition-s);
    z-index: 1;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.8);
    opacity: 0;
    transition: var(--transition-s);
}
.icon-loading {
    animation: loader 2s linear infinite paused;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
