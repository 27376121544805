<template>
    <div
        class="ht-dropdown"
        :class="{disabled}"
    >
        <button
            role="button"
            class="ht-dropdown-btn"
            :class="{'fullwidth': fullwidth}"
            :disabled="disabled"
            @click="toggle"
        >
            <slot name="toggle" />
        </button>
        <div
            v-click-outside="clickOutsideMenu"
            class="ht-dropdown-menu"
            :class="[alignPositionClasses, { open }]"
            :style="{
                width: fullwidth ? `100%` : `${width}px`,
                maxHeight: maxHeight && maxHeight !== 'none' ? `${maxHeight}px` : 'none',
                '--offset' : `${offset}px`
            }"
        >
            <slot :toggle-dropdown="toggle" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            required: false,
            default: '320',
        },
        maxHeight: {
            type: String,
            required: false,
            default: '',
        },
        align: {
            type: String,
            required: false,
            default: 'start',
            validator(value) {
                return ['start', 'end'].includes(value);
            },
        },
        position: {
            type: String,
            required: false,
            default: 'block-end',
            validator(value) {
                return [
                    'block-end',
                    'block-start',
                    'inline-end',
                    'inline-start',
                ].includes(value);
            },
        },
        offset: {
            type: String,
            required: false,
            default: '8',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        fullwidth: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        open: false,
        isFullyOpened: false,
    }),
    computed: {
        alignPositionClasses() {
            return `align-${this.align} position-${this.position}`;
        },
    },
    methods: {
        toggle() {
            if (this.disabled) return;
            this.open = !this.open;
            this.isFullyOpened = false;
        },
        clickOutsideMenu() {
            // When opening the vClickOutside is also triggered
            // We're doing this check to not close it directly on the opening
            if (this.open) {
                if (this.isFullyOpened) {
                    this.open = false;
                    this.isFullyOpened = false;
                } else {
                    this.isFullyOpened = true;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.ht-dropdown {
    position: relative;
}

.ht-dropdown-btn {
    &.fullwidth {
        width: 100%;
    }
    &:disabled {
        cursor: not-allowed;
    }
}

.ht-dropdown-menu {
    position: absolute;
    border-radius: var(--radius);
    background: #fff;
    border: 1px solid var(--border-primary);
    padding-bottom: 4px;
    box-shadow: var(--shadow-2);
    transform: scale(0.9);
    z-index: 1000;
    opacity: 0;
    transition: var(--transition-s);
    pointer-events: none;
    visibility: hidden;
    overflow: auto;
    &.open {
        transform: scale(1);
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
    }
    /***** Position Block *****/
    &.position-block-end {
        top: calc(100% + var(--offset));
        &.align-start {
            left: 0;
            transform-origin: 0% 0%;
        }
        &.align-end {
            right: 0;
            transform-origin: 100% 0%;
        }
    }
    &.position-block-start {
        bottom: calc(100% + var(--offset));
        &.align-start {
            left: 0;
            transform-origin: 0% 100%;
        }
        &.align-end {
            right: 0;
            transform-origin: 100% 100%;
        }
    }

    /***** Position Inline *****/
    &.position-inline-end {
        left: calc(100% + var(--offset));
        &.align-start {
            top: 0;
            transform-origin: 0% 0%;
        }
        &.align-end {
            bottom: 0;
            transform-origin: 0% 100%;
        }
    }
    &.position-inline-start {
        right: calc(100% + var(--offset));
        &.align-start {
            top: 0;
            transform-origin: 100% 0%;
        }
        &.align-end {
            bottom: 0;
            transform-origin: 100% 100%;
        }
    }

    ::v-deep {
        > .ht-dropdown-item:first-child,
        > .ht-dropdown-group:first-child > .ht-dropdown-item:first-child {
            border-radius: var(--radius) var(--radius) 0 0;
        }
    }
}
</style>
