<template>
    <div
        class="ht-select"
        :class="{disabled}"
    >
        <HtDropdown
            :max-height="maxHeight"
            :disabled="disabled"
            :fullwidth="fullwidth"
        >
            <template #toggle>
                <div
                    class="ht-select-btn"
                    :class="{'no-label': !label}"
                >
                    <span
                        v-if="label"
                        class="ht-select-label"
                    >{{ label }} :</span>
                    <span
                        class="ht-select-value"
                        :class="{'fullwidth': fullwidth}"
                    >{{ value.label }}</span>
                    <HtIcon
                        name="nav-arrow-down"
                        stroke-width="2"
                        size="16"
                    />
                </div>
            </template>
            <template #default="{ toggleDropdown }">
                <HtDropdownItem
                    v-for="option in options"
                    :key="option.value"
                    @click.native="select(option, toggleDropdown)"
                >
                    {{ option.label }}
                </HtDropdownItem>
            </template>
        </HtDropdown>
    </div>
</template>

<script>
export default {
    name: 'HtSelect',
    props: {
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: [Object, String, Number, Array, Boolean],
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        fullwidth: {
            type: Boolean,
            required: false,
            default: false,
        },
        maxHeight: {
            type: String,
            required: false,
            default: '140',
        },
    },
    methods: {
        select(option, toggleDropdown) {
            this.$emit('input', option);
            toggleDropdown();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-select-btn {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: var(--radius);
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    box-shadow: var(--shadow-1);
    @include ht-body;
    .ht-select-value {
        text-align: left;
        color: var(--text-secondary);
        &.fullwidth {
            flex: 1 1 auto;
        }
    }
    .ht-icon {
        flex: 0 0 auto;
        margin-left: 8px;
        color: var(--icon-secondary);
    }
    .ht-select.disabled & {
        .ht-select-label, .ht-select-value {
            opacity: 0.4;
        }
    }
    &.no-label {
        .ht-select-value {
            color: var(--text-primary);
        }
    }
}
.ht-select-label {
    flex: 0 0 auto;
    margin-right: 3px;
}
</style>
