<template>
    <div v-if="userRequirement.value">
        {{ getFormatedValue }}
    </div>
    <div v-else>
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
</template>

<script>

export default {
    name: 'NewUserRequirementDateRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        getFormatedValue() {
            return (this.userRequirement.value) ? this.$Utils.moment(this.userRequirement.value).format('L') : null;
        },
    },
};
</script>
