<template>
    <transition name="fade">
        <div
            class="onboarding-settings-software"
        >
            <div class="gsuite__image mb-20">
                <img :src="gSuiteConfig.image">
            </div>
            <div class="gsuite__description mb-20">
                <t>
                    By activating this connector, you will be able, to automatically create an account on Gsuite
                    when adding an onboardee on Heyteam and synchronize his events with Google Agenda
                </t>
            </div>

            <div class="gsuite__button">
                <Button
                    :state="buttonState"
                    data-cy="add-gsuite"
                    @click="onValidate()"
                >
                    <t>Connect GSuite</t>
                </Button>
            </div>
        </div>
    </transition>
</template>

<script>
import OAuth from '@/OAuth';

export default {
    name: 'GSuiteEdit',

    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    computed: {
        configuration() {
            return this.value;
        },
        buttonState() {
            return this.formLoading ? 'loading' : 'idle';
        },
        gSuiteConfig() {
            return this.$store.getters['config/getGSuiteConfig'];
        },
    },

    methods: {
        async onValidate() {
            const config = [
                'https://www.googleapis.com/auth/admin.directory.user',
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly',
                'https://www.googleapis.com/auth/admin.directory.domain',
            ];

            const emailScope = [
                'https://www.googleapis.com/auth/admin.directory.user',
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly',
                'https://www.googleapis.com/auth/admin.directory.domain',
            ];

            const eventScope = [
                'https://www.googleapis.com/auth/admin.directory.user',
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly',
                'https://www.googleapis.com/auth/admin.directory.domain',
                'https://apps-apis.google.com/a/feeds/calendar/resource/',
                'https://www.googleapis.com/auth/calendar',
                'https://www.googleapis.com/auth/admin.directory.resource.calendar',
            ];

            config.scope = [...emailScope, ...eventScope].join(' ');

            OAuth.auth({
                url: 'https://accounts.google.com/o/oauth2/v2/auth',
                client_id: '680215368016-6hljitf8291nf5fou6dopcjtsol9h4c3.apps.googleusercontent.com',
                scope: config.scope,
                redirect_uri: 'https://beta.heyteam.com/oAuth2',
                access_type: 'offline',
                response_type: 'code',
                prompt: 'consent',
            }, {
                200: ({ code }) => {
                    this.configuration.code = code;
                    this.configuration.is_enabled = true;

                    this.$emit('on-save');
                },
                403: () => {
                    this.$Notifier('App').showError(this.translate('Sorry we are unable to connect to this api'));
                },
            });
        },
        async onCancel() {
            this.configuration.is_enabled = false;

            this.$emit('on-save');
        },
    },
};
</script>

<style lang="scss" scoped>
.gsuite__image {
    text-align: center;

    > img {
        width: 100px;
    }
}
</style>
