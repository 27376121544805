<template>
    <div
        v-if="item && item.isLoaded()"
        class="wrapper-main-footer"
    >
        <div class="modal-wrapper-main">
            <div class="wrapper-description">
                <DescLine v-if="item.company_user_program_task">
                    <template #title>
                        <t>Setup date</t>
                    </template>
                    <template #content>
                        {{
                            $Utils
                                .moment(item.company_user_program_task.datetime_end)
                                .format(
                                    (shared.session.companyUser.company_language.key === 'fr') ?
                                        'DD MMMM YYYY' : 'MMMM DD, YYYY'
                                )
                        }}
                    </template>
                </DescLine>
                <DescLine>
                    <template #title>
                        <t>Status</t>
                    </template>
                    <template #content>
                        <StatusLabel
                            :status="item.company_user_program_task.status"
                            :validator-type="item.company_user_program_task.validator_type"
                            :datetime="item.company_user_program_task.datetime_end"
                        />
                    </template>
                </DescLine>
                <DescLine>
                    <template #title>
                        <t>Name</t>
                    </template>
                    <template #content>
                        <p>{{ item.name }}</p>
                    </template>
                </DescLine>
                <DescLine v-if="item.description">
                    <template #title>
                        <t>Description</t>
                    </template>
                    <template #content>
                        <p>{{ item.description }}</p>
                    </template>
                </DescLine>
            </div>
        </div>
        <div
            v-if="!isCancelled"
            class="modal-wrapper-footer"
        >
            <HtButton
                v-if="software.permissions.delete"
                type="secondary"
                size="large"
                data-cy="software-delete-button"
                @click="onDelete()"
            >
                <t>Remove</t>
            </HtButton>
            <HtButton
                v-if="software.permissions.update"
                type="secondary"
                size="large"
                data-cy="software-edit-button"
                @click="openEditModal()"
            >
                <t>Edit</t>
            </HtButton>
            <HtButton
                v-if="software.company_user_program_task.permissions.can_validate && canValidate"
                type="branding"
                size="large"
                data-cy="software-toggle-status-button"
                :loading="isButtonLoading"
                @click="toggleStatus()"
            >
                <t>{{ toggleButtonLabel }}</t>
            </HtButton>
        </div>
        <modalable
            ref="modalableUserEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSoftwareEdit
                :id="id"
                ref="userEdit"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onRemove(...arguments)"
            />
        </modalable>
    </div>
</template>
<script>
import CompanyUserSoftwareCollection from '@/models/CompanyUserSoftwareCollection';
import CompanyUserSoftware from '@/models/CompanyUserSoftware';
import DescLine from '@/components/pages/dashboard/modals/DescLine.vue';
import StatusLabel from '@/components/pages/dashboard/modals/StatusLabel.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import UserSoftwareEdit from './UserSoftwareEdit.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';

export default {
    name: 'NewUserSoftwareItem',
    components: {
        UserSoftwareEdit,
        DescLine,
        StatusLabel,
        HtButton,
    },
    inject: ['modal'],

    permissions: [
        'ModelCompanyUserSoftware',
    ],

    props: {
        companyUserId: { type: [String, Number], required: true },
        value: { type: CompanyUserSoftware, required: false },
        collection: { type: CompanyUserSoftwareCollection, required: false },
        id: { type: [String, Number], required: false },
        canValidate: { type: Boolean, default: true },
    },

    data() {
        return {
            // FOR LAZY LOADING
            software: new CompanyUserSoftware([
                'id', 'company_user_id', 'company_software_id', 'permissions',
            ]).with({
                companySoftware: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'description']);
                        },
                    }).withTrashed(true);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'status', 'datetime_end', 'validator_type', 'permissions']);
                },
            }).where([['id', '=', this.id]]),
            item: null,
            isButtonLoading: false,
        };
    },

    computed: {
        labelTitle() {
            return this.item.company_software.resource.name;
        },

        toggleButtonLabel() {
            return this.item.status === 'done' ? 'Mark as undone' : 'Mark as done';
        },

        isCancelled() {
            return this.item.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.isButtonLoading = !!val;
        },
    },

    async created() {
        if (this.value) this.item = this.value.fromSaveState();
        else {
            this.software.company_user_id = this.companyUserId;
            await this.software.get();
            this.item = this.software;
        }

        this.modal.setTitle(this.labelTitle);
        this.modal.setSubtitle(this.translate('Software'));
    },

    methods: {
        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onRemove() {
            this.modal.close();
            this.$emit('onDelete');
        },

        openEditModal() {
            this.$refs.modalableUserEdit.open();
        },

        async onDelete() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this software? Once you click on delete, you will no longer be able to access this software.') })) {
                this.item.delete().then(() => {
                    this.onRemove();
                });
            }
        },

        async toggleStatus() {
            if (this.item.status === 'pending_system') {
                this.$Notifier('App').showError(this.translate('The installation of this software is handled automatically by HeyTeam'));
                return Promise.resolve();
            }

            try {
                await this.$handleValidationTasks({
                    id: this.item.company_user_program_task.id,
                    status: (this.item.status === 'done') ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
                });
                this.modal.close();
                this.$emit('onUpdate');
            } catch (error) {

            }
        },
    },
};
</script>
<style lang="scss" scoped>
    @import "~@/styles/var";

    .image {
        width: 75px;
        height: 75px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .status-icon {
        display: flex;

        .icon {
            margin-right: 1em;
        }
    }

    .color-primary {
        color: $color-primary;
    }

    .color-green {
        color: $color-green;
    }

    .color-grey-lighter {
        color: $color-grey-lighter;
    }
</style>
