<template>
    <HtMainBarNotification
        v-if="item.name === 'notifications'"
        class="mainbar-menu-item"
    />
    <HtMainBarMessage
        v-else-if="item.name === 'messages' && shared.config.allowChat"
        class="mainbar-menu-item"
    />
    <HtMainBarMenuSub
        v-else-if="item.children?.length"
        :item="item"
        class="mainbar-menu-item"
        @onChangeSubbar="onChangeSubbar"
    />
    <HtMainBarMenuPage
        v-else-if="item.icon"
        :item="item"
        class="mainbar-menu-item"
    />
</template>

<script>
import HtMainBarNotification from './HtMainBarNotification.vue';
import HtMainBarMessage from './HtMainBarMessage.vue';
import HtMainBarMenuPage from './HtMainBarMenuPage.vue';
import HtMainBarMenuSub from './HtMainBarMenuSub.vue';

export default {
    name: 'HtMainBarMenuItem',
    components: {
        HtMainBarNotification,
        HtMainBarMenuPage,
        HtMainBarMessage,
        HtMainBarMenuSub,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onChangeSubbar(subbar) {
            this.$emit('onChangeSubbar', subbar);
        },
    },
};
</script>

<style lang="scss" scoped>
.mainbar-menu-item {
    position: relative;
    padding: 2px 0;
    ::v-deep .mainbar-menu-item-link {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;
        padding: 8px 16px;
        color: var(--text-primary);
        font-size: var(--text-medium);
        line-height: 23px;
        z-index: 1;
        cursor: pointer;
        overflow: hidden;
        transition: padding-left var(--transition-s);
    }
    ::v-deep .mainbar-menu-item-label{
        white-space: nowrap;
        transition: opacity var(--transition-s);
    }
    ::v-deep .ht-icon {
        margin-right: 8px;
    }
    ::v-deep .ht-badge {
        margin-left: auto;
    }
    ::v-deep .ht-tooltip-target {
        display: none;
    }

    &::after {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 0;
        right: 0;
        border-radius: var(--radius);
        opacity: 0;
        transform: scale(0.8);
        background-color: var(--branding-color);
        transition: 0.5s cubic-bezier(0.34, 1.4, 0.64, 1);
    }

    &:hover, &:focus, &:has(.mainbar-menu-item-link.active) {
        &::after {
            opacity: 0.08;
            transform: scale(1);
        }
    }
}
</style>
