<template>
    <div class="wrapper-program-detail">
        <div class="wrapper-program-item">
            <ActionProgramItem
                ref="userProgram"
                :from="'cop'"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :can-multi-select="true"
                :program="program"
                @on-task-collection-fetched="companyUserProgramTaskCollection = $event"
            />
        </div>
        <div class="wrapper-progress">
            <div class="content-progress">
                <ActionTypeProgress
                    v-if="companyUserProgramTaskCollection"
                    :company-user-program-task-collection="companyUserProgramTaskCollection"
                />
            </div>
        </div>
    </div>
</template>
<script>
import ActionProgramItem from './ActionProgramItem.vue';
import ActionTypeProgress from './ActionTypeProgress.vue';

export default {
    name: 'ActionProgramDetail',
    components: {
        ActionProgramItem,
        ActionTypeProgress,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: true,
        },
        programLabel: {
            type: String,
            default: '',
        },
        program: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            companyUserProgramTaskCollection: null,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.wrapper-program-detail {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (min-width: $tablet-large) {
    .wrapper-program-detail {
        flex-direction: row;
    }
    .wrapper-program-item {
        flex: 1 1 auto;
        padding: 16px;
        margin-bottom: 20px;
        border-radius: var(--radius-block);
        background-color: var(--fill-secondary);
    }
    .wrapper-progress {
        flex: 0 0 auto;
        width: 360px;
    }
    .content-progress {
        position: sticky;
        top: 20px;
    }
}
</style>
