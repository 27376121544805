<template>
    <HtModal
        ref="modal"
        @beforeOpen="beforeOpen"
        @beforeClose="beforeClose"
    >
        <template #header>
            <t>Plan an event</t>
        </template>
        <template #default>
            <form
                v-if="isLoaded"
                class="delegated-event-edit"
                data-cy="delegated-event-edit-form"
                @submit.prevent
            >
                <div class="delegated-event-section-title">
                    {{ event.name }}
                </div>

                <div class="details">
                    <FontAwesomeIcon
                        class="schedule-icon"
                        color="#5869FF"
                        :icon="['fad', 'calendar-alt']"
                    />
                    <t>Date and time to be scheduled</t>
                </div>

                <div class="organizer mb-3">
                    <span class="details"><t>Organized by</t> :</span>
                    <!-- add a v-if in case the owner have been deleted from the platform-->
                    <div
                        v-if="event.owner"
                        class="d-flex align-items-center"
                    >
                        <UserBlock
                            :item="{ company_user: event.owner }"
                            :size="16"
                        />
                        <span>{{ event.owner.firstname }} {{ event.owner.lastname }}</span>
                    </div>
                    <div v-else>
                        -
                    </div>
                </div>

                <div
                    v-if="event.company_file && event.company_file.original_name"
                    class="d-flex align-items-center"
                >
                    <span class="mr-3"><t>Attachment</t> :</span>
                    <Downloadable
                        class="flex-grow-1"
                        :company-file="event.company_file"
                    />
                </div>

                <HtTag
                    class="schedule-tag"
                    color="purple"
                >
                    <FontAwesomeIcon
                        class="schedule-tag-icon"
                        :icon="['fad', 'calendar-alt']"
                    />
                    <t>To schedule</t>
                </HtTag>

                <fieldset
                    class="mt-2"
                    :class="{ error: errors.has('participants')}"
                >
                    <label><t>Participants</t> *</label>
                    <ParticipantList
                        :can-add="true"
                        :type="'stacked'"
                        :users="event.attendees"
                        cypress="participants"
                        @onAdd="openAddUsersModal"
                    />

                    <input
                        v-model="event.attendees"
                        v-validate.disable="'array'"
                        type="hidden"
                        name="participants"
                        :data-vv-as="translate('Participants')"
                    >
                </fieldset>

                <ParametersBox>
                    <HtFormEditor
                        id="description"
                        v-model="event.description"
                        class="description"
                        :label="`${translate('Add a description')}`"
                        name="description"
                        cypress="description"
                        minimal
                        :variables="textVariables"
                    />

                    <div class="date">
                        <!-- Necessary wrapper to be able to CSS select the element -->
                        <div class="ht-form-label">
                            <t>Choose a date</t>
                        </div>
                        <flat-pickr
                            v-model="eventDate"
                            class="flatpickr-input-none"
                            :config="{
                                inline: true,
                                dateFormat: 'Y-m-d',
                                altInput: true,
                                animate: false,
                                locale: locale
                            }"
                        />
                    </div>

                    <PlanningTimeSlots
                        class="time"
                        :from.sync="event.time_start"
                        :to.sync="event.time_end"
                        :time_to="23"
                        cypress="time"
                    />

                    <HtTimezoneSelect
                        v-model="event.timezone"
                        class="timezone"
                        cypress="timezone"
                    />
                </ParametersBox>
            </form>
        </template>
        <template #footer>
            <div
                v-if="isLoaded"
                class="button-container"
            >
                <Button
                    v-show="canDelete"
                    :state="buttonState['delete']"
                    class="negative"
                    cypress="delegated-event-delete-button"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    v-if="event.permissions.update"
                    :state="buttonState['schedule']"
                    cypress="delegated-event-save-button"
                    @click="save()"
                >
                    <t>Schedule</t>
                </Button>
            </div>
            <modalable
                ref="modalableSearchUser"
                class="modalable-1 small"
                :options="{ title: translate('Add Event Participants') }"
            >
                <SearchRolePanel
                    ref="searchUserPanel"
                    :multiple-mode="true"
                    :has-roles="false"
                />
            </modalable>
        </template>
    </HtModal>
</template>

<script>
import Modalable from '@/Modalable';
import HtTimezoneSelect from '@/components/globals/HtTimezoneSelect.vue';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import PlanningTimeSlots from '@/components/resources/planningEvent/PlanningTimeSlots.vue';
import moment from 'moment';
import 'moment-timezone';
import Locale from 'flatpickr/dist/l10n/fr';
import CompanyUser from '@/models/CompanyUser';
import api from '@/store/api';

export default {
    name: 'NewUserPlanningDelegatedEventEdit',
    components: {
        PlanningTimeSlots,
        HtTimezoneSelect,
        ParametersBox,
    },

    data() {
        return {
            event: null,
            buttonState: {
                schedule: 'idle',
                delete: 'idle',
            },
            textVariables: [
                { name: 'enrollee_firstname', label: 'Firstname' },
                { name: 'enrollee_lastname', label: 'Lastname' },
                { name: 'enrollee_starting_date', label: 'Starting date' },
                { name: 'enrollee_job_title', label: 'Job title' },
                { name: 'enrollee_department', label: 'Department' },
                { name: 'enrollee_contract', label: 'Contract' },
            ],
        };
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    computed: {
        locale() {
            if (this.shared.session.companyUser.company_language.key === 'fr') {
                return Locale.fr;
            }

            return null;
        },
        isLoaded() {
            return this.event;
        },
        eventDate: {
            get() {
                return this.event.task.datetime_start
                    ? moment(this.event.task.datetime_start).format('Y-MM-DD')
                    : null;
            },
            set(date) {
                if (!this.event.task.datetime_start) {
                    this.event.task.datetime_start = date;
                } else if (moment(this.event.task.datetime_start).format('Y-MM-DD') !== date) {
                    this.event.task.datetime_start = `${date} ${moment(this.event.task.datetime_start).format('HH:mm')}:00`;
                }

                if (!this.event.task.datetime_end) {
                    this.event.task.datetime_end = date;
                } else if (moment(this.event.task.datetime_end).format('Y-MM-DD') !== date) {
                    this.event.task.datetime_end = `${date} ${moment(this.event.task.datetime_end).format('HH:mm')}:00`;
                }
            },
        },
        canDelete() {
            return this.event.permissions && this.event.permissions.delete;
        },
    },

    methods: {
        openAddUsersModal() {
            this.$refs.searchUserPanel.init({
                onValidate: (attendees) => {
                    this.event.attendees = attendees;
                    this.$refs.modalableSearchUser.close();
                },
                selected: this.event.attendees,
            });
            this.$refs.modalableSearchUser.open();
        },
        beforeOpen(resolve) {
            resolve();
        },

        beforeClose(resolve) {
            this.event = null;
            resolve();
        },

        open(eventId) {
            this.buttonState.schedule = 'idle';
            this.buttonState.delete = 'idle';

            api.user.planningEvents.get(eventId).then((response) => {
                this.event = response.data.data;
            });

            this.$refs.modal.open();
        },

        save() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.buttonState.schedule = 'loading';
                    this.event.is_delegated = false;
                    this.event.task.offset_end_unit = 'specific_date';

                    api.user.planningEvents.update(this.event.id, this.event).then(() => {
                        this.$emit('onUpdate');
                        this.$refs.modal.close();
                        this.buttonState.schedule = 'idle';
                    });
                } else {
                    this.$Utils.firstParentScrollableToTop(this.$el);
                    this.buttonState.schedule = 'idle';
                }
            });
        },

        onDelete() {
            this.buttonState.delete = 'loading';
            Modalable.openRegisteredWindow('Deletewindow', {}, this.translate('this event')).then((resolve) => {
                if (resolve === true) {
                    api.user.planningEvents.delete(this.event.id).then(() => {
                        this.buttonState.delete = 'idle';
                        this.$refs.modal.close();
                        this.$emit('onDelete');
                    });
                } else {
                    this.buttonState.delete = 'idle';
                }
            }).catch(() => {
                this.buttonState.delete = 'idle';
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "~@/styles/var";

    .delegated-event-section-title {
        font-size: 2rem;
        padding-bottom: 1.2rem;
        margin-bottom: 1.2rem;
        border-bottom: 1px solid $grey-ultra-light;
    }

    * + .delegated-event-section-title {
        margin-top: 3.4rem;
    }

    .details {
        font-size: 1.4rem;
    }

    .organizer {
        margin-top: 0.8rem;
        display: flex;
        gap: 0.8rem;
    }

    .schedule-icon {
        margin-right: 0.8rem;
    }

    .participants {
        display: flex;
        gap: 0.6rem;
        margin-bottom: 5.2rem;
    }

    .schedule-tag {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
    }

    .schedule-tag-icon {
        margin-right: 0.4rem;
    }

    .ht-form-label {
        margin-bottom: 0.8rem;
    }

    .description, .date, .time {
        margin-bottom: 2.4rem;
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-bottom: 2.2rem;

        .button {
            padding: 1.2rem 4.8rem;
        }
    }
</style>

<style lang="scss">
    .delegated-event-edit .flatpickr-calendar {
        width: 100%;

        .flatpickr-rContainer {
            margin: auto;
        }
    }
</style>
