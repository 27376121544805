<template>
    <div class="user-program-item-wrapper">
        <div v-if="isLoaded">
            <div class="wrapper-selects">
                <div class="wrapper-selects-in">
                    <HtSelect
                        v-model="currentTab"
                        :label="translate('Show')"
                        :options="optionTabs"
                    />
                    <HtSelect
                        v-model="status"
                        :label="translate('Status')"
                        :options="statusOptions"
                    />
                </div>
                <div class="wrapper-selects-in">
                    <HtButton
                        v-if="canAdd"
                        type="primary"
                        class="add-task"
                        data-cy="period-element-add-button"
                        @click="openAddElement"
                    >
                        <t>Add an action</t>
                    </HtButton>
                    <HtButton
                        v-if="canMultiSelect"
                        type="secondary"
                        class="user-program-item-multiselect-toggle"
                        data-cy="toggle-multiselect"
                        @click="toggleMultiSelect"
                    >
                        <t v-if="showMultiSelect === false">
                            Select
                        </t>
                        <t v-else>
                            Cancel
                        </t>
                    </HtButton>
                </div>
            </div>

            <div
                v-if="tasksGroupedByPeriod.length === 0"
                class="align-center"
            >
                <h3 style="margin-top: 2em;">
                    <t>You don't have any action to do</t>
                </h3>
                <Button
                    v-if="canAdd"
                    cypress="element-add-button"
                    @click="openAddElement"
                >
                    <t>Add an action</t>
                </Button>
            </div>
            <div class="wrapper-task-timelines">
                <div
                    v-for="(tasks, order) in tasksGroupedByPeriod"
                    :key="order"
                    class="task-timelines"
                >
                    <ActionProgramTimelineItem
                        :from="from"
                        :can-add="canAdd"
                        :period-label="tasks[0].period.label"
                        :tasks="tasks"
                        :selected-action-list="selectedActionList"
                        :selected-timeline-list="selectedTimelineList"
                        :show-multi-select="showMultiSelect"
                        :data-cy="`timeline-item-${order}`"
                        :program-id="companyUserProgram.company_program_id"
                        :show-done="showDone"
                        @onAddAction="openAddElement"
                        @onToggleStatus="onToggleStatus"
                    />
                </div>
            </div>
            <NewModalAddUserElement
                ref="modalableAddElement"
                :from="from"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                @onUpdate="onUpdate"
                @onDelete="onUpdate"
            />
            <NewHtBannerSelect
                :show="showMultiSelect"
                :count="selectedActionList.length"
                :can-delete="canDeleteTask"
                :button-state-delete="buttonStateDelete"
                :button-state-validate="buttonStateValidate"
                @onCancel="cancelSelected"
                @onSelectAll="selectAll"
                @onDelete="deleteActions"
                @onValidate="validateActions"
            />
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';
import NewHtBannerSelect from '@/components/globals/NewHtBannerSelect.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import NewModalAddUserElement from '@/components/profile/NewModalAddUserElement.vue';
import moment from 'moment';
import { arrayToTree } from 'performant-array-to-tree';
import TimelineHelper from '@/classes/Timeline/TimelineHelper';
import resourceEventBus from '@/eventBus/resourceEventBus';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import ActionProgramTimelineItem from './ActionProgramTimelineItem.vue';

export default {
    name: 'ActionProgramItem',
    permissions: [
        'AbstractAllTasksTab',
        'AbstractEmployeeTasksTab',
        'ModelCompanyPlanningEvent',
        'ModelCompanyUserDoc',
        'ModelCompanyUserDocument',
        'ModelCompanyUserEmailCustom',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserNudge',
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserQuiz',
        'ModelCompanyUserRequirementCategory',
        'ModelCompanyUserSoftware',
        'ModelCompanyUserSurvey',
    ],

    components: {
        ActionProgramTimelineItem,
        NewModalAddUserElement,
        NewHtBannerSelect,
        HtButton,
    },

    props: {
        from: {
            type: String,
            required: true,
        },
        companyUserProgramId: {
            type: [String, Number],
            required: true,
        },
        companyUserId: {
            type: [String, Number],
            required: true,
        },
        canMultiSelect: {
            type: Boolean,
            default: false,
        },
        program: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            buttonStateDelete: 'idle',
            buttonStateValidate: 'idle',
            selectedActionList: [],
            selectedTimelineList: [],
            showMultiSelect: false,
            currentTab: {
                label: this.translate('My Actions'),
                value: 1,
            },
            status: {
                label: this.translate('All'),
                value: true,
            },
            companyUserProgram: this.getFreshUserProgramModel(),
            statusOptions: [
                {
                    label: this.translate('All'),
                    value: true,
                },
                {
                    label: this.translate('To do'),
                    value: false,
                },
            ],
        };
    },

    computed: {
        showDone() {
            return this.status.value;
        },
        /**
        * @returns {CompanyUserProgramTask[]}
        */
        tasksGroupedByPeriod() {
            const tasks = arrayToTree(this.currentTasks, {
                id: 'id',
                parentId: 'dependentId',
                dataField: null,
                childrenField: 'dependenciesTasks',
            });

            return TimelineHelper.groupTasksByPeriod(
                tasks,
                moment(this.companyUserProgram.main_key_date.starts_at),
                this.companyUserProgram.company_program.ht_program_type.slug,
            );
        },

        /**
        * Are all async requests done
        * @returns {boolean}
        */
        isLoaded() {
            return (
                this.companyUserProgram.isLoaded()
                && this.currentTasks
                && this.permissionsLoaded
            );
        },

        /**
        * Can the user add a resource
        * @returns {boolean}
        */
        canAdd() {
            // Si le program est complété, je ne peux pas ajouter d'action
            if (this.companyUserProgram.status === CompanyUserProgram.STATUS_COMPLETED) {
                return false;
            }

            // Si je peux créer une tâche type custom, je peux ajouter une action
            if (this.$canCreate('ModelCompanyUserProgramTask', {
                company_user_id: this.companyUserId,
                resource: CompanyUserProgramTask.TASK_CUSTOM,
            })) {
                return true;
            }

            // Si il peut créer une resource, je peux ajouter une action
            for (const permission of this.$options.permissions) {
                if (this.$canCreate(permission, { company_user_id: this.companyUserId })) {
                    return true;
                }
            }

            return false;
        },

        canDeleteTask() {
            return this.shared.session.companyUser.id !== this.companyUserId && this.canDeleteAll;
        },

        /**
             * L'utilisateur peut delete toutes les ressources du dashboard
             * @returns {boolean}
             */
        canDeleteAll() {
            return (
                this.$canDelete('ModelCompanyUserRequirementCategory', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserEquipment', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserSoftware', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserQuiz', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserNudge', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyPlanningEvent', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserDoc', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserDocument', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserSurvey', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserProgramTask', {
                    company_user_id: this.shared.session.companyUser.id,
                    resource: CompanyUserProgramTask.TASK_CUSTOM,
                })
            );
        },

        currentTasks() {
            let tasks = this.companyUserProgram.company_user_program_task.tasksGrouped
                .filter((task) => (this.showDone
                    ? true : task.status === CompanyUserProgramTask.STATUS_PENDING));
            if (this.currentTab.value === 1) {
                tasks = tasks.filter((task) => {
                    if (task.groupedTasks.length > 0) {
                        task.groupedTasks = task.groupedTasks
                            .filter((groupedTask) => groupedTask
                                .company_user_program_task_participant.models
                                .some((participant) => participant
                                    .company_user_id === this.shared.session.companyUser.id));
                    }
                    return task.company_user_program_task_participant.models
                        .some((participant) => participant
                            .company_user_id === this.shared.session.companyUser.id);
                }).filter((task) => moment().isAfter(moment(task.datetime_availability)));
            } if (this.currentTab.value === 2) {
                tasks = tasks.filter((task) => {
                    if (task.groupedTasks.length > 0) {
                        task.groupedTasks = task.groupedTasks
                            .filter((groupedTask) => groupedTask
                                .company_user_program_task_participant.models
                                .some((participant) => participant
                                    .company_user_id === this.companyUserId));
                    }
                    return task.company_user_program_task_participant.models
                        .some((participant) => participant.company_user_id === this.companyUserId);
                });
            }

            tasks = tasks.map((task) => {
                const taskWithoutHiddenParent = task;
                if (!tasks.some((oneTask) => oneTask.id === taskWithoutHiddenParent.dependentId)) {
                    taskWithoutHiddenParent.dependentId = null;
                }
                return taskWithoutHiddenParent;
            });

            return tasks;
        },

        optionTabs() {
            const optionTabs = [{
                label: this.translate('My Actions'),
                value: 1,
            }];

            if (this.$canRead('AbstractEmployeeTasksTab', { company_user_id: this.companyUserId })) {
                optionTabs.push({
                    label: this.translate('Enrollee\'s actions'),
                    value: 2,
                });
            }
            if (this.$canRead('AbstractAllTasksTab', { company_user_id: this.companyUserId })) {
                optionTabs.push({
                    label: this.translate('All actions'),
                    value: 3,
                });
            }

            return optionTabs;
        },

        // showDoneTasks: {
        //     get() {
        //         return this.shared.program.showDoneTasks;
        //     },
        //     set(value) {
        //         this.shared.program.showDoneTasks = value;
        //     },
        // },
    },

    watch: {
        currentTab(val) {
            this.selectedActionList = [];
            this.selectedTimelineList = [];
            this.shared.program.currentTabActive = val.value;
            this.resetTasks();
        },

        'shared.program.removeIncrementTask': function () {
            this.getUserProgramTaskCollection();
        },

        'shared.program.updateIncrementTask': function () {
            this.getUserProgramTaskCollection();
        },

        'shared.program.showDoneTasks': function (val) {
            this.showDone = val;
        },

        companyUserProgramId() {
            this.getUserProgramTaskCollection();
        },
    },

    created() {
        if (this.from === 'bop') {
            this.currentTab = {
                label: this.translate('All actions'),
                value: 3,
            };
        }

        this.getUserProgramTaskCollection();
        resourceEventBus.$on('survey-done', () => {
            this.getUserProgramTaskCollection();
        });
    },

    shared: {
        program: {
            removeIncrementTask: {
                type: Number,
                default: 0,
            },
            updateIncrementTask: {
                type: Number,
                default: 0,
            },
            showDoneTasks: {
                type: Boolean,
                default: true,
            },
            currentTabActive: {
                type: Number,
                default: 1,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },
    },

    methods: {
        onToggleStatus(item) {
            const task = this.companyUserProgram.company_user_program_task.models.find((taskItem) => taskItem.id === item.id);
            task.status = item.status;

            this.shared.program.isLoading = true;

            this.$handleValidationTasks(item)
                .then(() => {
                    this.shared.program.updateIncrementTask += 1;
                })
                .catch(() => {});
        },

        cancelSelected() {
            this.selectedActionList = [];
            this.selectedTimelineList = [];
            this.showMultiSelect = false;
        },

        getFreshUserProgramModel() {
            return new CompanyUserProgram([
                'id',
                'company_user_id',
                'status',
                'company_program_id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['starts_at']);
                },
                companyProgram: (query) => {
                    query.select(['id'])
                        .with({
                            htProgramType: (queryHtProgramType) => {
                                queryHtProgramType.select(['slug']);
                            },
                        });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'company_user_program_id',
                        'company_user_id',
                        'title',
                        'prefix',
                        'tag',
                        'description',
                        'resource',
                        'resource_id',
                        'status',
                        'order',
                        'order_onboarding_start',
                        'order_onboarding_end',
                        'order_availability',
                        'datetime_start',
                        'datetime_end',
                        'datetime_availability',
                        'permissions',
                        'period',
                        'validator_type',
                        'full_title',
                    ]).with({
                        dependents: (query) => {
                            query.select(['id', 'title', 'resource', 'resource_id', 'status']);
                        },
                        recurrence: (query) => {
                            query.select(['id', 'company_user_program_task_id', 'recurrence_frequence_value', 'recurrence_frequence_unit']);
                        },
                        companyPlanningEvent: (query) => {
                            query.select(['id', 'company_user_id', 'owner_id', 'is_delegated', 'permissions']);
                        },
                        companyUserSoftware: (query) => {
                            query.select(['id', 'type', 'permissions']);
                        },
                        companyUserEquipment: (query) => {
                            query.select(['id', 'company_user_id', 'type', 'is_heyteam', 'permissions']);
                        },
                        companyUserRequirementCategory: (query) => {
                            query.select(['id', 'order', 'is_heyteam', 'permissions', 'company_user_id', 'display_survey']);
                        },
                        companyUserProgramTaskParticipant: (query) => {
                            query.select(['company_user_id', 'type'])
                                .with({
                                    companyUser: (query) => {
                                        query.select(['id', 'firstname', 'lastname', 'image']);
                                    },
                                });
                        },
                        companyUserDoc: (query) => {
                            query.select(['id', 'permissions', 'company_user_id', 'permissions']);
                        },
                        companyUserNudge: (query) => {
                            query.select(['id', 'company_user_id', 'permissions']);
                        },
                        companyUserDocument: (query) => {
                            query.select(['id', 'company_user_id', 'permissions', 'order']).orderBy([
                                ['order', 'asc'],
                            ]);
                        },
                        companyUserQuiz: (query) => {
                            query.select(['id', 'company_user_id', 'company_user_participant_id', 'permissions']);
                        },
                        companyUserSurvey: (query) => {
                            query.select(['id', 'company_user_id', 'permissions']);
                        },
                        companyUserEmailCustom: (query) => {
                            query.select(['id', 'company_user_id', 'company_user_participant_id', 'permissions']);
                        },
                    })
                        .where([
                            ['status', '!=', 'awaiting'],
                            ['resource', '!=', 'company_requirement'],
                        ])
                        .orderBy([['datetime_end', 'asc'], ['datetime_start', 'asc'], ['order', 'asc']]);
                },
            });
        },

        selectAll() {
            let nbTaskAdded = 0;
            this.tasksGroupedByPeriod.forEach((tasks) => {
                tasks.forEach((task) => {
                    if (task.is_grouped) {
                        task.groupedTasks.forEach((taskGrouped) => {
                            if (this.canSelect(taskGrouped)) {
                                this.checkIfAddOrRemove(taskGrouped.id);
                                this.nbTaskAdded += 1;
                            }
                        });
                    } else if (this.canSelect(task)) {
                        this.checkIfAddOrRemove(task.id);
                        this.nbTaskAdded += 1;
                    }
                });
            });

            if (nbTaskAdded > 0 && !this.selectedTimelineList.includes(this.period_label)) {
                this.selectedTimelineList.push(this.period_label);
                nbTaskAdded = 0;
            }
        },

        checkIfAddOrRemove(taskId) {
            if (!this.selectedActionList.includes(taskId)) {
                this.selectedActionList.push(taskId);
            }
        },

        canSelect(task) {
            const { resource } = task;

            if (task.validator_type === 'system') {
                return false;
            }

            return resource !== 'company_survey'
                && resource !== 'company_requirement_category'
                && resource !== 'company_user_quiz'
                && resource !== 'company_email_custom';
        },

        deleteActions() {
            this.buttonStateDelete = 'loading';
            this.$deleteTasks(this.selectedActionList).then(() => {
                this.selectedActionList = [];
                this.getUserProgramTaskCollection();
            }).finally(() => {
                this.buttonStateDelete = 'idle';
            });
        },

        validateActions() {
            this.buttonStateValidate = 'loading';
            this.$handleValidationTasks(
                this.selectedActionList.map((_id) => ({
                    id: _id,
                    status: CompanyUserProgramTask.STATUS_DONE,
                })),
            ).then(() => {
                this.selectedActionList = [];
                this.selectedTimelineList = [];
                this.getUserProgramTaskCollection();
            }).finally(() => {
                this.buttonStateValidate = 'idle';
            });
        },

        toggleMultiSelect() {
            this.selectedTimelineList = [];
            this.selectedActionList = [];
            this.showMultiSelect = !this.showMultiSelect;
        },

        resetTasks() {
            this.companyUserProgram.company_user_program_task.models.forEach((task) => {
                task.is_checked = false;
            });
        },

        openAddElement() {
            this.$refs.modalableAddElement.open();
        },

        onUpdate() {
            this.getUserProgramTaskCollection();
        },

        getUserProgramTaskCollection() {
            this.companyUserProgram = this.getFreshUserProgramModel();
            this.companyUserProgram.where([['id', '=', this.companyUserProgramId]]).get().then((companyUserProgram) => {
                this.$emit('on-task-collection-fetched', companyUserProgram.company_user_program_task);
                this.shared.program.isLoading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";
@import "~@/styles/var";

.wrapper-task-timelines {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.task-timelines {
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    box-shadow: var(--shadow-1);
}
.wrapper-selects {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 12px;
}
.wrapper-selects-in {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}
</style>
