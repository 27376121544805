<template>
    <HtFormCard v-if="isLoaded">
        <HtFormSwitch
            :id="'toogle-has-perimeter'"
            v-model="value.has_perimeter"
            :label="translate('Perimeter by filter')"
            :on-label="translate('Yes')"
            :off-label="translate('No')"
            :show-optional="false"
            :name="'toogle-has-perimeter'"
            :is-branded="true"
            class="mb-0"
        />
        <div v-if="value.has_perimeter">
            <HtDivider class="my-4" />
            <div
                v-for="entity in entities"
                :key="entity.id"
            >
                <HtFormSelector
                    :id="`perimeterFilter${entity.slug}`"
                    :value="valuesByEntityId[entity.id].data"
                    :options="optionsByEntityId[entity.id].data"
                    :placeholder="entity.is_heyteam ? translate(entity.name) : entity.name"
                    :name="`perimeterFilter${entity.slug}`"
                    :label="entity.is_heyteam ? translate(entity.name) : entity.name"
                    :show-optional="false"
                    :enable-selector="true"
                    :cypress="`filter-${entity.slug}`"
                    @input="values => updateEntityFilters(entity, values)"
                    @onDeleteParent="removeEntityFilters(entity)"
                >
                    <div slot="noOptions">
                        <t>All filters already selected</t>
                    </div>
                </HtFormSelector>
            </div>
        </div>
    </HtFormCard>
</template>

<script>
import keyBy from 'lodash.keyby';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtFormCard from './HtFormCard.vue';
import HtDivider from './HtDivider.vue';

export default {
    name: 'PerimeterFilters',
    components: {
        HtFormCard,
        HtDivider,
        HtFormSwitch,
        HtFormSelector,
    },

    props: {
        value: {
            required: true,
        },
    },

    data() {
        return {
            filters: null,
            valuesByEntityId: null,
            loading: true,
        };
    },

    computed: {
        entities() {
            return this.$store.state.entities.entities;
        },

        optionsByEntityId() {
            const entities = this.entities.map((entity) => {
                const data = entity.values
                    .map((r) => ({
                        id: r.company_entity_value_id,
                        name: r.is_heyteam ? this.translate(r.name) : r.name,
                        company_entity_value_id: r.company_entity_value_id,
                        company_entity_id: entity.id,
                    }));

                return {
                    id: entity.id,
                    data,
                };
            });

            return keyBy(entities, 'id');
        },

        isLoaded() {
            return this.$store.state.entities.entitiesLoaded && !this.loading;
        },
    },

    created() {
        this.$store.dispatch('entities/fetchEntities').then(() => {
            this.setValuesByEntityId();
        });

        this.filters = this.value.perimeters;
    },

    methods: {
        setValuesByEntityId() {
            const entities = this.entities.map((entity) => {
                const data = this.filters.models
                    .filter((f) => f.company_entity_id === entity.id)
                    .map((f) => ({
                        id: f.company_entity_value_id,
                        name: f.name_translated,
                        company_entity_value_id: f.company_entity_value_id,
                        company_entity_id: f.company_entity_id,
                    }));

                return {
                    id: entity.id,
                    data,
                };
            });

            this.valuesByEntityId = keyBy(entities, 'id');
            this.loading = false;
        },
        updateEntityFilters(entity, values) {
            this.removeEntityFilters(entity);

            values.forEach((value) => {
                this.filters.add({
                    id: value.company_entity_value_id,
                    name: value.name,
                    company_entity_id: value.company_entity_id,
                    company_entity_value_id: value.company_entity_value_id,
                });
            });

            this.setValuesByEntityId();
        },

        removeEntityFilters(entity) {
            const filtersToRemove = this.filters.models.filter((filter) => filter.company_entity_id === entity.id);

            filtersToRemove.forEach((filter) => {
                this.filters.remove(filter);
            });

            this.setValuesByEntityId();
        },
    },
};
</script>
