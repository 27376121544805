<template>
    <div>
        <History v-if="isOldUi" />
        <NewHistory v-else />
    </div>
</template>
<script>
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';
import History from './History.vue';
import NewHistory from './NewHistory.vue';

export default {
    name: 'HistoryPage',
    components: {
        History,
        NewHistory,
    },
    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
    },
};
</script>
