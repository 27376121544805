<template>
    <div>
        <h2 class="actions-title">
            My actions
        </h2>
        <NewPageWithSidebar>
            <template #sidebar>
                <NewHtPageSidebar
                    v-if="userPrograms.length > 1"
                    :pages="userPrograms"
                />
            </template>

            <template>
                <router-view v-if="isLoaded" />
            </template>
        </NewPageWithSidebar>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import HtProgramType from '@/models/HtProgramType';
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import NewHtPageSidebar from '@/components/globals/NewHtPageSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';

export default {
    name: 'PagesDashboard',
    components: {
        NewHtPageSidebar,
        NewPageWithSidebar,
    },
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: {},
            },
        },
    },
    data() {
        return {
            userPrograms: [],
            isLoaded: false,
            companyUserProgramCollection: new CompanyUserProgramCollection([
                'id',
                'company_user_id',
                'title',
                'description',
                'type',
                'status',
                'timelines',
                'enrollee_completion',
                'participants_completion',
                'task_count_late',
                'company_program_id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyProgram: (query) => {
                    query.select(['id']).with({
                        locales: (query) => {
                            query.select(['id', 'language_key', 'name']);
                        },
                    });
                },
            }).where([
                ['company_user_id', '=', this.shared.session.companyUser.id],
            ]).whereIn([
                ['status', [CompanyUserProgram.STATUS_ACTIVE, CompanyUserProgram.STATUS_COMPLETED]],
            ])
                .whereHas({
                    companyProgram: (query) => {
                        query.whereHas({
                            htProgramType: (query) => {
                                query.where([
                                    ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                ]);
                            },
                        });
                    },
                }),

        };
    },

    computed: {

    },

    watch: {
        $route() {
            this.redirect();
        },
    },

    async created() {
        const tabs = [];
        await this.companyUserProgramCollection.get().then(() => {
            this.companyUserProgramCollection.$.models.forEach((userProgram) => {
                tabs.push({
                    label: userProgram.company_program.name_translated,
                    params: { program_id: userProgram.id },
                    name: 'ProgramActions',
                });
            });
        }).catch(() => {
            this.$router.push({
                name: 'Dashboard',
            });
        });

        const usersIHaveActionsFor = await this.$store.dispatch('tasks/fetchCurrentUsersAndEmployees')
            .then((result) => result.data.data);

        const hasActionsOnlyForHimself = !usersIHaveActionsFor.length
            || (usersIHaveActionsFor.length === 1
                && usersIHaveActionsFor[0].id === this.shared.session.companyUser.id);

        const canSeeAllActionsTabs = !hasActionsOnlyForHimself || tabs.length > 1;

        if (canSeeAllActionsTabs) {
            tabs.unshift({
                name: 'AllActions',
                label: this.translate('All actions'),
            });
        }

        if (!tabs.length) {
            this.$router.push({
                name: 'Dashboard',
            });
        }

        this.userPrograms = tabs;

        this.isLoaded = true;
        this.redirect();
    },

    methods: {
        redirect() {
            if (this.$route.name !== 'Actions') return;
            if (this.userPrograms.length === 2) {
                this.$router.push(this.userPrograms[1]);
            } else {
                this.$router.push(this.userPrograms[0]);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.actions-title {
    margin-bottom: 20px;
    @include ht-heading-2;
}
</style>
