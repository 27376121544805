<template>
    <div
        class="checkable-container"
        :class="{'no-margin': simple || classic}"
    >
        <slot v-if="classic === false && showLabel === true">
            <label class="mr-2">{{ label }}</label>
        </slot>

        <div
            v-if="simple"
            class="white-bg-checkbox"
            :class="{'disabled': disabled}"
            :style="{'height': `${height}em`}"
            @click="changeValue"
        >
            <div
                class="select-simple"
                :class="{'selected': val}"
            />
        </div>

        <template v-else>
            <div
                v-if="!classic"
                class="checkable"
                :class="{'disabled': disabled}"
            >
                <label :data-cy="cypress">
                    <input
                        v-model="val"
                        type="checkbox"
                        :checked="value"
                        :disabled="disabled"
                        @click="e => $emit('onClick', !val)"
                    >

                    <div class="labels">

                        <template v-if="!toggleLabel">
                            <div class="off"><span class="">{{ _offLabel }}</span></div>
                            <div class="on"><span class="">{{ _onLabel }}</span></div>
                        </template>

                        <template v-else>
                            <div class="toggle-label">{{ toggleLabel }}</div>
                        </template>

                    </div>
                </label>

                <div
                    v-if="tooltip"
                    v-tooltip.left-start="tooltip"
                    class="position-relative cursor-help"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </div>
            </div>

            <div
                v-else
                class="checkable-classic"
                @click="changeValue"
            >
                <div class="big-square">
                    <div
                        class="square"
                        :class="{'green': val}"
                    />
                </div>
                <label>{{ label }}</label>
            </div>
        </template>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';

export default {
    mixins: [
        CypressMixin,
    ],
    props: {
        value: { type: [Boolean, Number], default: false },
        reversed: { type: Boolean, default: false },
        disabled: { type: [Boolean, Number], default: false },
        classic: { type: Boolean, default: false }, // GREEN CHECKBOX
        simple: { type: Boolean, default: false }, // SIMPLE STANDARD CHECKBOX
        label: { type: String, default: '' },
        tooltip: { type: String, default: '' },
        toggleLabel: { type: String, required: false },
        onLabel: { type: String, default: '' },
        offLabel: { type: String, default: null },
        showLabel: { type: Boolean, default: true },
        height: { type: [String, Number], default: 'auto' },
    },

    data() {
        return {
            val: false,
        };
    },

    computed: {

        _offLabel() {
            return this.offLabel || this.translate('Off');
        },

        _onLabel() {
            return this.onLabel || this.translate('On');
        },

    },

    watch: {
        val(v) {
            if (!this.disabled) {
                this.$emit('input', (!this.reversed ? v : !v));
                this.$emit('change');
            }
        },
        value() {
            this.val = !this.reversed ? this.value : !this.value;
        },
    },

    mounted() {
        this.val = !this.reversed ? this.value : !this.value;
    },

    methods: {
        changeValue(e) {
            if (!this.disabled) {
                this.val = !this.val;
                this.$emit('onChange', this.val);
                e.stopPropagation();
            }
        },
    },
};

</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.checkable-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;

    label {
        margin-bottom: 0;
    }
}

.no-margin {
    margin: unset;
}

.checkable {
    height: 24px;
    padding: 0 0 0 40px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-self: start;

    input {
        position: absolute;
        left: -999em;
        &:checked{
            + .labels {
                background: #5BDDB4;
                .on{
                    opacity: 1;
                }
                .off{
                    opacity: 0;
                }
                &:after{
                    transform: translateX(14px);
                }
            }
        }
    }
    .labels {
        width: 40px;
        height: 100%;
        border-radius: 15px;
        background: #E5E6E6;
        cursor: pointer;
        &:after{
            content: '';
            height: 20px;
            width: 22px;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 0 1px 0 rgba(#000, 0.5), 0 2px 1px 0 rgba(#000, 0.17);
            position: absolute;
            top: 2px;
            left: 2px;
            transition: $transition;
        }
        span{
            font-family: $lato;
            body:not(.old-ui) &{
                font-family: var(--poppins);
            }
            font-size: 1.4rem;
            position: absolute;
            top: 4px;
            left: -400px;
            width: 390px;
            text-align: right;
            transition: $transition;
            color: $black;
            white-space: nowrap;
        }
    }
    .on{
        opacity: 0;
    }
    label{
        position: relative;
        height: 100%;
        width: 100%;
    }
    &.disabled {

        .labels {
            background-color: $grey-ultra-light !important;
            &:after {
                background: $grey;
            }
        }

        .on, .off {
            color: $grey;
        }
    }
}

.square{
    cursor: pointer;
    border: 1px solid rgb(231, 232, 232);
    width: 15px;
    height: 15px;
    display: -webkit-inline-box;
    border:1px solid #e7e8e8;
    border-radius: 4px;

    &.green{
        background-color: #50e3c2;
    }
}

.big-square{
    padding: 5px;
    border: 1px solid rgb(231, 232, 232);
    width: 27px;
    height: 27px;
    display: -webkit-inline-box;
    border:1px solid #e7e8e8;
    margin-right: 10px;
    border-radius: 4px;
}

.checkable-classic {
    display: flex;
    align-items: center;
}

.white-bg-checkbox {
    max-height: 5em;
    padding: 0 1.5em;
    margin-left: -1.5em;
    margin-right: -1.5em;
    display:flex;
    align-items: center;
    cursor: pointer;

    &.disabled{
        .select-simple{
            opacity: 0.5;
            mix-blend-mode: difference;

        }
    }

    .select-simple {
        position: relative;
        width: 16px;
        height: 16px;
        background-image: url('/static/icons/new/checkbox-off-dark.svg');
        background-color: white;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px;

        &.selected {
            background-image: url('/static/icons/new/checkbox-on-dark.svg');
        }
    }
}

.toggle-label {
    white-space: nowrap;
    position: absolute;
    right: 45px;
}

.cursor-help {
    cursor: help !important;
}
</style>
