<template>
    <div>
        <AllMyActions />
    </div>
</template>
<script>

import AllMyActions from '@/components/pages/actions/all/AllMyActions.vue';

export default {
    name: 'AllActions',
    components: {
        AllMyActions,
    },
};
</script>
