<template>
    <div
        class="wrapper-dashboard-header"
        :style="{'--bgImage': `url(${coverImage})`}"
        :class="{'has-bg': coverImage}"
    >
        <div class="dashboard-header-user">
            <router-link
                :to="goToProfile"
                class="dashboard-header-avatar"
            >
                <HtAvatar
                    :user="$Utils.getAvatarData(companyUserData)"
                    :size="isMobile ? 's' : 'l'"
                />
            </router-link>
            <div class="dashboard-header-intro">
                <h1 class="dashboard-header-title">
                    {{ translate('Hello {firstname}', { firstname: companyUserData.firstname }) }}
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DashboardHeader',
    data() {
        return {
            isMobile: false,
        };
    },
    computed: {
        coverImage() {
            return this.$Utils.resolveS3PublicImage(this.shared.session.company.cover);
        },
        companyUserData() {
            return {
                firstname: this.shared.session.companyUser.firstname,
                lastname: this.shared.session.companyUser.lastname,
                image: this.shared.session.companyUser.image,
            };
        },
        goToProfile() {
            return {
                name: 'Profile',
                params: {
                    company_user_id: this.shared.session.companyUser.id,
                },
            };
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 780;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";
.wrapper-dashboard-header {
    margin-bottom: 24px;
    &.has-bg {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 24px;
        z-index: 1;
        color: var(--text-inversed-primary);
        &::before {
            content: '';
            position: absolute;
            top: -22px;
            bottom: 0;
            right: -22px;
            left: -22px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 35%, rgba(0, 0, 0, 0.20) 100%), var(--bgImage) lightgray;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: var(--radius);
            z-index: -1;
        }
    }
}
.dashboard-header-user {
    display: flex;
    flex-direction: column;
}
.dashboard-header-avatar {
    flex: 0 0 auto;
    margin-bottom: 25px;
    text-decoration: none;
}
.dashboard-header-title {
    @include ht-heading-2;
    margin: 0;
}

@media (min-width: $tablet) {
    .wrapper-dashboard-header.has-bg {
        height: 208px;
    }
    .dashboard-header-user {
        flex-direction: row;
        align-items: center;
    }
    .dashboard-header-avatar {
        margin: 0 16px 0 0;
    }
    .dashboard-header-title {
        @include ht-heading-1;
    }
}
</style>
