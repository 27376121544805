<template>
    <span>
        <Modalable
            v-if="isTranslatable && needToOpen"
            ref="modalLocale"
            class="modal modalable-1 small"
        >
            <form class="form">

                <fieldset @click="onCopy">
                    <label for="en">English</label>
                    <div class="item-content"><input
                        v-model="ht_locale.en"
                        style="cursor:pointer"
                        type="text"
                        name="en"
                        placeholder="Write the email"
                        disabled=""
                    ></div>
                </fieldset>

                <fieldset>
                    <label for="fr">French</label>
                    <div class="item-content"><input
                        v-model="ht_locale.fr"
                        type="text"
                        name="fr"
                        placeholder="Write the translation"
                        @keyup.13="onValidate"
                    ></div>
                </fieldset>

                <Button @click="onValidate"><t>Save</t></Button>
            </form>
        </Modalable>
        <span
            :style="style"
            @contextmenu.stop.prevent="openTranslationModal"
        >{{ getTranslation() }}</span>
    </span>
</template>

<script>
import { config } from '@/helpers/env';

export default {
    name: 'I18nComponent',

    data() {
        return {
            ht_locale: {},
            needToOpen: false,
        };
    },

    computed: {
        isTranslatable() {
            return config('APP_ENV') === 'qa';
        },

        style() {
            const rules = {};

            if (this.$I18n.isPackLoaded() && !this.$I18n.translationExists(this.$slots.default[0].text) && this.isTranslatable) {
                rules.textDecoration = 'red wavy underline';
            }

            return rules;
        },
    },

    methods: {
        getTranslation() {
            return this.translate(this.$slots.default[0].text, this.$vnode.data.attrs, true, this.$parent);
        },

        openTranslationModal() {
            this.needToOpen = true;

            this.$nextTick(() => {
                document.body.appendChild(this.$refs.modalLocale.$el);
                this.$refs.modalLocale.open();
                this.$I18n.getTranslation(this.$slots.default[0].text).then((data) => {
                    this.ht_locale = data;
                });
            });
        },

        onCopy() {
            this.ht_locale.fr = this.ht_locale.en;
        },

        onValidate() {
            this.$refs.modalLocale.close();
            this.$I18n.saveTranslation(this.ht_locale).then(() => {
                this.$I18n.getTranslationPack('fr').then(() => {
                    this.$forceUpdate();
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>

.modal{
	.key{
		color:black;
		font-size:1rem;
	}

	.button{
		width: 100%;
	}
}

</style>
