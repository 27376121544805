<template>
    <div
        :class="['input-wrapper', {'disabled': disabled}]"
        @click.stop="onOpen"
    >
        <InputParticipantItem
            v-for="(participant, index) in participants"
            :key="participant.id"
            :participant="participant"
            :disabled="disabled"
            @on-remove="onRemove(index)"
        />
    </div>
</template>

<script>
import InputParticipantItem from '@/components/globals/participant/InputParticipantItem.vue';

export default {
    name: 'InputParticipantList',
    components: {
        InputParticipantItem,
    },
    props: {
        participants: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onOpen() {
            if (this.disabled) {
                return;
            }

            this.$emit('on-open');
        },
        onRemove(index) {
            if (this.disabled) {
                return;
            }

            this.$emit('on-remove', index);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/styles/var';

    .input-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-family: $lato;
        body:not(.old-ui) &{
            font-family: var(--poppins);
        }
        font-size: 1.4rem;
        min-height: 48px;
        padding: 0 6px;
        border: 1px solid $grey-ultra-light;
        border-radius: 5px;
        background: $white;

        cursor: pointer;

        &.disabled {
            background: $grey-lighter;
            cursor: not-allowed;
        }
    }
</style>
