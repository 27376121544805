<template>
    <header class="profile-header">
        <div
            class="wrapper-cover-image"
            :style="{'--bgImage': `url(${coverImage})`}"
        />
        <div class="wrapper-user-actions">
            <div class="wrapper-user">
                <HtAvatar
                    :user="$Utils.getAvatarData(companyUser)"
                    size="xl"
                    class="profile-avatar"
                />
                <div class="wrapper-user-infos">
                    <div class="profile-user-name">
                        {{ companyUser.firstname }} {{ companyUser.lastname }}
                    </div>
                    <div
                        v-if="companyUser.company_job_position"
                        class="profile-user-job-title"
                    >
                        {{ companyUser.company_job_position.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper-menu">
            <ul
                class="menu-list"
                :style="{
                    '--indicator-offset': `${indicatorOffset}px`,
                    '--indicator-width': `${itemsWidth[currentItemIndex]}px`
                }"
            >
                <li
                    v-for="(page, index) in pages"
                    :key="index"
                    ref="items"
                    @mouseenter="hoverItemIndex = index"
                    @mouseleave="hoverItemIndex = -1"
                >
                    <router-link
                        :to="{ name: page.name, params: page.params }"
                        :data-cy="'sidebar-'+page.name"
                        @click.native="emitUpdatePage(page.name)"
                    >
                        <HtIcon
                            :name="page.icon"
                            size="20"
                        />
                        <span>{{ page.label }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import isEqual from 'lodash.isequal';
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'ProfileHeader',
    props: {
        pages: {
            type: Array,
            default: () => [],
        },
        companyUser: {
            type: CompanyUser,
            required: true,
        },
    },
    data() {
        return {
            hoverItemIndex: -1,
            isHoveringItem: false,
            itemsWidth: [],
        };
    },
    computed: {
        coverImage() {
            return this.$Utils.resolveS3PublicImage(this.shared.session.company.cover);
        },
        currentItemIndex() {
            return Math.max(
                this.hoverItemIndex > -1 ? this.hoverItemIndex : this.activeLinkIndex,
                0,
            );
        },
        activeLinkIndex() {
            let activeLinkIndex = -1;

            // Exact active route
            activeLinkIndex = this.pages.findIndex((p) => this.$route.name === p.name && isEqual(this.$route.params, p.params));

            // Active route
            if (activeLinkIndex === -1) {
                activeLinkIndex = this.pages.findIndex((p) => this.$route.matched.some((r) => p.name === r.name));
            }

            return activeLinkIndex;
        },
        indicatorOffset() {
            return this.itemsWidth.reduce((offset, itemWidth, index) => {
                if (index < this.currentItemIndex) offset += itemWidth;
                return offset;
            }, 0);
        },
    },

    watch: {
        pages() {
            this.itemsWidth = this.getItemsWidth();
        },
    },

    mounted() {
        this.itemsWidth = this.getItemsWidth();
    },

    methods: {
        getItemsWidth() {
            return this.$refs.items.map((i) => i.offsetWidth);
        },
        emitUpdatePage(name) {
            this.$emit('update-page', name);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/typography";

.profile-header {
    margin-bottom: 32px;
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
    overflow: hidden;
}
.wrapper-cover-image {
    height: 130px;
    background: linear-gradient(
        180deg, rgba(0, 0, 0, 0.00) 35%, rgba(0, 0, 0, 0.20) 100%), var(--bgImage) lightgray;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.wrapper-user-actions {
    padding: 20px;
}
.wrapper-user {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.profile-avatar {
    flex: 0 0 auto;
    margin-top: -65px;
}
.profile-user-name {
    @include ht-heading-3;
}
.profile-user-job-title {
    @include ht-caption;
    color: var(--text-secondary);
}

.wrapper-menu {
    padding: 0 10px;
    border-top: 1px solid var(--border-primary);
    border-bottom-left-radius: var(--radius-block);
    border-bottom-right-radius: var(--radius-block);
    overflow-y: scroll;
    // Hiding the scrollbar
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
}
.menu-list {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 -1px;
    padding: 0;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 2px;
        width: var(--indicator-width);
        height: 2px;
        transform: translateX(var(--indicator-offset));
        background-color: var(--branding-color);
        transition: 0.3s cubic-bezier(0.34, 1.4, 0.64, 1);
        pointer-events: none;
    }
    li {
        display: flex;
        flex: 0 0 auto;
        margin: 0;
        a {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            margin: 0;
            padding: 12px 10px;
            font-size: 1.4rem;
            line-height: 21px;
            letter-spacing: var(--letter-spacing-xsmall);
            text-decoration: none;
            color: var(--text-primary);
            > * {
                flex: 0 0 auto;
            }
            &.active {
                color: var(--branding-color);
            }
        }
    }
}

@media (min-width: $tablet-large) {
    .wrapper-cover-image {
        height: 190px;
    }
}
</style>
