<template>
    <div>
        <div
            v-if="isLoaded"
            class="frieze-single"
        >
            <span class="frieze-single-title">
                <t v-if="!allPeriodsDone">{{ currentPeriod.title }}</t>
                <t v-else>You have completed your program</t>
            </span>
            <div class="wrapper-task-timelines">
                <div
                    v-for="period in tasksGroupedByPeriod"
                    :key="period.order"
                    class="task-timeline"
                    :class="{
                        'current': periodIsCurrent(period),
                        'past': periodIsPast(period),
                        'late': periodIsLate(period)
                    }"
                >
                    <HtTooltip
                        position="block-end"
                        align="center"
                        :offset="-4"
                    >
                        <div class="timeline-progress">
                            <div
                                class="timeline-progress-bar"
                                :style="{width: getProgressFromTasks(period.tasks)*100+'%'}"
                            />
                        </div>
                        <template #target>
                            {{ translate('{completion}% of tasks completed', {
                                completion: Math.round(getProgressFromTasks(period.tasks)*100),
                            }) }}
                        </template>
                    </HtTooltip>
                    <span class="timeline-label">
                        <t>{{ period.simpleLabel }}</t>
                    </span>
                </div>
                <div class="wrapper-complete-icon">
                    <span>✅</span>
                </div>
            </div>
        </div>
        <HtIconLoading v-else />
    </div>
</template>

<script>
import CompanyUserProgram from '@/models/CompanyUserProgram';
import moment from 'moment';
import { arrayToTree } from 'performant-array-to-tree';
import TimelineHelper from '@/classes/Timeline/TimelineHelper';

export default {
    name: 'FriezeSingle',
    props: {
        program: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            companyUserProgram: this.getFreshUserProgramModel(),
            periods: [
                {
                    title: 'Preparing for your arrival',
                    simpleLabel: 'Before my arrival',
                    order: 1,
                },
                {
                    title: 'You arrive today',
                    simpleLabel: 'D-Day',
                    order: 2,
                },
                {
                    title: 'You are in your first week',
                    simpleLabel: 'First week',
                    order: 3,
                },
                {
                    title: 'You are in your first month',
                    simpleLabel: 'First month',
                    order: 4,
                },
                {
                    title: "You've been here for more than a month",
                    simpleLabel: 'More than a month after',
                    order: 5,
                },
            ],
        };
    },
    computed: {
        /**
        * Are all async requests done
        * @returns {boolean}
        */
        isLoaded() {
            return (
                this.companyUserProgram.isLoaded()
                && this.currentTasks
            );
        },
        /**
        * @returns {CompanyUserProgramTask[]}
        */
        tasksGroupedByPeriod() {
            const tasks = arrayToTree(this.currentTasks, {
                id: 'id',
                parentId: 'dependentId',
                dataField: null,
                childrenField: 'dependenciesTasks',
            });

            const tasksGrouped = TimelineHelper.groupTasksByPeriod(
                tasks,
                moment(this.companyUserProgram.main_key_date.starts_at),
                this.companyUserProgram.company_program.ht_program_type.slug,
            );

            return this.periods.map((period) => {
                const p = period;
                p.tasks = tasksGrouped.find((ts) => ts[0].period.order === period.order) || [];
                return p;
            });
        },
        currentPeriod() {
            return TimelineHelper.getTaskPeriod(moment(this.companyUserProgram.main_key_date.starts_at), moment());
        },
        currentTasks() {
            let tasks = this.companyUserProgram.company_user_program_task.tasksGrouped;
            if (this.currentTab.value === 1) {
                tasks = tasks.filter((task) => {
                    if (task.groupedTasks.length > 0) {
                        task.groupedTasks = task.groupedTasks
                            .filter((groupedTask) => groupedTask
                                .company_user_program_task_participant.models
                                .some((participant) => participant
                                    .company_user_id === this.shared.session.companyUser.id));
                    }
                    return task.company_user_program_task_participant.models
                        .some((participant) => participant
                            .company_user_id === this.shared.session.companyUser.id);
                }).filter((task) => moment().isAfter(moment(task.datetime_availability)));
            } if (this.currentTab.value === 2) {
                tasks = tasks.filter((task) => {
                    if (task.groupedTasks.length > 0) {
                        task.groupedTasks = task.groupedTasks
                            .filter((groupedTask) => groupedTask
                                .company_user_program_task_participant.models
                                .some((participant) => participant
                                    .company_user_id === this.companyUserId));
                    }
                    return task.company_user_program_task_participant.models
                        .some((participant) => participant.company_user_id === this.companyUserId);
                });
            }

            tasks = tasks.map((task) => {
                const taskWithoutHiddenParent = task;
                if (!tasks.some((oneTask) => oneTask.id === taskWithoutHiddenParent.dependentId)) {
                    taskWithoutHiddenParent.dependentId = null;
                }
                return taskWithoutHiddenParent;
            });

            return tasks;
        },
        allPeriodsDone() {
            for (let i = 0; i < this.tasksGroupedByPeriod.length; i++) {
                if (this.getProgressFromTasks(this.tasksGroupedByPeriod[i].tasks) < 1) {
                    return false;
                }
            }
            return true;
        },
    },
    created() {
        this.getUserProgramTaskCollection();
        this.currentTab = {
            label: this.translate('My Actions'),
            value: 1,
        };
    },
    methods: {
        getProgressFromTasks(tasks) {
            if (!tasks.length) return 1;
            return (tasks.length - this.countPendingTasks(tasks)) / tasks.length;
        },
        countPendingTasks(tasks) {
            return tasks.filter((task) => task.groupedTasks.length === 0 && ['pending', 'draft'].includes(task.status)).length;
        },
        getFreshUserProgramModel() {
            return new CompanyUserProgram([
                'id',
                'company_user_id',
                'status',
                'company_program_id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['starts_at']);
                },
                companyProgram: (query) => {
                    query.select(['id'])
                        .with({
                            htProgramType: (queryHtProgramType) => {
                                queryHtProgramType.select(['slug']);
                            },
                        });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'company_user_program_id',
                        'company_user_id',
                        'title',
                        'prefix',
                        'tag',
                        'description',
                        'resource',
                        'resource_id',
                        'status',
                        'order',
                        'order_onboarding_start',
                        'order_onboarding_end',
                        'order_availability',
                        'datetime_start',
                        'datetime_end',
                        'datetime_availability',
                        'permissions',
                        'period',
                        'validator_type',
                        'full_title',
                    ]).with({
                        dependents: (query) => {
                            query.select(['id', 'title', 'resource', 'resource_id', 'status']);
                        },
                        recurrence: (query) => {
                            query.select(['id', 'company_user_program_task_id', 'recurrence_frequence_value', 'recurrence_frequence_unit']);
                        },
                        companyPlanningEvent: (query) => {
                            query.select(['id', 'company_user_id', 'owner_id', 'is_delegated', 'permissions']);
                        },
                        companyUserSoftware: (query) => {
                            query.select(['id', 'type', 'permissions']);
                        },
                        companyUserEquipment: (query) => {
                            query.select(['id', 'company_user_id', 'type', 'is_heyteam', 'permissions']);
                        },
                        companyUserRequirementCategory: (query) => {
                            query.select(['id', 'order', 'is_heyteam', 'permissions', 'company_user_id', 'display_survey']);
                        },
                        companyUserProgramTaskParticipant: (query) => {
                            query.select(['company_user_id', 'type'])
                                .with({
                                    companyUser: (query) => {
                                        query.select(['id', 'firstname', 'lastname', 'image']);
                                    },
                                });
                        },
                        companyUserDoc: (query) => {
                            query.select(['id', 'permissions', 'company_user_id', 'permissions']);
                        },
                        companyUserNudge: (query) => {
                            query.select(['id', 'company_user_id', 'permissions']);
                        },
                        companyUserDocument: (query) => {
                            query.select(['id', 'company_user_id', 'permissions', 'order']).orderBy([
                                ['order', 'asc'],
                            ]);
                        },
                        companyUserQuiz: (query) => {
                            query.select(['id', 'company_user_id', 'company_user_participant_id', 'permissions']);
                        },
                        companyUserSurvey: (query) => {
                            query.select(['id', 'company_user_id', 'permissions']);
                        },
                        companyUserEmailCustom: (query) => {
                            query.select(['id', 'company_user_id', 'company_user_participant_id', 'permissions']);
                        },
                    })
                        .where([
                            ['status', '!=', 'awaiting'],
                            ['resource', '!=', 'company_requirement'],
                        ])
                        .orderBy([['datetime_end', 'asc'], ['datetime_start', 'asc'], ['order', 'asc']]);
                },
            });
        },
        getUserProgramTaskCollection() {
            this.companyUserProgram = this.getFreshUserProgramModel();
            this.companyUserProgram.where([['id', '=', this.program.id]]).get();
        },
        periodIsCurrent(period) {
            return period.order === this.currentPeriod.order;
        },
        periodIsPast(period) {
            return period.order < this.currentPeriod.order;
        },
        periodIsLate(period) {
            const isPast = this.periodIsPast(period);
            const isNotComplete = this.getProgressFromTasks(period.tasks) < 1;
            return isPast && isNotComplete;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/typography";

.frieze-single {
    padding: 20px 20px 30px;
}
.frieze-single-title {
    display: block;
    @include ht-heading-3;
    margin-bottom: 32px;
}
.wrapper-task-timelines {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 7px;
}
.wrapper-complete-icon {
    position: absolute;
    top: -12px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 2rem;
    border-radius: 50%;
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
    > span {
        position: relative;
        top: 2px;
    }
}
.task-timeline {
    flex: 1 1 0;
    &.current, &.past {
        .timeline-label {
            color: var(--text-primary);
        }
    }
    &.late {
        .timeline-progress-bar {
            background-color: var(--fill-active);
            box-shadow: 0px 0px 4px 0px rgba(255, 96, 88, 0.60), 0px 0px 13px 0px rgba(255, 96, 88, 0.40);
        }
    }
}
.timeline-progress {
    flex: 1 1 auto;
    display: flex;
    height: 28px;
    border-radius: var(--radius-full);
    background: repeating-linear-gradient(
        -45deg,
        var(--fill-primary),
        var(--fill-primary) 5px,
        #E8ECEB 5px,
        #E8ECEB 10px
    );
}
.timeline-progress-bar {
    height: 100%;
    border-radius: var(--radius-full);
    background-color: var(--fill-success);
    box-shadow: 0px 0px 4px 0px rgba(83, 180, 131, 0.60), 0px 0px 13px 0px rgba(54, 255, 154, 0.40);
}
.timeline-label {
    display: block;
    margin-top: 10px;
    @include ht-caption;
    color: var(--text-inactive);
}

</style>
