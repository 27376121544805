<template>
    <div>
        <div v-if="items.length || loading">
            <div
                v-if="items.length"
                class="widget-list"
            >
                <div
                    v-for="item in items"
                    :key="item.id"
                    class="widget-list-item"
                >
                    <div class="list-item-left">
                        <HtAvatar
                            :user="$Utils.getAvatarData(item)"
                            class="item-fixed"
                        />
                        <div class="item-infos item-auto">
                            <div class="item-infos-title">
                                {{ item.firstname }} {{ item.lastname }}
                            </div>
                        </div>
                    </div>
                    <div class="list-item-right">
                        <div class="item-infos-title">
                            {{ item.detail }}
                        </div>
                    </div>
                </div>
            </div>
            <HtIconLoading v-else />
        </div>
        <div
            v-else
            class="empty-state"
        >
            <div>
                {{ translate('Stay zen !') }}
            </div>
            <div>
                {{ translate('Everyone invited has already joined HeyTeam.') }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UsersTrackingFuture',
    computed: {
        items() {
            return this.$store.state.dashboard.statistics.notJoined.map((line) => ({
                id: line.id,
                image: line.image,
                firstname: line.firstname,
                lastname: line.lastname,
                detail: this.translate('Invitation on {date}', {
                    date: this.$Utils.moment(line.mail_schedule).format('L'),
                }),
            }));
        },
        loading() {
            return !this.$store.state.dashboard.statistics.notJoinedLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchNotJoined');
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped src="./PilotingWidgetList.scss" />
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.empty-state {
    @include ht-body;
}
</style>
