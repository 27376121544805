<template>
    <div class="wrapper-pagination">
        <ul
            v-if="lastPage > 1"
            class="pagination"
        >
            <li
                class="pagination-item btn-arrow btn-next"
                @click="previousPage"
            >
                <font-awesome-icon icon="chevron-left" />
            </li>
            <li
                v-for="(page, key) in pageRange"
                :key="key"
                :class="pageClass(page)"
                @click="go(page)"
            >
                {{ page }}
            </li>
            <li
                class="pagination-item btn-arrow btn-prev"
                @click="nextPage"
            >
                <font-awesome-icon icon="chevron-right" />
            </li>
        </ul>
        <div
            v-if="lastPage >= 5 && gotoIsEnabled"
            class="wrapper-direct-page"
        >
            <t>Go to</t>
            <form @submit.prevent="go(directPage)">
                <input
                    v-model.number="directPage"
                    name="directPage"
                    type="number"
                    :min="1"
                    :max="lastPage"
                    class="direct-page-input"
                    required
                >
            </form>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'HtPagination',
    components: { HtButton },
    props: {
        value: {
            type: Number,
            default: () => 1,
        },
        lastPage: {
            type: Number,
            required: true,
        },
        gotoIsEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            directPage: null,
        };
    },
    computed: {
        delta() {
            return 1;
        },
        currentPage() {
            return this.value;
        },
        pageRange() {
            const pageLeft = this.currentPage - this.delta;
            const pageRight = this.currentPage + this.delta;

            const range = [];
            for (let page = 1; page <= this.lastPage; page++) {
                if (page === 1 || page === this.lastPage || (page >= pageLeft && page <= pageRight)) {
                    range.push(page);
                }
            }

            return range.reduce((items, page, index) => {
                if (index > 0 && page - range[index - 1] > 1) {
                    items.push('...');
                }
                items.push(page);
                return items;
            }, []);
        },
    },
    watch: {
        currentPage() {
            this.init();
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if (this.currentPage > this.pages || this.currentPage < 1) {
                this.$emit('input', 1);
            }
        },
        nextPage() {
            if (this.currentPage === this.lastPage) {
                return;
            }
            this.$emit('input', this.currentPage + 1);
        },
        previousPage() {
            if (this.currentPage === 1) {
                return;
            }
            this.$emit('input', this.currentPage - 1);
        },
        go(page) {
            if (this.currentPage === page || page === '...') {
                return;
            }
            this.$emit('input', page);
        },
        pageClass(page) {
            const classes = ['pagination-item'];

            if (this.currentPage === page) {
                classes.push('active');
            } else {
                classes.push('text-secondary');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.wrapper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    font-size: 1.4rem;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination-item {
    padding: 4px 8px;
    border: 1px solid $white;
    border-radius: 4px;
    margin-right: 4px;
    cursor: pointer;
    color: $neutral-500;
    &.active, &.btn-arrow {
        color: $black-2;
        border-color: $grey-1;
    }
    &.btn-next {
        margin-right: 16px;
    }
    &.btn-prev {
        margin-left: 16px;
    }
    &:nth-last-child(-n + 2) {
        margin-right: 0;
    }
}
.wrapper-direct-page {
    display: flex;
    align-items: center;
    margin-left: 25px;
    > span {
        margin-right: 5px;
    }
}
.direct-page-input {
    width: 60px;
    margin-bottom: 0;
    padding: 4px 8px;
    border: 1px solid $grey-1;
    border-radius: 4px;
    &:focus-visible {
        outline: none;
    }
}
</style>
