<template>
    <div>
        <NewProfileBloc
            :is-edit.sync="isEdit"
            :can-edit="canUpdate"
            :button-state="buttonState"
            class="has-image"
            @on-cancel="reset"
            @on-save="onUpdate"
        >
            <div
                :key="renderKey"
                class="profile-row profile-user-row"
            >
                <!-- Image -->
                <div
                    v-if="canUpdate"
                    class="content-user-image hover-img profile-image"
                >
                    <Croppable
                        ref="croppable_profile"
                        class="croppable-container"
                        :show-width="200"
                        :show-height="200"
                        :dest-width="250"
                        :dest-height="250"
                        :resize-multiple="[0.1]"
                        :radius="200"
                        save-url="company_file"
                        :image="getUserImage"
                        :no-bg="true"
                        @onChange="onPictureChange"
                        @onUploadEnd="onPictureSave"
                    />
                    <div
                        class="overlay overlay-upload"
                        @click="$refs.croppable_profile.onOpenFileSelector()"
                    >
                        <button
                            class="button button-icon"
                            :title="translate('Edit profile picture')"
                        >
                            <svg class="icon icon-edit-fill">
                                <use xlink:href="#icon-edit-fill" />
                            </svg>
                        </button>
                    </div>
                    <div class="overlay overlay-download">
                        <button
                            class="button button-icon"
                            :title="translate('Download profile picture')"
                            @click="open()"
                        >
                            <svg class="icon icon-download">
                                <use xlink:href="#icon-download" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div
                    v-else
                    v-user-image="{
                        image: companyUser.image,
                        firstname: companyUser.firstname,
                        lastname: companyUser.lastname,
                        size: 3.4,
                    }"
                    class="profile-image"
                />

                <!-- Job position -->
                <div class="profile-user-block">
                    <template v-if="isEdit">
                        <div class="profile-row">
                            <div>
                                <label
                                    class="d-flex profile-item-title"
                                    :for="'firstname'"
                                ><t>First name</t> *</label>
                                <HtFormInput
                                    :id="'firstname'"
                                    v-model.trim="companyUser.firstname"
                                    v-validate.disable="'required'"
                                    :name="'firstname'"
                                    :data-vv-as="translate('first name')"
                                    :disabled="!canModifyPersonalInformation"
                                />
                            </div>
                            <div>
                                <label
                                    class="d-flex profile-item-title"
                                    :for="'lastname'"
                                ><t>Last name</t> *</label>
                                <HtFormInput
                                    :id="'lastname'"
                                    v-model.trim="companyUser.lastname"
                                    v-validate.disable="'required'"
                                    :name="'lastname'"
                                    :data-vv-as="translate('last name')"
                                    :disabled="!canModifyPersonalInformation"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h1>{{ companyUser.$.firstname }} {{ companyUser.$.lastname }}</h1>
                    </template>
                    <template v-if="!isEdit">
                        <h2 v-if="companyUser.company_job_position">
                            {{ $Utils.capitalize(companyUser.company_job_position.name) }}
                        </h2>
                    </template>
                    <div
                        v-else
                        class="profile-item"
                    >
                        <label
                            class="d-flex profile-item-title"
                            :for="'lastname'"
                        ><t>Job position</t></label>
                        <HtFormSelect
                            :id="'job_position'"
                            v-model="companyUser.company_job_position_id"
                            v-validate.disable="!canModifyEntities ? '' : 'required'"
                            :name="'job_position'"
                            :options="getJobPositionOptions"
                            :data-vv-as="translate('job position')"
                            :disabled="!canModifyEntities"
                        />
                    </div>
                </div>
            </div>

            <div class="profile-row">
                <!-- PROFESSIONAL EMAIL -->
                <div class="profile-item">
                    <div class="profile-item-title has-tooltip">
                        <span><t>Professional E-mail</t></span>
                        <HtTooltip v-if="isOwnProfile && companyUser.main_email === 'professional_email'">
                            <HtIcon
                                size="18"
                                name="info-circle"
                            />
                            <template #target>
                                <t>This is the login connexion</t>
                            </template>
                        </HtTooltip>
                    </div>
                    <HtFormInput
                        v-if="isEdit"
                        :id="'email'"
                        v-model.trim="companyUser.professional_email"
                        v-validate.disable="professionalEmailRules"
                        :name="'email'"
                        :data-vv-as="translate('professional email')"
                        :disabled="!canModifyPersonalInformation"
                    />
                    <div
                        v-else
                        class="profile-item-content"
                    >
                        {{ companyUser.$.professional_email | empty }}
                    </div>
                </div>
                <!-- PRESENTATION -->
                <div class="profile-item">
                    <div class="profile-item-title">
                        <t>Quick presentation</t>
                    </div>

                    <div
                        v-if="!isEdit"
                        class="profile-item-content"
                    >
                        {{ companyUser.$.about | empty }}
                    </div>
                    <HtFormTextarea
                        v-else
                        :id="'about'"
                        v-model.trim="companyUser.about"
                        :name="'about'"
                    />
                </div>
            </div>

            <div class="profile-row">
                <!-- HOBBIES -->
                <div class="profile-item">
                    <NewProfilMetadata
                        v-model="newHobby"
                        :company-user-metadata-collection="companyUser.company_user_metadata"
                        :type="'hobby'"
                        :is-edit="isEdit"
                        :section-title="translate('Hobbies')"
                    />
                </div>
                <!-- KEY DATE -->
                <div
                    v-if="userActiveProgram && userActiveProgram.key_dates.length > 0"
                    class="profile-item"
                >
                    <div
                        v-for="keyDate in sortedKeyDates"
                        :key="`keyDates_${keyDate.id}`"
                    >
                        <div class="profile-item-title">
                            <t>{{ keyDate.key_date.name_translated }}</t>
                        </div>

                        <div
                            v-if="!isEdit"
                            class="profile-item-content"
                        >
                            <span> {{ $Utils.moment(keyDate.starts_at).format('L') }}</span>
                        </div>

                        <template v-else>
                            <HtFormInput
                                :id="'arrival_date'"
                                :value="$Utils.moment(keyDate.starts_at).format('L')"
                                :name="'arrival_date'"
                                :disabled="true"
                                class="mb-3"
                            />
                            <div
                                v-if="canModifyKeyDate"
                                class="button button-secondary"
                                @click="editKeyDate(keyDate)"
                            >
                                <span><t>Modify</t> {{ keyDate.key_date.name_translated }} </span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </NewProfileBloc>
        <Modalable
            v-if="userActiveProgram && canModifyKeyDate"
            ref="modalableKeyDateEdit"
            class="modalable-1 big"
            :mode="2"
        >
            <KeyDateEdit
                ref="KeyDateEdit"
                :company-user-program-id="userActiveProgram.id"
                :key-date="selectedKeyDate"
            />
        </Modalable>
    </div>
</template>
<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserMetadata from '@/models/CompanyUserMetadata';
import CompanyJobPositionCollection from '@/models/CompanyJobPositionCollection';
import Croppable from '@/components/tools/Croppable.vue';
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';
import KeyDateEdit from '@/components/KeyDateEdit.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import HtProgramType from '@/models/HtProgramType';
import NewProfilMetadata from './ProfilMetadata/NewProfilMetadata.vue';
import NewProfileBloc from '../NewProfileBloc.vue';

export default {
    name: 'NewProfileInfoMain',
    components: {
        Croppable,
        NewProfileBloc,
        NewProfilMetadata,
        KeyDateEdit,
    },
    mixins: [ProfileBlocMixin],

    data() {
        return {
            newHobby: '',
            selectedKeyDate: null,
            companyJobPosition: new CompanyJobPositionCollection([
                'id',
                'name',
                'is_heyteam',
            ]),
        };
    },

    computed: {
        canModifyKeyDate() {
            return !this.isOwnProfile && this.$canRead('AbstractManageUserPrograms', { company_user_id: this.companyUser.id });
        },

        getJobPositionOptions() {
            return this.companyJobPosition.models.map((jobPosition) => ({
                name: jobPosition.name,
                id: jobPosition.id,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },

        professionalEmailRules() {
            const rules = {
                required: false,
                email: true,
                unique_email: this.companyUser.id,
            };

            if (this.companyUser.main_email === 'professional_email') {
                rules.required = true;
            }

            return rules;
        },

        getUserImage() {
            if (!this.companyUser.image) {
                return null;
            }
            return this.$Utils.resolveS3PublicImage(this.companyUser.image);
        },

        userActiveProgram() {
            return this.companyUser.company_user_program.$.models.find(
                // eslint-disable-next-line max-len
                (userProgram) => userProgram.company_program.ht_program_type.slug !== HtProgramType.SLUG_DEFAULT
                    && userProgram.status === CompanyUserProgram.STATUS_ACTIVE,
            );
        },
        sortedKeyDates() {
            return this.userActiveProgram.key_dates.models.slice(0).sort((a, b) => {
                if (a.key_date.is_main) {
                    return -1;
                }
                if (b.key_date.is_main) {
                    return 1;
                }
                return a.id - b.id;
            });
        },
    },

    created() {
        this.companyJobPosition.get();
    },

    methods: {
        open() {
            window.open(this.$Utils.resolveS3PublicImage(this.companyUser.image));
        },

        editKeyDate(keyDate) {
            this.selectedKeyDate = keyDate;
            this.$refs.modalableKeyDateEdit.open();
        },

        reloadCompanyUser(companyUser) {
            this.selectedKeyDate = null;
            if (this.shared.session.companyUser.id === companyUser.id) {
                this.shared.session.companyUser.fill(companyUser);
            }

            this.selectedKeyDate = null;
            const iconAction = () => { this.$router.push(this.$Utils.getBackroute(this.$router)); };

            this.setPage({
                avatar: {
                    image: companyUser.image,
                    firstname: companyUser.firstname,
                    lastname: companyUser.lastname,
                },
                icon: ['far', 'arrow-left'],
                iconAction,
                title: companyUser.fullname,
                subtitles: companyUser.company_job_position?.name || null,
            });
        },

        onPictureChange() {
            if (this.buttonState === 'idle') {
                this.$refs.croppable_profile.onSave();
            }
        },

        onPictureSave(uploadInfo, data) {
            const companyUser = new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'fullname',
                'image',
                'company_job_position_id',
                'company_file_id',
            ]).with({
                companyJobPosition: (query) => {
                    query.select([
                        'id',
                        'name',
                        'is_heyteam',
                    ]);
                },
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'detected_extension',
                        'uuid',
                    ]);
                },
            });
            companyUser.fill(this.companyUser);
            companyUser.company_file = data;
            companyUser.save().then(() => {
                this.reloadCompanyUser(companyUser);
                this.companyUser.fill(companyUser);
            });
        },

        onUpdate() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.buttonState = 'loading';

                    const splittedMetadataToAdd = this.newHobby.split(',');

                    if (splittedMetadataToAdd.length > 0) {
                        for (const item of splittedMetadataToAdd) {
                            const companyMetaData = this.companyUser.company_user_metadata.new();
                            companyMetaData.type = CompanyUserMetadata.TYPE_HOBBY;
                            companyMetaData.value = item.trim();
                            companyMetaData.company_user_id = this.companyUser.id;

                            if (companyMetaData.value && companyMetaData.value.length > 0) {
                                this.companyUser.company_user_metadata.add(companyMetaData);
                            }
                        }
                    }

                    this.onUpdateUser().then(() => {
                        this.reloadCompanyUser(this.companyUser);
                        this.reset();
                    });
                }
            });
        },

        reset() {
            this.newHobby = '';
        },
    },
};
</script>
<style lang="scss" src="../ProfileInfo.scss" scoped></style>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";
@import '~@/styles/var';

.profile-user-block {
    > * {
        display: block;
        margin-bottom: 0;
    }
    h1 {
        @include ht-heading-1;
    }
    h2 {
        @include ht-heading-2;
        color: var(--text-secondary);
    }
}
</style>
