<template>
    <HtCard>
        <template #title>
            <HtCardTitleBar
                :title="translate('Create a program')"
                :show-close="true"
                :show-search="false"
                @on-close="$router.push({ name: 'Programs' })"
            />
        </template>
        <template #default>
            <HtFormCard class="grey-box">
                <div class="padded-content">
                    <div
                        class="card"
                        @click="$router.push({ name: 'ProgramsForm' })"
                    >
                        <IconFile />
                        <t>From scratch</t>
                    </div>
                    <div
                        class="card"
                        @click="
                            $router.push({ name: 'ProgramTemplateSelection' })
                        "
                    >
                        <IconBox />
                        <t>From a model</t>
                    </div>
                </div>
            </HtFormCard>
        </template>
    </HtCard>
</template>

<script>
import HtCard from '@/components/globals/HtCard.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import IconFile from '@/components/icons/IconFile.vue';
import IconBox from '@/components/icons/IconBox.vue';

export default {
    name: 'New',
    components: {
        HtCard, HtCardTitleBar, HtFormCard, IconFile, IconBox,
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.grey-box {
    max-width: 67.2rem;
    margin: auto;
}

.padded-content {
    // Remember to compensate the 3.2rem padding of the parent parameter box
    margin: 6rem -3.2rem;
    display: flex;
    gap: 2.4rem;
    justify-content: center;
}

.card {
    background-color: white;
    width: 18.4rem;
    height: 20rem;
    border-radius: 0.8rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;

    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }

    cursor: pointer;
}
</style>
