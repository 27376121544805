<template>
    <div
        class="indicator"
    >
        <HtEmojiIcon
            size="40"
            font-size="16"
            theme="secondary"
            class="indicator-icon"
        >
            {{ icon }}
        </HtEmojiIcon>
        <div class="indicator-description">
            <span class="keyfigure">{{ keyfigure }} </span>
            <span class="label">{{ label }}</span>
        </div>
        <HtButton
            type="secondary"
            class="indicator-button"
            @click="$emit('click')"
        >
            <t>See</t>
        </HtButton>
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';

export default {
    name: 'IndicatorTemplate',
    components: {
        HtButton,
    },

    props: {
        icon: {
            type: String,
            required: true,
        },
        keyfigure: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/tokens";
@import "~@/styles/ds/typography";

.indicator {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 32px 20px;
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
}
.indicator-icon {
    flex: 0 0 auto;
}
.indicator-description {
    flex: 1 1 auto;
}
.indicator-button {
    flex: 0 0 auto;
}
.keyfigure {
    display: block;
    @include ht-heading-2;
}
.label {
    display: block;
    @include ht-body;
}
</style>
