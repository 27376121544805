<template>
    <NewHtSidebar>
        <ul
            class="menu-list"
            :style="{
                '--indicator-offset': `${indicatorOffset}px`,
                '--indicator-width': `${itemsWidth[currentItemIndex]}px`
            }"
        >
            <li
                v-for="(page, index) in pages"
                :key="index"
                ref="items"
                @mouseenter="hoverItemIndex = index"
                @mouseleave="hoverItemIndex = -1"
            >
                <router-link
                    :to="{ name: page.name, params: page.params }"
                    :data-cy="'sidebar-'+page.name"
                    @click.native="emitUpdatePage(page.name)"
                >
                    <span>{{ page.label }}</span>
                    <HtBadge
                        v-if="page.count > 0"
                        class="sidebar-badge"
                        theme="branding"
                    >
                        {{ page.count }}
                    </HtBadge>
                </router-link>
            </li>
        </ul>
    </NewHtSidebar>
</template>

<script>
import isEqual from 'lodash.isequal';
import NewHtSidebar from './NewHtSidebar.vue';

export default {
    name: 'NewHtPageSidebar',
    components: {
        NewHtSidebar,
    },

    props: {
        pages: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            hoverItemIndex: -1,
            isHoveringItem: false,
            itemsWidth: [],
        };
    },

    computed: {
        currentItemIndex() {
            return Math.max(
                this.hoverItemIndex > -1 ? this.hoverItemIndex : this.activeLinkIndex,
                0,
            );
        },
        activeLinkIndex() {
            let activeLinkIndex = -1;

            // Exact active route
            activeLinkIndex = this.pages.findIndex((p) => this.$route.name === p.name && isEqual(this.$route.params, p.params));

            // Active route
            if (activeLinkIndex === -1) {
                activeLinkIndex = this.pages.findIndex((p) => this.$route.matched.some((r) => p.name === r.name));
            }

            return activeLinkIndex;
        },
        indicatorOffset() {
            return this.itemsWidth.reduce((offset, itemWidth, index) => {
                // 20 is the padding-right
                if (index < this.currentItemIndex) offset += itemWidth + 20;
                return offset;
            }, 0);
        },
    },

    watch: {
        pages() {
            this.$nextTick(() => {
                this.itemsWidth = this.getItemsWidth();
            });
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.itemsWidth = this.getItemsWidth();
        });
    },

    methods: {
        getItemsWidth() {
            // 20 is the padding-right
            return this.$refs.items.map((i) => i.offsetWidth - 20);
        },
        emitUpdatePage(name) {
            this.$emit('update-page', name);
        },
    },
};
</script>

<style lang="scss" scoped>
.menu-list {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 -1px;
    padding: 0;
    overflow-y: scroll;
    // Hiding the scrollbar
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: var(--indicator-width);
        height: 3px;
        transform: translateX(var(--indicator-offset));
        background-color: var(--branding-color);
        transition: 0.3s cubic-bezier(0.34, 1.4, 0.64, 1);
        pointer-events: none;
    }
    li {
        display: flex;
        flex: 0 0 auto;
        margin: 0;
        a {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            padding: 0 20px 12px 0;
            font-size: 1.4rem;
            line-height: 21px;
            letter-spacing: var(--letter-spacing-xsmall);
            text-decoration: none;
            color: var(--text-primary);
            > * {
                flex: 0 0 auto;
            }
            &.active {
                color: var(--branding-color);
            }
        }
    }
}
.sidebar-badge {
    margin-left: 8px;
}
</style>
