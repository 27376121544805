<template>
    <div
        class="file-upload-wrapper"
        :data-cy="cypress"
    >
        <div
            :class="[
                'file-upload',
                {'border-straight': hasFile && widthProgressBar > 0},
                {'inline': inline}
            ]"
        >
            <NewFilledBar
                v-if="hasFile"
                ref="filledBar"
                :inline="inline"
                @on-edit="$emit('on-edit')"
                @file-deleted="$emit('file-deleted')"
            />
            <NewEmptyBar
                v-else
                :label="emptyLabel"
            />
        </div>
        <NewProgressBar
            class="progress"
            :width="widthProgressBar"
        />
        <label v-if="showMediaLegend">
            <span class="file-upload-sub-text">
                <t>File type:</t> {{ mediaLegend }}
            </span>
        </label>
        <label v-if="showFileSizeLegend && !value">
            <span class="file-upload-sub-text">
                <t>Max size:</t> {{ Math.round((maxSize + Number.EPSILON) * 100) / 100 }} <t>MB</t>
            </span>
        </label>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';
import NewEmptyBar from './NewEmptyBar.vue';
import NewFilledBar from './NewFilledBar.vue';
import NewProgressBar from './NewProgressBar.vue';

export default {
    components: { NewEmptyBar, NewFilledBar, NewProgressBar },

    mixins: [
        CypressMixin,
    ],

    provide() {
        return {
            uploadBar: this,
        };
    },

    props: {
        isRequirement: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        media: {
            type: Array,
            required: true,
        },
        extension: {
            type: Array,
            default: null,
        },
        maxSize: {
            type: Number,
            default: 20,
        },
        showMediaLegend: {
            type: Boolean,
            default: false,
        },
        showFileSizeLegend: {
            type: Boolean,
            default: false,
        },
        withEdit: {
            type: Boolean,
            default: false,
        },
        withTrash: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Object,
        },
        emptyLabel: {
            type: String,
            default: 'Add a file',
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            widthProgressBar: 0,
        };
    },

    computed: {
        hasFile() {
            return Boolean(this.value?.id);
        },

        mediaLegend() {
            const medias = {
                pdf: 'PDF',
                docx: 'docx',
                txt: 'txt',
                office: 'Office (word, excel, txt, csv...)',
                csv: 'CSV',
                image: 'Image (png, jpeg...)',
            };

            const string = this.media.map((m) => medias[m]);

            return string.join(', ');
        },
    },

    methods: {
        resetProgression() {
            this.widthProgressBar = 0;
        },
        destroy() {
            this.$refs.filledBar.destroy();
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";

.file-upload {
    &:not(.inline) {
        background-color: var(--fill-primary);
        border: 1px solid var(--border-primary);
        border-radius: var(--radius);
        padding: 9px 16px;
        @include ht-body-semi;
        &.border-straight {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}

.progress {
    margin-top: -1px; // marge négative pour que la bar de progression soit à l'intérieur du champ d'input
}
.file-upload-sub-text {
    display: block;
    margin-top: 8px;
    @include ht-caption;
    color: var(--text-secondary);
}
</style>
