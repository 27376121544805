<template>
    <div v-if="getValue">
        {{ getValue }}
    </div>
    <div v-else>
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
</template>

<script>
import get from 'lodash.get';

export default {
    name: 'NewUserRequirementSingleSelectRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getValue() {
            return get(this.userRequirement.value, 'value', null);
        },
    },
};
</script>
<style lang='scss' src='./UserRequirementMultipleSelectEdit.scss' scoped />
