<template>
    <NewUploadBar
        v-model="companyFile"
        :is-requirement="isRequirement"
        :with-trash="false"
        :media="['office', 'pdf', 'image']"
        :inline="inline"
    />
</template>
<script>
import CompanyFile from '../../models/CompanyFile';
import NewUploadBar from './UploadBar/NewUploadBar.vue';

export default {
    name: 'NewDownloadable',
    components: { NewUploadBar },

    props: {
        isRequirement: {
            type: Boolean,
            default: false,
        },
        companyFile: {
            type: [CompanyFile, Object],
            required: true,
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
