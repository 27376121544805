<template>
    <HtCard
        v-if="permissionsLoaded"
        class="mb-5"
    >
        <template #title>
            <HtCardTitleBar
                :title="title"
                :loading="loading"
                :show-button="$canCreate('ModelCompanyProgram')"
                :button-text="translate('Add a program')"
                :show-search-on-mobile="false"
                @search="setSearchValue"
                @onAdd="onActionClicked(null, 'edit')"
            />
        </template>
        <template #default>
            <div class="filters show-mobile">
                <HtSearchField
                    id="mobile-search"
                    v-model.trim="search"
                    name="mobile-search"
                />
            </div>
            <HtTable
                :items="filteredPrograms"
                :columns="columns"
                :external-search="search"
                :loading="loading"
                :clickable="$canRead('PageProgramDetail')"
                cypress="programs-table"
                @onRowClicked="onRowClicked"
            >
                <template #cell(settings)="scope">
                    <HtActionMenu
                        v-if="$canUpdate('ModelCompanyProgram')"
                        :actions="getProgramActions(scope.item)"
                        @action-clicked="onActionClicked(scope.item.id, ...arguments)"
                    />
                </template>

                <template #responsive="scope">
                    <div class="mobile-program">
                        <div class="mobile-program-container">
                            <div class="mobile-program-name">
                                {{ scope.item.name_translated }}
                            </div>
                            <ul class="mobile-program-stats">
                                <li>
                                    <div class="mobile-program-stats-count">
                                        {{ scope.item.user_programs_completed_count + scope.item.user_programs_draft_count + scope.item.user_programs_in_progress_count + scope.item.user_programs_late_count }}
                                    </div>
                                    <div class="mobile-program-stats-label">
                                        <t :count="scope.item.user_programs_completed_count + scope.item.user_programs_draft_count + scope.item.user_programs_in_progress_count + scope.item.user_programs_late_count">
                                            Participant | Participants
                                        </t>
                                    </div>
                                </li>
                                <li>
                                    <div class="mobile-program-stats-count">
                                        {{ scope.item.user_programs_late_count }}
                                    </div>
                                    <div class="mobile-program-stats-label">
                                        <t :count="scope.item.user_programs_late_count">
                                            Late | Lates
                                        </t>
                                    </div>
                                </li>
                                <li>
                                    <div class="mobile-program-stats-count">
                                        {{ scope.item.user_programs_draft_count }}
                                    </div>
                                    <div class="mobile-program-stats-label">
                                        <t :count="scope.item.user_programs_draft_count">
                                            Draft | Drafts
                                        </t>
                                    </div>
                                </li>
                                <li>
                                    <div class="mobile-program-stats-count">
                                        {{ scope.item.user_programs_completed_count }}
                                    </div>
                                    <div class="mobile-program-stats-label">
                                        <t :count="scope.item.user_programs_completed_count">
                                            Completed | Completed
                                        </t>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <HtActionMenu
                            v-if="$canUpdate('ModelCompanyProgram')"
                            ref="actionMenu"
                            position="top-right"
                            :actions="getProgramActions(scope.item)"
                            @action-clicked="onActionClicked(scope.item.id, ...arguments)"
                        />
                    </div>
                </template>
            </HtTable>
        </template>
    </HtCard>
</template>

<script>
import HtActionMenu from '@/components/globals/HtActionMenu.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import CompanyProgram from '@/models/CompanyProgram';
import Modalable from '@/Modalable';
import api from '@/store/api';
import CompanyUser from '@/models/CompanyUser';
import HtCardTitleBar from '../../components/globals/HtCardTitleBar.vue';
import HtTable from '../../components/globals/HtTable.vue';

export default {
    name: 'PagesPrograms',
    permissions: [
        'ModelCompanyProgram',
    ],

    components: {
        HtCardTitleBar,
        HtTable,
        HtActionMenu,
        HtSearchField,
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    data() {
        return {
            search: null,
            show_icon: false,
            loading: true,
            programsList: [],
            /**
             * id du programme qu'on est en train de survoler
             */
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    search: true,
                },
                {
                    label: this.translate('In progress'),
                    key: 'user_programs_in_progress_count',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    label: this.translate('Delays'),
                    key: 'user_programs_late_count',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    label: this.translate('Drafts'),
                    key: 'user_programs_draft_count',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    label: this.translate('Completed'),
                    key: 'user_programs_completed_count',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    label: '',
                    key: 'settings',
                    sortable: false,
                    class: 'w-3rem',
                },
            ],
        };
    },
    computed: {
        title() {
            return this.translate('{count} program | {count} programs', {
                count: this.programsList.length,
            });
        },
        filteredPrograms() {
            const accessAll = this.$canUpdate('ModelCompanyProgram') || this.$canCreate('ModelCompanyUserProgram');
            if (accessAll) {
                return this.programsList;
            }
            if (this.programsList.length == 0) {
                return [];
            }
            return this.programsList.filter((program) => program.user_programs_completed_count > 0
                || program.user_programs_draft_count > 0
                || program.user_programs_in_progress_count > 0
                || program.user_programs_late_count > 0);
        },
    },

    created() {
        this.setPage({
            title: this.translate('Programs'),
        });
        this.fetchProgramsAndStats();
    },

    methods: {
        setSearchValue(val) {
            this.search = val;
        },

        /**
         * Ouvre le formulaire de modification ou envoie vers la liste des userPrograms
         */
        onRowClicked(row) {
            if (!this.$canRead('PageProgramDetail') && typeof row.item.id === 'number') {
                return;
            }

            this.$router.push({ name: 'ProgramDetail', params: { program_id: row.item.id } });
        },

        fetchProgramsAndStats() {
            this.loading = true;
            api.configuration.programs.getPrograms({ with_statistics: true, with_default_program: false }).then(({ data }) => {
                this.programsList = data.data.map((program) => ({ ...program, name_translated: this.getTranslatedName(program) }));
            }).finally(() => {
                this.loading = false;
            });
        },

        getProgramActions(program) {
            const actions = [{ label: this.translate('Edit'), id: 'edit' }];

            const initialProgram = this.programsList.slice()
                .sort((programA, programB) => (new Date(programA.created_at) > new Date(programB.created_at) ? 1 : -1))[0];

            if (initialProgram && initialProgram.id !== program.id) {
                actions.push({ label: this.translate('Delete'), id: 'delete' });
            }

            return actions;
        },

        /**
         * @param {Number} id id du programme cliqué
         * @returns {void}
         */
        onActionClicked(id, actionId) {
            if (actionId === 'delete') {
                Modalable.openRegisteredWindow('Deletewindow', { content: this.translate('Are you sure you want to delete this program ? All users present on this program will be permanently removed from it, and all resources associated with it will be deleted.') }).then((didUserClickDelete) => {
                    if (didUserClickDelete) {
                        const selectedProgram = new CompanyProgram(['id']);
                        selectedProgram.id = id;

                        selectedProgram.delete().then(() => {
                            this.fetchProgramsAndStats();
                        });
                    }
                });
            } else if (actionId === 'edit') {
                if (id) {
                    this.$router.push({
                        name: 'ProgramsForm',
                        params: { id },
                    });
                } else {
                    this.$router.push({
                        name: 'NewProgram',
                    });
                }
            }
        },
        getTranslatedName(program) {
            const translated = program.locales.find((l) => l.language_key === this.shared.session.companyUser.company_language.key);
            if (translated) {
                return translated.name;
            }
            return program.name;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.mobile-program {
    position: relative;
}

.mobile-program-container {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.mobile-program-name {
    font-family: $lato-bold;
    body:not(.old-ui) &{
        font-family: var(--poppins);
        font-weight: 600;
    }
    font-size: 1.6rem;
}

.mobile-program-stats {
    display: flex;
    justify-content: space-between;
}

.mobile-program-stats-count {
    text-align: center;
    font-family: $lato;
    body:not(.old-ui) &{
        font-family: var(--poppins);
    }
    font-size: 1.4rem;
    font-weight: 600;
}

.mobile-program-stats-label {
    text-align: center;
    font-family: $lato;
    body:not(.old-ui) &{
        font-family: var(--poppins);
    }
    font-size: 1.2rem;
    color: #6f7183;
}

.show-mobile {
    display: none;
}

.filters {
    margin-bottom: 1.8rem;
}

@media (max-width: $phone) {
    .show-mobile {
        display: block;
    }
}
</style>
