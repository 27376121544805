<template>
    <NewHtStickySidebar class="ht-stepper-sticky">
        <NewHtStepperVertical
            :current-step="value"
            :steps="steps"
            @input="$emit('input', $event)"
        >
            <slot />
        </NewHtStepperVertical>
        <template #footer>
            <div
                class="ht-stepper-sidebar-footer"
                @click="goBack"
            >
                <div class="ht-stepper-sidebar-footer-icon">
                    <HtIcon
                        name="xmark"
                        size="10"
                        class="icon-second"
                        stroke-width="2"
                    />
                </div>
                <div class="ht-stepper-sidebar-footer-label">
                    <t>Leave</t>
                </div>
            </div>
        </template>
    </NewHtStickySidebar>
</template>
<script>
import NewHtStickySidebar from './NewHtStickySidebar.vue';
import NewHtStepperVertical from './NewHtStepperVertical.vue';

export default {
    name: 'NewHtStepperSidebar',
    components: {
        NewHtStickySidebar,
        NewHtStepperVertical,
    },

    props: {
        value: {
            type: Number,
            default: 0,
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        goBack() {
            this.$emit('back');
            this.$router.goBack();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/tokens";
@import "~@/styles/ds/typography";

.ht-stepper-sticky {
    position: sticky;
    top: 20px;
}

.ht-stepper-sidebar-footer {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: color .2s ease;

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: white;
        border: 1px solid var(--branding-color);
        width: 12px;
        height: 12px;
        transition: background-color .2s ease;
        .icon-second {
            display: block;
            color: currentColor;
            ::v-deep svg {
                display: block;
            }
        }
    }

    &-label {
        @include ht-heading-4;
        margin: 0;
    }
}
</style>
