<template>
    <div class="wrapper-action-type-progress">
        <header class="action-type-progress-header">
            <span class="action-type-progress-title"><t>Progress by type of action</t></span>
            <span class="action-type-progress-subtitle">
                <t>Track the progress of your program by type of action</t>
            </span>
        </header>
        <div class="action-type-progresses">
            <div
                v-for="(resource, index) in getAvailableRessource"
                :key="index"
                :class="{'complete':
                    company_user_resource_stats[resource.key].done ===
                    company_user_resource_stats[resource.key].total
                }"
                class="action-type-progress"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: resource.color }"
                >
                    <HtIcon
                        :name="resource.iconName || 'task-list'"
                        stroke-width="2"
                        size="16"
                        :style="{color: resource.iconColor }"
                    />
                </HtEmojiIcon>
                <div class="action-type-progress-detail">
                    <div class="action-type-label">
                        <t>{{ resource.label }}</t>
                    </div>
                    <div class="action-type-progress-content">
                        {{ company_user_resource_stats[resource.key].done }}/{{ company_user_resource_stats[resource.key].total }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Elements } from '@/models/common/Elements';
import CompanyUserProgramTaskCollection from '@/models/CompanyUserProgramTaskCollection';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'ActionTypeProgress',
    props: {
        companyUserProgramTaskCollection: {
            type: CompanyUserProgramTaskCollection,
            required: true,
        },
    },
    shared: {
        program: {
            validateBulk: {
                type: Boolean,
                default: false,
            },
            deleteBulk: {
                type: Boolean,
                default: false,
            },
            removeIncrementTask: {
                type: Number,
                default: 0,
            },
            updateIncrementTask: {
                type: Number,
                default: 0,
            },
        },
    },

    data() {
        return {
            is_loaded: false,
            company_user_resource_stats: this.$Utils.clone(Elements, true),
            installationProperties: [
                {
                    key: 'company_user_requirement_stats',
                    label: 'Administrative',
                    color: '#FFF9C7',
                    iconName: 'journal',
                    iconColor: '#A69712',
                },
                {
                    key: 'company_user_training_stats',
                    label: 'Trainings',
                    color: '#D7F5F3',
                    iconName: 'bookmark-book',
                    iconColor: '#4F7075',
                },
                {
                    key: 'company_user_software_stats',
                    label: 'Software',
                    color: '#D0FFF4',
                    iconName: 'pc-mouse',
                    iconColor: '#31B59E',
                },
                {
                    key: 'company_user_planning_stats',
                    label: 'Events',
                    color: '#D9EEFF',
                    iconName: 'group',
                    iconColor: '#305E95',
                },
                {
                    key: 'company_user_equipment_stats',
                    label: 'Equipment',
                    color: '#E3DCFF',
                    iconName: 'computer',
                    iconColor: '#51397A',
                },
                {
                    key: 'company_user_doc_stats',
                    label: 'Documents',
                    color: '#FFE2E2',
                    iconName: 'submit-document',
                    iconColor: '#B73E23',
                },
                {
                    key: 'company_user_survey_stats',
                    label: 'Surveys',
                    color: '#FFDCCE',
                    iconName: 'chat-bubble-question',
                    iconColor: '#9A3F21',
                },
                {
                    key: 'company_user_nudge_stats',
                    label: 'Nudges',
                    color: '#F5F4F4',
                    iconName: 'playstation-gamepad',
                    iconColor: '#504F4F',
                },
                {
                    key: 'company_user_quiz_stats',
                    label: 'Quiz',
                    color: '#EDE5DE',
                    iconName: 'chat-lines',
                    iconColor: '#694F4F',
                },
            ],
        };
    },

    computed: {
        getAvailableRessource() {
            return this.installationProperties;
        },
    },

    watch: {
        'shared.program.removeIncrementTask': function () {
            this.init();
        },

        'shared.program.updateIncrementTask': function () {
            this.init();
        },
        companyUserProgramTaskCollection() {
            this.init();
        },
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            this.is_loaded = false;
            this.setResourceStats();
            this.is_loaded = true;
        },

        setResourceStats() {
            this.company_user_resource_stats.company_user_requirement_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY).length,
            };
            this.company_user_resource_stats.company_user_training_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT).length,
            };
            this.company_user_resource_stats.company_user_software_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE).length,
            };
            this.company_user_resource_stats.company_user_planning_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_PLANNING_EVENT).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_PLANNING_EVENT).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_PLANNING_EVENT).length,
            };
            this.company_user_resource_stats.company_user_equipment_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT).length,
            };
            this.company_user_resource_stats.company_user_doc_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOC).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOC).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOC).length,
            };
            this.company_user_resource_stats.company_user_survey_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SURVEY).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SURVEY).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SURVEY).length,
            };
            this.company_user_resource_stats.company_user_nudge_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_NUDGE).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_NUDGE).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_NUDGE).length,
            };
            this.company_user_resource_stats.company_user_quiz_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ).length,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/typography";

.wrapper-action-type-progress {
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding: 20px;
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    box-shadow: var(--shadow-1);
}
.action-type-progress-header {
    > span {
        display: block;
    }
}
.action-type-progress-title {
    @include ht-heading-4;
}
.action-type-progress-subtitle {
    @include ht-caption;
    color: var(--text-secondary);
}
.action-type-progresses {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.action-type-progress {
    display: flex;
    align-items: center;
    gap: 12px;
    @include ht-body;
    &.complete {
        opacity: 0.2;
    }
}
.ht-emoji-icon {
    flex: 0 0 auto;
}
.action-type-progress-content {
    color: var(--text-secondary);
}
</style>
