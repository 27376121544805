<template>
    <div v-if="permissionsLoaded">
        <ProfileBloc
            :is-edit.sync="isEdit"
            :can-edit="canEditTeam"
            :button-state="loading ? 'loading' : 'idle'"
            @on-save="onValidate"
        >
            <div
                class="profile-onboarding-team"
            >
                <div class="profile-onboarding-team-title">
                    <h2>
                        <t>Team</t>
                    </h2>
                </div>

                <MyTeam
                    ref="teamList"
                    :company-user-id="companyUserId"
                    :is-edit.sync="isEdit"
                    @change="roleUser => changeUserRole(roleUser)"
                    @remove="roleUser => removeRoleUser(roleUser)"
                />
            </div>
        </ProfileBloc>
        <!-- TEAM -->
        <SetSupervisorModal
            v-if="clickedRoleUser"
            ref="modal"
            :role-name="clickedRoleUser.role.alias_translated"
            :role-id="clickedRoleUser.role.id"
            :is-guest-role="false"
            @user-selected="onUserSelected"
            @user-invited="onUserInvited"
        />
    </div>
</template>
<script>

import SupervisorMixin from '@/mixins/SupervisorMixin';
import SetSupervisorModal from '@/components/globals/modals/SetSupervisorModal.vue';
import MyTeam from '@/components/pages/team/MyTeam.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import ProfileBloc from '../ProfileBloc.vue';

export default {
    name: 'ProfileInfoTeam',
    permissions: [
        'ModelCompanyUser',
    ],

    components: {
        MyTeam,
        SetSupervisorModal,
        ProfileBloc,
    },

    mixins: [
        SupervisorMixin,
        ModalMixin,
    ],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            /**
             * @type {CompanyRoleUser}
             */
            clickedRoleUser: null,
            isEdit: false,
            loading: false,
        };
    },

    computed: {
        canEditTeam() {
            return this.$can('ModelCompanyUser', { id: this.companyUserId }, 'updateSupervisors')
                && this.$can('AbstractManageFocRoles');
        },
    },

    methods: {
        editTeam() {
            this.isEdit = !this.isEdit;
        },
        onUserSelected(user) {
            this.$refs.modal.close();
            if (user.from_delegations?.length) {
                const closestDelegation = user.from_delegations.sort((a, b) => new Date(a.start_date) > new Date(b.start_date))[0];
                const startDate = (new Date(closestDelegation.start_date)).toLocaleDateString();
                const endDate = closestDelegation.end_date ? (new Date(closestDelegation.end_date)).toLocaleDateString() : null;
                let message = this.translate(
                    'A delegation is running from this user to {to_user} since {start_date} ',
                    {
                        start_date: startDate,
                        to_user: closestDelegation.to_user.fullname,
                    },
                );
                if (endDate) {
                    message += this.translate('to {end_date}', { end_date: endDate });
                }
                this.genericConfirm(
                    this.translate('A delegation is active'),
                    message,
                    null,
                    'Validate',
                ).then(async () => {
                    this.clickedRoleUser.company_user_id = user.id;
                    this.clickedRoleUser.user = user;
                }).catch(() => {});
            } else {
                this.clickedRoleUser.company_user_id = user.id;
                this.clickedRoleUser.user = user;
            }
        },

        onUserInvited(user) {
            this.clickedRoleUser.company_user_id = user.id;
            this.clickedRoleUser.user = user;
            this.$refs.modal.close();
        },

        async onValidate() {
            this.loading = true;
            this.$refs.teamList.onValidate().finally(() => {
                this.loading = false;
            });
        },

        async changeUserRole(roleUser) {
            this.clickedRoleUser = roleUser;
            console.log(this.clickedRoleUser);
            await this.$nextTick();
            this.$refs.modal.open();
        },
        removeRoleUser(roleUser) {
            const roleUserCopy = roleUser;
            roleUserCopy.user = undefined;
            roleUserCopy.company_user_id = undefined;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.profile-onboarding-team {
    &-title {
        display:flex;
        align-items: center;
        justify-content: space-between;

        &-icon {
            cursor: pointer;
            margin-right: 20px;

            .icon-circle {
                font-size: 34px;
                border-radius: 50%;
                color: $white;
                border: 1px solid $grey-ultra-light;
                transition: $transition;
            }

            .icon-pencil {
                font-size: 34px;
                transition: $transition;
            }

            &:hover,
            &:focus {
                .icon-circle {
                    color: $primary;
                    border:none;
                }

                .icon-pencil {
                    color: $white;
                }
            }
        }

    }

    &-button {
        display:flex;
        justify-content: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .mb-30 {
        margin-bottom: 30px;
    }
}

</style>
