<template>
    <div v-if="is_loaded">
        <fieldset class="switch-label">
            <HtFormSwitch
                :id="'is-enabled-mail'"
                v-model="item.is_enabled"
                :label="translate('Enable this email')"
                :disabled="!item.is_editable_enabled"
                :on-label="translate('On')"
                :off-label="translate('Off')"
                :name="'is-enabled-mail'"
                :show-optional="false"
                class="mt-5"
            />

            <HtFormSwitch
                v-if="item.is_editable_enabled_slack && slackChannels.length"
                :id="'is-enabled-slack'"
                v-model="item.is_enabled_slack"
                :label="translate('Send a notification on slack')"
                :on-label="translate('On')"
                :off-label="translate('Off')"
                :name="'is-enabled-slack'"
                :show-optional="false"
                class="mt-5"
            />
        </fieldset>

        <HtFormUserOrRoleSelect
            :id="'sender'"
            v-model="item.company_sender"
            v-validate.disable="'required'"
            :data-vv-as="translate('sender')"
            :label="translate('Sender')"
            :name="'sender'"
            :show-asterisk="true"
            @on-add="openAddSenderModal"
            @on-remove="resetSender"
        />
        <fieldset
            v-if="!(item.is_editable_recipient == 0 && item.company_email_template_recipient.length == 0) "
            :class="{ error: errors.has('recipients') }"
        >
            <label><t>Recipients</t> *</label>

            <ParticipantList
                :can-add="item.is_editable_recipient"
                :type="'stacked'"
                :users="getRecipients"
                has-group
                @onAdd="openAddRecipientsModal"
            />

            <input
                v-validate.disable="item.template_slug === 'gazette' ? '':'required'"
                :value="item.company_email_template_recipient && item.company_email_template_recipient.length ? '-' : ''"
                type="hidden"
                name="recipients"
                :data-vv-as="translate('recipients')"
            >
            <div
                v-if="errors.has('recipients')"
                class="ht-form-error mt-3"
            >
                {{ errors.first('recipients') }}
            </div>
        </fieldset>

        <fieldset
            v-if="item.trigger_event"
            :class="{ error: errors.has('order') }"
        >
            <HtFormSelect
                id="event-trigger"
                v-validate.disable="'required'"
                value="event"
                :label="translate('Sending date')"
                name="event-trigger"
                :disabled="true"
                open-direction="top"
                :options="[{id: 'event', name: translate(item.trigger_event)}]"
                :data-vv-as="translate('sending date')"
            />
        </fieldset>

        <fieldset
            v-else
            :class="{ error: errors.has('order') }"
        >
            <label><t>Sending date</t> *</label>
            <div class="inline-selects">
                <div
                    style="margin-right: 2rem;"
                    class="medium"
                >
                    <div class="item-content">
                        <input
                            v-model="item.order"
                            v-validate.disable="'required|numeric'"
                            v-next-field
                            :data-vv-as="translate('specific date')"
                            class="medium"
                            type="text"
                            name="order"
                            :disabled="!item.is_editable_date"
                        >
                        <div
                            v-if="errors.has('order')"
                            class="ht-form-error mt-3"
                        >
                            {{ errors.first('order') }}
                        </div>
                    </div>
                </div>

                <HtFormSelect
                    v-if="isProbationaryPeriod"
                    id="event-trigger"
                    v-validate.disable="'required'"
                    class="mr-4"
                    value="event"
                    name="event-trigger"
                    :disabled="true"
                    :options="[{id: 'event', name: translate('working day before end of probationary period | working days before end of probationary period', {count: item.order})}]"
                    :data-vv-as="translate('sending-date-offset')"
                />

                <HtFormSelect
                    v-else
                    id="sending-date-offset"
                    v-model="item.isOrderMinus"
                    v-validate.disable="'required'"
                    class="mr-4"
                    name="sending-date-offset"
                    :disabled="!item.is_editable_date"
                    :options="[
                        {id: true, name: translate('working day before start date | working days before start date', {count: item.order})},
                        {id: false, name: translate('working day after start date | working days after start date', {count: item.order})}
                    ]"
                    :data-vv-as="translate('sending date')"
                />
                <div
                    v-if="errors.has('sending-date-offset')"
                    class="ht-form-error mt-3"
                >
                    {{ errors.first('sending-date-offset') }}
                </div>

                <HtFormSelect
                    id="sending-date-time"
                    v-model="item.time"
                    v-validate.disable="'required'"
                    name="sending-date-time"
                    :disabled="!item.is_editable_date"
                    :options="sendingDateTimeOptions"
                    :data-vv-as="translate('sending date')"
                />
                <div
                    v-if="errors.has('sending-date-time')"
                    class="ht-form-error mt-3"
                >
                    {{ errors.first('sending-date-time') }}
                </div>
            </div>
        </fieldset>

        <fieldset>
            <div :class="{'inline-selects': item.is_editable_enabled_slack && slackChannels.length}">
                <HtFormSelect
                    v-if="item.is_editable_enabled_slack && slackChannels.length"
                    id="notification-type"
                    v-model="notificationType"
                    v-validate.disable="'required'"
                    style="width: 50%;"
                    class="mr-4"
                    :label="translate('Notification type')"
                    name="notification-type"
                    :options="[{id: 'email', name: translate('Email')}, {id: 'slack', name: 'Slack'}]"
                    :data-vv-as="translate('notification type')"
                />
                <div
                    v-if="errors.has('notification-type')"
                    class="ht-form-error mt-3"
                >
                    {{ errors.first('notification-type') }}
                </div>

                <HtLanguageWithOptionsSelect
                    id="language"
                    v-model="language"
                    name="language"
                    :options="availableLanguages"
                />
            </div>
        </fieldset>

        <transition name="fade">
            <fieldset
                v-if="notificationType == 'slack'"
                :class="{ error: errors.has('slack_channel') }"
            >
                <HtFormSelect
                    id="slack-channel"
                    v-model="item.slack_channel"
                    v-validate.disable="'required'"
                    :label="translate('Slack channel')"
                    name="slack-channel"
                    :options="slackChannelOptions"
                    :data-vv-as="translate('slack channel')"
                />
                <div
                    v-if="errors.has('slack-channel')"
                    class="ht-form-error mt-3"
                >
                    {{ errors.first('slack-channel') }}
                </div>
            </fieldset>
        </transition>

        <div
            v-show="notificationType == 'email'"
            class="mb-3"
        >
            <HtFormInput
                :id="'subject'"
                v-model="item[`subject_${language}`]"
                v-validate.disable="'required'"
                :name="'subject'"
                :label="translate('Subject')"
                :data-vv-as="translate('e-mail subject')"
                :variables="getVariablesOfField(item.variables, 'subject')"
                :disabled="!item.is_editable_subject"
            />
        </div>

        <fieldset v-if="item.template === 'invite'">
            <HtFormSwitch
                id="file-join"
                v-model="is_file_box_checked"
                :label="translate('Attach a file')"
                :off-label="translate('No')"
                :on-label="translate('Yes')"
                name="file-join"
                cypress="email-attach-file"
            />
            <div v-if="is_file_box_checked">
                <label>
                    <t>Upload file</t>
                    <span style="font-size:1rem; color:grey">(<t>Up to 20 MB</t>)</span>
                </label>

                <UploadBar
                    v-if="is_file_box_checked"
                    v-model="item.company_file"
                    :media="['office', 'pdf', 'image']"
                    class="item-content"
                />
            </div>
        </fieldset>

        <fieldset
            v-show="notificationType == 'email'"
            :class="{ error: errors.has('content') }"
        >
            <HtFormEditor
                :id="'content'"
                v-model="item[`content_${language}`]"
                v-validate.disable="'required'"
                :name="'content'"
                :label="translate('Email content')"
                :data-vv-as="translate('content')"
                :variables="getVariablesOfField(item.variables, 'content')"
                :disabled="!item.is_editable_content"
            />
            <div
                v-if="errors.has('content')"
                class="ht-form-error mt-3"
            >
                {{ errors.first('content') }}
            </div>
        </fieldset>

        <fieldset
            v-show="notificationType == 'slack'"
            class="medium"
            :class="{ error: errors.has('content') }"
        >
            <label><t>Slack message content</t> *</label>

            <RichEditable
                v-if="is_loaded"
                key="1"
                v-model="item[`content_slack_${language}`]"
                :p-key="1"
                :in-high-light-white-list="filterSlackVariables(getVariablesOfField(item.variables, 'content', 'slack'))"
                :configs="slackEditorOptions"
            />
        </fieldset>

        <modalable
            ref="modalableSearchRole"
            class="modalable-1 small"
            :options="{ title: translate('Add recipient') }"
        >
            <SearchRolePanel
                ref="searchRolePanel"
                :multiple-mode="true"
                :is_assignable_onboardee="true"
                :has-company-group="item.template_slug!=='gazette'"
                @onValidate="$refs.modalableSearchRole.close()"
            />
        </modalable>

        <modalable
            ref="modalableSearchUser"
            class="modalable-1 small"
            :options="{ title: translate('Add sender') }"
        >
            <SearchRolePanel
                ref="searchUserPanel"
                :has-roles="false"
                @onValidate="$refs.modalableSearchUser.close()"
            />
        </modalable>
    </div>
</template>

<script>
import HtLanguageWithOptionsSelect from '@/components/globals/HtLanguageWithOptionsSelect.vue';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import NotificationVariableMixin from '@/mixins/NotificationVariableMixin';
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'EmailEdit',

    components: {
        HtFormUserOrRoleSelect,
        HtFormEditor,
        HtFormSwitch,
        HtLanguageWithOptionsSelect,
    },

    mixins: [
        NotificationVariableMixin,
    ],

    props: {
        slackChannels: { type: Array, default: () => [] },
        value: { type: Object },
    },

    data() {
        return {
            is_loaded: false,
            is_saving: false,
            is_file_box_checked: false,
            item: null,
            language: 'en',
            availableLanguages: [
                {
                    value: 'fr',
                    text: this.translate('The content will be in {language}', { language: this.translate('french') }),
                    codeFlag: 'fr',
                },
                {
                    value: 'en',
                    text: this.translate('The content will be in {language}', { language: this.translate('english') }),
                    codeFlag: 'gb',
                },
            ],
            notificationType: 'email',
            slackEditorOptions: {
                modules: {
                    toolbar: false,
                },
                formats: [],
            },
            hours: [
                '00:00 h',
                '01:00 h',
                '02:00 h',
                '03:00 h',
                '04:00 h',
                '05:00 h',
                '06:00 h',
                '07:00 h',
                '08:00 h',
                '09:00 h',
                '10:00 h',
                '11:00 h',
                '12:00 h',
                '13:00 h',
                '14:00 h',
                '15:00 h',
                '16:00 h',
                '17:00 h',
                '18:00 h',
                '19:00 h',
                '20:00 h',
                '21:00 h',
                '22:00 h',
                '23:00 h',
            ],
        };
    },

    computed: {
        getRecipients() {
            if (!this.item.company_email_template_recipient.length) {
                return [{
                    company_user: {
                        id: 10000,
                        firstname: this.translate('All task recipients'),
                        lastname: '',
                    },
                }];
            }

            return this.item.company_email_template_recipient;
        },
        isProbationaryPeriod() {
            return ['End of initial probationary period', 'End of renewed probationary period'].includes(this.item.title);
        },
        sendingDateTimeOptions() {
            return this.hours.map((hour) => ({
                id: hour,
                name: hour,
            }));
        },

        slackChannelOptions() {
            return this.slackChannels.map((channel) => ({
                id: channel.id,
                name: channel.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    created() {
        this.item = this.value;
        if (this.item.company_file && this.item.company_file.original_name) {
            this.is_file_box_checked = true;
        }

        if (this.shared.session.company.company_language.key === 'fr') {
            this.language = 'fr';
        }

        this.is_loaded = false;
        this.is_saving = false;
        this.errors.clear();

        this.is_loaded = true;
    },

    methods: {
        filterSlackVariables(vars) {
            return [...vars.filter((variable) => !variable.value.includes('recipient')), { key: 'wave', value: 'wave' }, { key: 'tada', value: 'tada' }];
        },

        openAddSenderModal() {
            this.$refs.searchUserPanel.init({
                onSelect: (item) => {
                    this.item.company_sender_id = item.id;
                    this.item.company_sender = item;
                    this.$refs.modalableSearchUser.close();
                },
            });
            this.$refs.modalableSearchUser.open();
        },

        async validateEmail() {
            await this.$validator.validateAll();
            if (this.errors.any()) {
                this.$Utils.firstParentScrollableToTop(this.$el);
                return true;
            }
            return false;
        },

        openAddRecipientsModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addRecipients,
                selected: this.item.company_email_template_recipient,
            });
            this.$refs.modalableSearchRole.open();
        },

        addRecipients(participants) {
            this.item.company_email_template_recipient = participants;
        },

        onRemoveRecipient(participant) {
            this.item.company_email_template_recipient = this.item.company_email_template_recipient.filter(
                (item) => (
                    item.company_role_id !== participant.company_role_id
                    || item.company_user_id !== participant.company_user_id
                ),
            );
        },

        resetSender() {
            this.item.company_sender_id = null;
            this.item.company_sender = null;
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	label {
		font-family: $lato-bold;
        body:not(.old-ui) &{
            font-family: var(--poppins);
            font-weight: 600;
        }
	}

	.trash {
		width: 35px;
		height: 35px;
		position: absolute;
		top: -15px;
		right: -15px;
		background: #fff;
		box-sizing: border-box;
		padding: 3px;
		border: 1px solid #ebebeb;
		border-radius: 50%;
		text-align: center;
		padding: 8px;

		svg {
			width: 15px !important;
			height: 15px !important;
		}
	}

	.inline-selects {
		display: flex;

		.select-wrapper {
			width: 100%;
			margin-right: 0.5em;

			&:last-child {
				margin-right: 0;
			}
		}
	}
</style>
