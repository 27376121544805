<template>
    <div
        ref="button"
        class="ht-tooltip"
        :class="{'no-tip': noTip, inverted, show}"
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip"
    >
        <slot />
        <div
            ref="tooltip"
            class="ht-tooltip-target"
            :class="alignPositionClasses"
            :style="{'--offset' : `${offset}px`}"
        >
            <div
                :class="{'has-supporting': $slots.supporting}"
                class="ht-tooltip-target-inner"
            >
                <div class="ht-tooltip-content">
                    <slot name="target" />
                </div>
                <div
                    v-if="$slots.supporting"
                    class="ht-tooltip-supporting"
                >
                    <slot name="supporting" />
                </div>
                <div
                    v-if="!noTip"
                    class="ht-tooltip-tip"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { computePosition, autoUpdate, offset } from '@floating-ui/dom';

export default {
    props: {
        align: {
            type: String,
            required: false,
            default: 'center',
            validator(value) {
                return ['start', 'end', 'center'].includes(value);
            },
        },
        position: {
            type: String,
            required: false,
            default: 'block-end',
            validator(value) {
                return [
                    'block-end',
                    'block-start',
                    'inline-end',
                    'inline-start',
                ].includes(value);
            },
        },
        offset: {
            type: Number,
            required: false,
            default: 8,
        },
        crossOffset: {
            type: Number,
            required: false,
            default: 0,
        },
        noTip: {
            type: Boolean,
            required: false,
            default: false,
        },
        inverted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        show: false,
        cleanup: null,
    }),
    computed: {
        alignPositionClasses() {
            return `align-${this.align} position-${this.position}`;
        },
        floatingPlacement() {
            const positionToPlacement = {
                'block-end': 'bottom',
                'block-start': 'top',
                'inline-end': 'right',
                'inline-start': 'left',
            }[this.position];

            const alignToPlacement = this.align === 'center' ? '' : `-${this.align}`;
            return `${positionToPlacement}${alignToPlacement}`;
        },
    },
    beforeDestroy() {
        if (this.cleanup) this.cleanup();
    },
    methods: {
        showTooltip() {
            this.show = true;
            const { button, tooltip } = this.$refs;

            this.cleanup = autoUpdate(button, tooltip, () => {
                computePosition(button, tooltip, {
                    middleware: [offset({ mainAxis: this.offset, crossAxis: this.crossOffset })],
                    strategy: 'fixed',
                    placement: this.floatingPlacement,
                }).then(({ x, y }) => {
                    Object.assign(tooltip.style, {
                        left: `${x}px`,
                        top: `${y}px`,
                    });
                });
            });
        },
        hideTooltip() {
            this.show = false;
            if (this.cleanup) this.cleanup();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.ht-tooltip {
    position: relative;
    --bg-color: var(--fill-primary);
    &.no-tip .ht-tooltip-target {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }
    &.show {
        .ht-tooltip-target {
            transform: scale(1);
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }
    }
    &.inverted {
        --bg-color: var(--fill-inversed-primary);
        .ht-tooltip-target::after,
        .ht-tooltip-tip::after {
            content: none;
            box-shadow: none;
        }
        .ht-tooltip-content, .ht-tooltip-supporting {
            color: var(--fill-primary);
        }
    }
}

.ht-tooltip-target {
    position: fixed;
    width: max-content;
    transform: scale(0.9);
    transition: var(--transition-s);
    transition-property: transform, opacity;
    opacity: 0;
    z-index: 1000;
    pointer-events: none;
    visibility: hidden;
    overflow: visible;
}

.ht-tooltip-target-inner {
    max-width: 328px;
    padding: 8px 16px;
    border-radius: var(--radius);
    // Tooltip Background
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: var(--radius);
        background: var(--bg-color);
        z-index: -1;
    }
    // Tooltip Shadows
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: var(--radius);
        box-shadow: var(--shadow-1);
        z-index: -3;
    }
    &.has-supporting {
        padding: 12px;
        .ht-tooltip-content {
            @include ht-body-semi;
            color: var(--text-primary);
            margin-bottom: 4px;
        }
    }
}

.ht-tooltip-tip {
    position: absolute;
    width: 12px;
    height: 12px;
    background: var(--bg-color);
    border-radius: 2px;
    transform: rotate(-45deg);
    box-shadow: 1px -1px 0px 0px rgba(47, 43, 67, 0.10) inset;
    z-index: -2;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 11px;
        height: 11px;
        border-radius: 1px;
        box-shadow: -0.5px 0.5px 3px 0px rgba(47, 43, 67, 0.1);
    }
}

.ht-tooltip-content {
    @include ht-caption;
}

.ht-tooltip-supporting {
    @include ht-caption;
    color: var(--text-secondary);
}

/***** POSITIONS AND ALIGNMENT *****/

.ht-tooltip-target{
    /***** Position Block *****/
    &.position-block-end {
        .ht-tooltip-tip {
            top: -6px;
        }
        &.align-start {
            .ht-tooltip-tip {
                left: 11px;
            }
        }
        &.align-end {
            .ht-tooltip-tip {
                right: 11px;
            }
        }
        &.align-center {
            .ht-tooltip-tip {
                left: 50%;
                transform: translateX(-50%) rotate(-45deg);
            }
        }
    }
    &.position-block-start {
        .ht-tooltip-tip {
            bottom: -6px;
        }
        &.align-start {
            .ht-tooltip-tip {
                left: 11px;
            }
        }
        &.align-end {
            .ht-tooltip-tip {
                right: 11px;
            }
        }
        &.align-center {
            .ht-tooltip-tip {
                left: 50%;
                transform: translateX(-50%) rotate(-45deg);
            }
        }
    }

    /***** Position Inline *****/
    &.position-inline-end {
        .ht-tooltip-tip {
            top: 50%;
            left: -5px;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
    &.position-inline-start {
        .ht-tooltip-tip {
            top: 50%;
            right: -5px;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}
</style>
