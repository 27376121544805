<template>
    <div class="section-list">
        <template v-if="loading">
            <div
                v-for="i in [1, 2, 3]"
                class="section-list-add"
            >
                <Skeleton
                    :key="i"
                    :loading="true"
                    width="150px"
                    height="20px"
                />
            </div>
        </template>
        <template>
            <Draggable
                class="dropzone"
                @end="orderChanged"
            >
                <div
                    v-for="(section, index) in sections"
                    class="section-list-item"
                    :class="{'active': index === selectedIndex}"
                    @click="$emit('on-select', index)"
                >
                    <FontAwesomeIcon
                        :icon="['fas', 'bars']"
                        class="handler"
                    />

                    <span>{{ section.translations.name[defaultLanguageKey] }}</span>
                </div>
            </Draggable>

            <div
                class="section-list-add"
                @click="$refs.modalable.open()"
            >
                <t>Add</t>

                <FontAwesomeIcon :icon="['fal', 'plus']" />
            </div>
        </template>

        <Modalable
            ref="modalable"
            class="modalable-1 medium"
            :mode="2"
            @onClose="$emit('on-close-modalable')"
        >
            <SectionTemplateModal
                @on-select="onSelectTemplate"
            />
        </Modalable>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import orderBy from 'lodash.orderby';
import { Skeleton } from 'vue-loading-skeleton';
import Modalable from '@/components/globals/Modalable.vue';
import SectionTemplateModal from '@/components/settings/EnterprisePage/Form/Section/SectionTemplateModal.vue';

export default {
    name: 'SectionList',

    components: {
        SectionTemplateModal,
        Modalable,
        Draggable,
        Skeleton,
    },

    props: {
        sections: {
            type: Array,
            required: true,
        },

        selectedIndex: {
            type: Number,
            required: true,
        },

        loading: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            defaultLanguageKey: this.shared.session.company.company_language.key,
        };
    },

    methods: {
        onSelectTemplate(template) {
            const { content, css } = template;

            this.$emit('on-add-new-section', {
                content,
                active_custom_style: !!content,
                is_full_page: template.fullPageBorder,
                custom_style: css || null,
            });

            this.$refs.modalable.close();
        },

        orderChanged(event) {
            const { newIndex, oldIndex } = event;
            if (newIndex === oldIndex) {
                return;
            }

            const movedSections = newIndex > oldIndex
                ? this.handleMoveDown(newIndex, oldIndex)
                : this.handleMoveUp(newIndex, oldIndex);

            this.$emit('on-update-orders', orderBy(movedSections, (section) => section.order));
        },

        handleMoveDown(newIndex, oldIndex) {
            return this.sections.map((section, index) => {
                if (index === oldIndex) {
                    return {
                        ...section,
                        order: newIndex,
                    };
                }

                if (index > oldIndex && index <= newIndex) {
                    return {
                        ...section,
                        order: index - 1,
                    };
                }

                return {
                    ...section,
                    order: index,
                };
            });
        },

        handleMoveUp(newIndex, oldIndex) {
            return this.sections.map((section, index) => {
                if (index === oldIndex) {
                    return {
                        ...section,
                        order: newIndex,
                    };
                }

                if (index < oldIndex && index >= newIndex) {
                    return {
                        ...section,
                        order: index + 1,
                    };
                }

                return {
                    ...section,
                    order: index,
                };
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.section-list {
    .dropzone {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &-item, &-add {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        border: 1px solid #E1E1E1;
        border-radius: 8px;
        padding: 16px;
        cursor: pointer;
        background-color: white;

        &:hover {
            border-color: var(--branding-color);
        }
    }

    &-item {
        &.active {
            border-color: var(--branding-color);
        }
    }

    &-add {
        margin-top: 24px;
        justify-content: space-between;
    }
}
</style>
